// imports

import { useParams } from "react-router-dom"
import { useSetRecoilState } from "recoil"
import { Segment } from "semantic-ui-react"

import { drawerIsOpen } from "../../recoil/Atoms"
import LevelSelector from "./LevelSelector"
import PlanificationSelector from "./PlanificationSelector"
import ProgramSelector from "./ProgramSelector"
import ChoicesConfirm from "./ChoicesConfirm"
import { useEffect, useLayoutEffect } from "react"
import { Mixpanel } from "../../utils/mixpanel"
import RaceSelector from "./RaceSelector"
import RaceOrPlanSelector from "./RaceOrPlanSelector"

const ProgramSelectPage = () => {
  const setRecoilDrawerIsOpen = useSetRecoilState(drawerIsOpen)

  useEffect(() => {
    Mixpanel.track("Select Planification Page Viewed")
  }, [])
  useLayoutEffect(() => {
    return () => {
      setRecoilDrawerIsOpen(false)
    }
  }, [setRecoilDrawerIsOpen])

  let { feature = "program" } = useParams()
  let stepsComponents = {
    program: <ProgramSelector />,
    race: <RaceSelector />,
    planification: <PlanificationSelector />,
    level: <LevelSelector />,
    confirm: <ChoicesConfirm />,
    choose: <RaceOrPlanSelector />,
  }
  return (
    <div className="page select program">
      <Segment
        basic
        style={{
          padding: 0,
          paddingTop: "30%",
        }}
      >
        {stepsComponents[feature]}{" "}
      </Segment>
    </div>
  )
}

export default ProgramSelectPage
