// imports

import { Trans, plural } from "@lingui/macro"
import { i18n } from "@lingui/core"
import { StyledButton, StyledList } from "../../../../Styled"
import { Icon, List } from "semantic-ui-react"
import { formatSecondsToTimeSmart } from "../../../utils"
import HelpIcon from "../../../../shared/HelpIcon"
import Help from "../../../../shared/Help"

const RoundsForTimeHelp = ({ rounds, exercises }) => {
  return (
    <HelpIcon
      style={{ display: "inline" }}
      drawerContent={
        <Help
          title={<Trans>rounds for time</Trans>}
          description={
            <>
              <p>
                <Trans>You must do this workout as fast as possible.</Trans>
              </p>
              <p>
                <Trans>
                  Once you've done all the reps for a given exercice click{" "}
                  <StyledButton
                    style={{
                      verticalAlign: "text-bottom",
                    }}
                    onClick={() => {}}
                  >
                    <Icon name="check" />
                    <Trans>Done</Trans>
                  </StyledButton>
                  to go to the next exercise
                </Trans>
              </p>
              <p>
                <Trans>
                  You can break your sets when necessary (but do not stop the
                  stopwatch), the goal being to limit the rest time as much as
                  possible.
                </Trans>
              </p>
            </>
          }
          details={
            <>
              {plural(rounds, {
                one: "1 round of :",
                other: "# rounds of :",
              })}

              <StyledList inverted style={{ marginTop: "0.5em" }}>
                {exercises.map(({ data: { name }, reps, work }, index) => (
                  <List.Item key={index}>
                    <List.Icon
                      name="dot circle outline"
                      className={reps ? "secondary" : "primary"}
                    />
                    <List.Content>
                      {reps && (
                        <Trans>
                          {reps} reps of {name && i18n._(name)}
                        </Trans>
                      )}

                      {work && (
                        <Trans>
                          {`${formatSecondsToTimeSmart(work)}`} of{" "}
                          {name && i18n._(name)}
                        </Trans>
                      )}
                    </List.Content>
                  </List.Item>
                ))}
              </StyledList>
            </>
          }
        />
      }
    />
  )
}

export default RoundsForTimeHelp
