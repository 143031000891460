// imports

import { t } from "@lingui/macro"
import { Container, List, Image, Icon } from "semantic-ui-react"
import { StyledList } from "../Styled"

import { useNavigate } from "react-router-dom"
import { useRecoilValue } from "recoil"
import { exercises } from "../../recoil/Atoms"
import NextBackButtons from "../shared/NextBackButtons"
import { movementsTranslations } from "../../locales/movementsKeys"
import { i18n } from "@lingui/core"
import { useEffect, useState } from "react"
import { Mixpanel } from "../../utils/mixpanel"

import useAudio from "../../hooks/useAudio"
import SessionCard from "../Session/OpenSessions/SessionCard"

const LibraryPage = (props) => {
  const recoilExercises = useRecoilValue(exercises)
  const navigate = useNavigate()

  const [exercisesList, setExercisesList] = useState([])

  const { startAudioContext } = useAudio()

  useEffect(() => {
    setExercisesList(recoilExercises)
  }, [recoilExercises])

  useEffect(() => {
    Mixpanel.track("Library Page Opened")
  }, [])

  const handleClick = (id) => {
    startAudioContext()
    navigate(`/exercise/${id}`)
  }

  return (
    <Container className="page library list">
      <SessionCard title={t`Library`} keyName="library" />

      {!exercisesList.length ? (
        //the display of the whole list is long so we display the fist 20 waiting for the rest
        <StyledList divided verticalAlign="middle" inverted>
          {recoilExercises
            .slice(0, 20)
            .map(({ id, name, videoId, videoThumbnailTime: time }) => (
              <List.Item
                key={id}
                onClick={() => handleClick(id)}
                style={{ padding: "0.8em 0" }}
              >
                <List.Content
                  floated="right"
                  style={{ margin: "1.5em 0.25rem 0 0" }}
                >
                  <Icon name="chevron right" />
                </List.Content>
                <Image
                  avatar
                  src={
                    "https://image.mux.com/" +
                    videoId +
                    `/thumbnail.jpg?${
                      time ? `time=${time}&` : ""
                    }width=70&height=70&fit_mode=crop`
                  }
                />
                <List.Content style={{ height: 50 }}>
                  <List.Header style={{ textTransform: "capitalize" }}>
                    {movementsTranslations[id]?.["name"] &&
                    i18n._(movementsTranslations[id]?.["name"]).endsWith(" R")
                      ? i18n
                          ._(movementsTranslations[id]?.["name"])
                          .slice(0, -2)
                          .replace(" R", "")
                      : i18n._(movementsTranslations[id]?.["name"])}
                  </List.Header>
                </List.Content>
              </List.Item>
            ))}
        </StyledList>
      ) : (
        <StyledList divided verticalAlign="middle" inverted>
          {exercisesList.map(
            ({ id, name, videoId, videoThumbnailTime: time }) => (
              <List.Item
                key={id}
                onClick={() => handleClick(id)}
                style={{ padding: "0.8em 0" }}
              >
                <List.Content
                  floated="right"
                  style={{ margin: "1.5em 0.25rem 0 0" }}
                >
                  <Icon name="chevron right" />
                </List.Content>
                <Image
                  avatar
                  src={
                    "https://image.mux.com/" +
                    videoId +
                    `/thumbnail.jpg?${
                      time ? `time=${time}&` : ""
                    }width=70&height=70&fit_mode=crop`
                  }
                />
                <List.Content style={{ height: 50 }}>
                  <List.Header style={{ textTransform: "capitalize" }}>
                    {movementsTranslations[id]?.["name"] &&
                    i18n._(movementsTranslations[id]?.["name"]).endsWith(" R")
                      ? i18n
                          ._(movementsTranslations[id]?.["name"])
                          .slice(0, -2)
                          .replace(" R", "")
                      : i18n._(movementsTranslations[id]?.["name"])}
                  </List.Header>
                </List.Content>
              </List.Item>
            )
          )}
        </StyledList>
      )}
      <NextBackButtons backOnly="true" />
    </Container>
  )
}

export default LibraryPage
