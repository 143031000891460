import { SessionsPageHeader } from "./SessionsPageHeader"
import { SessionListItem } from "./SessionListItem"
// imports

import { Trans } from "@lingui/macro"
import { i18n } from "@lingui/core"
import { useRecoilValue } from "recoil"
import {
  hasSubscription,
  planificationWeek,
  userPlanification,
} from "../../recoil/Atoms"
import { Container } from "semantic-ui-react"
import { StyledHeader, StyledList, StyledSegment } from "../Styled"
import { Link, useNavigate, useParams } from "react-router-dom"
import NextBackButtons from "../shared/NextBackButtons"
import { planificationInfos } from "../../locales/SessionInfos"
import RenfoRun from "../shared/Renforun"
import { useFirstname } from "../../hooks/useUserInfo"
import CTASubscribe from "../shared/CTASubscribe"
import { Mixpanel } from "../../utils/mixpanel"

import SessionsPageHelpIcon from "./HelpIcons/SessionsPageHelpIcon"
import { useEffect } from "react"

const SessionsPage = () => {
  const navigate = useNavigate()
  const { week } = useParams()

  const recoilWeek = useRecoilValue(planificationWeek(week))
  const recoilHasSubscription = useRecoilValue(hasSubscription)
  const { freeDurationWeeks } = useRecoilValue(userPlanification) || {}

  const firstname = useFirstname()

  useEffect(() => {
    if (recoilWeek.code === "no-planification") {
      //no current subscription
      window.location.href = "/"
    }

    console.log("recoilWeek.code " + recoilWeek.code)
    if (recoilWeek.code === "no-subscription") {
      //no current subscription
      navigate("/subscribe", { replace: true })
    }
  }, [navigate, recoilWeek.code])

  const {
    name,
    weekNumber,
    sessions = [],
    weeksCount = null,
    weekIndex = null,
    raceDate,
    raceName,
    program,
  } = recoilWeek || {}

  //not translated
  const subscribeMessage = (
    <>
      <p>
        <Trans>
          {firstname} ! on espère que tu as apprécié ces deux semaines gratuites
          avec <RenfoRun /> !
        </Trans>{" "}
        <Trans>Que faire maintenant ?</Trans>
      </p>
      <p>
        <Trans>
          Tu peux terminer le plan d'initiation pour continuer à habituer ton
          corps aux exercices, ou enchainer avec le plan "Continu" si tu te sens
          à l'aise.
        </Trans>
      </p>
      <p>
        <Trans>
          Si tu as une course dans les 16 ou 10 semaines à venir, tu peux
          attaquer ta prépa pour arriver prêt comme jamais le jour J !
        </Trans>
      </p>
    </>
  )
  let trialEnded = false

  if (!recoilHasSubscription) {
    if (freeDurationWeeks && weekNumber > freeDurationWeeks) {
      trialEnded = true
      Mixpanel.track("Trial Ended Page Viewed")
    }
  }

  let sessionsInfoKey = `${name}-${("000" + weekIndex).slice(-3)}`

  if ([4, 8, 12].includes(weekIndex) && weekNumber < 4) {
    // si la planif vient de commencer on ne parle pas de récup
    sessionsInfoKey = ""
  }

  const planificationName =
    raceDate && raceName ? raceName : name ? i18n._(name) : ""

  return (
    <Container className="page onramp list" textAlign="left">
      <SessionsPageHeader
        name={name}
        weekNumber={weekNumber}
        weeksCount={weeksCount}
        planificationName={planificationName}
        program={program}
        hasSessions={!!sessions.length}
      />
      <div
        style={{
          marginTop: 7,
          fontSize: "1.5em",
          textAlign: "center",
        }}
      >
        <SessionsPageHelpIcon
          iconStyle={{
            //   position: "absolute",
            //  top: 0,
            //right: 10,
            fontSize: "2em",
          }}
          helpKey={name === "race-ready-base" ? "race-ready" : name}
          weeksCount={weeksCount}
          title={planificationName}
        />
      </div>
      {planificationInfos[sessionsInfoKey] && (
        <StyledSegment
          info="true"
          // style={{ marginLeft: "0.5em", marginRight: "0.5em" }}
        >
          {i18n._(planificationInfos[sessionsInfoKey])}
        </StyledSegment>
      )}

      {trialEnded && (
        <>
          <StyledSegment info="true" data-testid="trial-end-message">
            {subscribeMessage}
          </StyledSegment>
          <CTASubscribe />
        </>
      )}

      {sessions.length !== 0 && (
        <StyledList
          inverted
          divided
          style={{
            padding: "0 0 .25em 0",
          }}
        >
          {sessions.map((session, index) => (
            <SessionListItem
              key={session.id}
              session={session}
              index={index}
              trialEnded={trialEnded}
            />
          ))}
        </StyledList>
      )}

      {sessions.length === 0 && name && (
        <StyledHeader>
          <Trans>
            Good job, you can now pick a{" "}
            <Link to={"/select-program/confirm?reset-planification=1"}>
              new one!
            </Link>
          </Trans>
        </StyledHeader>
      )}

      <NextBackButtons backOnly backUrl="/" />
    </Container>
  )
}

export default SessionsPage
