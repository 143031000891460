import "react-circular-progressbar/dist/styles.css"

import { useRecoilValue } from "recoil"
import { countAudioPlayer, goAudioPlayer } from "../../../../recoil/Atoms"
let previous = 0
const CountDownAudio = ({ seconds }) => {
  const recoilCountAudioPlayer = useRecoilValue(countAudioPlayer)
  const recoilGoAudioPlayer = useRecoilValue(goAudioPlayer)

  const play = (sound) => {
    let current = null
    if (sound === "count") {
      current = recoilCountAudioPlayer
    } else {
      current = recoilGoAudioPlayer
    }
    if (current) {
      current.pause()
      current.currentTime = 0
      current.play()
    }
  }

  if ([3, 2, 1].includes(seconds) && previous !== seconds) {
    play("count")
  }

  if (seconds === 0) {
    play("go")
  }
  previous = seconds

  return <></>
}

export default CountDownAudio
