import { useRecoilValue } from "recoil"
import { Icon } from "semantic-ui-react"
import { bookmarkedSessionsIds } from "../../../../recoil/Atoms"
import { useSetBookmarks } from "../../../../hooks/appfetch"
import { t } from "@lingui/macro"
import { toast } from "react-toastify"
import { secondaryColor } from "../../../Styled"
import { Mixpanel } from "../../../../utils/mixpanel"

export function Bookmark({
  style,
  sessionInfos: { id: sessionId, custom_name },
}) {
  const setUserBookmarks = useSetBookmarks()
  const recoilBookmarkedSessionsIds = useRecoilValue(bookmarkedSessionsIds)
  const isBookmarked = recoilBookmarkedSessionsIds.includes(sessionId)

  const handleClick = () => {
    let sessions
    let successMessage
    if (isBookmarked) {
      sessions = [...recoilBookmarkedSessionsIds].filter(
        (id) => id !== sessionId
      )
      successMessage = t`"${custom_name}" removed from your favorites`

      Mixpanel.track("Removed from favorites", {
        "Session Custom Name": custom_name,
      })
    } else {
      sessions = [...recoilBookmarkedSessionsIds, sessionId]
      successMessage = t`"${custom_name}" added to your favorites`

      Mixpanel.track("Added to favorites", {
        "Session Custom Name": custom_name,
      })
    }
    setUserBookmarks({ sessions })
    toast.success(successMessage, { autoClose: 1000 })
  }

  return (
    <Icon
      data-testid={!isBookmarked ? "bookmark-add" : "bookmark-del"}
      className={`star${!isBookmarked ? " outline" : ""}`}
      style={{ ...style, color: secondaryColor }}
      onClick={handleClick}
    />
  )
}
