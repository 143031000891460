import "fomantic-ui-css/semantic.min.css"
import { BrowserRouter as Router } from "react-router-dom"
import "./App.css"
import AppDrawer from "./components/App/AppDrawer"
import AppRoutes from "./components/App/AppRoutes"
import MenuTop from "./components/App/MenuTop"
import AuthProvider from "./components/auth/AuthProvider"
import { GlobalStyle, StyledToastContainer } from "./components/Styled"
import "react-toastify/dist/ReactToastify.css"
import { useServiceWorker } from "./hooks/useServiceWorker"
import { useEffect } from "react"

import { Mixpanel } from "./utils/mixpanel"
import { version } from "./version"

function App() {
  const { waitingWorker, showReload, reloadPage } = useServiceWorker()

  // decides when to show the toast
  useEffect(() => {
    if (showReload && waitingWorker) {
      console.log("flaging update needed")
      localStorage.setItem("mustUpdatePwa", "1")
      Mixpanel.track("App Update Flag Set")
    } else {
      //     toast.dismiss()
    }
  }, [waitingWorker, showReload, reloadPage])

  useEffect(() => {
    Mixpanel.track("App Opended", { Version: version })
    Mixpanel.people.set({
      "App Last Opened": new Date(),
    })
  }, [])

  return (
    <div className="App">
      <GlobalStyle />
      <Router>
        <AuthProvider>
          <MenuTop />
          <AppRoutes />
        </AuthProvider>
        <AppDrawer />
      </Router>
      <StyledToastContainer
        theme="colored"
        autoClose={5000}
        position="top-center"
      />
    </div>
  )
}

export default App
