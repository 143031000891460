import dayjs from "dayjs"
import { useRecoilValue } from "recoil"

import { Container, Divider, Icon } from "semantic-ui-react"
import { useLogout } from "../../hooks/AuthHooks"
import { analysisFocus, userLevel, userProgram } from "../../recoil/Atoms"
import { Mixpanel } from "../../utils/mixpanel"
import { StyledButton, StyledHeader, StyledSegment } from "../Styled"

import { useUserId } from "../../hooks/useUserInfo"
import { Trans } from "@lingui/macro"

const AppError = ({ error, resetErrorBoundary }) => {
  const logout = useLogout()
  let recoilUserLevel = useRecoilValue(userLevel)
  let recoilUserProgram = useRecoilValue(userProgram)
  let recoilUserFocus = useRecoilValue(analysisFocus)
  let userId = useUserId()
  return (
    <Container className="page profile">
      <StyledHeader className={"page-title"} textAlign="center">
        <span style={{ display: "inline-block" }}>
          <Icon className="dizzy outline" />
          <Trans>oups</Trans>...
        </span>
      </StyledHeader>
      <StyledSegment basic>
        <StyledHeader textAlign="center">
          Apparement il y a un évenement non prévu...
        </StyledHeader>
        <div>
          Peux tu nous envoyer une capture d'écran à{" "}
          <a href="mailto:app@renforun.com">app@renforun.com</a>
        </div>
        <div> On va faire le max pour corriger ça vite !</div>
        <Divider />
        {Mixpanel.track("Error", {
          "Message": error.message,
          "Page": window.location.href,
          "User Id": userId,
          "Playground": recoilUserProgram,
          "Niveau": recoilUserLevel,
          "Focus": recoilUserFocus,
        })}
        <div> Erreur : {error.message}</div>
        <div style={{ overflowWrap: "break-word" }}>{window.location.href}</div>
        <div> {dayjs().format()}</div>
        <div>-{userId}-</div>
      </StyledSegment>

      <StyledButton
        onClick={() => {
          resetErrorBoundary()
          window.location = "/"
        }}
      >
        Aller à l'accueil
      </StyledButton>

      <StyledButton
        onClick={() => {
          resetErrorBoundary()
          logout()
        }}
      >
        Se deconnecter
      </StyledButton>
    </Container>
  )
}

export default AppError
