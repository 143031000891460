import { useRecoilState, useRecoilValue } from "recoil"
import { drawerIsOpen, drawerContent, drawerEvents } from "../../recoil/Atoms"
import { StyledDrawer } from "../Styled"
import { Icon } from "semantic-ui-react"

function AppDrawer() {
  const [recoilDrawerIsVisible, setRecoilDrawerIsVisible] =
    useRecoilState(drawerIsOpen)
  const recoilDrawerContent = useRecoilValue(drawerContent)
  const recoilDrawerEvents = useRecoilValue(drawerEvents)

  const closeDrawer = () => {
    setRecoilDrawerIsVisible(false)
  }

  const buttonCloseDrawer = () => {
    setRecoilDrawerIsVisible(false)
    recoilDrawerEvents?.onButtonClose?.()
  }

  return (
    <StyledDrawer
      isVisible={recoilDrawerIsVisible}
      onClose={closeDrawer}
      className="drawer"
      hideScrollbars={false}
    >
      <div className="info">info</div>
      <div style={{ paddingTop: "0em", marginBottom: 0 }}>
        {recoilDrawerContent}
      </div>

      <Icon
        circular
        name="close"
        onClick={buttonCloseDrawer}
        data-testid="drawer-close-button"
        style={{
          position: "absolute",
          top: 10,
          right: 10,
          zIndex: 101,
          padding: 5,
          boxShadow: "none",
        }}
      />
    </StyledDrawer>
  )
}

export default AppDrawer
