import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar"
import "react-circular-progressbar/dist/styles.css"
import {
  primaryColor,
  bgColorLight,
  ambiantColor,
  ambiantColorRGB,
  secondaryColor,
} from "../../../Styled"
import { Icon } from "semantic-ui-react"
import { formatMinutesAndSeconds } from "../../utils"
import { useMediaQuery } from "react-responsive"

const TimerDisplay = ({ value, minutes, seconds, isRunning, type }) => {
  const isPortrait = useMediaQuery({ query: "(orientation: portrait)" })
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" })
  return (
    <>
      <style>
        {`
			.blinking {
				animation: mymove 0.75s infinite alternate;
			}

			@keyframes mymove {
				from {opacity:0;}
				to {opacity: 1;}
			}
		`}
      </style>
      <CircularProgressbarWithChildren
        value={value}
        strokeWidth={2}
        styles={buildStyles({
          pathColor: type === "work" ? primaryColor : secondaryColor,
          trailColor: bgColorLight,
          pathTransition:
            value === 100 || value === 0
              ? "none"
              : "stroke-dashoffset 1.0s ease 0s",
        })}
      >
        <div style={{ position: "relative" }}>
          <div
            style={{
              color: ambiantColor,
              fontSize: isPortrait || !isTabletOrMobile ? "2rem" : "1.2em",
              marginTop: isPortrait || !isTabletOrMobile ? -25 : -10,
              marginBottom: isPortrait || !isTabletOrMobile ? 5 : 0,
              fontWeight: "bold",
            }}
            className={!isRunning ? "blinking" : ""}
          >
            {formatMinutesAndSeconds(minutes, seconds)}
          </div>
          {minutes + seconds !== 0 && (
            <Icon
              style={{
                color: `rgb(${ambiantColorRGB} / 75%)`,
                position: "absolute",
                left: "50%",
                transform: "translateX(-50%)",
                bottom: -20,
              }}
              name={isRunning ? "pause" : "play"}
            />
          )}
        </div>
      </CircularProgressbarWithChildren>
    </>
  )
}

export default TimerDisplay
