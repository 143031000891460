import { i18n } from "@lingui/core"
import { Trans } from "@lingui/macro"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { Icon, Label, List } from "semantic-ui-react"
import useAudio from "../../hooks/useAudio"
import useSessionCategory from "../../hooks/useSessionTitle"
import { upperFirst } from "../../utils"
import {
  ambiantColor,
  defaultTextColor,
  primaryColor,
  primaryColorAlpha,
  secondaryColor,
} from "../Styled"
import Focuses from "./Focuses"

export function SessionListItem({
  session: {
    id,
    isNext,
    important,
    high_impact,
    duration,
    focusStats,
    done,
    custom_name,
    planificationName,
  },
  index,
  trialEnded,
  displayImportant = true,
  isLocked = false,
  freeSessionLocked = false,
  showBookmarkIcon = false,
  showCategory = false,
}) {
  const [nextBorder, setNextBorder] = useState(null)
  const [, setNextScale] = useState(1)
  const navigate = useNavigate()
  const { startAudioContext } = useAudio()

  const getSessionCategory = useSessionCategory()

  useEffect(() => {
    setTimeout(() => {
      setNextScale(1.02)
      setNextBorder(`1px solid ${primaryColorAlpha}`)
    }, 1000)
  }, [])

  const handleClick = (id) => {
    if (trialEnded) {
      navigate(`/subscribe`)
      return
    }

    startAudioContext()
    navigate(`/session/${id}`)
  }

  return (
    <List.Item
      key={id}
      onClick={() => handleClick(id)}
      style={{
        marginTop: "1em",
        padding: "2rem 1.4rem 2rem 0.5rem",
        position: "relative",
        border: isNext ? `${nextBorder}` : `1px solid rgb(211 211 211 / 10%)`,
        borderRadius: "0.5em",
        transition: "all 0.6s ease", // transform: isNext ? `scale(${nextScale})` : "1",
        overflow: "hidden",
      }}
    >
      <List.Content
        style={{
          position: "absolute",
          right: 0,
          top: "50%",
          transform: "translateY(-50%)",
        }}
      >
        <Icon name="chevron right" />
      </List.Content>
      <List.Content>
        <List.Header
          style={{
            textTransform: "uppercase",
            fontSize: "1.2rem",
            color: defaultTextColor,
          }}
        >
          {!!custom_name ? (
            <>
              {i18n._(custom_name)}

              {showCategory && (
                <span
                  style={{
                    fontSize: "0.9em",
                    color: "#b9b9b9",
                    textTransform: "initial",
                    marginRight: "1em",

                    fontWeight: "normal",
                  }}
                >
                  {" - "}
                  {upperFirst(i18n._(getSessionCategory(planificationName)))}
                </span>
              )}
            </>
          ) : (
            <>
              {" "}
              <Trans>Session {index + 1}</Trans>
            </>
          )}

          {!!done && (
            <Icon
              name="check"
              style={{
                verticalAlign: "super",
                marginLeft: "0.8em",
                color: primaryColorAlpha,
              }}
            />
          )}
          {!!isLocked && (
            <Label
              color="blue"
              corner="right"
              icon="lock"
              data-testid="label-locked"
            />
          )}
          {!!freeSessionLocked && (
            <Label color="teal" corner="right" icon="check" />
          )}
          {showBookmarkIcon && !isLocked && (
            <Icon
              name="star"
              style={{
                position: "absolute",
                right: 5,
                top: 10,
                color: secondaryColor,
              }}
            />
          )}
        </List.Header>
      </List.Content>

      <List.Content
        style={{
          paddingTop: "1em",
        }}
      >
        {!!duration && (
          <span
            style={{
              display: "inline-block",
              marginRight: "0.5em",
            }}
          >
            <Icon
              className={"stopwatch "}
              style={{
                color: ambiantColor,
                marginRight: 1,
              }}
            />
            <span>
              {duration} <Trans>minutes</Trans>
            </span>
          </span>
        )}
        <span
          style={{
            display: "inline-block",
            marginRight: "0.5em",
          }}
        >
          {" "}
          <Icon
            className={"heartbeat"}
            style={{
              color: high_impact ? primaryColor : "rgb(219 245 16)",
              marginRight: 2,
            }}
          />
          <span>
            {high_impact ? (
              <Trans>high impact</Trans>
            ) : (
              <Trans>moderate impact</Trans>
            )}
          </span>
        </span>

        {!!important && !!displayImportant && (
          <span
            style={{
              display: "inline-block",
              marginRight: "0.5em",
            }}
          >
            {" "}
            <Icon
              className={"bookmark"}
              style={{
                color: secondaryColor,
                marginRight: 1,
              }}
            />
            <span>
              <Trans>do not miss</Trans>
            </span>
          </span>
        )}
      </List.Content>

      <List.Content
        style={{
          paddingTop: "1.5em",
        }}
      >
        <Focuses focusStats={focusStats} />
      </List.Content>
    </List.Item>
  )
}
