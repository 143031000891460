// imports

import { NavLink, useLocation } from "react-router-dom"
import { Icon, Menu } from "semantic-ui-react"
import { useMediaQuery } from "react-responsive"

import { StyledBottomMenu } from "../Styled"
const MenuBottom = () => {
  const { pathname } = useLocation()
  let iOS =
    window.navigator.userAgent.match(/iPad/i) ||
    window.navigator.userAgent.match(/iPhone/i)

  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" })

  return (
    (isTabletOrMobile || process.env.NODE_ENV === "development") && (
      <>
        <style>{`.App{padding-bottom:55px!important;}`}</style>
        <StyledBottomMenu
          icon
          borderless
          fluid
          fixed="bottom"
          inverted
          style={{ paddingBottom: iOS ? 10 : 0 }}
        >
          <Menu.Item
            name="chart bar"
            as={NavLink}
            to="/dashboard"
            data-testid="menu-dashboard"
          >
            <Icon className="th large" style={{ fontSize: "1em" }} />
          </Menu.Item>
          <Menu.Item
            name="home"
            as={NavLink}
            to="/"
            className={pathname === "/index.html" ? "active" : ""}
            data-testid="menu-home"
          >
            <div className="app-logo-menu" />
          </Menu.Item>
          <Menu.Item
            name="cog"
            as={NavLink}
            to="/settings"
            data-testid="menu-settings"
            className={pathname === "/subscribe" ? "active" : ""}
          >
            <Icon className="cog" />
          </Menu.Item>
        </StyledBottomMenu>
      </>
    )
  )
}

export default MenuBottom
