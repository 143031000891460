// imports

import { Trans, plural } from "@lingui/macro"
import { StyledList } from "../../../../Styled"
import { List } from "semantic-ui-react"
import { secondsToMinutes } from "../../../utils"
import HelpIcon from "../../../../shared/HelpIcon"
import Help from "../../../../shared/Help"

const AmrapHelp = ({ time, exercises }) => {
  return (
    <HelpIcon
      style={{ display: "inline" }}
      drawerContent={
        <Help
          title={<Trans>amrap</Trans>}
          description={
            <>
              <p>
                <Trans>
                  The goal is to complete a maximum of rounds with the given
                  reps and exercises during a specific time.
                </Trans>
              </p>{" "}
              <p>
                <Trans>
                  You can break your sets when necessary (but do not stop the
                  timer), the goal being to limit the rest time as much as
                  possible.
                </Trans>
              </p>
            </>
          }
          details={
            <>
              {plural(secondsToMinutes(time), {
                one: "For 1 minute do",
                other: "For # minutes, do",
              })}
              <StyledList inverted style={{ marginTop: "0.5em" }}>
                {exercises.map(({ data: { name }, reps }, index) => (
                  <List.Item key={index}>
                    <List.Icon name="dot circle outline" className="primary" />
                    <List.Content>
                      <Trans>{reps} reps of</Trans> <Trans id={`${name}`} />
                    </List.Content>
                  </List.Item>
                ))}
                {exercises.map(({ data: { name }, reps }, index) => (
                  <List.Item key={index}>
                    <List.Icon name="dot circle outline" className="primary" />
                    <List.Content>
                      <Trans>{reps} reps of</Trans> <Trans id={`${name}`} />
                    </List.Content>
                  </List.Item>
                ))}
                <trans>etc</trans>...
              </StyledList>
            </>
          }
        />
      }
    />
  )
}

export default AmrapHelp
