// imports
import PropTypes from "prop-types"
import { Statistic } from "semantic-ui-react"
import { StyledStatCard } from "../Styled"
const StatCard = ({ className = null, value, label }) => {
  return (
    <StyledStatCard className={className} inverted size="tiny">
      <Statistic.Value>{value}</Statistic.Value>
      <Statistic.Label>{label}</Statistic.Label>
    </StyledStatCard>
  )
}

StatCard.propTypes = {
  floated: PropTypes.string,
}

export default StatCard
