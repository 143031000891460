import { useEffect } from "react"
import "react-circular-progressbar/dist/styles.css"
import { useStopwatch } from "react-timer-hook"
import { useSetRecoilState } from "recoil"
import {
  currentTimer,
  stopwatchIsRunning,
  stopwatchTime,
} from "../../../../recoil/Atoms"
import NextPrevIntervalButtons from "./NextPrevIntervalButtons"
import TimerDisplay from "./TimerDisplay"

const StopWatch = ({ intervals, currentInterval }) => {
  const setRecoilStopwatchIsRunning = useSetRecoilState(stopwatchIsRunning)
  const setRecoilStopwatchTime = useSetRecoilState(stopwatchTime)
  const setRecoilCurrentTimer = useSetRecoilState(currentTimer)

  const { seconds, minutes, isRunning, start, pause /*,reset */ } =
    useStopwatch({
      autoStart: true,
    })

  useEffect(() => {
    setRecoilCurrentTimer("stopwatch")
    setRecoilStopwatchIsRunning(isRunning)
  }, [isRunning, setRecoilCurrentTimer, setRecoilStopwatchIsRunning])

  useEffect(() => {
    setRecoilStopwatchTime({ minutes, seconds })
  }, [minutes, seconds, setRecoilStopwatchTime])

  const percentage = () => {
    return (seconds / 60) * 100
  }

  const togglePlayPause = () => (isRunning ? pause() : start())

  let { type } = currentInterval
  return (
    <>
      <div
        style={{ width: "40%", margin: "0em auto", maxWidth: 162 }}
        onClick={togglePlayPause}
      >
        <TimerDisplay
          value={percentage()}
          minutes={minutes}
          seconds={seconds}
          isRunning={isRunning}
          type={type}
        />
      </div>
      {isRunning && <NextPrevIntervalButtons intervals={intervals} />}
    </>
  )
}

export default StopWatch
