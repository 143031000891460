// imports

import { Trans } from "@lingui/macro"
import PropTypes from "prop-types"
import { useRecoilState } from "recoil"
import { Segment } from "semantic-ui-react"
import { muscleToStrike } from "../../recoil/Atoms"
import { StyledHeader, StyledLabel } from "../Styled"

const BodyPartsButtons = ({
  bodyParts: { principal = [], accessory = [] },
}) => {
  const [recoilMuscleToStrike, setRecoilMuscleToStrike] =
    useRecoilState(muscleToStrike)
  const toggleStroke = (muscle) => {
    setRecoilMuscleToStrike(recoilMuscleToStrike === muscle ? null : muscle)
  }

  return (
    <>
      <StyledHeader>
        <Trans>Areas strengthened</Trans>
      </StyledHeader>
      <Segment basic style={{ marginTop: 0, marginBottom: 0 }}>
        {principal.map((part) => (
          <StyledLabel
            ambiant="true"
            size="small"
            key={part}
            onClick={() => toggleStroke(part)}
            overrideborder={recoilMuscleToStrike === part ? "#fff" : null}
          >
            <Trans id={part}></Trans>
          </StyledLabel>
        ))}
        {accessory.map((part) => (
          <StyledLabel
            ambiant="true"
            alpha="true"
            size="small"
            key={part}
            onClick={() => toggleStroke(part)}
            overrideborder={recoilMuscleToStrike === part ? "#fff" : null}
          >
            <Trans id={part}></Trans>
          </StyledLabel>
        ))}
      </Segment>
    </>
  )
}

BodyPartsButtons.propTypes = {
  floated: PropTypes.string,
}
export default BodyPartsButtons
