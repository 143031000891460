import "react-circular-progressbar/dist/styles.css"
import { StyledSpan, StyledHeader, StyledProgressStep } from "../../../Styled"
import { Trans } from "@lingui/macro"
import { Segment } from "semantic-ui-react"
import { Fragment } from "react"

const RoundsCounter = ({
  blocksTypesArray,
  blockNumber,
  rounds,
  round,
  intervalName,
}) => {
  let toCome = []
  let passed = []
  let current = []

  blocksTypesArray?.forEach((step, index) => {
    if (index < ~~blockNumber) {
      passed = passed.concat(step)
    } else if (index > ~~blockNumber) {
      toCome = toCome.concat(step)
    } else {
      current = current.concat(step)
    }
  })

  return (
    <Segment
      basic
      size="small"
      style={{
        //position: "absolute",
        //  bottom: "5rem",
        //left: "50%",
        //transform: "translateX(-50%)",
        width: "100%",
        maxWidth: 800,
      }}
    >
      {rounds && round && (
        <StyledHeader
          size="small"
          style={{
            textTransform: "uppercase",
            marginBottom: "0em",
          }}
        >
          <StyledSpan>
            <Trans id={intervalName}></Trans>
          </StyledSpan>
          <div>
            {round} / {rounds}
          </div>
        </StyledHeader>
      )}
      <div style={{ position: "relative" }}>
        <div
          className="passed"
          style={{
            position: "absolute",
            left: 0,
            width: "33%",
            textAlign: "left",
          }}
        >
          {passed?.map(({ name }, index) => (
            <StyledProgressStep key={index} className={`${name}`} />
          ))}
        </div>

        <div
          className="current"
          style={{
            position: "absolute",
            left: "50%",
            transform: "translateX(-50%)",
            width: "33%",
          }}
        >
          {current?.map(({ name }, index) => (
            <Fragment key={index}>
              <StyledProgressStep className={`${name} track`} />
              {rounds && round ? (
                <>
                  <StyledProgressStep
                    className={`${name} actual`}
                    style={{
                      width: `${(1 / rounds) * 100}%`,
                      left: `${(round - 1) * (1 / rounds) * 100}%`,
                    }}
                  />
                  <StyledProgressStep
                    key={index}
                    className={`${name} done`}
                    style={{
                      width: `${((round - 1) / rounds) * 100}%`,
                    }}
                  />
                </>
              ) : (
                <StyledProgressStep
                  className={`${name} actual`}
                  style={{
                    width: `${(round / rounds) * 100}%`,
                  }}
                />
              )}
            </Fragment>
          ))}
        </div>

        <div
          className="to-come"
          style={{
            position: "absolute",
            right: 0,
            width: "33%",
            textAlign: "right",
          }}
        >
          {toCome?.map(({ name }, index) => (
            <StyledProgressStep key={index} className={`${name}`} />
          ))}
        </div>
      </div>
    </Segment>
  )
}

export default RoundsCounter
