import { t } from "@lingui/macro"
import { i18n } from "@lingui/core"
import { List } from "semantic-ui-react"
import { StyledFeatures, StyledList } from "../Styled"

const features = [
  {
    key: "road",
    text: t`Road and trail versions of planifications`,
    products: ["Free", "Classic", "Premium"],
  },
  {
    key: "levels",
    text: t`Beginner, intermediate, and advanced levels`,
    products: ["Free", "Classic", "Premium"],
  },

  {
    key: "videos",
    text: t`140+ exercises videos`,
    products: ["Free", "Classic", "Premium"],
  },
  {
    key: "on-ramp",
    text: t`On ramp planification (2 weeks free)`,
    products: ["Free", "Classic", "Premium"],
    hide: ["Classic", "Premium"],
  },

  {
    key: "on-ramp-free",
    text: t`On ramp planification`,
    products: ["Free", "Classic", "Premium"],
    hide: ["Free"],
  },

  {
    key: "open-sessions",
    text: t`70+ open sessions (One free per category)`,
    products: ["Free", "Classic", "Premium"],
    hide: ["Classic", "Premium"],
  },
  {
    key: "open-sessions-free",
    text: t`70+ open sessions `,
    products: ["Free", "Classic", "Premium"],
    hide: ["Free"],
  },

  {
    key: "daily",
    text: t`Daily planification`,
    products: ["Classic", "Premium"],
  },
  {
    key: "race-ready",
    text: t`Race-ready planification`,
    products: ["Classic", "Premium"],
  },
  {
    key: "content",
    text: t`Access to all updates`,
    products: ["Classic", "Premium"],
  },
  {
    key: "analysis",
    text: t`Stride analysis by one of our coaches`,
    products: ["Premium"],
  },
  /*  {
    key: "personalized-development",
    text: t`Development plan adapted to your stride`,
    products: ["Premium"],
  },*/
  {
    key: "personalized-daily",
    text: t`Daily plan adapted to your stride`,
    products: ["Premium"],
  },
  {
    key: "personalized-race-ready",
    text: t`Race-ready planification adapted to your stride`,
    products: ["Premium"],
  },
]

const Features = ({ selectedProductName }) => {
  return (
    <StyledFeatures className="features">
      <StyledList inverted divided relaxed verticalAlign="top">
        {features?.map(({ key, text, products, hide = [] }) => (
          <List.Item
            key={key}
            style={{
              paddingTop: 8,
              paddingBottom: 8,
              display: hide.includes(selectedProductName) ? "none" : "block",
            }}
          >
            <List.Content floated="right">
              {products?.includes(selectedProductName) ? (
                <List.Icon
                  name="check"
                  className={
                    products.length === 2
                      ? "primary"
                      : products.length === 1
                      ? "primary circular-white "
                      : ""
                  }
                />
              ) : (
                <List.Icon name="close" style={{ opacity: 0.5 }} />
              )}
            </List.Content>
            <List.Content>{text && i18n._(text)}</List.Content>
          </List.Item>
        ))}
      </StyledList>
    </StyledFeatures>
  )
}

export default Features
