import { useState } from "react"
import { Icon } from "semantic-ui-react"

const ShareIcon = ({ label, icon, url, onClick }) => {
  const [clicked, setClicked] = useState(false)
  const navigateNext = (url) => {
    setClicked(true)
    window.open(url, "share")

    const timer = setTimeout(() => setClicked(false), 1000)
    return () => clearTimeout(timer)
  }

  return (
    <Icon
      key={label}
      onClick={onClick ? onClick : () => navigateNext(url)}
      className={!clicked ? icon : "loading circle notch"}
      size="large"
      style={{
        margin: "0 5px 0 0",
        display: "inline-block",
      }}
    />
  )
}
export default ShareIcon
