// imports

import { Trans } from "@lingui/macro"
import { useRecoilValue } from "recoil"
import { Icon, Menu } from "semantic-ui-react"
import { userLevel, userProgram } from "../../recoil/Atoms"
import Loading from "../App/Loading"
import { StyledPreferences } from "../Styled"
import { i18n } from "@lingui/core"
import { Suspense, useEffect } from "react"
import { useNavigate } from "react-router-dom"

const PreferencesData = ({ weekNumber = null, weeksCount }) => {
  let recoilUserLevel = useRecoilValue(userLevel)
  let recoilUserProgram = useRecoilValue(userProgram)
  let navigate = useNavigate()
  let programIcons = { trail: "mountain", road: "road" }

  useEffect(() => {
    if (!recoilUserLevel) {
      navigate("/select-program/level?&hidenav=1")
    }
  }, [navigate, recoilUserLevel])

  return (
    <StyledPreferences inverted size={"small"} compact>
      <>
        {weekNumber !== null && (
          <Menu.Item data-testid={`preference-${recoilUserProgram}`}>
            <Icon
              className={"ambiantColor " + programIcons[recoilUserProgram]}
            />
            <Trans>week</Trans> {weekNumber}{" "}
            {!!weeksCount && <> / {weeksCount}</>}
          </Menu.Item>
        )}

        {recoilUserProgram && (
          <Menu.Item data-testid={`preference-${recoilUserProgram}`}>
            <Icon
              className={"primaryColor " + programIcons[recoilUserProgram]}
            />
            {i18n._(recoilUserProgram)}
          </Menu.Item>
        )}

        {recoilUserLevel && (
          <Menu.Item
            className="icons"
            data-testid={`preference-${recoilUserLevel}`}
          >
            <Icon className={"bolt secondaryColor"} />
            <Icon
              className={`bolt ${
                ["advanced", "intermediate"].includes(recoilUserLevel)
                  ? "secondaryColor"
                  : "white"
              }`}
            />
            <Icon
              className={`bolt ${
                ["advanced"].includes(recoilUserLevel)
                  ? "secondaryColor"
                  : "white"
              }`}
            />
            <Trans id={recoilUserLevel}></Trans>
          </Menu.Item>
        )}
      </>
    </StyledPreferences>
  )
}

const Preferences = (props) => {
  return (
    <Suspense fallback={<Loading />}>
      <PreferencesData {...props} />
    </Suspense>
  )
}

export default Preferences
