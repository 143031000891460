import { Trans } from "@lingui/macro"
import { Statistic } from "semantic-ui-react"

const Price = ({
  products,
  selectedProductName,
  selectedFrequency,
  coupon,
  productIsDiscounted,
}) => {
  const formatPrice = (price) => {
    let finalPrice = price === 0 ? price : (price / 100).toFixed(6).slice(0, -4)
    return (
      <>
        {finalPrice} <span style={{ fontSize: "0.7em" }}>€</span>
      </>
    )
  }

  const computeMonthlyPrice = (price) => {
    let finalPrice = price / 12
    return formatPrice(finalPrice)
  }

  const findSelectedProduct = () => {
    return (
      products?.find(
        (product) =>
          product.name === selectedProductName &&
          product.frequency === selectedFrequency
      ) || {}
    )
  }
  const selectedProductPrice = () => {
    const { unit_amount = null } = findSelectedProduct()
    return unit_amount
  }

  const selectedProductDiscoutPrice = (params) => {
    const { unit_amount = null } = findSelectedProduct()
    return (
      parseFloat(unit_amount) -
      parseFloat(unit_amount * coupon.percent_off * 0.01)
    )
  }

  return (
    <>
      <Statistic inverted size="tiny">
        <Statistic.Value>
          {productIsDiscounted() && (
            <span style={{}}>
              {selectedFrequency === "yearly"
                ? computeMonthlyPrice(selectedProductDiscoutPrice())
                : formatPrice(selectedProductDiscoutPrice())}
            </span>
          )}

          <span
            style={
              productIsDiscounted()
                ? {
                    paddingLeft: "1em",
                    textDecoration: "line-through",
                    fontSize: "0.6em",
                  }
                : {}
            }
          >
            {selectedFrequency === "yearly"
              ? computeMonthlyPrice(selectedProductPrice())
              : formatPrice(selectedProductPrice())}
          </span>
        </Statistic.Value>
        {!!selectedProductPrice() && (
          <Statistic.Label>
            /<Trans>month</Trans>
          </Statistic.Label>
        )}
      </Statistic>

      <div
        style={{
          position: "absolute",
          bottom: 5,
          right: 0,
          left: 0,
          visibility:
            selectedFrequency === "yearly" && selectedProductPrice() !== 0
              ? "visible"
              : "hidden",
        }}
      >
        (
        {productIsDiscounted() && (
          <span style={{}}>{formatPrice(selectedProductDiscoutPrice())}</span>
        )}
        <span
          style={
            productIsDiscounted()
              ? {
                  paddingLeft: "0.6em",
                  paddingRight: "0.6em",
                  textDecoration: "line-through",
                  fontSize: "0.9em",
                }
              : {}
          }
        >
          {formatPrice(selectedProductPrice())}
        </span>{" "}
        <Trans>per year</Trans>)
      </div>
    </>
  )
}

export default Price
