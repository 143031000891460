// imports

import { useRecoilValue } from "recoil"
import {
  bookmarkedSessionsIds,
  hasSubscription,
  openSessionCards,
  openSessionsFiltered,
} from "../../../recoil/Atoms"
import { Container, Icon } from "semantic-ui-react"
import {
  secondaryColor,
  StyledHeader,
  StyledList,
  StyledSegment,
} from "../../Styled"
import { useParams } from "react-router-dom"
import NextBackButtons from "../../shared/NextBackButtons"
import { SessionListItem } from "../SessionListItem"
import { i18n } from "@lingui/core"
import Preferences from "../../Home/Preferences"
import SessionCard from "./SessionCard"
import { Trans } from "@lingui/macro"

const OpenSessionsListPage = () => {
  const { filter } = useParams()

  const recoilOpenSessions = useRecoilValue(openSessionsFiltered(filter))
  const recoilBookmarkedSessionsIds = useRecoilValue(bookmarkedSessionsIds)

  const title = openSessionCards.find((card) => card.key === filter)?.title
  //not translated

  const recoilHasSubscription = useRecoilValue(hasSubscription)

  const isFreeUser = !recoilHasSubscription
  const isBookMarksList = filter === "favorites"

  return (
    <Container className="page open-sessions-list list" textAlign="left">
      <SessionCard
        title={title && i18n._(title)}
        keyName={filter}
        bottom={<Preferences />}
      />

      {recoilOpenSessions.length !== 0 && (
        <StyledList inverted divided>
          {recoilOpenSessions.map((session, index) => (
            <SessionListItem
              key={session.id}
              session={session}
              index={index}
              displayImportant={false}
              isLocked={isFreeUser && !session.isFree} //hidden|enabled|disabled
              freeSessionLocked={
                localStorage.getItem(session.planificationName + "Done") &&
                isFreeUser &&
                session.isFree
              }
              planificationName={title && i18n._(title)}
              showBookmarkIcon={
                !isBookMarksList &&
                recoilBookmarkedSessionsIds.includes(session.id)
              }
              showCategory={isBookMarksList}
            />
          ))}
        </StyledList>
      )}
      {recoilOpenSessions.length === 0 && isBookMarksList && (
        <StyledSegment basic data-testid="no-favorites">
          <StyledHeader>
            <Trans>No favorites sessions for now :-(</Trans>
          </StyledHeader>

          <p style={{ fontSize: "1.2em" }}>
            <Trans>
              You can add a session to your favorites by clicking the{" "}
              <Icon
                name="star outline"
                style={{ color: secondaryColor, marginLeft: 4 }}
              />{" "}
              on the session description
            </Trans>
            .
          </p>
        </StyledSegment>
      )}

      <NextBackButtons backOnly />
    </Container>
  )
}

export default OpenSessionsListPage
