import { t, Trans } from "@lingui/macro"
import { useCallback, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import { useRecoilValue, useSetRecoilState } from "recoil"
import { Progress } from "semantic-ui-react"
import useFetch from "use-http"
import {
  canUploadVideo,
  forceAnalysisUpdate,
  hasSubscription,
  selectedFile,
  selectedFilename,
  subscriptionId,
} from "../../../recoil/Atoms"

import { create } from "apisauce"
import { StyledHeader } from "../../Styled"
import dayjs from "dayjs"
import localizedFormat from "dayjs/plugin/localizedFormat"
dayjs.extend(localizedFormat)

const Upload = () => {
  const [isUploading, setIsUploading] = useState()
  const [progressPct, setProgressPct] = useState(0)
  const [, setUploadDone] = useState(false)

  const recoilforceAnalysisUpdate = useSetRecoilState(forceAnalysisUpdate)
  const setRecoilCanUploadVideo = useSetRecoilState(canUploadVideo)
  const navigate = useNavigate()
  const recoilSelectedFile = useRecoilValue(selectedFile)
  const recoilSelectedFilename = useRecoilValue(selectedFilename)
  const recoilHasSubscription = useRecoilValue(hasSubscription)
  const recoilSubscriptionId = useRecoilValue(subscriptionId)

  const recoilCanUploadVideo = useRecoilValue(canUploadVideo)
  const { post, response } = useFetch()

  useEffect(() => {
    if (!recoilSelectedFilename) {
      navigate("selectFile", { replace: true })
    }
  }, [navigate, recoilSelectedFilename])

  const handleUpload = useCallback(async () => {
    if (!recoilHasSubscription) {
      navigate("/subscribe", { replace: true })
      return
    }

    if (!recoilCanUploadVideo) {
      navigate("/", { replace: true })
      return
    }

    setIsUploading(true)
    setUploadDone(false)
    const fileName = recoilSelectedFilename

    if (!fileName) return
    const fileType = recoilSelectedFilename.split(".").pop()

    //gets signed urls
    const s3Credentials = await post("/s3/credentials", { fileName, fileType })
    if (!response.ok) {
      if (response.status === 403) {
        toast.error(t`Number of available analyses reached already`)
        navigate("/", { replace: true })
      } else {
        toast.error(t`Upload initiation failed, please retry`)
      }
      return
    }

    let { uploadUrl, finalUrl } = s3Credentials

    //does actual upload
    let s3Api = create({
      headers: {
        "Content-Type": fileType,
        "Content-Disposition": "attachment",
      },
    })
    let upload = await s3Api.put(uploadUrl, recoilSelectedFile, {
      onUploadProgress: (value) => progressTracker(value),
    })
    console.log(upload)
    if (upload.ok) {
      setIsUploading(false)
    } else {
      setIsUploading(false)
      toast.error(t`Upload failed, please retry`)
      return
    }
    let analysisCreated = await post("/analyses/", {
      video: finalUrl,
      subscription: recoilSubscriptionId,
    })
    if (!response.ok) {
      toast.error(t`Number of available analyses reached`)
      return
    }
    console.log(analysisCreated)
    setUploadDone(true)
    setRecoilCanUploadVideo(false)
    recoilforceAnalysisUpdate((prev) => prev + 1)

    navigate("done", { replace: true })
  }, [
    recoilHasSubscription,
    recoilCanUploadVideo,
    recoilSelectedFilename,
    post,
    response,
    recoilSelectedFile,
    recoilSubscriptionId,
    setRecoilCanUploadVideo,
    recoilforceAnalysisUpdate,
    navigate,
  ])

  useEffect(() => {
    handleUpload()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const progressTracker = (progress) => {
    const { loaded, total } = progress
    let uploadPct = Math.round((loaded / total) * 100)
    uploadPct = uploadPct < 0 ? 0 : uploadPct
    setProgressPct(uploadPct)
  }
  return (
    recoilSelectedFilename && (
      <div>
        {isUploading && (
          <>
            <StyledHeader>
              <Trans>Almost done ...</Trans>
            </StyledHeader>

            <Progress color="teal" percent={progressPct} size="large" />
          </>
        )}
      </div>
    )
  )
}

export default Upload
