// imports

import LogRocket from "logrocket"

import { Suspense, useEffect } from "react"
import { ErrorBoundary } from "react-error-boundary"
import {
  Navigate,
  Route,
  Routes,
  useMatch,
  useSearchParams,
} from "react-router-dom"
import { useIsLoggedIn } from "../../hooks/AuthHooks"
import {
  useEmail,
  useFirstname,
  useFullname,
  useUserId,
} from "../../hooks/useUserInfo"
import { Mixpanel } from "../../utils/mixpanel"
import tracker from "../../utils/trackerSetup"
import ForgotPage from "../auth/Forgot/ForgotPage"
import LoginPage from "../auth/Login/LoginPage"
import LogoutPage from "../auth/Login/LogoutPage"
import PasswordChangePage from "../auth/PasswordChange/PasswordChangePage"
import SignupPage from "../auth/Signup/SignupPage"
import DashboardPage from "../Dashboard/DashboardPage"
import EventsPage from "../Events/EventsPage"
import ExercisePage from "../Exercise/ExercisePage"
import HomePage from "../Home/HomePage"
import LibraryPage from "../Library/LibraryPage"
import OnRampPage from "../OnRamp/OnRampPage"
import ProgramSelectPage from "../ProgramSelect/ProgramSelectPage"
import RacePage from "../Race/RacePage"
import AllSessionsDonePage from "../Session/AllSessionsDonePage"
import SessionDonePage from "../Session/SessionDonePage"
import SessionPage from "../Session/SessionPage"
import SessionsPage from "../Session/SessionsPage"
import SessionSubscriptionMismatch from "../Session/SessionSubscriptionMismatch"
import SettingsPage from "../Settings/SettingsPage"
import UserPage from "../Settings/User/UserPage"
import SubscribePage from "../Subscribe/SubscribePage"
import SuccessPage from "../Subscribe/SuccessPage"
import PackSuccessPage from "../Race/Pack/SuccessPage"
import VideoUploadPage from "../VideoUpload/VideoUploadPage"
import AppError from "./AppError"
import GlobalLoading from "./GlobalLoading"
import MenuBottom from "./MenuBottom"
import OpenSessionsPage from "../Session/OpenSessions/OpenSessionsPage"
import OpenSessionsListPage from "../Session/OpenSessions/OpenSessionsListPage"
import SetupPage from "../Settings/Setup/SetupPage"

function AppRoutes(props) {
  let routesWithMenu = [
    "/",
    "/index.html",
    "/stats",
    "/settings",
    "/onramp",
    "/dashboard",
    "/session/all-done",
    "/session/mismatch",
    "/subscribe/success",
    "/subscribe/cancel",
    "/subscribe",
    "/race/pack",
  ]
  const matchesSignup = !!useMatch("/signup")
  const matchesToken = !!useMatch("/forgot/:token")
  const matchesForgot = !!useMatch("/forgot")
  const matchesLogin = !!useMatch("/login")
  const matchesSetup = !!useMatch("/setup")
  const matchesLogout = !!useMatch("/logout")

  const isUnprotectedRoute =
    matchesForgot || matchesSignup || matchesLogin || matchesToken

  let isLoggedIn = useIsLoggedIn()
  const [searchParams, setSearchParams] = useSearchParams()

  const userId = useUserId()
  const fullname = useFullname()
  const firstName = useFirstname()
  const email = useEmail()

  useEffect(() => {
    if (searchParams.has("src")) {
      let referral = searchParams.get("src")
      Mixpanel.track("App Opened From referral", { Referral: referral })
      setSearchParams(searchParams)
    }
  }, [email, fullname, searchParams, setSearchParams, userId])

  useEffect(() => {
    LogRocket.identify(userId, {
      name: fullname,
      email: email,
    })
  }, [email, fullname, userId])

  useEffect(() => {
    tracker?.start()
  }, [])
  useEffect(() => {
    tracker?.setUserID(email)
    tracker?.setMetadata("userId", userId)
    tracker?.setMetadata("fullname", fullname)
    tracker?.setMetadata("email", email)
  }, [email, fullname, userId])

  if (!isUnprotectedRoute && !isLoggedIn) {
    return <Navigate to={"/login" + window.location.search} />
  }

  if (isLoggedIn && matchesSetup && firstName) {
    return <Navigate to={"/" + window.location.search} />
  }
  if (isLoggedIn && !matchesSetup && !matchesLogout && !firstName) {
    return <Navigate to={"/setup" + window.location.search} />
  }

  if (isUnprotectedRoute && isLoggedIn) {
    return <Navigate to={"/" + window.location.search} />
  }

  return (
    <div className="main-container" style={{ paddingTop: 30 }}>
      <ErrorBoundary FallbackComponent={AppError}>
        <Suspense fallback={<GlobalLoading />}>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/index.html" element={<HomePage />} />
            <Route path={"/signup"} element={<SignupPage />} />
            <Route path={"/setup"} element={<SetupPage />} />
            <Route path={"/login"} element={<LoginPage />} />
            <Route path={"/logout"} element={<LogoutPage />} />
            <Route path={"/forgot"} element={<ForgotPage />}>
              <Route path={":token"} element={<ForgotPage />} />
            </Route>
            <Route path={"/subscribe/"}>
              <Route index={true} element={<SubscribePage />} />

              <Route path="success" element={<SuccessPage />} />
            </Route>
            <Route path={"password/"} element={<PasswordChangePage />} />
            <Route path={"/select-program"} element={<ProgramSelectPage />}>
              <Route path={":feature"} element={<ProgramSelectPage />} />
            </Route>
            <Route path={"/library"} element={<LibraryPage />} />
            <Route path={"/exercise/:id"} element={<ExercisePage />} />
            <Route path={"/onramp/"} element={<OnRampPage />} />
            <Route path={"/dashboard/"} element={<DashboardPage />} />
            <Route path={"/settings"} element={<SettingsPage />} />
            <Route path={"user/"} element={<UserPage />} />
            <Route path={"video-upload/"} element={<VideoUploadPage />}>
              <Route path={":step"} element={<VideoUploadPage />} />
            </Route>

            <Route path="/events" element={<EventsPage />} />
            <Route path="/race">
              <Route index={true} element={<RacePage />} />
              <Route path={"pack/success"} element={<PackSuccessPage />} />
              <Route path={":step"} element={<RacePage />} />
            </Route>

            <Route path="/session">
              <Route
                path="mismatch"
                element={<SessionSubscriptionMismatch />}
              />
              <Route path="open/" element={<OpenSessionsPage />} />
              <Route path="open/:filter" element={<OpenSessionsListPage />} />
              <Route path="week/" element={<SessionsPage />} />
              <Route path="week/:week" element={<SessionsPage />} />
              <Route path=":id" element={<SessionPage />} />
              <Route path=":id/:mode" element={<SessionPage />} />
              <Route path=":id/:action" element={<SessionPage />} />
              <Route path="done/:id" element={<SessionDonePage />} />
              <Route path="all-done" element={<AllSessionsDonePage />} />
            </Route>
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>

          <Routes>
            {routesWithMenu.map((path) => (
              <Route key={path} path={path} element={<MenuBottom />} />
            ))}
            <Route path="*" element={<></>} />
          </Routes>
        </Suspense>
      </ErrorBoundary>
    </div>
  )
}

export default AppRoutes
