import { Trans } from "@lingui/macro"
import { Link } from "react-router-dom"
import { defaultTextColor, StyledHeader } from "../Styled"

const Greetings = ({ firstname }) => {
  return (
    <StyledHeader className={"page-title"} style={{ paddingTop: 20 }}>
      <Link
        to="/user"
        data-testid="greetings"
        style={{ color: defaultTextColor }}
      >
        <Trans>Hi</Trans>, {firstname}
      </Link>
    </StyledHeader>
  )
}

export default Greetings
