// imports

import { Trans } from "@lingui/macro"
import PropTypes from "prop-types"
import { useEffect } from "react"
import { Link, useSearchParams } from "react-router-dom"
import { useRecoilValue, useSetRecoilState } from "recoil"
import { Container } from "semantic-ui-react"
import { forcePlanificationUpdate, subscriptionName } from "../../recoil/Atoms"
import { Mixpanel } from "../../utils/mixpanel"
import Celebration from "../App/Celebration"
import { StyledHeader, StyledSegment } from "../Styled"

const AllSessionsDonePage = () => {
  const recoilSubscriptionName = useRecoilValue(subscriptionName)
  const recoilForcePlanificationGetUpdate = useSetRecoilState(
    forcePlanificationUpdate
  )
  const [searchPrams] = useSearchParams()

  useEffect(() => {
    const planificationName = searchPrams.get("planification-name")
    Mixpanel.track("Planification Done", {
      Name: planificationName,
    })
    if (planificationName === "on-ramp") {
      Mixpanel.track("On-Ramp Done", {
        Subscription: recoilSubscriptionName,
      })
    }
    recoilForcePlanificationGetUpdate((prev) => prev + 1)
  }, [recoilSubscriptionName, searchPrams, recoilForcePlanificationGetUpdate])

  return (
    <Container className="page session done">
      <StyledHeader
        className={"page-title"}
        style={{ textTransform: "capitalize" }}
      >
        <Trans>Planification done !</Trans>
      </StyledHeader>

      <div
        style={{
          width: "100%",
          position: "absolute",
          left: "50%",
          top: "30%",
          transform: "translate(-50%, -50%)",
          maxWidth: 800,
        }}
      >
        <Celebration style={{ width: 200, margin: "0 auto" }} />
        <StyledSegment basic>
          <StyledHeader>
            {recoilSubscriptionName !== "free" && (
              <Trans>
                Well done ! You can go back to the
                <p>
                  {" "}
                  <Link to="/">homepage</Link>{" "}
                </p>
                and select your next planification .
              </Trans>
            )}
          </StyledHeader>
        </StyledSegment>
      </div>
    </Container>
  )
}

AllSessionsDonePage.propTypes = {
  floated: PropTypes.string,
}
export default AllSessionsDonePage
