// imports

import { Trans, plural } from "@lingui/macro"
import { i18n } from "@lingui/core"
import { StyledList } from "../../../../Styled"
import { List } from "semantic-ui-react"
import { formatSecondsToTimeSmart } from "../../../utils"
import HelpIcon from "../../../../shared/HelpIcon"
import Help from "../../../../shared/Help"

const RoundsForRepsHelp = ({ rounds, exercises }) => {
  return (
    <HelpIcon
      style={{ display: "inline" }}
      drawerContent={
        <Help
          title={<Trans>max reps</Trans>}
          description={
            <>
              <p>
                <Trans>
                  The goal is to complete a maximum of reps in each interval of
                  work, for a given number of rounds.
                </Trans>
              </p>
              <p>
                <Trans>
                  You can break your sets when necessary (but do not stop the
                  timer), the goal being to limit the rest time as much as
                  possible.
                </Trans>
              </p>
            </>
          }
          details={
            <>
              {plural(rounds, {
                one: "1 round of :",
                other: "# rounds of :",
              })}

              <StyledList inverted style={{ marginTop: "0.5em" }}>
                {exercises.map(({ data: { name }, work }, index) => (
                  <List.Item key={index}>
                    <List.Icon name="dot circle outline" className="primary" />
                    <List.Content>
                      <Trans>
                        Max reps of {name && i18n._(name)} during{" "}
                        {formatSecondsToTimeSmart(work)}
                      </Trans>
                    </List.Content>
                  </List.Item>
                ))}
              </StyledList>
            </>
          }
        />
      }
    />
  )
}

export default RoundsForRepsHelp
