import { t, Trans } from "@lingui/macro"
import { Navigate, useNavigate } from "react-router-dom"
import { useRecoilState, useRecoilValue } from "recoil"
import { Label, List, Segment, Statistic } from "semantic-ui-react"
import {
  hasSubscription,
  selectedRace,
  stripePackPrices,
  userProgram,
} from "../../../recoil/Atoms"
import {
  StyledPacksList,
  StyledHeader,
  ambiantColor,
  StyledInput,
} from "../../Styled"
import useStripe from "../../../hooks/useStripe"
import { toast } from "react-toastify"
import { Mixpanel } from "../../../utils/mixpanel"
import { useState } from "react"

const PackSelector = () => {
  const [couponCode, setCouponCode] = useState("")
  const [coupon, setCoupon] = useState({})
  const recoilPacksPrices = useRecoilValue(stripePackPrices)
  const [recoilSelectedRace, setRecoilSelectedRace] =
    useRecoilState(selectedRace)
  const recoilUserProgram = useRecoilValue(userProgram)
  const recoilHasSubscription = useRecoilValue(hasSubscription)
  let navigate = useNavigate()
  const { validateCoupon } = useStripe()
  if (recoilHasSubscription) {
    return <Navigate to={"/race/confirm"} />
  }

  const applyCoupon = async () => {
    if (!couponCode) {
      return
    }

    const couponResponse = await validateCoupon(couponCode)

    if (couponResponse) {
      if (!couponCode) {
        return
      }

      if (
        couponResponse &&
        couponResponse.valid &&
        couponResponse.object === "giftcard" &&
        couponResponse.isRacePrep === true &&
        window.location.pathname.includes("race/pack")
      ) {
        toast.success(t`Coupon is valid`)
        Mixpanel.track("Coupon used", { Coupon: couponCode })
        setCoupon(couponResponse)
        handleClick(couponResponse.subscriptionData.name)

        let pack = recoilPacksPrices.find(
          (pack) => pack.title === couponResponse.subscriptionData.name
        )
        pack = { ...pack, price: 0 }
        setRecoilSelectedRace((prevState) => ({
          ...prevState,
          pack,
          [couponResponse.object]: couponCode,
        }))
        // alert(couponCode)
      } else if (
        couponResponse &&
        couponResponse.valid &&
        couponResponse.object === "giftcard" &&
        couponResponse.isRacePrep === false &&
        window.location.pathname.includes("race/pack")
      ) {
        toast.success(
          t`Coupon is valid for a subscription, please click the subscribe button on the homepage`
        )
        Mixpanel.track("Coupon used", { Coupon: couponCode })
        setCoupon(couponResponse)
      } else if (
        couponResponse &&
        !couponResponse.valid &&
        couponResponse.object === "giftcard"
      ) {
        toast.error(t`Coupon is not valid or has been used already`)
        setCouponCode("")
      } else {
        toast.error(t`Coupon is not valid for the selected product`)
        setCouponCode("")
      }
    } else {
      toast.error(t`Coupon is not valid !`)
      setCouponCode("")
    }
  }

  const packs = [
    {
      title: "classic",
      features: [
        recoilUserProgram === "road" ? (
          <Trans>10 to 16 weeks of strengh training for road running</Trans>
        ) : (
          <Trans>10 to 16 weeks of strengh training for trail running</Trans>
        ),
        <Trans>2 main sessions a week and 1 accessory </Trans>,
      ],
    },
    {
      title: "premium",
      features: [
        <Trans>
          <span style={{ fontWeight: "bold" }}>1 video analysis</span> of your
          stride to customize your training
        </Trans>,

        recoilUserProgram === "road" ? (
          <Trans>
            10 to 16 weeks of{" "}
            <span style={{ fontWeight: "bolder", fontSize: "1.05em" }}>
              personalized
            </span>{" "}
            strengh training for road running
          </Trans>
        ) : (
          <Trans>
            10 to 16 weeks of{" "}
            <span style={{ fontWeight: "bolder", fontSize: "1.05em" }}>
              personalized
            </span>{" "}
            strengh training for trail running
          </Trans>
        ),
        <Trans>2 main sessions a week and 1 accessory </Trans>,
      ],
    },
  ]

  const handleClick = (packTitle) => {
    let pack = recoilPacksPrices.find((pack) => pack.title === packTitle)
    setRecoilSelectedRace((prevState) => ({ ...prevState, pack }))
    navigate("/race/order")
  }
  const handleCoupon = (value) => {
    setCouponCode(value.toUpperCase())
  }
  return (
    <>
      <StyledHeader textAlign="center">
        <Trans>Choose your pack</Trans>
      </StyledHeader>
      <StyledPacksList inverted divided>
        {packs.map(({ title, features }) => (
          <List.Item
            key={title}
            onClick={() => handleClick(title)}
            className={
              recoilSelectedRace?.pack?.title === title ? "selected" : ""
            }
            style={{ overflow: "hidden" }}
            data-testid={`pack-${title}`}
          >
            {title === "premium" && (
              <Label
                corner="right"
                style={{ borderColor: ambiantColor, color: "white" }}
                icon="heart"
              />
            )}
            <List.Header className="main">{title}</List.Header>
            <List.Content className="infos">
              <List.Description>
                <PackFeatures features={features} />
              </List.Description>
            </List.Content>
            <div style={{ textAlign: "center" }}>
              <Statistic inverted className="price" horizontal>
                <Statistic.Value>
                  {recoilPacksPrices.find((pack) => pack.title === title)
                    .price / 100}
                </Statistic.Value>
                <Statistic.Label>€</Statistic.Label>
              </Statistic>
            </div>
          </List.Item>
        ))}
      </StyledPacksList>

      {!recoilHasSubscription && (
        <div
          style={{
            padding: "0 0 1em 0",
            textAlign: "center",
          }}
        >
          <StyledInput
            inverted
            disabled={!!coupon.object}
            transparent
            name="coupon"
            placeholder={t`COUPON / GIFT CARD`}
            value={couponCode}
            onChange={(e, { value }) => {
              handleCoupon(value)
            }}
            style={{
              borderRadius: " 0.3rem 0.3rem ",
              border: `1px solid  ${ambiantColor}`,
              maxWidth: 400,
              width: "95%",
            }}
            action={{
              color: "blue",
              content: t`Apply`,
              // icon: productIsDiscounted() ? "check" : null,
              onClick: applyCoupon,
            }}
            className="centered-text uppercase"
          />
        </div>
      )}
    </>
  )
}
const PackFeatures = ({ features }) => (
  <Segment basic style={{ paddingTop: 0, paddingBottom: 0 }}>
    <List bulleted>
      {features.map((feature, index) => (
        <List.Item key={index}>{feature}</List.Item>
      ))}
    </List>
  </Segment>
)

export default PackSelector
