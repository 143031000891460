import { t, Trans } from "@lingui/macro"
import { useEffect } from "react"
import { Form } from "semantic-ui-react"
import { StyledForm } from "../Styled"

import dayjs from "dayjs"
import localizedFormat from "dayjs/plugin/localizedFormat"
import isSameOrAfter from "dayjs/plugin/isSameOrAfter"

import weekday from "dayjs/plugin/weekday"
import { useRecoilState } from "recoil"
import { selectedRace } from "../../recoil/Atoms"
import { formatWeekendFromSunday } from "../../utils"
import { useForm } from "react-hook-form"
import { useNavigate } from "react-router-dom"

dayjs.extend(isSameOrAfter)
dayjs.extend(weekday)
dayjs.extend(localizedFormat)

let minWeeks = 4
let maxDuration = 16

const RaceDateSelector = () => {
  let endOfWeek = dayjs().endOf("week")
  let startWeek = endOfWeek.add(minWeeks, "w")

  const [recoilSelectedRace, setRecoilSelectedRace] =
    useRecoilState(selectedRace)

  const navigate = useNavigate()

  const raceDateOptions = [...Array(maxDuration - minWeeks + 1).keys()].map(
    (week) => {
      let sundayDate = startWeek.add(week, "w").startOf("d")

      return {
        text: `${formatWeekendFromSunday(sundayDate)} (${
          minWeeks + week
        } ${t`wks`})`,
        value: sundayDate.unix(),
        key: sundayDate,
        /*  disabled:
          recoilSubscriptionDayjsEndate &&
          sundayDate.isSameOrAfter(recoilSubscriptionDayjsEndate, "day"),*/
      }
    }
  )
  const { raceDate: recoilRaceDate = null } = recoilSelectedRace || {}

  const {
    setValue,
    register,

    formState: { errors },
  } = useForm()

  useEffect(() => {
    register("raceDate", { required: true })
    setValue(
      "raceDate",
      (dayjs.isDayjs(recoilRaceDate) ? recoilRaceDate : dayjs(recoilRaceDate))
        ?.startOf("d")
        .unix()
    )
  }, [recoilRaceDate, register, setValue])

  const handleChange = ({ name, value }) => {
    setValue(name, value)
    setRecoilSelectedRace((previousState) => ({
      ...previousState,
      [name]: dayjs.unix(value),
    }))
    navigate("/race/name")
  }

  return (
    <>
      <StyledForm
        style={{ textAlign: "left", marginTop: "2em" }}
        data-testid="raceform"
      >
        <label>{t`Race date`}</label>
        <Form.Select
          fluid
          //scrolling
          className="date-select"
          name="raceDate"
          options={raceDateOptions}
          defaultValue={(dayjs.isDayjs(recoilRaceDate)
            ? recoilRaceDate
            : dayjs(recoilRaceDate)
          )
            ?.startOf("d")
            .unix()}
          onChange={(e, { name, value }) => {
            handleChange({ name, value })
          }}
        />

        {errors.raceDate && (
          <div style={{ marginTop: "-1em", fontSize: "0.9em" }}>
            <Trans>Enter the date of the race</Trans>
          </div>
        )}
      </StyledForm>
    </>
  )
}

export default RaceDateSelector
