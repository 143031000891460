// imports

import { plural, t } from "@lingui/macro"
import { i18n } from "@lingui/core"
import PropTypes from "prop-types"
import { Icon, Segment } from "semantic-ui-react"
import { StyledHeader, StyledLabel } from "../../../Styled"
import SessionPageHelpIcon from "../../HelpIcons/SessionPageHelpIcon"
import { upperFirst } from "../../../../utils"
import { Bookmark } from "./Bookmark"
import useSessionCategory from "../../../../hooks/useSessionTitle"
import { Suspense } from "react"
import Loading from "../../../App/Loading"

const SessionHeader = ({
  session: {
    id,
    program,
    duration,
    blocks,
    important,
    high_impact,
    planificationName,
    custom_name,
  },
}) => {
  const getSessionCategory = useSessionCategory()

  let blockCount = blocks?.filter(
    (block) => block?.format?.name !== "rest"
  ).length
  let keypoints = [
    {
      icon: program === "trail" ? "mountain" : "road",
      description: program === "trail" ? t`trail` : t`road`,
    },
    {
      icon: "stopwatch",
      description: `${duration} ${t`min.`}`,
    },

    {
      icon: "check double",
      description: plural(blockCount, {
        one: "# part",
        other: "# parts",
      }),
    },
  ]

  return (
    <>
      {custom_name && planificationName.includes("open") && (
        <>
          <StyledHeader className={"session-title"}>
            {custom_name}
            {"  "}
            <span
              style={{
                fontSize: "0.9em",
                color: "#b9b9b9",
                textTransform: "initial",
                marginRight: "1em",

                fontWeight: "normal",
              }}
            >
              {" - "}
              {upperFirst(i18n._(getSessionCategory(planificationName)))}
            </span>

            <Suspense
              fallback={
                <span
                  style={{
                    // marginRight: "0.5em",
                    marginTop: 2,
                    float: "right",
                  }}
                >
                  <Loading />
                </span>
              }
            >
              <Bookmark
                style={{
                  fontSize: "1.1em",
                  textTransform: "initial",
                  marginRight: "0.5em",
                  marginTop: 2,
                  float: "right",
                  fontWeight: "normal",
                }}
                sessionInfos={{ id, custom_name }}
              />
            </Suspense>
          </StyledHeader>
        </>
      )}
      <Segment
        basic
        style={{
          textAlign: "left",
          marginBottom: 0,
          position: "relative",
          paddingLeft: 5,
        }}
      >
        {keypoints.map(({ icon, description }) => (
          <StyledLabel
            secondary="true"
            basicwhite="true"
            alpha="true"
            key={description}
          >
            <Icon className={icon} />
            {description}
          </StyledLabel>
        ))}
        <SessionPageHelpIcon
          high_impact={high_impact}
          important={important}
          displayImportant={planificationName.includes("open") ? false : true}
        />
      </Segment>
    </>
  )
}

SessionHeader.propTypes = {
  floated: PropTypes.string,
}
export default SessionHeader
