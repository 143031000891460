import { t, Trans } from "@lingui/macro"
import { useEffect, useState } from "react"
import { toast } from "react-toastify"
import { useRecoilValue } from "recoil"
import useStripe from "../../../hooks/useStripe"
import { selectedRace, userProgram } from "../../../recoil/Atoms"
import { Mixpanel } from "../../../utils/mixpanel"
import Loading from "../../App/Loading"
import { StyledButton } from "../../Styled"

const PackOrder = () => {
  const recoilSelectedRace = useRecoilValue(selectedRace)
  const recoilUserProgram = useRecoilValue(userProgram)
  const { pay } = useStripe()

  const [checkoutClicked, setCheckoutClicked] = useState()

  const {
    raceName,
    distance,
    raceDate,
    pack: { priceId = null, title = null, price = null, currency = null } = {},
    giftcard,
  } = recoilSelectedRace || {}

  useEffect(() => {
    Mixpanel.track(
      "Pack Order Page Viewed",

      {
        Product: `pack-${title}`,
        frequency: "onetime",
        RaceName: raceName,
        RaceDate: raceDate?.format("YYYY-MM-DD"),
        Distance: distance,
        Program: recoilUserProgram,
      }
    )
  }, [distance, raceDate, raceName, recoilUserProgram, title])

  const order = async () => {
    setCheckoutClicked(true)

    const base_url = window.location.href.replace("/confirm", "/pack")

    const cancel_url = base_url + "?canceled=1"

    let success_return_base_url =
      base_url +
      `/success?product=pack-${title}&frequency=one-time&distance=${distance}&program=${recoilUserProgram}` +
      `&value=${parseInt(price) / 100}&currency=${currency.toUpperCase()}`

    success_return_base_url = success_return_base_url.replace(
      "//success",
      "/success"
    )

    let payload = {
      priceId,
      locale: "fr",
      allow_promotion_codes: true,
      race: {
        distance,
        raceName,
        raceDate: raceDate.unix(),
        program: recoilUserProgram,
        giftcard,
      },
      cancel_url,
      success_return_base_url,
    }

    const { url = null, e = null } = await pay(payload, "pack")
    if (url) {
      window.location.href = url
      //      navigate()
    } else {
      if (e?.param?.includes("discounts")) {
        toast.error(t`Coupon code is not valid`)
      } else {
        toast.error(t`Could not reach successfully the payment server`)
      }
    }
  }

  return (
    <div style={{ textAlign: "center" }}>
      <StyledButton
        primary
        uppercase={1}
        data-testid={`subscribe`}
        onClick={order}
      >
        {checkoutClicked ? <Loading /> : <Trans>Checkout</Trans>}
      </StyledButton>
    </div>
  )
}

export default PackOrder
