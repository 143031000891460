//import "./wdyr" // <-- first import

//import { StrictMode } from "react"
import ReactDOM from "react-dom/client"
import "./index.css"
import App from "./App"
import "@fontsource/bakbak-one"

import reportWebVitals from "./reportWebVitals"
import * as serviceWorkerRegistration from "./serviceWorkerRegistration"
import { RecoilRoot } from "recoil"
import { i18n } from "@lingui/core"
import { fr as plurals_fr } from "make-plural/plurals"
import { I18nProvider } from "@lingui/react"
import { messages as messages_fr } from "./locales/fr/messages"
import dayjs from "dayjs"

import "dayjs/locale/fr" // ES 2015

dayjs.locale("fr") // use locale globally

let fr = { ...messages_fr /*, ...movements_fr*/ }

i18n.loadLocaleData({
  fr: { plurals: plurals_fr },
})
i18n.load("fr", fr)

i18n.activate("fr")

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
  // <StrictMode>
  <I18nProvider i18n={i18n}>
    <RecoilRoot>
      <App />
    </RecoilRoot>
  </I18nProvider>
  //</StrictMode>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
