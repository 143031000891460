import { Icon } from "semantic-ui-react"

const Loading = ({ textAlign = "center", minHeight = null }) => {
  return (
    <div
      style={{
        textAlign,
        marginLeft: textAlign === "left" ? "3em" : 0,
        minWidth: 50,
        minHeight,
        paddingTop: minHeight ? parseInt(minHeight) / 2 - 20 : "initial",
      }}
    >
      <Icon loading name="circle notch" />
    </div>
  )
}

export default Loading
