import { Trans, t } from "@lingui/macro"

import { Form, Message, Segment } from "semantic-ui-react"
import { defaultTextColor, StyledButton, StyledForm } from "../../Styled"
import { useForm } from "react-hook-form"
import { Link, useNavigate } from "react-router-dom"
import { useEffect } from "react"
import useFetch from "use-http"
import { Buffer } from "buffer"
import { Mixpanel } from "../../../utils/mixpanel.js"
import { useSetRecoilState } from "recoil"
import { signupEmail } from "../../../recoil/Atoms"
import { useAuthResponse } from "../../../hooks/AuthHooks"

const LoginForm = () => {
  const { post, response, error } = useFetch()
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm()
  let navigate = useNavigate()

  const setRecoilSignupEmail = useSetRecoilState(signupEmail)
  const { storeAuthInfos } = useAuthResponse()

  const onSubmit = async (data) => {
    const { email, password } = data
    let tok = email + ":" + password
    let hash = Buffer.from(tok).toString("base64")
    const authResponse = await post("/auth", { hash })
    if (response.ok) {
      storeAuthInfos(authResponse)

      Mixpanel.identify()
      Mixpanel.track("Logged In")
      navigate("/")
    }
  }

  useEffect(() => {
    register("email", {
      required: true,
      pattern:
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    })
    register("password", { required: true, minLength: 6 })
  }, [register])

  return (
    <StyledForm>
      <Segment basic style={{ marginBottom: 0 }}>
        <Form.Input
          name="email"
          icon="at"
          iconPosition="left"
          fluid
          placeholder={t`Email`}
          style={{ caretColor: defaultTextColor }}
          onChange={(e, { name, value }) => {
            setRecoilSignupEmail(value)
            setValue(name, value.trim())
          }}
        />
        {errors.email && (
          <div style={{ marginTop: "-1em", fontSize: "0.9em" }}>
            <Trans>Enter your email</Trans>
          </div>
        )}
        <Form.Input
          name="password"
          icon="lock"
          type="password"
          iconPosition="left"
          fluid
          placeholder={t`Password`}
          style={{ caretColor: defaultTextColor }}
          onChange={(e, { name, value }) => {
            setValue(name, value)
          }}
        />
        {errors.password && (
          <div style={{ marginTop: "-1em", fontSize: "0.9em" }}>
            <Trans>Enter at least 6 characters</Trans>
          </div>
        )}
        <div style={{ fontSize: "1em", marginTop: 10 }}>
          <Link to="/forgot" style={{ color: "rgb(255 255 255 / 75%)" }}>
            <Trans>Forgotten password</Trans>
          </Link>
        </div>
      </Segment>
      {error && (
        <Message negative>
          <Trans>Wrong email / password combination</Trans>
        </Message>
      )}
      <Segment basic style={{ marginTop: 0 }}>
        <StyledButton fluid onClick={handleSubmit(onSubmit)}>
          <Trans>Sign in</Trans>
        </StyledButton>

        {window.location.hostname !== "renforun.app" && <></>}
        <div style={{ marginTop: 20 }}>
          <StyledButton secondary fluid onClick={() => navigate("/signup")}>
            <Trans>Sign up</Trans>
          </StyledButton>
        </div>
      </Segment>
    </StyledForm>
  )
}

export default LoginForm
