import { useResetRecoilState, useSetRecoilState } from "recoil"
import { RFRToken } from "../recoil/Atoms"
import { Mixpanel } from "../utils/mixpanel"
import { useLocalStorage } from "./useLocalStorage"

export function useLogout() {
  const recoilResetRFRToken = useResetRecoilState(RFRToken)
  const logout = () => {
    window.localStorage.removeItem("RFRUser")
    window.localStorage.removeItem("RFRToken")
    recoilResetRFRToken()
    window.location.href = "/login"
  }
  return logout
}

export function useIsLoggedIn() {
  return !!window.localStorage.getItem("RFRToken")
}

export function useAuthResponse() {
  const [, setStorage] = useLocalStorage()
  const recoilRFRToken = useSetRecoilState(RFRToken)
  const postAuth = (authResponse) => {
    storeAuthInfos(authResponse)
    Mixpanel.people.set_once({
      "First Login Date": new Date(),
      "$created": new Date(),
    })
  }
  const storeAuthInfos = (authResponse) => {
    const { token = null, user = {} } = authResponse
    setStorage("RFRToken", token)
    setStorage("RFRUser", user)
    recoilRFRToken(token)
  }

  return { postAuth, storeAuthInfos }
}
