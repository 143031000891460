// imports

import { useRecoilValue } from "recoil"
import { Container, Icon, Segment } from "semantic-ui-react"
import { i18n } from "@lingui/core"
import { hasSubscription, selectedRace, userProgram } from "../../recoil/Atoms"
import DistanceSelector from "./DistanceSelector"
import { primaryColor, secondaryColor, StyledHeader } from "../Styled"
import { Trans } from "@lingui/macro"
import HelpIcon from "../shared/HelpIcon"
import Help from "../shared/Help"

import { t } from "@lingui/macro"
import { upperFirst } from "../../utils"
import { useNavigate, useParams } from "react-router-dom"
import Summary from "./Summary"
import RaceNameSelector from "./RaceNameSelector"
import NextBackButtons from "../shared/NextBackButtons"
import RaceDateSelector from "./RaceDateSelector"
import PackSelector from "./Pack/PackSelector"
import { Suspense, useEffect } from "react"
import PackOrder from "./Pack/PackOrder"
import { Mixpanel } from "../../utils/mixpanel"
import Loading from "../App/Loading"
import Confirm from "./Confirm"

const helpKeys = {
  "race-ready": {
    description: (
      <>
        {" "}
        <Trans>
          <p>
            Your training plan is 10 to 16 weeks long depending on your race
            date.
          </p>
          <p>
            Each four-week block contains 3 weeks during which the workload
            during which the workload gradually increases. Then, you have a
            lighter week to allow your muscles to assimilate the work done.
          </p>
          <p>
            The last two weeks leading up to the race, we lower the volume, but
            we keep some intensity to recover while maintaining what you have
            gained.
          </p>
        </Trans>
      </>
    ),
    details: (
      <>
        <p>
          <Trans>
            Each week there are several sessions, the order is not important.
            important. You just have to adapt them to your running training.
          </Trans>
        </p>{" "}
        <p>
          <Trans>
            {" "}
            The "
            <span style={{ display: "inline-block" }}>
              <Icon className={"bookmark"} style={{ color: secondaryColor }} />
              <span>{t`do not miss`}</span>
            </span>
            " sessions are key, try to complete them every week.
          </Trans>
        </p>
        <p>
          <Trans>
            {" "}
            It is better to do the "
            <span style={{ display: "inline-block" }}>
              <Icon className={"heartbeat"} style={{ color: primaryColor }} />
              <span>{t`high impact`}</span>
            </span>
            " session on an easy run day. If the next day is a complete rest
            day, it's even better. You can do the other sessions whenever you
            want, they will have little impact on your intervals, threshold or
            long sessions tranings.
          </Trans>
        </p>
      </>
    ),
  },
}

const RacePage = () => {
  let { step = "distance" } = useParams()
  const { distance, raceName, raceDate, pack } = useRecoilValue(selectedRace)

  const recoilUserProgram = useRecoilValue(userProgram)
  const recoilHasSubscription = useRecoilValue(hasSubscription)
  const navigate = useNavigate()

  useEffect(() => {
    if (window.location.search.includes("cancel")) {
      Mixpanel.track("Canceled Pack Payment Done")
    }
  }, [])

  useEffect(() => {
    if (step !== "distance" && !distance) {
      navigate("/race")
    }
  }, [distance, navigate, step])

  let nextUrls = {
    distance: "/race/date",
    date: "/race/name",
    name: !recoilHasSubscription ? "/race/pack" : "/race/confirm",
    pack: "/race/order",
    confirm: "/select-program/confirm",
  }

  let backUrls = {
    confirm: !recoilHasSubscription ? "/race/pack" : "/race/name",
    order: "/race/pack",
    pack: "/race/name",
    name: "/race/date",
    date: "/race/distance",
  }

  let stepsComponents = {
    distance: <DistanceSelector />,
    date: <RaceDateSelector />,
    name: <RaceNameSelector />,
    pack: <PackSelector />,
    confirm: <Confirm />,
    order: <PackOrder />,
  }
  const hideNext =
    (step === "distance" && !distance) ||
    (step === "date" && !raceDate) ||
    (step === "name" && !raceName) ||
    (step === "pack" && !pack?.title) ||
    step === "order"

  return (
    <div className="page race packs">
      <Segment
        basic
        style={{
          padding: 0,
          paddingTop: step === "pack" ? "initial" : "30%",
        }}
      >
        <Container textAlign="left">
          <StyledHeader
            className={"page-title"}
            style={{
              textTransform: "capitalize",
              position: "relative",
              marginBottom: "1em",
            }}
          >
            <Trans>race-ready</Trans> - {upperFirst(i18n._(recoilUserProgram))}
            <HelpIcon
              style={{
                position: "absolute",
                top: 20,
                right: 10,
                fontSize: "2em",
              }}
              fontSize="1em"
              drawerContent={
                <Help
                  title={t`race-ready`}
                  description={helpKeys["race-ready"]["description"]}
                  detailsTitle={t`Explanations`}
                  details={helpKeys["race-ready"]["details"]}
                />
              }
            />{" "}
          </StyledHeader>
          {step !== "distance" && <Summary step={step} />}

          <Suspense fallback={<Loading minHeight={200} />}>
            {stepsComponents[step]}
          </Suspense>
        </Container>

        <NextBackButtons
          position={step === "pack" ? "relative" : "fixed"}
          hideNext={hideNext}
          nextButtonProps={{ ambiant: "true" }}
          nextUrl={nextUrls[step]}
          backUrl={backUrls[step]}
        />
      </Segment>
    </div>
  )
}

export default RacePage
