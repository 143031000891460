// imports

import { Trans } from "@lingui/macro"
import { Suspense } from "react"
import { Link } from "react-router-dom"
import { useRecoilValue } from "recoil"
import { Grid, Icon, Segment } from "semantic-ui-react"
import { userLevel, userProgram } from "../../recoil/Atoms"
import { Mixpanel } from "../../utils/mixpanel"
import Loading from "../App/Loading"

import { StyledDashboardCard } from "../Styled"

const Preferences = () => {
  //

  let programIcons = { trail: "mountain", road: "road" }
  let levelIcons = {
    beginner: (
      <>
        <Icon className={"bolt"} />
      </>
    ),
    intermediate: (
      <>
        <Icon className={"bolt"} />
        <Icon className={"bolt"} />
      </>
    ),
    advanced: (
      <>
        <Icon className={"bolt"} />
        <Icon className={"bolt"} />
        <Icon className={"bolt"} />
      </>
    ),
  }

  const ProgramData = () => {
    let recoilUserProgram = useRecoilValue(userProgram)
    return recoilUserProgram ? (
      <div data-testid={recoilUserProgram}>
        <Icon className={programIcons[recoilUserProgram]} />
        <div>{recoilUserProgram && <Trans id={recoilUserProgram}></Trans>}</div>
      </div>
    ) : (
      <div data-testid="no-program">-</div>
    )
  }

  const LevelData = () => {
    let recoilUserLevel = useRecoilValue(userLevel)
    return recoilUserLevel ? (
      <div data-testid={recoilUserLevel}>
        {levelIcons[recoilUserLevel]}
        <div>{recoilUserLevel && <Trans id={recoilUserLevel}></Trans>}</div>
      </div>
    ) : (
      <div data-testid="no-level">-</div>
    )
  }

  return (
    <Segment basic style={{ marginBottom: 0, paddingBottom: 5 }}>
      <Grid columns={2} className="cards">
        <Grid.Column>
          <Link
            to="/select-program"
            onClick={() => Mixpanel.track("Program Button Clicked")}
          >
            <StyledDashboardCard
              className="fluid primary"
              data-testid="program"
            >
              <div className="title">
                <Trans>Playground</Trans>
              </div>
              <div className="content">
                <Suspense fallback={<Loading />}>
                  <ProgramData />
                </Suspense>
              </div>
            </StyledDashboardCard>
          </Link>
        </Grid.Column>

        <Grid.Column>
          <Link
            to="/select-program/level"
            onClick={() => Mixpanel.track("Level Button Clicked")}
          >
            <StyledDashboardCard
              className="fluid secondary"
              data-testid="level"
            >
              <div className="title">
                <Trans>difficulty</Trans>
              </div>
              <div className="content">
                <Suspense fallback={<Loading />}>
                  <LevelData />
                </Suspense>
              </div>
            </StyledDashboardCard>
          </Link>
        </Grid.Column>
      </Grid>
    </Segment>
  )
}

export default Preferences
