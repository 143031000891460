import { t } from "@lingui/macro"

export const ProfileJS = [
  t`road`,
  t`trail`,
  t`on-ramp`,
  t`development`,
  t`race-ready`,

  t`race-pack`,
  t`per-unit`,
  t`with-subscription`,
  t`race-ready-base`,
  t`daily`,
  t`weeks`,
  t`video-analysis`,

  t`beginner`,
  t`intermediate`,
  t`advanced`,

  t`pectorals`,
  t`deltoids`,
  t`trapezius`,
  t`triceps`,
  t`biceps`,
  t`lats`,
  t`adductors`,
  t`hips`,
  t`quadriceps`,
  t`hamstrings`,
  t`glutes`,
  t`calves`,
  t`abdominals`,
  t`lowerback`,
  t`upperback`,
  t`footwork`,
  t`achilles`,
  t`oops`,
  t`initial evaluation`,
  t`final evaluation`,

  t`lower body`,
  t`dynamic core`,
  t`upper body`,
  t`full-body`,
  t`favorites`,

  t`5 km`,
  t`10 km`,
  t`Half-marathon`,
  t`Marathon`,
  t`Short trail`,
  t`Long trail`,
  t`Utra trail`,
]

export const priorityTitle = {
  posture: t`Posture improvement`,
  efficiency: t`Stride efficiency`,
  dynamics: t`Stride dynamics`,
}
