import { t, Trans } from "@lingui/macro"
import { useEffect, useState } from "react"
import { Link, useNavigate, useSearchParams } from "react-router-dom"
import { toast } from "react-toastify"
import { useRecoilValue, useSetRecoilState } from "recoil"
import { Button, Container, List, Statistic } from "semantic-ui-react"
import useStripe from "../../hooks/useStripe"
import {
  forcePlanificationUpdate,
  hasPremiumSubscription,
  hasSubscription,
  stripeProducts,
  userProgram,
} from "../../recoil/Atoms"
import { Mixpanel } from "../../utils/mixpanel"
import Celebration from "../App/Celebration"
import CTASubscribe from "../shared/CTASubscribe"
import NextBackButtons from "../shared/NextBackButtons"
import {
  ambiantColor,
  ambiantColorAlpha,
  primaryColor,
  StyledButton,
  StyledGiftOptionCard,
  StyledHeader,
  StyledInput,
  StyledList,
  StyledSegment,
} from "../Styled"
import Features from "./Features"
import Price from "./Price"

const SubscribePage = () => {
  const navigate = useNavigate()
  const [selectedProductName, setSelectedProductName] = useState("Premium")
  const [selectedFrequency, setSelectedFrequency] = useState("yearly")
  const [couponCode, setCouponCode] = useState("")
  const [coupon, setCoupon] = useState({})

  const recoilHasSubscription = useRecoilValue(hasSubscription)
  const recoilHasPremiumSubscription = useRecoilValue(hasPremiumSubscription)
  const { pay, validateCoupon } = useStripe()
  const recoilProducts = useRecoilValue(stripeProducts)
  const recoilForcePlanificationGetUpdate = useSetRecoilState(
    forcePlanificationUpdate
  )
  const selectPrice = (name) => {
    setSelectedProductName(name)
  }

  const [searchPrams] = useSearchParams()
  const [planificationDone, setPlanificationDone] = useState()
  const recoilProgram = useRecoilValue(userProgram)

  useEffect(() => {
    Mixpanel.track("Subscribe Page Viewed")
    if (window.location.search.includes("cancel")) {
      Mixpanel.track("Canceled Payment Done")
    }
  }, [])

  useEffect(() => {
    if (
      searchPrams.has("planification-done") &&
      searchPrams.get("planification-done")
    ) {
      recoilForcePlanificationGetUpdate((prev) => prev + 1)
      setPlanificationDone(searchPrams.get("planification-done"))
    }
  }, [recoilForcePlanificationGetUpdate, searchPrams])

  const selectFrequency = (frequency) => {
    setSelectedFrequency(frequency)
  }
  const frequencyOptions = {
    yearly: t`Yearly`,
    monthly: t`Monthly`,
    onetime: t`Race only`,
  }

  const applyCoupon = async () => {
    if (!couponCode) {
      return
    }

    let product = findSelectedProduct()
    const couponResponse = await validateCoupon(couponCode)

    if (couponResponse) {
      if (
        couponResponse &&
        couponResponse.valid &&
        couponResponse.object === "coupon" &&
        selectedFrequency === "yearly" &&
        couponResponse?.applies_to?.products?.includes(product.productId)
      ) {
        toast.success(t`Coupon is valid`)
        Mixpanel.track("Coupon used", { Coupon: couponCode })
        setCoupon(couponResponse)
      } else if (
        couponResponse &&
        couponResponse.valid &&
        couponResponse.object === "giftcard" &&
        couponResponse.isRacePrep === true &&
        window.location.pathname.includes("subscribe")
      ) {
        Mixpanel.track("Giftcard race tried", { giftcard: couponCode })
        toast.success(
          t`Coupon is valid for a race event, please select your race from the home page`
        )
        setCouponCode("")
      } else if (
        couponResponse &&
        couponResponse.valid &&
        couponResponse.object === "giftcard"
      ) {
        Mixpanel.track("Giftcard used", { giftcard: couponCode })
        toast.success(t`Coupon is valid`)
        setCoupon(couponResponse)
      } else if (
        couponResponse &&
        !couponResponse.valid &&
        couponResponse.object === "giftcard"
      ) {
        toast.error(t`Coupon is not valid or has been used already`)
        setCouponCode("")
      } else {
        toast.error(t`Coupon is not valid for the selected product`)
        setCouponCode("")
      }
    } else {
      toast.error(t`Coupon is not valid !`)
      setCouponCode("")
    }
  }

  const order = async () => {
    const productToOrder = recoilProducts.find(
      ({ frequency, name }) =>
        frequency === selectedFrequency && name === selectedProductName
    )

    const { priceId } = productToOrder

    const cancel_url = window.location + "?canceled=1"

    let success_return_base_url =
      window.location +
      `/success?product=${selectedProductName}&frequency=${selectedFrequency}&`

    success_return_base_url = success_return_base_url.replace(
      "//success",
      "/success"
    )

    let payload = {
      priceId,
      locale: "fr",
      allow_promotion_codes: true,
      //coupon: couponCode,
      coupon: selectedFrequency === "yearly" ? couponCode : null,
      giftcard: coupon.object === "giftcard" ? couponCode : null,
      cancel_url,
      success_return_base_url,
    }

    //await pay(payload)

    const { url = null, e = null } = await pay(payload)
    if (url) {
      window.location.href = url
      //      navigate()
    } else {
      if (e?.param.includes("discounts")) {
        toast.error(t`Coupon code is not valid`)
      } else {
        toast.error(t`Could not reach successfully the payment server`)
      }
    }
  }

  useEffect(() => {
    if (recoilHasPremiumSubscription) {
      navigate("/", { replace: true })
    }
  }, [navigate, recoilHasPremiumSubscription])

  const handleCoupon = (value) => {
    setCouponCode(value.toUpperCase())
  }

  const productIsDiscounted = () => {
    const { productId = null } = findSelectedProduct()
    return (
      selectedFrequency === "yearly" &&
      coupon.valid &&
      coupon?.applies_to?.products?.includes(productId)
    )
  }

  const findSelectedProduct = () => {
    return (
      recoilProducts?.find(
        (product) =>
          product.name === selectedProductName &&
          product.frequency === selectedFrequency
      ) || {}
    )
  }

  return (
    <Container className="page  subscribe">
      <StyledHeader className={"page-title"}>
        {planificationDone === "on-ramp" ? (
          <Trans>Well done !</Trans>
        ) : (
          <Trans>Subscriptions</Trans>
        )}
      </StyledHeader>

      <StyledSegment
        className="no-horizontal-padding"
        basic
        style={{
          border: "1px solid transparent",
          maxWidth: "800px",
          padding: 0,
          marginBottom: 0,
        }}
      >
        {planificationDone === "on-ramp" ? (
          <>
            <Celebration style={{ width: 100, margin: "0 auto" }} />
            <StyledHeader
              style={{
                fontWeight: 400,
                marginBottom: "1.2em",
              }}
            >
              <p>
                {recoilProgram === "road" ? (
                  <Trans>
                    To keep strengthening yourself and to keep flying at the end
                    of your races,
                  </Trans>
                ) : (
                  <Trans>
                    To keep strengthening yourself and to keep flying uphill and
                    downhill during your whole trail races,
                  </Trans>
                )}
              </p>
              <Trans>choose the subscription that suits you best !</Trans>
            </StyledHeader>
          </>
        ) : (
          <StyledHeader
            style={{
              fontWeight: 400,
              marginBottom: "1.2em",
              textAlign: "left",
            }}
          >
            <Trans>
              Finally, a specific strengthening program for running that will
              keep you motivated and progress over the long term.
            </Trans>
          </StyledHeader>
        )}
        <div style={{ marginBottom: "2em" }}>
          {["monthly", "yearly"].map((frequency) => (
            <div
              key={frequency}
              onClick={() => selectFrequency(frequency)}
              style={{
                fontWeight: 600,
                fontSize: "1.2em",
                display: "inline-block",
                padding: "0.2em 0.7em",
                border:
                  frequency === selectedFrequency
                    ? `1px solid ${primaryColor}`
                    : "1px solid transparent",
                borderRadius: "1em",
                margin: "0.2em 0.2em",
                transition: "all 0.6s ease",
              }}
            >
              {frequencyOptions[frequency]}
            </div>
          ))}

          <div
            onClick={() => navigate("/race")}
            style={{
              fontWeight: 600,
              fontSize: "1.2em",
              display: "inline-block",
              padding: "0.2em 0.7em",
              border: `1px solid ${ambiantColor}`,
              backgroundColor: ambiantColorAlpha,

              borderRadius: "1em",
              margin: "0.2em 0.2em 0 0.6em",
              transition: "all 0.6s ease",
            }}
          >
            {frequencyOptions["onetime"]}
          </div>
        </div>
        <div style={{ textAlign: "right" }}>
          <Button.Group>
            {recoilProducts
              .filter((product) => product.frequency === selectedFrequency)
              .map(({ name, unit_amount, productId, frequency }) => (
                <StyledButton
                  key={name}
                  onClick={() => {
                    selectPrice(name)
                    Mixpanel.track("Price Button Clicked", {
                      Product: name,
                      Frequency: selectedFrequency,
                    })
                  }}
                  data-testid={"option-" + name}
                  className={
                    "as-tab " +
                    (selectedProductName === name ? "selected" : "no-border")
                  }
                  simple={selectedProductName !== name ? "true" : null}
                  style={{ marginBottom: 0, marginRight: 0 }}
                  size="large"
                >
                  <div className="subscription-name">{name}</div>
                </StyledButton>
              ))}
          </Button.Group>
        </div>
        <div style={{ position: "relative", padding: "0 0em 0 0em" }}>
          <Features selectedProductName={selectedProductName} />
          <div style={{ textAlign: "center", marginTop: 20 }}>
            {coupon.object === "giftcard" ? (
              <StyledGiftOptionCard
                className={"selected"}
                style={{ padding: "1em" }}
              >
                <div className="title">{coupon.name}</div>
                <StyledList
                  inverted
                  divided
                  relaxed
                  verticalAlign="top"
                  className="description"
                >
                  <List.Item style={{ paddingTop: 8, paddingBottom: 0 }}>
                    <List.Content>{coupon.description}</List.Content>
                  </List.Item>
                </StyledList>

                <Statistic inverted size="tiny" style={{ marginTop: 0 }}>
                  <Statistic.Value style={{ textTransform: "capitalize" }}>
                    <Trans>Gift</Trans>
                  </Statistic.Value>
                </Statistic>
              </StyledGiftOptionCard>
            ) : (
              <div style={{ position: "relative", paddingBottom: 20 }}>
                <Price
                  products={recoilProducts}
                  selectedProductName={selectedProductName}
                  selectedFrequency={selectedFrequency}
                  coupon={coupon}
                  productIsDiscounted={productIsDiscounted}
                />
              </div>
            )}

            {selectedProductName !== "Free" && (
              <>
                {!recoilHasSubscription && (
                  <div
                    style={{
                      padding: "0 0 1em 0",
                      textAlign: "center",
                    }}
                  >
                    <StyledInput
                      inverted
                      disabled={!!coupon.object}
                      transparent
                      name="coupon"
                      placeholder={t`COUPON / GIFT CARD`}
                      value={couponCode}
                      onChange={(e, { value }) => {
                        handleCoupon(value)
                      }}
                      style={{
                        borderRadius: " 0.3rem 0.3rem ",
                        border: "1px solid teal",
                        maxWidth: 400,
                        width: "95%",
                      }}
                      action={{
                        color: "teal",
                        content: t`Apply`,
                        icon: productIsDiscounted() ? "check" : null,
                        onClick: applyCoupon,
                      }}
                      className="centered-text uppercase"
                    />
                  </div>
                )}
                <CTASubscribe
                  orderFunction={order}
                  buttonLabel={
                    coupon.object === "giftcard" ? t`go for it !` : null
                  }
                />
              </>
            )}
          </div>
          {selectedProductName !== "Free" && coupon.object !== "giftcard" && (
            <StyledSegment basic textAlign="left">
              <Trans>
                The subscription automatically renews unless it is cancelled at
                least 24 hours before the end of the period. You can manage and
                cancel your subscription from the{" "}
                <Link to="/settings">settings page</Link> page of the app.
              </Trans>
            </StyledSegment>
          )}
        </div>{" "}
        <NextBackButtons position={"relative"} backOnly />
      </StyledSegment>
    </Container>
  )
}

export default SubscribePage
