// imports

import { Trans } from "@lingui/macro"
import { useEffect, useRef, useState } from "react"
import useScreenOrientation from "react-hook-screen-orientation"
import { useLocation } from "react-router-dom"
import { useRecoilValue } from "recoil"
import { Button, Icon } from "semantic-ui-react"
import { currentVideoId } from "../../recoil/Atoms"
import { StyledNextVideoOverlay, StyledVideoOverlay } from "../Styled"
import Metronome from "./Metronome/Metronome"

const VideoLoop = ({
  id,
  videoLoopStart: startLoop,
  videoLoopEnd: endLoop,
  overlay,
  isPlaying,
  metronomeStart,
  metronomeBpm,
  metronomeStartsMuted = false,
}) => {
  const player = useRef()
  var frameRate = 24

  const [timeFrame, setTimeFrame] = useState(0)
  const [metronomeMuted, setMetronomeMuted] = useState(metronomeStartsMuted)
  const [metronomePlaying, setMetronomePlaying] = useState(false)
  const { pathname } = useLocation()

  let recoilCurrentVideo = useRecoilValue(currentVideoId)

  const phoneOrientation = useScreenOrientation()
  const phoneOrientationIsPortrait =
    phoneOrientation?.includes("portrait") || window.orientation === 0

  useEffect(() => {
    let { current: video } = player

    if (isPlaying) {
      video.play()
    } else {
      video.pause()
    }
  }, [isPlaying])

  useEffect(() => {
    let { current: video } = player
    if (video) {
      video.onplay = () => {
        setInterval(function () {
          //  getCurrentVideoFrame(video)
          let curTime = video.currentTime
          let timeFrame1 = Math.floor(curTime * frameRate)
          setTimeFrame(timeFrame1)

          if (timeFrame1 >= endLoop) {
            video.currentTime = (startLoop + 0) / frameRate
          }
        }, 1000 / frameRate)
      }
    }
  }, [endLoop, frameRate, startLoop])

  const playerOptions = {
    muted: true,
    volume: 0,
    width: "100%",
    height: "100%",
    loop: true,
    playsInline: true,
  }
  const toggleMuteMetronome = (e) => {
    // startAudioContext()
    setMetronomeMuted((prev) => !prev)
    e.stopPropagation()
  }
  const startMetronome = (params) => {
    setMetronomePlaying(true)
  }

  useEffect(() => {
    if (parseInt(timeFrame) >= parseInt(metronomeStart) && metronomeBpm) {
      startMetronome()
    }
  }, [
    id,
    metronomeBpm,
    metronomeStart,
    pathname,
    recoilCurrentVideo,
    timeFrame,
  ])

  return (
    <div style={{ position: "relative" }}>
      {id && (
        <>
          <video
            poster={
              "https://image.mux.com/" + id + "/thumbnail.jpg?time=0&width=500"
            }
            {...playerOptions}
            ref={player}
            style={{
              //  filter: "contrast(1.15) brightness(0.8)",
              maxHeight: !phoneOrientationIsPortrait ? "none" : "45vh",
            }}
          >
            <source
              type="video/mp4"
              src={
                "https://stream.mux.com/" + id + "/low.mp4#0"
                /*.replace('https', 'http')*/
              }
            />
          </video>
        </>
      )}
      {metronomeBpm && (
        <div
          style={{
            zIndex: 10,
            position: "absolute",
            bottom: 8,
            left: "50%",
            transform: "translateX(-50%)",
          }}
        >
          <Button
            circular
            onClick={toggleMuteMetronome}
            size="mini"
            color={"blue"}
            // style={{ backgroundColor: ambiantColor, color: "white" }}
            className="icon"
          >
            <Icon className={`volume ${!metronomeMuted ? "up" : "mute"}`} />
          </Button>

          <Metronome
            embedded={true}
            bpm={metronomeBpm}
            isPlaying={metronomePlaying && !metronomeMuted}
          />
        </div>
      )}

      {overlay ? (
        <StyledNextVideoOverlay>
          <div className="content">
            <div className="to-come">
              <Trans>To come</Trans> :
            </div>
            <div className="exercise">{overlay}</div>
          </div>
        </StyledNextVideoOverlay>
      ) : (
        <StyledVideoOverlay></StyledVideoOverlay>
      )}
    </div>
  )
}

export default VideoLoop
