import { useSearchParams } from "react-router-dom"
import NextBackButtons from "../../shared/NextBackButtons"
import SessionContent from "./SessionContent/SessionContent"
import SessionHeader from "./SessionContent/SessionHeader"
import { WarmupButton } from "./SessionContent/WarmupButton"
import StartButton from "./StartButton"
import VideoCarousel from "./VideoCarousel"

const Abstract = ({ videos, session }) => {
  const [searchParams] = useSearchParams()
  return (
    <div style={{ paddingBottom: 30 }}>
      <SessionHeader session={session} />
      <VideoCarousel videos={videos} />
      <SessionContent session={session} />
      <NextBackButtons
        backOnly
        position="absolute"
        backUrl={
          searchParams.has("is-warmup") ||
          session.planificationName.includes("open")
            ? null /*default*/
            : "/session/week"
        }
      />
      <WarmupButton sessionId={session.id} warmupId={session.warmupId} />
      <StartButton active={!session.done} />
    </div>
  )
}

export default Abstract
