import { Trans } from "@lingui/macro"
import { useEffect, useState } from "react"
import { useMediaQuery } from "react-responsive"
import { useRecoilState } from "recoil"
import { Image } from "semantic-ui-react"
import useDrawer from "../../hooks/useDrawer"
import playInstallButton from "../../images/play-install-btn.png"
import shareIcon from "../../images/iphone_share.png"
import {
  googlePlayStoreInstallUrl,
  installMessageDisplayed,
} from "../../recoil/Atoms"
import { ambiantColor, defaultTextColor, StyledHeader } from "../Styled"
import { Mixpanel } from "../../utils/mixpanel"

const PwaInstallPrompt = () => {
  var userAgent = navigator.userAgent.toLowerCase()

  var isAndroid = userAgent.indexOf("android") > -1
  var isIos = !isAndroid

  const isInStandaloneMode =
    "standalone" in window.navigator && window.navigator.standalone //ios

  var isInstalled =
    isAndroid && document.referrer.startsWith("android-app://")
      ? true
      : isInStandaloneMode
      ? true
      : false

  const { setContentAndOpen, closeDrawer } = useDrawer()

  const [hasDismissedInstallPrompt] = useState(
    localStorage.getItem("RFRinstallDismissed")
  )
  const [recoilInstallMessageDisplayed, setRecoilInstallMessageDisplayed] =
    useRecoilState(installMessageDisplayed)

  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" })

  const recoilGooglePlayStoreInstallUrl = googlePlayStoreInstallUrl

  useEffect(() => {
    const navigateToStore = () => {
      Mixpanel.track("Button Play Store Clicked")
      window.location.href = recoilGooglePlayStoreInstallUrl
    }

    const dismissMessage = () => {
      closeDrawer()
      localStorage.setItem("RFRinstallDismissed", "1")
    }
    const install = (os) => {
      setTimeout(() => {
        setContentAndOpen(
          <>
            {os === "android" ? (
              <>
                {Mixpanel.track("Android Install Prompt Displayed")}
                <StyledHeader className="info">
                  <Trans>
                    For the smoothest experience, install RenfoRun from the
                    google Play store !
                  </Trans>
                </StyledHeader>
                <Image
                  src={playInstallButton}
                  size="small"
                  style={{ margin: "auto" }}
                  onClick={navigateToStore}
                />
              </>
            ) : (
              <>
                {Mixpanel.track("Ios Install Prompt Displayed")}
                <StyledHeader className="info">
                  <Trans>
                    For the smoothest experience, add RenfoRun to your home
                    screen !
                  </Trans>
                </StyledHeader>
                <div
                  style={{
                    marginTop: "1em",
                    color: defaultTextColor,
                  }}
                  onClick={dismissMessage}
                >
                  <div>
                    1 - <Trans>Open the app with Safari</Trans>
                  </div>
                  <div>
                    2 - <Trans>Click on Share</Trans>{" "}
                    <img
                      src={shareIcon}
                      style={{ height: 25, verticalAlign: "bottom" }}
                      alt=""
                    />
                  </div>
                  <div>
                    3 - <Trans>Choose 'add to home screen"</Trans>{" "}
                  </div>
                </div>
              </>
            )}
            <div
              style={{
                textAlign: "center",
                marginTop: "1em",
                color: ambiantColor,
              }}
              onClick={dismissMessage}
            >
              <Trans>Do not display this message anymore</Trans>
            </div>
          </>
        )

        setRecoilInstallMessageDisplayed(true)
      }, 1000)
    }

    if (
      isTabletOrMobile &&
      !hasDismissedInstallPrompt &&
      !recoilInstallMessageDisplayed
    ) {
      if (isAndroid && !isInstalled) {
        install("android")
      }

      if (isIos && !isInstalled) {
        install("ios")
      }
    }
  }, [
    closeDrawer,
    hasDismissedInstallPrompt,
    isAndroid,
    isInStandaloneMode,
    isInstalled,
    isIos,
    isTabletOrMobile,
    recoilGooglePlayStoreInstallUrl,
    recoilInstallMessageDisplayed,
    setContentAndOpen,
    setRecoilInstallMessageDisplayed,
  ])
}

export default PwaInstallPrompt
