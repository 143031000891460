import LogRocket from "logrocket"
import setupLogRocketReact from "logrocket-react"
import { mustExcludeFromTracking } from "."
import { Mixpanel } from "./mixpanel"
import Tracker from "@openreplay/tracker"
const PROJECT_KEY_DEV = "czf482/renforun"
const PROJECT_KEY_PROD = "czf482/renforun-prod"

const OR_PROJECT_KEY_DEV = "mCulXJNudHzeiPaB3SQF"
const OR_PROJECT_KEY_PROD = "GdsZRm5LG3wNyy5Z3wr6"

const isDevMode = window.location.host !== "renforun.app" ? true : false

const trackerCode = isDevMode ? OR_PROJECT_KEY_DEV : OR_PROJECT_KEY_PROD

const disableSecure = isDevMode ? true : false

let tracker = null

if (!mustExcludeFromTracking() /*|| isDevMode*/) {
  LogRocket.init(isDevMode ? PROJECT_KEY_DEV : PROJECT_KEY_PROD)

  setupLogRocketReact(LogRocket)
  LogRocket.getSessionURL(function (sessionURL) {
    Mixpanel.track("LogRocket", { sessionURL: sessionURL })
  })

  tracker = new Tracker({
    projectKey: trackerCode,
    obscureTextEmails: false,
    obscureTextNumbers: false,
    obscureInputEmails: true,
    __DISABLE_SECURE_MODE: disableSecure,
  })
}

export default tracker
