import styled, { createGlobalStyle, css } from "styled-components"
import {
  Button,
  Card,
  Form,
  Header,
  Input,
  Label,
  List,
  Menu,
  Segment,
  Statistic,
} from "semantic-ui-react"
import Drawer from "react-bottom-drawer"
import Slider from "react-slick"
import { ToastContainer } from "react-toastify"
import homeIcon from "../images/home_logo.png"
import homeIconInactive from "../images/home_logo_inactive.png"

export const defaultTextColorRGB = "221 221 221"
export const defaultTextColor = `rgb(${defaultTextColorRGB})`

//const bgColorDarkRGB = "6 9 14"
export const bgColorDarkRGB = "12 22 34"
const bgColorDark = `rgb(${bgColorDarkRGB})`
const bgBottomMenu = `rgb(13 23 36)`

const bgColorLightRGB = "39 53 84 "
export const bgColorLight = `rgb(${bgColorLightRGB})`

//const primaryColorRGB = "242 113 70" //orange d'origine

const primaryColorRGB = "243 95 66" //orange logo
export const primaryColor = `rgb(${primaryColorRGB})`

const secondaryColorRGB = "0 181 173 "
export const secondaryColor = `rgb(${secondaryColorRGB})`

//const ternaryColor = "#8261c0"
export const ternaryColor = "#794ccd"

export const primaryColorAlpha = `rgb(${primaryColorRGB}/ 80%)`
export const primaryColorAlpha60 = `rgb(${primaryColorRGB}/ 60%)`
export const secondaryColorAlpha = `rgb(${secondaryColorRGB} / 40%)`

export const ambiantColorRGB = `62 152 199` //text that can match the general ambiance
export const ambiantColor = `rgb(${ambiantColorRGB})`
export const ambiantColorAlpha = `rgb(${ambiantColorRGB} / 60%)`

export const GlobalStyle = createGlobalStyle`
body {
	color:${defaultTextColor};
	background: linear-gradient(180deg,  ${bgColorLight} 0%,${bgColorDark} 100%) !important;
}
  .App{
	min-height: 100vh;
  }
  #root{
	background: linear-gradient(180deg,  ${bgColorLight} 0%,${bgColorDark} 100%) !important;
  }
`
export const StyledBody = (props) => {
  let { url, backgroundPosition = "center center" } = props
  return (
    <>
      <style>
        {`
		  #root {
			background: none !important;
		  }

		  body {
			-webkit-transition: all 0.6s ease;
			-moz-transition: all 0.6s ease;
			transition: all 0.6s ease;
			background: none !important;
			background-image: url(${url}) !important;
			background-position: ${backgroundPosition} !important;
			background-repeat: no-repeat;
			background-size: cover !important;
		  }
		  .overlay {
			position: fixed !important;
			top: 0;
			left: 0;
			display: block;
			height: 100vh;
			width: 100%;

			background: linear-gradient(to bottom, rgb(5 39 94 / 53%), rgb(10 16 25));
		  }
	`}
      </style>
      <div className="overlay"></div>
    </>
  )
}

export const StyledToastContainer = styled(ToastContainer)`
  .Toastify__toast {
    border-radius: 0.8em;
  }

  .Toastify__toast--success {
    border: 1px solid #0adf87;
  }
`

export const StyledHeader = styled(Header)`
  color: ${defaultTextColor} !important;
  // word-wrap: break-word; //permet d'éviter les ? seuls sur une ligne
  // white-space: pre; //permet d'éviter les ? seuls sur une ligne
  &.white {
    color: #fff !important;
  }

  &.info {
    text-transform: uppercase;
    font-size: 11px !important;
    letter-spacing: 2px;
    font-weight: 800;
    margin-bottom: 0.3em !important;
  }
  &.section-title {
    text-align: left;
    margin-bottom: 0 !important;
  }

  &.page-title {
    color: ${defaultTextColor};
    text-align: left;
    font-size: 1.7rem !important;
    padding: 1.5rem 0rem 1rem 0;
    //font-family: "Bakbak One";
    font-weight: bold !important;
    height: 50px;
    margin-bottom: 0;
  }

  &.session-title {
    text-transform: uppercase;
    color: ${defaultTextColor};
    text-align: left;
    font-size: 1.1rem !important;
    padding: 1rem 0rem 1rem 5px;
    //font-family: "Bakbak One";
    font-weight: bold !important;
    height: 40px;
    margin-bottom: -10px;
  }
  ${(props) =>
    props.underlined &&
    css`
      border-bottom: 1px solid ${defaultTextColor} !important;
    `}

  ${(props) =>
    props.ambiant &&
    css`
      color: ${ambiantColor}!important;
    `}
`

export const StyledHelpIcon = styled.div`
  font-size: 1.2em !important;
  vertical-align: middle;
  padding: 0px 0px 0px 10px;
  position: relative;

  & i {
    color: ${ambiantColor} !important;
  }
  ${(props) =>
    props.float &&
    css`
      position: absolute;
      top: 0rem;
      right: 0rem;
      padding: 10px 10px 20px 20px;
    `}

  ${(props) =>
    props.fontSize &&
    css`
      font-size: ${props.fontSize} !important;
    `}
`

export const StyledTopMenu = styled(Menu)`
  &.ui.menu.fixed {
    max-width: 800px;
    left: 0;
    right: 0;
    margin: 0 auto;
    text-align: center;
    min-height: 0;
    /*   bottom: 0px;
    padding-bottom: 5px;
	*/
  }
  .app-logo {
    height: 20px;
    margin: 8px auto 8px;
    left: 0;
    right: 0;
    @media only screen and (max-width: 350px) {
      /* iPhone 5 only */
      //max-width: 240px;
      height: 24px;
    }
  }

  &.ui.menu .item {
    margin: 0 auto;
  }
  &.inverted.menu {
    background-color: ${bgColorLight} !important ;

    a.active.item,
    a.active.item:hover {
      color: ${primaryColor} !important;
      background-color: transparent !important ;
    }
  }
`

export const StyledBottomMenu = styled(Menu)`
  &.ui.menu.fixed {
    max-width: 800px;
    bottom: 0px;
    padding-bottom: 5px;
    left: 0;
    right: 0;
    margin: 0 auto;
  }

  &.ui.menu .item {
    margin: 0 auto;
    padding: 7px 16px 13px;
  }
  &.inverted.menu {
    background-color: ${bgBottomMenu} !important ;

    a.active.item,
    a.active.item:hover {
      color: ${primaryColor} !important;
      background-color: ${bgBottomMenu}!important;
    }
  }
  .item {
    .app-logo-menu {
      height: 20px;

      &::after {
        content: url(${homeIconInactive});
        height: 20px;
      }
    }
  }

  .item.active {
    .app-logo-menu {
      height: 20px;

      &::after {
        content: url(${homeIcon});
        height: 20px;
      }
    }
  }
`
export const StyledSpan = styled.span`
  color: ${primaryColor}!important;

  ${(props) =>
    props.secondary &&
    css`
      color: ${secondaryColor}!important;
    `}
`
export const StyledLabel = styled(Label)`
  background-color: ${primaryColorAlpha}!important;
  color: #fff !important;
  text-align: center;
  border: 1px solid ${primaryColor} !important;
  &.small {
    padding: 0.2em 0.5em 0.3em !important;
  }

  ${(props) =>
    props.basic &&
    css`
      background-color: transparent !important;
      border-color: ${primaryColorAlpha}!important;
      color: ${primaryColor}!important;
    `}

  ${(props) =>
    props.basicwhite &&
    css`
      background-color: transparent !important;
      border: 2px solid ${primaryColor} !important;
      //  border-color: ${primaryColorAlpha}!important;
      color: #fff !important;
    `}

  ${(props) =>
    props.fullwhite &&
    css`
      background-color: transparent !important;
      border: 2px solid ${defaultTextColor} !important;
      //  border-color: ${primaryColorAlpha}!important;
      color: ${defaultTextColor} !important;
    `}
  ${(props) =>
    props.secondary &&
    css`
      background-color: ${secondaryColor}!important;
      border-color: ${secondaryColor}!important;
      color: #fff !important;
    `}

  ${(props) =>
    props.ambiant &&
    css`
      background-color: rgb(
        ${ambiantColorRGB} / ${props.alpha ? 40 : 100}%
      ) !important;
      border-color: ${ambiantColor}!important;
      color: #fff !important;
    `}
	${(props) =>
    props.ambiant &&
    props.basic &&
    css`
      background-color: transparent !important;
      border-color: rgb(${ambiantColorRGB} / 40%) !important;
      color: ${ambiantColor}!important;
    `}

  ${(props) =>
    props.seconday &&
    props.alpha &&
    css`
      background-color: ${secondaryColorAlpha}!important;
      border-color: ${secondaryColorAlpha}!important;
    `}
	${(props) =>
    props.overrideborder &&
    css`
      border-color: ${props.overrideborder}!important;
    `}

	${(props) =>
    props.secondary &&
    props.basic &&
    css`
      background-color: transparent !important;
      border-color: ${secondaryColorAlpha}!important;
      color: ${secondaryColor}!important;
    `}

	${(props) =>
    props.secondary &&
    props.basicwhite &&
    css`
      background-color: transparent !important;
      border-color: ${secondaryColor}!important;
      color: #fff !important;
    `}
`

export const StyledCard = styled(Card)`
  background-color: ${bgColorLight} !important;
`

export const StyledGAuthContainer = styled(Segment)`
  & > iframe {
    margin: 0 auto;
  }
`
export const StyledSegment = styled(Segment)`
  white-space: pre-wrap;
  &.no-horizontal-padding {
    padding-left: 0;
    padding-right: 0;
  }
  .settings & {
    background-color: rgb(${bgColorDarkRGB} / 20%);
    border-radius: 0.5em;
    margin-bottom: 0.2em;
    padding: 0.8em;
  }

  ${(props) =>
    props.info &&
    css`
      background: linear-gradient(
        180deg,
        rgb(${ambiantColorRGB} / 25%) 0%,
        rgb(34 113 155 / 25%) 100%
      ) !important;
      border-radius: 0.5em !important;
      border: 1px solid rgb(62 152 199 / 63%) !important;
      color: #fff !important;
    `}

  ${(props) =>
    props.infoPrimary &&
    css`
      background: linear-gradient(
        180deg,
        rgb(${primaryColorRGB} / 100%) 0%,
        rgb(${primaryColorRGB} / 90%) 100%
      ) !important;
      border-radius: 0.5em !important;
      color: #fff !important;
    `}


  ${(props) =>
    props.primarybg &&
    css`
      background-color: ${primaryColor}!important;
      color: #fff !important;
    `}
  ${(props) =>
    props.primarybgalpha &&
    css`
      background-color: ${primaryColorAlpha}!important;
      border: 1px solid ${primaryColor}!important;
      color: #fff !important;
    `}
	${(props) =>
    props.lightbgalpha &&
    css`
      background-color: ${`rgb(${bgColorLightRGB}/ 10%)`} !important;
      border-radius: 0.5em !important;
    `}


	${(props) =>
    props.ambiantbg &&
    css`
      background-color: rgb(
        ${ambiantColorRGB} / ${props.alpha ? props.alpha : 100}%
      ) !important;
      border-color: ${ambiantColor}!important;
      color: #fff !important;
    `}
`

export const StyledList = styled(List)`
  text-align: left;

  &.default-color .content {
    color: ${defaultTextColor} !important;
  }

  &.divided-light > .item {
    border-color: rgba(255, 255, 255, 0.3) !important;
  }
  &.very-relaxed {
    .item {
      padding-top: 0.8em !important;
      padding-bottom: 0.8em !important;
    }
  }

  &.very-very-relaxed {
    .item {
      padding-top: 1em !important;
      padding-bottom: 1em !important;
    }
  }

  & .item {
    & .image {
      border-radius: 0.2em;
      width: 3.5em;
      height: 3.5em;
      float: left;
    }
    & > img.image + .content {
      display: flex !important;
      align-items: center;
    }
    & .icon.right {
      //   margin: 1.5em 0.25rem 0 0;
    }

    & .icon.primary {
      color: ${primaryColor} !important;

      &.circular-white {
        background-color: ${primaryColor};
        color: white !important;
        border-radius: 50em;
        width: 1.3em;
        height: 1.3em;
        padding: 0.15em 0em 0em 0em;
        font-size: 0.9em;
      }
    }
    & .icon.secondary {
      color: ${secondaryColor} !important;
    }
    & .primary-bg-alpha {
      background-color: rgb(${primaryColorRGB} / 30%);
    }
    &.secondary-bg {
      background-color: ${secondaryColor};
      color: #fff !important;
      font-weight: bold;
      .content {
        color: #fff !important;
      }
    }

    &.get-premium {
      background-color: ${secondaryColor};

      border-radius: 0.3em;
      color: #fff !important;
      font-weight: bold;
      padding-left: 0.2em;
      .content {
        color: #fff !important;
        text-align: center;
      }
    }

    &.secondary {
      color: ${secondaryColor}!important;
      font-weight: bold;
    }

    &.selected {
      border-radius: 0.5em;
    }
  }
`

export const StyledPacksList = styled(List)`
  text-align: left;

  .content {
    color: ${defaultTextColor} !important;
  }
  & > .item {
    margin-bottom: 20px;
    padding: 0 0 1.1em 0 !important;
    position: relative;
    border: 1px solid ${ambiantColor} !important;
    border-radius: 10px;

    &.selected {
      background-color: ${ambiantColor} !important;
      .header.main,
      .description,
      .value,
      .label {
        color: #fff !important;
      }
    }

    .header {
      border-radius: 8px 8px 0 0;
      padding: 0.5em 0 1em 1em;
      color: #ffffff;

      // background-color: ${primaryColor}!important;

      background: linear-gradient(
        125deg,
        rgb(41 108 143 / 17%) 0%,
        rgb(62 152 199 / 27%) 100%
      ) !important ;

      &.main {
        color: ${ambiantColor} !important;
        text-transform: uppercase;
        font-size: 1.1rem;
        padding-top: 1em;
        font-weight: 900;
      }
    }

    .infos {
      padding: 0 0.5em 0 0.5em;
      // display: inline-block;
      //vertical-align: middle;

      .description {
        line-height: 1.2em;
        margin-top: 0.5em;
        font-size: 1.1rem;
      }
    }

    .statistic {
      & > .label {
        margin-left: 0.25em !important;
      }

      &.price {
        margin-top: 5px !important;

        & .value {
          color: ${ambiantColor};
          font-size: 1.9rem !important;
        }
        & .label {
          font-size: 1.1rem !important;
          margin-left: 0.1em !important;
          color: ${ambiantColor};
        }
      }
      &.original-price {
        text-decoration-line: line-through;
        text-decoration-color: ${secondaryColor};
        margin-left: 0.1em !important;
        & .value {
          color: ${secondaryColor}!important;
          font-size: 1.2rem !important;
        }
        & .label {
          margin-left: 0.1em !important;
          color: ${secondaryColor}!important;
        }
      }
    }
  }
`

export const StyledEventsList = styled(List)`
  text-align: left;

  &.default-color .content {
    color: ${defaultTextColor} !important;
  }

  .item {
    margin-bottom: 20px;
    padding: 0 0 1.1em 0 !important;
    position: relative;
    border: 1px solid ${ambiantColor} !important;
    border-radius: 10px;

    &.current {
      border: 1px solid ${primaryColor} !important;
    }

    .event-header {
      border-radius: 8px 8px 0 0;
      padding: 0.5em 0 1em 1em;
      color: #ffffff;

      // background-color: ${primaryColor}!important;

      background: linear-gradient(
        125deg,
        rgb(41 108 143 / 17%) 0%,
        rgb(62 152 199 / 27%) 100%
      ) !important ;

      .event-date {
        font-weight: normal !important;
        font-size: 0.9rem;
      }
    }
    .main {
      color: ${primaryColor} !important;
      text-transform: uppercase;
      font-size: 1.1rem;
      padding-top: 0.5em;
      font-weight: 900;
    }
  }
  .link-chevron {
    position: absolute;
    right: 0;
    top: 1.3rem;
  }

  .infos {
    padding: 0 0.5em 0 0.5em;
    // display: inline-block;
    //vertical-align: middle;

    .description {
      line-height: 1.2em;
      margin-top: 0.5em;
      font-size: 1.1rem;
    }
  }
  & .icon.primary {
    color: ${primaryColor} !important;
  }

  .prices {
    float: right;
  }
  .statistic {
    & > .label {
      margin-left: 0.25em !important;
    }

    &.distance,
    &.program {
      padding-top: 0.2em;
      & .value {
        font-weight: bold;
        color: ${primaryColor}!important;
      }
    }

    &.price {
      //  margin-top: 5px !important;
      margin-right: 5px !important;
      & .value {
        color: ${secondaryColor}!important;
        font-size: 1.8rem !important;
      }
      & .label {
        font-size: 1.1rem !important;
        margin-left: 0.1em !important;
        color: ${secondaryColor}!important;
      }
    }
    &.original-price {
      text-decoration-line: line-through;
      text-decoration-color: ${secondaryColor};
      margin-left: 0.1em !important;
      & .value {
        color: ${secondaryColor}!important;
        font-size: 1.2rem !important;
      }
      & .label {
        margin-left: 0.1em !important;
        color: ${secondaryColor}!important;
      }
    }
  }
  .event-bottom {
    padding-top: 0.5em;
  }

  .price-included {
    text-align: center;
    color: ${secondaryColor}!important;
    font-size: 1rem !important;
  }

  .current-planification {
    text-align: center;
    color: ${primaryColor}!important;
    font-size: 1.1rem !important;
    font-weight: bold;
  }
`

const drawerSlideControlHeight = 60
export const StyledDrawer = styled(Drawer)`
  &.drawer {
    width: 100%;
    background: linear-gradient(180deg, #273554 0%, #374b78 100%) !important;
    padding-bottom: 15px;
    z-index: 1001;
    min-height: 150px;
    color: white;
  }
  & .info {
    text-transform: uppercase;
    font-size: 11px;
    letter-spacing: 2px;
    font-weight: 800;
    color: ${secondaryColor};
    opacity: 0.8;
    margin-bottom: 0.3em !important;
  }
  & .drawer__content {
    margin-top: -${drawerSlideControlHeight - 10}px;
    max-height: calc(100vh - 95px);

    text-align: left !important;
    .header {
      text-transform: uppercase;
      margin-bottom: 0 !important;
      padding-left: 1em;
    }
    .segment {
      font-size: 1em !important;
      margin-top: 0 !important;
    }
  }
  & .drawer__handle-wrapper {
    padding-bottom: ${drawerSlideControlHeight}px;
    z-index: 100;
    position: relative;
  }
  & .drawer__handle {
    background-color: ${secondaryColor};
  }
  & .drawer-close-button {
    text-transform: uppercase !important;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    bottom: 1.5rem;
    background-color: ${secondaryColor};
    color: #fff;
    font-size: 11px;
  }
`

export const StyledForm = styled(Form)`
  .field > label,
  label {
    color: ${ambiantColor}!important;
    font-weight: 700;
  }

  .field {
    background: transparent;
    color: #fff;
    border: none;
    border-bottom: 1px solid grey;
    border-radius: 0;
    padding: 0em 0em 0.2em 0.2em;
  }
  .input,
  .input input[type="text"],
  .search input[type="text"],
  .input input[type="password"] {
    background: transparent !important;
    //  box-shadow: 0 0 0 100px #263452 inset !important;
    color: ${defaultTextColor} !important;
    border: none !important;
    &:focus {
      color: ${defaultTextColor} !important;
      border: none;
    }

    & i.icon {
      color: ${defaultTextColor};
    }
  }
  &.ui.form .field.field {
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      box-shadow: 0 0 0 100px #263452 inset !important;
      border: none !important;
      border-radius: 0;
      color: grey;
      -webkit-text-fill-color: ${defaultTextColor};
      transition: background-color 5000s ease-in-out 0s;
    }
  }

  .ui.selection.dropdown,
  .select {
    background: transparent;
    padding-right: 0em;
    color: ${defaultTextColor} !important;
    .dropdown.icon {
      padding-top: 0.2em;
    }
    & > .text {
      color: ${defaultTextColor} !important;
    }
  }
  .date-select {
    .dropdown .menu {
      max-height: 12rem;
      background: ${bgColorLight};
      padding-bottom: 1em;
      .item {
        color: #fff;
        border-top: 1px solid hsl(0deg 0% 98% / 5%);
        &.selected {
          background: ${ambiantColor}!important;
        }
      }
    }
  }

  .signup-select {
    .dropdown {
      padding-left: 2.67142857em;
      padding-right: 1em;
    }
    i.icon {
      cursor: default;
      position: absolute;
      line-height: 1;
      text-align: center;
      top: 0;
      right: 0;
      margin: 0;
      height: 100%;
      width: 2.1em;
      opacity: 0.5;
      right: auto;
      left: 1px;
      font-size: 1.3em;
      &:before {
        left: 0;
        position: absolute;
        text-align: center;
        top: 50%;
        width: 100%;
        margin-top: -0.5em;
      }
    }
    .selection .default.text {
      color: grey !important;
    }
  }
`

export const StyledInput = styled(Input)`
  &.centered-text {
    input {
      text-align: center;
    }

    input:active {
      background-color: transparent !important;
    }
  }

  .ui.teal.button {
    background-color: ${secondaryColorAlpha};
  }
`

export const StyledFormCheckbox = styled(Form.Input)`
  background: transparent;
  color: #fff;
  border: none;
  padding: 0.15em 1em 0.2em 0.2em;

  .ui.input {
    background: transparent;
    width: auto !important;
    color: ${defaultTextColor};

    &:focus {
      border: none;
    }
    input[type="checkbox"] {
      width: 20px !important;
      margin-right: 0.5em;
    }
  }

  .ui.form &.field {
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      transition: background-color 5000s ease-in-out 0s;
      border: none !important;
      border-radius: 0;
      color: grey;
      -webkit-text-fill-color: ${defaultTextColor};
    }
  }
`

export const StyledButton = styled(Button)`
  background-color: ${primaryColor} !important;
  text-transform: capitalize;
  color: #fff !important;
  margin-top: 0.3em;
  margin-left: 0.3em !important;
  margin-right: 0.3em !important;

  a {
    color: #fff !important;
  }

  ${(props) =>
    props.small &&
    css`
      padding: 0.4em 1.5em 0.45em !important;
    `}

  ${(props) =>
    props.uppercase &&
    css`
      text-transform: uppercase !important;
    `}
  ${(props) =>
    props.wide
      ? css`
          width: 95%;
          border-radius: 0.3em !important;
          max-width: 400px;
		  margin 0 auto;
        `
      : css`
          // border-radius: 4em !important;
          border-radius: 0.3em !important;
        `}
  ${(props) =>
    props.simple &&
    css`
      background: transparent !important;
      color: ${defaultTextColor} !important;
      border: 1px solid ${primaryColor} !important;
    `}
  ${(props) =>
    props.secondary &&
    css`
      background-color: ${`rgb(${secondaryColorRGB} / ${
        props.alpha ? props.alpha : 100
      }%)`} !important;
    `}
	${(props) =>
    props.ambiant &&
    css`
      background-color: ${`rgb(${ambiantColorRGB} / ${
        props.alpha ? props.alpha : 100
      }%)`} !important;
    `}

	${(props) =>
    props.simple &&
    props.secondary &&
    css`
      background: transparent !important;
      color: ${defaultTextColor} !important;
      border: 1px solid ${secondaryColor} !important;
    `}
	${(props) =>
    props.ternary &&
    css`
      background-color: ${ternaryColor} !important;
    `}
	${(props) =>
    props.simple &&
    props.ternary &&
    css`
      background: transparent !important;
      color: ${ternaryColor} !important;
      border: 1px solid ${ternaryColor} !important;
    `}

	${(props) =>
    props.none &&
    css`
      background: transparent !important;
      color: ${defaultTextColor} !important;
      border: none !important;
    `}
	&.as-tab {
    padding: 0.5em 0.5em;
    margin-left: 0.2em !important;
    margin-right: 0em !important;
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
  &.no-border {
    border: 2px solid transparent !important;
  }
  &.disabled {
    background-color: rgb(${primaryColorRGB} / 55%) !important;
    opacity: 1 !important;
  }
  &.secondary.disabled {
    background-color: rgb(${secondaryColorRGB} / 55%) !important;
    opacity: 0.55 !important;
  }
`

export const StyledProgressStep = styled.div`
  display: inline-block;
  height: 7px;
  width: 15px;

  margin: 2px;

  @keyframes progress-active {
    0% {
      opacity: 1;
      transform: scaleX(0);
    }
    to {
      opacity: 0.5;
      transform: scale(1);
    }
  }
  .passed & {
    left: 0;
    background: ${primaryColorAlpha};
    border: 1px solid ${primaryColor};
    &.rest {
      background: ${secondaryColorAlpha};
      border: 1px solid ${secondaryColor};
    }
  }
  .current & {
    width: 100%;
    border: 1px solid ${primaryColor};
    &.rest {
      border: 1px solid ${secondaryColorAlpha};
      &.content {
        background: ${secondaryColorAlpha};
      }
    }
    &.track,
    &.done,
    &.actual {
      position: absolute;
      left: 0;
    }
    &.actual {
      background: ${primaryColor};
      animation: progress-active 2s ease infinite;
      &.rest {
        background: ${secondaryColorAlpha};
      }
    }
    &.done {
      background: ${primaryColor};
    }
  }

  .to-come & {
    right: 0;
    border: 1px solid ${primaryColor};

    &.rest {
      border: 1px solid ${secondaryColorAlpha};
    }
  }

  &.rest {
    &.to-come {
      float: right;
      border: 1px solid ${secondaryColorAlpha};
    }
    &.current {
      border: 1px solid ${secondaryColorAlpha};
    }
    &.passed {
      float: left;
      background: ${secondaryColorAlpha};
    }
  }
`

export const StyledNextVideoOverlay = styled.div`
  text-align: right;
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  height: 100%;
  width: 100%;
  background: linear-gradient(to bottom, rgb(0 75 201 / 23%), rgb(6 9 14));

  .content {
    padding: 0.2em 1em;
    position: absolute;
    top: 0;
    right: 0;

    .exercise {
      text-transform: "capitalize";
      font-weight: 800;
      font-size: 1.3em;
    }
  }
`

export const StyledVideoOverlay = styled.div`
  text-align: right;
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  height: 100%;
  width: 100%;
  //background: rgb(0 0 0 / 43%);
`
export const StyledSessionCard = styled.div`
  min-height: 125px;
  position: relative;
  border-radius: 0.75rem !important;

  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  //transform: scale(0.95);
  position: relative;
  -webkit-transition: all 0.6s ease;
  -moz-transition: all 0.6s ease;
  transition: all 0.6s ease;

  .list & {
    margin-top: 1em;
    min-height: 10px;
    height: 130px;
  }

  .slick-current & {
    transform: scale(1);
  }

  .header {
    position: absolute;
    top: -15px;
  }

  .content {
    position: absolute;
    top: 45%;
    transform: translateY(-50%);
    width: 100%;
    text-align: left;
    padding: 0.5em;

    @media only screen and (max-height: 600px) {
      top: 30%;
    }
  }
  .title {
    width: 100%;
    word-wrap: break-word; //permet d'éviter les ? seuls sur une ligne
    white-space: pre-line; //permet d'éviter les ? seuls sur une ligne
    color: ${defaultTextColor};
    font-weight: 800;
    font-size: 1.5em;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    height: 100%;
    width: 100%;
    border-radius: 0.7rem !important;

    background: linear-gradient(to bottom, #004bc93b, #06090e);
    //background: linear-gradient(to bottom, #004bc91a, #06090e73);
  }
  .bottom {
    position: absolute;
    bottom: 1em;
    padding: 0.5em;
    width: 100%;
  }

  .help-icon {
    .icon {
      font-size: 2em !important;
      // background-color: white;
    }
  }
`

export const StyledHomeCard = styled.div`
  // min-height: 300px;
  position: relative;
  border-radius: 0.75rem !important;
  // margin: 1rem 0;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  transform: scale(0.95);
  position: relative;
  -webkit-transition: all 0.6s ease;
  -moz-transition: all 0.6s ease;
  transition: all 0.6s ease;
  .slick-current & {
    transform: scale(1);
  }

  .header {
    position: absolute;
    top: -15px;
  }

  .content {
    position: absolute;
    top: 45%;
    transform: translateY(-50%);
    width: 100%;
    text-align: left;
    padding: 0.5em;

    @media only screen and (max-height: 600px) {
      top: 30%;
    }
  }
  .title {
    width: 100%;
    word-wrap: break-word; //permet d'éviter les ? seuls sur une ligne
    white-space: pre-line; //permet d'éviter les ? seuls sur une ligne
    color: ${defaultTextColor};
    font-weight: 800;
    font-size: 1.5em;
  }

  .description {
    //  margin-top: 5px;
    text-align: center;

    color: ${defaultTextColor};
    font-weight: 800;
    font-size: 1em;
    line-height: 1em;
  }
  .bottom {
    position: absolute;
    bottom: 1em;
    padding: 0.5em;
    width: 100%;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    height: 100%;
    width: 100%;
    border-radius: 0.7rem !important;

    background: linear-gradient(to bottom, #004bc93b, #06090e);
    //background: linear-gradient(to bottom, #004bc91a, #06090e73);
  }
  .label {
    padding: 0.4em 0.833em !important;
  }

  .primary {
    background-color: ${primaryColor}!important;
  }

  .choose-planification-button {
    position: absolute;
    top: 50%;
    left: 0.5em;
  }

  .play-button {
    position: absolute;
    bottom: 25%;
    text-align: center;
    width: 100%;

    .button-text {
      margin-top: 5px;
      color: ${defaultTextColor};
      font-weight: bold;
      font-weight: 800;
      font-size: 1.2em;
    }

    .icon.play {
      color: ${primaryColor};
      padding-left: 0.15em !important;
      background-color: white;
    }
  }
`

export const StyledPreferences = styled(Menu)`
  background-color: transparent !important;

  .item {
    text-transform: capitalize !important;
    padding-left: 0 !important;
    padding-bottom: 0 !important;
    padding-top: 0 !important;
    &:before {
      display: none;
    }
    .icon {
      min-width: 0.55em !important;
      &.bolt {
        margin: 0 !important;
        width: 0.8em;
      }
      &.icon:last-child {
        margin-right: 0.3em !important;
      }
    }
  }
  .primaryColor {
    color: ${primaryColor};
  }
  .secondaryColor {
    color: ${secondaryColor};
  }

  .ambiantColor {
    color: ${ambiantColor};
  }
`
export const StyledHorizontalShortMenu = styled(Menu)`
  position: absolute;
  bottom: ${(props) => (props.bottom ? props.bottom : "10px")};

  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  background-color: transparent !important;

  .item {
    border: 1px solid grey;
    text-transform: capitalize !important;
  }
  .item:first-child {
    border-radius: 2.1em 0 0 2.1em !important;
    border-right: none;
  }
  .item:last-child {
    border-radius: 0 2.1em 2.1em 0 !important;
    border-left: none;
  }

  .icon {
    min-width: 0.55em !important;
    &.bolt {
      margin: 0 !important;
      width: 0.8em;
    }
    &.icon:last-child {
      margin-right: 0.3em !important;
    }
  }

  .primaryColor {
    color: ${primaryColor};
  }
  .secondaryColor {
    color: ${secondaryColor};
  }
  .ternaryColor {
    color: ${ternaryColor};
  }

  .selected {
    background-color: ${primaryColor}!important;
    border-color: ${primaryColor}!important;
    .icon {
      color: #fff !important;
    }
  }

  .white {
    color: white;
  }
`

export const StyledSlider = styled(Slider)`
  & .slick-list {
    //  margin: 0 -7px;
    & .slick-slide > div {
      padding: 0 10px 0 0;
    }
  }
`

export const StyledSliderDots = styled.div`
  & i.icon {
    color: ${primaryColor};
    margin-right: 1.3em;
  }
`

export const StyledDashboardCard = styled.div`
  // display: inline-flex;
  flex-direction: column;
  margin: 1em 0;
  max-width: none;
  background: linear-gradient(
    180deg,
    ${`rgb(${bgColorDarkRGB} / 10%)`} 0%,
    ${`rgb(${bgColorDarkRGB} / 40%)`} 100%
  );

  //rgb(227 106 66) 0%, rgb(197 80 42) 100% )

  padding: 0.5em 0em 0.5em 0em;
  margin: 0;
  border-radius: 0.9em;
  &.fluid {
    width: 100%;
  }

  i.icon.bolt {
    margin-right: 0;
    width: 0.8em;
  }

  &.primary-bg {
    background: linear-gradient(
      180deg,
      ${`rgb(${primaryColorRGB} / 100%)`} 0%,
      ${`rgb(${primaryColorRGB} / 100%)`} 100%
    ) !important;
  }
  &.primary {
    border-bottom: 1px solid ${primaryColor};
    .content {
      color: ${primaryColor};
    }
  }

  &.secondary-bg {
    background: linear-gradient(
      180deg,
      ${`rgb(${secondaryColorRGB} / 100%)`} 0%,
      ${`rgb(${secondaryColorRGB} / 100%)`} 100%
    ) !important;
  }

  &.secondary {
    border-bottom: 1px solid ${secondaryColor};
    .content {
      color: ${secondaryColor};
    }
  }

  &.ambiant-bg {
    background: linear-gradient(
      180deg,
      ${`rgb(${ambiantColorRGB} / 10%)`} 0%,
      ${`rgb(${ambiantColorRGB} / 10%)`} 100%
    ) !important;
  }

  &.ambiant {
    border-bottom: 1px solid ${ambiantColor};
    &.up {
      border-top: 1px solid ${ambiantColor};
      border-bottom: none;
      background: linear-gradient(
        180deg,
        ${`rgb(${bgColorDarkRGB} / 40%)`} 0%,
        ${`rgb(${bgColorDarkRGB} / 10%)`} 100%
      ) !important;
    }
    .content {
      color: ${ambiantColor};

      &.button.selected {
        background-color: ${`rgb(${ambiantColorRGB} / 70%)`} !important;
        color: #fff;
        border-radius: 0.3em;
      }
      &.button {
        margin-left: 0.5em;
        margin-right: 0.5em;
        border: 1px solid ${`rgb(${ambiantColorRGB} / 90%)`} !important;
        color: ${`rgb(${ambiantColorRGB})`};
        padding: 0.5em;
        border-radius: 0.3em;
        font-size: 0.8em;
      }
    }
  }
  a & .title,
  a & .content {
    color: rgb(${defaultTextColorRGB} / 80%);
  }
  & .title {
    text-transform: capitalize;
    margin-bottom: 0.5em;
    font-family: "Bakbak One";
    color: rgb(${defaultTextColorRGB} / 80%);
  }

  & .extra {
    font-size: 0.9em;
    color: rgb(${defaultTextColorRGB} / 60%);
    font-weight: 800;
  }
  & .content {
    min-height: 20px;
    text-transform: uppercase;
    font-weight: 800;
    font-size: 1.2em;
    margin-left: 0.2em;
    margin-right: 0.2em;
  }
  & .bodyHighlight {
    margin-top: 1em !important;
    margin: 0 auto;
    width: 50%;
    display: inline-block;
  }
  & .bodyparts {
    margin-top: 1em !important;
    margin: 0 auto;
    width: 50%;
    vertical-align: top;
    text-align: left;
    display: inline-block;
  }
`

export const StyledStatCard = styled(Statistic)`
  background: linear-gradient(
    180deg,
    ${`rgb(${bgColorLightRGB} / 40%)`} 0%,
    ${`rgb(${bgColorLightRGB} / 10%)`} 100%
  ) !important;

  padding: 1.5em;
  margin: 0;
  border-radius: 0.9em;
  &.fluid {
    width: 100%;
  }
  .cards & {
    .label {
      font-size: 0.7em;
    }
    padding-left: 0;
    padding-right: 0;
  }

  &.primary .value {
    color: ${primaryColor} !important;
  }
  &.secondary .value {
    color: ${secondaryColor} !important;
  }
  &.ternary {
    .value {
      color: ${ternaryColor} !important;
    }
    border-bottom: 1px solid ${ternaryColor};
  }

  &.ambiant .value {
    color: ${ambiantColor} !important;
  }
`

export const StyledOptionCard = styled.div`
  // background-color: transparent;

  background-color: rgb(247 243 243 / 5%);
  border: 2px solid grey;
  position: relative;
  width: 44%;
  display: inline-block;
  margin: 3%;
  padding: 0.3em 0.5em;
  height: 10em;

  border-radius: 0.9em;
  &.selected {
    .title {
      color: #fff !important;
    }
    .description {
      color: #fff !important;
    }
  }

  &.secondary {
    border-color: ${secondaryColor}!important;
  }
  &.ambiant {
    border-color: ${ambiantColor}!important;
  }

  &.unavailable {
    border-color: #939393 !important;
  }

  &.selected {
    &.primary {
      background-color: ${primaryColorAlpha}!important;
      border-color: ${primaryColor}!important;
    }
    &.secondary {
      background-color: ${secondaryColor}!important;
      border-color: ${secondaryColor}!important;
    }
    &.ambiant {
      background-color: ${ambiantColor}!important;
      border-color: ${ambiantColor}!important;
    }
  }

  .icon-container {
    i.icon.bolt {
      margin-right: 0;
      width: 0.8em;
    }
  }
  &.selected .icon-container {
    position: absolute;
    top: 5px;
    right: 5px;

    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.15em 0em 0.2em 0.2em;
    border-radius: 2em;
    width: auto;
    min-width: 25px;
    height: 25px;
  }

  &.selected {
    .icon-container {
      background-color: white;
    }
  }

  &.primary .icon-container {
    color: ${primaryColor};
  }
  &.secondary .icon-container {
    color: ${secondaryColor};
  }

  .title {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    //margin-top: 1.8em;
    //height: 45px;
    text-transform: capitalize;
    text-align: left;
    font-weight: 600;
    font-size: 1.3em;
  }
  &.primary .title {
    color: ${primaryColor};
  }

  &.secondary .title {
    color: ${secondaryColor};
  }

  &.ambiant .title {
    color: ${ambiantColor};
  }

  &.unavailable .title {
    color: #939393 !important;
  }

  .description {
    position: absolute;
    margin-top: 0.5em;
    text-align: left;
    font-size: 1.1em;
  }
`

export const StyledGiftOptionCard = styled.div`
  // background-color: transparent;

  background-color: rgb(247 243 243 / 5%);

  position: relative;

  display: inline-block;
  margin: 3%;
  padding: 0.7em 0.3em;

  border: 2px solid ${ambiantColor}!important;
  border-radius: 0.9em;

  .title {
    text-transform: capitalize;
    text-align: left;
    font-weight: 600;
    font-size: 1.3em;
  }
  .description {
    margin-top: 0.5em;
    text-align: left;
    font-size: 1.2em;
  }

  &.selected {
    background-color: ${ambiantColor}!important;
    border-color: ${ambiantColor}!important;
    .title {
      color: #fff !important;
    }
    .description .content {
      color: #fff !important;
    }
  }
`
export const StyledFeatures = styled.div`
  border-top: 2px solid ${primaryColor};
  padding-top: 0.3em;
  transition: all 0.3s ease;
  .list {
    font-size: 1.1rem !important;
    text-align: left;
  }
`

export const StyledDimmer = (props) => {
  return (
    <div
      className={`ui active ${props.partial ? props.partial : ""} dimmer ${
        props.shades ? props.shades : ""
      }`}
    >
      <div className="content">ui bottom dimmer</div>
    </div>
  )
}
