import { Trans } from "@lingui/macro"
import { useCallback, useEffect, useState } from "react"
import { defaultTextColor } from "../../Styled"
import CountDownAudio from "./Timers/CountdownAudio"

const StartCountdown = ({ countdownDone }) => {
  const [count, setCount] = useState()

  const startCountdown = useCallback(() => {
    let counter = 5

    const interval = setInterval(() => {
      setCount(counter)

      if (counter < 0) {
        clearInterval(interval)
        countdownDone()
      }
      counter--
    }, 1000)
  }, [countdownDone])

  useEffect(() => {
    startCountdown()
  }, [startCountdown])

  return (
    <>
      <CountDownAudio seconds={count} />
      <div
        style={{
          color: defaultTextColor,
          fontSize: "8.7rem",
          fontFamily: "Bakbak One",
          lineHeight: "0.9em",
          width: "100%",
          position: "absolute",
          left: "50%",
          top: "47%",
          transform: "translate(-50%, -50%)",
          maxWidth: 800,
        }}
      >
        {count === 0 ? <Trans>Go!</Trans> : count}
      </div>
    </>
  )
}

export default StartCountdown
