// imports

import { Trans } from "@lingui/macro"
import { useMediaQuery } from "react-responsive"
import { Icon } from "semantic-ui-react"

const PlayButton = (props) => {
  const isPortrait = useMediaQuery({ query: "(orientation: portrait)" })
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" })
  return (
    <div className="play-button">
      <Icon
        circular
        name="play"
        size={isPortrait || !isTabletOrMobile ? "big" : null}
      />
      <div className="button-text">
        <Trans>Let's workout!</Trans>
      </div>
    </div>
  )
}

PlayButton.propTypes = {}

export default PlayButton
