// imports

import { StyledButton } from "../Styled"
import useDrawer from "../../hooks/useDrawer"
import { useLayoutEffect } from "react"
import { useSetRecoilState } from "recoil"
import { drawerIsOpen } from "../../recoil/Atoms"
const DrawerOpenerButton = (props) => {
  const { drawerContent: newDrawerContent } = props
  const setRecoilDrawerIsOpen = useSetRecoilState(drawerIsOpen)

  let buttonProps = { ...props }
  delete buttonProps.drawerContent

  useLayoutEffect(() => {
    return () => {
      setRecoilDrawerIsOpen(false)
    }
  }, [setRecoilDrawerIsOpen])

  const { setContentAndOpen } = useDrawer()
  const setContentAndOpenDrawer = () => {
    setContentAndOpen(newDrawerContent)
  }
  return <StyledButton {...buttonProps} onClick={setContentAndOpenDrawer} />
}

export default DrawerOpenerButton
