// imports

import { useRecoilState, useSetRecoilState } from "recoil"
import {
  countAudioPlayer,
  goAudioPlayer,
  click175Player,
  click178Player,
  click180Player,
} from "../recoil/Atoms"

import countAudio from "../components/Session/Workout/Timers/sounds/count.mp3"
import goAudio from "../components/Session/Workout/Timers/sounds/go.mp3"

import click175 from "../components/Exercise/Metronome/sounds/175.mp3"
import click178 from "../components/Exercise/Metronome/sounds/178.mp3"
import click180 from "../components/Exercise/Metronome/sounds/180.mp3"

const useAudio = () => {
  const [recoilCountAudioPlayer, setRecoilCountAudioPlayer] =
    useRecoilState(countAudioPlayer)
  const recoilSetGoAudioPlayer = useSetRecoilState(goAudioPlayer)
  const recoilSetClick175Player = useSetRecoilState(click175Player)
  const recoilSetClick178Player = useSetRecoilState(click178Player)
  const recoilSetClick180Player = useSetRecoilState(click180Player)

  const startAudioContext = () => {
    if (recoilCountAudioPlayer !== null) {
      console.log("audio context already available")
      return
    }

    const _audioCount = new Audio(countAudio)
    _audioCount.load()
    _audioCount.addEventListener("canplaythrough", () => {
      console.log("loaded audio")
      setRecoilCountAudioPlayer(_audioCount)
    })

    const _audioGo = new Audio(goAudio)
    _audioGo.load()
    _audioGo.addEventListener("canplaythrough", () => {
      console.log("loaded audio")
      recoilSetGoAudioPlayer(_audioGo)
    })

    const _audio175 = new Audio(click175)
    _audio175.load()
    _audio175.addEventListener("canplaythrough", () => {
      console.log("loaded _audio175")
      recoilSetClick175Player(_audio175)
    })
    _audio175.addEventListener(
      "ended",
      function () {
        this.currentTime = 0
        this.play()
      },
      false
    )

    const _audio178 = new Audio(click178)
    _audio178.load()
    _audio178.addEventListener("canplaythrough", () => {
      console.log("loaded _audio178")
      recoilSetClick178Player(_audio178)
    })
    _audio178.addEventListener(
      "ended",
      function () {
        this.currentTime = 0
        this.play()
      },
      false
    )

    const _audio180 = new Audio(click180)
    _audio180.load()
    _audio180.addEventListener("canplaythrough", () => {
      console.log("loaded _audio180")
      recoilSetClick180Player(_audio180)
    })
    _audio180.addEventListener(
      "ended",
      function () {
        this.currentTime = 0
        this.play()
      },
      false
    )
  }

  return { startAudioContext }
}

export default useAudio
