//UI
import { Container, Segment } from "semantic-ui-react"
import LoginForm from "./LoginForm"
import GoogleAuthProvider from "../GoogleAuthProvider"
import IdentificationPagesBackground from "../../shared/IdentificationPagesBackground"
import { Mixpanel } from "../../../utils/mixpanel"
import { useEffect } from "react"

const LoginPage = () => {
  useEffect(() => {
    Mixpanel.track("Login Page Viewed")
  }, [])

  return (
    <Container className="page login">
      <IdentificationPagesBackground />
      <Segment
        basic
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%,-50%)",
          border: "1px solid transparent",
          padding: 0,
          minWidth: 300,
        }}
      >
        <GoogleAuthProvider />
        <LoginForm />
      </Segment>
    </Container>
  )
}

export default LoginPage
