//import useLocalStorage from 'react-use-localstorage'

export function useUserId() {
  const id = JSON.parse(window.localStorage.getItem("RFRUser"))?.["id"]
  return id
}

export function useFirstname() {
  const firstname = JSON.parse(window.localStorage.getItem("RFRUser"))?.[
    "firstname"
  ]
  return firstname
}

export function useFullname() {
  const fullname = `${
    JSON.parse(window.localStorage.getItem("RFRUser"))?.["firstname"]
  } ${JSON.parse(window.localStorage.getItem("RFRUser"))?.["lastname"]}`
  return fullname
}

export function useEmail() {
  const email = JSON.parse(window.localStorage.getItem("RFRUser"))?.["email"]
  return email
}

export function useUser() {
  const user = JSON.parse(window.localStorage.getItem("RFRUser"))
  return user
}
