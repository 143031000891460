// imports

import { t, Trans } from "@lingui/macro"
import { useRecoilValue } from "recoil"
import { eventsList, subscription } from "../../recoil/Atoms"
import { Container, Icon, List, Statistic } from "semantic-ui-react"
import { ambiantColor, StyledEventsList, StyledHeader } from "../Styled"
import NextBackButtons from "../shared/NextBackButtons"
import dayjs from "dayjs"

import weekday from "dayjs/plugin/weekday"
import localizedFormat from "dayjs/plugin/localizedFormat"
import { useEffect } from "react"
import { Mixpanel } from "../../utils/mixpanel"
import Price from "./Price"
import ActionButton from "./ActionButton"
dayjs.extend(localizedFormat)
dayjs.extend(weekday)

const EventsPage = () => {
  const recoilEvents = useRecoilValue(eventsList)
  const recoilSubscription = useRecoilValue(subscription)

  const planificationStarts = (eventDate, planificationDuration) => {
    let dateStart = dayjs(eventDate)
      .subtract(parseInt(planificationDuration) - 1, "week")
      .weekday(1)
      .startOf("week")
      .format("ll")
    return dateStart
  }

  const formatDate = (date) => {
    return dayjs(date).format("ll")
  }

  const weeksOut = (date) => {
    let eventDate = dayjs(date)
    return Math.ceil(eventDate.diff(dayjs(), "week", true))
  }

  useEffect(() => {
    Mixpanel.track("Events Page Opened")
  }, [])

  const gotoPaymentLink = ({ stripePaymentLink, name }) => {
    Mixpanel.track("Buy Event Button Clicked", { Event: name })
    window.location.href = stripePaymentLink
  }

  return (
    <Container
      className="page events"
      textAlign="left"
      style={{ paddingBottom: 30 }}
    >
      <StyledHeader className={"page-title"}>
        <Trans>Race Packs</Trans>
      </StyledHeader>
      {recoilEvents.length !== 0 && (
        <StyledEventsList inverted divided>
          {recoilEvents.map(
            ({
              stripePaymentLink,
              date,
              program,
              planificationDuration,
              distance,
              price,
              originalPrice,
              name,
              isAvailable,
              id,
              registrationStartDate,
              registrationEndDate,
            }) => (
              <List.Item
                key={stripePaymentLink}
                className={id === recoilSubscription.event ? "current" : ""}
              >
                <div className="event-header">
                  <List.Header className="event-date">
                    {formatDate(date)} {""}(
                    <Trans>{weeksOut(date)} weeks out</Trans>)
                  </List.Header>
                  <List.Header className="main" style={{}}>
                    {name}
                  </List.Header>
                </div>
                <List.Content className="infos">
                  <List.Description>
                    <div style={{ fontSize: "0.9em" }}>
                      <div>
                        -{" "}
                        <Trans>
                          <span style={{ fontWeight: "bold" }}>
                            {planificationDuration}
                          </span>{" "}
                          weeks of personalized{" "}
                          <span style={{ color: ambiantColor }}>Premium </span>{" "}
                          strengh training
                        </Trans>
                      </div>
                      <div>
                        -{" "}
                        <Trans>
                          <span style={{ fontWeight: "bold" }}>1</span> video
                          analysis of your stride to customize your training
                        </Trans>
                      </div>

                      <div>
                        - <Trans>Registrations from </Trans>{" "}
                        <span style={{ fontWeight: "bold" }}>
                          {formatDate(registrationStartDate)}{" "}
                        </span>
                        <Trans> to </Trans>{" "}
                        <span style={{ fontWeight: "bold" }}>
                          {formatDate(registrationEndDate)}
                        </span>{" "}
                      </div>
                      <div>
                        -{" "}
                        <Trans>
                          starts on{" "}
                          <span style={{ fontWeight: "bold" }}>
                            {planificationStarts(date, planificationDuration)}
                          </span>
                        </Trans>{" "}
                      </div>
                    </div>
                  </List.Description>
                  <div
                    className="event-bottom"
                    style={{ textAlign: "center", marginRight: 5 }}
                  >
                    <Statistic
                      inverted
                      horizontal
                      size="mini"
                      className="program"
                    >
                      <Statistic.Value>
                        <Icon
                          className={
                            "primary " +
                            (program === "trail" ? "mountain" : program)
                          }
                        />
                      </Statistic.Value>
                      <Statistic.Label>
                        {program === "trail" ? t`trail` : t`road`}
                      </Statistic.Label>
                    </Statistic>
                    <Statistic
                      horizontal
                      inverted
                      size="mini"
                      className="distance"
                    >
                      <Statistic.Value>{distance}</Statistic.Value>
                      <Statistic.Label>km</Statistic.Label>
                    </Statistic>

                    <Price
                      price={price}
                      originalPrice={originalPrice}
                      subscription={recoilSubscription}
                      isCurrentlyFollowed={id === recoilSubscription.event}
                    />
                  </div>
                  <div>
                    <ActionButton
                      gotoPaymentLink={() =>
                        gotoPaymentLink({ stripePaymentLink, name })
                      }
                      isAvailable={isAvailable}
                      subscription={recoilSubscription}
                      isCurrentlyFollowed={id === recoilSubscription.event}
                      registrationStartDate={registrationStartDate}
                      registrationEndDate={registrationEndDate}
                    />
                  </div>
                </List.Content>
              </List.Item>
            )
          )}
        </StyledEventsList>
      )}
      {recoilEvents.length === 0 && (
        <StyledHeader>
          <Trans>Nothing to display yet</Trans>...
        </StyledHeader>
      )}
      <NextBackButtons backOnly />
    </Container>
  )
}

export default EventsPage
