import { t } from "@lingui/macro"

export const onRampSessions = [
  {
    id: 1,
    name: `${t`on ramp`} - ${t`road`} 1`,
    program: "road",
    duration: "16",
    blocks: [
      {
        format: { name: "tabata", rounds: 4 },
        exercises: [
          {
            exercise: "5d797e4eda8c422b903f2eae", //hip dips
            work: 20,
            rest: 10,
            reps: null,
          },
          {
            exercise: "5d797e8dda8c422b903f2eb2", //"hip dips face up"
            work: 20,
            rest: 10,
            reps: null,
          },
        ],
      },
      {
        format: { name: "rest", time: 120 },

        exercises: null,
      },

      {
        format: { name: "tabata", rounds: 8 },
        exercises: [
          {
            exercise: "609fd1d061e44ba484f6ab09", //air squat
            work: 20,
            rest: 10,
            reps: null,
          },
        ],
      },
      {
        format: { name: "rest", time: 120 },
        exercises: null,
      },
      {
        format: { name: "tabata", rounds: 8 },
        exercises: [
          {
            exercise: "5d797ccdda8c422b903f2ea5", //air squat
            work: 20,
            rest: 10,
            reps: null,
          },
        ],
      },
    ],
  },

  {
    id: 2,
    name: `${t`on ramp`} - ${t`road`} 2`,
    program: "road",
    duration: "12",
    blocks: [
      {
        format: { name: "amrap", time: 6 * 60 },
        exercises: [
          {
            exercise: "5d797ce1da8c422b903f2ea6", //front lunge
            work: null,
            rest: null,
            reps: 8,
          },

          {
            exercise: "609fb77161e44ba484f6ab06", // good morning
            work: null,
            rest: null,
            reps: 8,
          },
        ],
      },
      {
        format: { name: "rest", time: 120 },
      },
      {
        format: { name: "amrap", time: 4 * 60 },
        exercises: [
          {
            exercise: "609fb75961e44ba484f6ab04", //sit up
            work: null,
            rest: null,
            reps: 16,
          },

          {
            exercise: "609fb77161e44ba484f6ab06", // good morning
            work: null,
            rest: null,
            reps: 16,
          },
        ],
      },
    ],
  },

  {
    id: 3,
    name: `${t`on ramp`} - ${t`trail`} 3`,
    program: "trail",
    duration: "15",
    blocks: [
      {
        format: { name: "rounds for reps", rounds: 5 },
        exercises: [
          {
            exercise: "5f3c285fd6692d69800007bc", //backward lunge
            work: 20,
            rest: null,
            reps: null,
          },

          {
            exercise: "5d797ccdda8c422b903f2ea5", // running in place
            work: 40,
            rest: null,
            reps: null,
          },
        ],
      },
      {
        format: { name: "rest", time: 120 },
      },
      {
        format: { name: "rounds for reps", rounds: 3 },
        exercises: [
          {
            exercise: "609fb75961e44ba484f6ab04", //sit up
            work: 60,
            rest: null,
            reps: null,
          },

          {
            exercise: "5d797ea8da8c422b903f2eb4", //hip dips lateral
            work: 30,
            rest: null,
            reps: null,
          },
          {
            exercise: "5d797ea8da8c422b903f2eb4", //hip dips lateral
            work: 30,
            rest: null,
            reps: null,
          },
        ],
      },
    ],
  },

  {
    id: 4,
    name: `${t`on ramp`} - ${t`trail`} 4`,
    duration: "10",
    program: "trail",
    blocks: [
      {
        format: { name: "rounds for time", rounds: 5 },
        exercises: [
          {
            exercise: "5f3c285fd6692d69800007bc", //backward lunge
            work: null,
            rest: null,
            reps: 30,
          },

          {
            exercise: "5d87c15fcdbf8aa87234c258", // high pull
            work: null,
            rest: null,
            reps: 30,
          },
          {
            exercise: "609fb75961e44ba484f6ab04", // sit up
            work: null,
            rest: null,
            reps: 30,
          },
          {
            exercise: "5d797e5cda8c422b903f2eaf", //hips dips one leg
            work: null,
            rest: null,
            reps: 30,
          },
          {
            exercise: "5f3c2772d6692d69800007bb", //runner squat
            work: 30,
            rest: null,
            reps: null,
          },
        ],
      },
    ],
  },
]

export const plans = [
  {
    name: "on ramp",
    program: "road",
    description: "",
    weeks: {
      week1: [1, 2],
      week2: [1, 2],
    },
  },
  {
    name: "on ramp",
    program: "trail",
    description: "",
    weeks: {
      week1: [3, 4],
      week2: [3, 4],
    },
  },
  {
    name: "strength - 4 weeks",
    program: "road",
    description: "",
    weeks: {
      week1: [1, 2],
      week2: [1, 2],
      week3: [1, 2],
      week4: [1, 2],
    },
  },
  {
    name: "strength - 4 weeks",
    program: "trail",
    description: "",
    weeks: {
      week1: [3, 4],
      week2: [3, 4],
      week3: [3, 4],
      week4: [3, 4],
    },
  },
  {
    name: "strength - 8 weeks",
    program: "road",
    description: "",
    weeks: {
      week1: [1, 2],
      week2: [1, 2],
      week3: [1, 2],
      week4: [1, 2],
      week5: [1, 2],
      week6: [1, 2],
      week7: [1, 2],
      week8: [1, 2],
    },
  },
  {
    name: "strength - 8 weeks",
    program: "trail",
    description: "",
    weeks: {
      week1: [3, 4],
      week2: [3, 4],
      week3: [3, 4],
      week4: [3, 4],
      week5: [3, 4],
      week6: [3, 4],
      week7: [3, 4],
      week8: [3, 4],
    },
  },
  {
    name: "race-ready",
    program: "road",
    description: "",
    weeks: {
      week1: [1, 2],
      week2: [1, 2],
      week3: [1, 2],
      week4: [1, 2],
      week5: [1, 2],
      week6: [1, 2],
      week7: [1, 2],
      week8: [1, 2],
      week9: [1, 2],
      week10: [1, 2],
      week11: [1, 2],
      week12: [1, 2],
    },
  },
  {
    name: "race-ready",
    program: "trail",
    description: "",
    weeks: {
      week1: [3, 4],
      week2: [3, 4],
      week3: [3, 4],
      week4: [3, 4],
      week5: [3, 4],
      week6: [3, 4],
      week7: [3, 4],
      week8: [3, 4],
      week9: [3, 4],
      week10: [3, 4],
      week11: [3, 4],
      week12: [3, 4],
    },
  },
]
