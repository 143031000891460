import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil"

import {
  currentIntervalIndex,
  currentVideoId,
  currentInterval,
  currentIntervalExerciseIndex,
  timerIsRunning,
  stopwatchIsRunning,
  isTimerOrWatchPaused,
} from "../../../recoil/Atoms"
import { useEffect, useLayoutEffect, useState } from "react"
import { StyledButton } from "../../Styled"
import { Divider, Icon, Transition } from "semantic-ui-react"
import { Trans } from "@lingui/macro"
import RoundsCounter from "./Timers/RoundsCounter"
import Timer from "./Timers/Timer"
import StopWatch from "./Timers/Stopwatch"
import useIntervalNavigation from "../../../hooks/useIntervalNavigation"
import ExerciseContent from "../../Exercise/ExerciseContent"
import { useMediaQuery } from "react-responsive"

const animationDuration = { hide: 50, show: 500 }
const WorkoutContent = ({ intervals }) => {
  //recoil states
  const [recoilCurrentIntervalIndex, setRecoilCurrentIntervalIndex] =
    useRecoilState(currentIntervalIndex)
  const [recoilCurrentInterval, setRecoilCurrentInterval] =
    useRecoilState(currentInterval)
  const [
    recoilCurrentIntervalExerciseIndex,
    setRecoilCurrentIntervalExerciseIndex,
  ] = useRecoilState(currentIntervalExerciseIndex)
  const setRecoilCurrentVideoId = useSetRecoilState(currentVideoId)

  const recoilTimerIsRunning = useRecoilValue(timerIsRunning)
  const recoilStopwatchIsRunning = useRecoilValue(stopwatchIsRunning)
  const recoilIsTimerOrWatchPaused = useRecoilValue(isTimerOrWatchPaused)

  //local state
  const [currentExerciseName, setCurrentExerciseName] = useState()
  const [currentExerciseId, setCurrentExerciseId] = useState()
  const [displayExerciseContent, setDisplayExerciseContent] = useState(false)
  const [displayDoneButton, setDisplayDoneButton] = useState()
  const [displayRoundCounter, setDisplayRoundCounter] = useState()
  const isPortrait = useMediaQuery({ query: "(orientation: portrait)" })
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" })

  let startInterval = intervals[recoilCurrentIntervalIndex]

  //hooks

  const { nextInterval } = useIntervalNavigation(intervals)
  //local functions
  const nextMovement = () => {
    let currentIntervalExercises =
      intervals[recoilCurrentIntervalIndex].exercises

    let nextIntervalExerciseIndex =
      recoilCurrentIntervalExerciseIndex + 1 >= currentIntervalExercises.length
        ? 0
        : recoilCurrentIntervalExerciseIndex + 1

    if ("rounds for time" === name && nextIntervalExerciseIndex === 0) {
      //on est à la fin du round
      nextInterval()
    }

    let nextIntervalExerciseName =
      currentIntervalExercises[nextIntervalExerciseIndex].exerciseName

    let nextIntervalExerciseVideoId =
      currentIntervalExercises[nextIntervalExerciseIndex].videoId
    setRecoilCurrentIntervalExerciseIndex(nextIntervalExerciseIndex)
    setCurrentExerciseName(nextIntervalExerciseName)
    setRecoilCurrentVideoId(nextIntervalExerciseVideoId)
  }

  //effect

  useEffect(() => {
    setRecoilCurrentVideoId(
      startInterval?.exercises?.[recoilCurrentIntervalExerciseIndex].videoId
    )
    setCurrentExerciseName(
      startInterval?.exercises?.[recoilCurrentIntervalExerciseIndex]
        .exerciseName
    )
    setCurrentExerciseId(
      startInterval?.exercises?.[recoilCurrentIntervalExerciseIndex].id
    )
    setRecoilCurrentInterval(intervals[recoilCurrentIntervalIndex])
  }, [
    setRecoilCurrentVideoId,
    startInterval?.exercises,
    recoilCurrentIntervalExerciseIndex,
    setRecoilCurrentInterval,
    intervals,
    recoilCurrentIntervalIndex,
  ])

  useLayoutEffect(() => {
    return () => {
      //	on unmount reset currentslide to 0
      setRecoilCurrentIntervalIndex(0)
      setRecoilCurrentInterval({})
    }
  }, [setRecoilCurrentInterval, setRecoilCurrentIntervalIndex])

  let { name, type, rounds, round, blocksTypesArray, blockNumber } =
    recoilCurrentInterval

  useEffect(() => {
    setDisplayDoneButton(
      !displayExerciseContent &&
        //   recoilStopwatchIsRunning &&
        startInterval?.exercises?.length > 1
    )

    setDisplayRoundCounter(recoilTimerIsRunning || recoilStopwatchIsRunning)

    setDisplayExerciseContent(type !== "rest" && recoilIsTimerOrWatchPaused)
  }, [
    displayExerciseContent,
    recoilIsTimerOrWatchPaused,
    recoilStopwatchIsRunning,
    recoilTimerIsRunning,
    startInterval?.exercises?.length,
    type,
  ])

  const displayTimerOrStopwatch = () => {
    if (
      [
        "tabata",
        "intervals",
        "amrap",
        "rounds for reps",
        "accessory",
        "warmup",
        "rest",
      ].includes(name)
    ) {
      return (
        <Timer intervals={intervals} currentInterval={recoilCurrentInterval} />
      )
    } else {
      return (
        //rounds for time
        <StopWatch
          intervals={intervals}
          currentInterval={recoilCurrentInterval}
        />
      )
    }
  }
  return (
    <>
      <style>
        {!isPortrait &&
          `.ui.menu.top.fixed{display:none!important;}
		.main-container{padding-top:0 !important;}`}
      </style>

      <div
        style={
          isPortrait || !isTabletOrMobile
            ? {
                maxWidth: 800,
                paddingBottom: 40,
              }
            : {
                maxWidth: 200,
                paddingTop: 10,
                paddingBottom: 10,
                position: "fixed",
                // transform: "translateY(-50%)",
                top: 10,
                right: 10,
                backgroundColor: "rgb(0 0 0 / 35%)",
                borderRadius: "1.5em",
              }
        }
      >
        <div
          className="title"
          style={{
            textTransform: "capitalize",
            fontSize: isPortrait || !isTabletOrMobile ? "2rem" : "1.2em",
            fontWeight: "800",
            lineHeight: "1em",
          }}
        >
          {type === "rest" ? (
            <span style={{ textTransform: "uppercase" }}>
              <Trans>Rest</Trans>
            </span>
          ) : (
            <>{currentExerciseName}</>
          )}
        </div>
        <Transition
          visible={displayExerciseContent}
          animation="fade down"
          duration={animationDuration}
        >
          <div>
            <ExerciseContent
              id={currentExerciseId}
              style={{ marginTop: "1rem" }}
            />
          </div>
        </Transition>

        <Transition
          visible={displayDoneButton}
          animation="fade up"
          duration={animationDuration}
        >
          <div style={{ marginTop: "1rem", marginBottom: "1rem", height: 36 }}>
            {startInterval?.exercises?.length > 1 && (
              <StyledButton
                style={{
                  verticalAlign: "text-bottom",
                }}
                onClick={nextMovement}
              >
                <Icon name="check" />
                <Trans>Done</Trans>
              </StyledButton>
            )}
          </div>
        </Transition>

        {!displayDoneButton && (
          <Divider
            hidden
            style={{ marginTop: isPortrait || !isTabletOrMobile ? 0 : -30 }}
          ></Divider>
        )}

        {name && displayTimerOrStopwatch()}

        <Transition
          visible={recoilStopwatchIsRunning || recoilTimerIsRunning}
          animation="fade up"
          duration={animationDuration}
        >
          <div>
            {displayRoundCounter && (
              <RoundsCounter
                blocksTypesArray={blocksTypesArray}
                blockNumber={blockNumber}
                rounds={rounds}
                round={round}
                intervalName={name}
              />
            )}
          </div>
        </Transition>
      </div>
    </>
  )
}

export default WorkoutContent
