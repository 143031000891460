import { Trans, t } from "@lingui/macro"

import { Form, Message, Segment } from "semantic-ui-react"
import { StyledButton, StyledForm } from "../../Styled"
import { useForm } from "react-hook-form"
import { Link, useNavigate, useParams } from "react-router-dom"
import { useCallback, useEffect, useState } from "react"
import useFetch from "use-http"
import { toast } from "react-toastify"
import { Mixpanel } from "../../../utils/mixpanel"
const LoginForm = () => {
  const { post, get, put, response, error } = useFetch()
  const [emailSent, setEmailSent] = useState(false)
  const [tokenChecked, setTokenChecked] = useState(false)
  const [resetDone] = useState(false)
  const [customError, setCustomError] = useState(false)

  const { token } = useParams()
  const navigate = useNavigate()

  const {
    register,
    unregister,
    setValue,
    getValues,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm()

  useEffect(() => {
    if (!tokenChecked) {
      register("email", {
        required: true,
        pattern:
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      })
    } else {
      unregister("email")
      register("password", { required: true, minLength: 6 })
      register("passwordConfirm", {
        validate: {
          match: (passwordConfirm) => watch("password") === passwordConfirm,
        },
      })
    }
  }, [register, tokenChecked, unregister, watch])
  const sendPasswordReset = async (data) => {
    var payload = {
      ...data,
      link: window.location.protocol + "//" + window.location.host + "/forgot",
    }
    await post("/password-resets", payload)
    if (response.ok) {
      Mixpanel.track("Forgot Password Request Sent")
      setEmailSent(true)
    }
  }

  const changePassword = async (data) => {
    await put(`/password-resets/${token}/`, data)

    if (response.ok) {
      toast.success(t`Password successfully changed`)
      Mixpanel.track("Forgot Password Changed")
      navigate("/")
    }
  }

  const validateToken = useCallback(async () => {
    if (!token) return
    await get(`/password-resets/${token}`)
    if (response.ok) {
      setTokenChecked(true)
    } else {
      setCustomError("Invalid link :-/")
    }
  }, [get, response, token])
  useEffect(() => {
    validateToken()
  }, [validateToken])

  return (
    <>
      {!emailSent && !tokenChecked && !resetDone && (
        <StyledForm onSubmit={handleSubmit(sendPasswordReset)}>
          <Segment basic textAlign="left">
            <Form.Input
              name="email"
              icon="at"
              iconPosition="left"
              fluid
              placeholder={t`Email`}
              onChange={(e, { name, value }) => {
                setValue(name, value.trim())
              }}
            />
            {errors.email && (
              <div style={{ marginTop: "-1em", fontSize: "0.9em" }}>
                <Trans>Enter your email</Trans>
              </div>
            )}
          </Segment>
          <Segment basic>
            <StyledButton fluid>
              <Trans>Reset password</Trans>
            </StyledButton>
            <div style={{ marginTop: 20 }}>
              <StyledButton simple="true">
                <Link to="/login">
                  <Trans>Cancel</Trans>
                </Link>
              </StyledButton>{" "}
            </div>
          </Segment>
        </StyledForm>
      )}
      {emailSent && (
        <Message positive icon className="forgot">
          <Message.Content>
            <Trans>
              A link to reset your password has been sent to{" "}
              <span style={{ padding: 2, fontWeight: 800 }}>
                {getValues("email")}
              </span>
              . (Check your spam folder).
            </Trans>
          </Message.Content>
        </Message>
      )}

      {tokenChecked && (
        <StyledForm onSubmit={handleSubmit(changePassword)}>
          <Segment basic textAlign="left">
            <Form.Input
              name="password"
              label={t`New password`}
              icon="lock"
              type="password"
              iconPosition="left"
              fluid
              placeholder={t`New password`}
              onChange={(e, { name, value }) => {
                setValue(name, value)
              }}
            />
            {errors.password && (
              <div style={{ marginTop: "-1em", fontSize: "0.9em" }}>
                <Trans>Enter at least 6 characters</Trans>
              </div>
            )}
            <Form.Input
              name="passwordConfirm"
              label={t`New password (again)`}
              icon="lock"
              type="password"
              iconPosition="left"
              fluid
              placeholder={t`New password (again)`}
              onChange={(e, { name, value }) => {
                setValue(name, value)
              }}
            />
            {errors.passwordConfirm && (
              <div style={{ marginTop: "-1em", fontSize: "0.9em" }}>
                <Trans>Passwords do not match</Trans>
              </div>
            )}
          </Segment>
          {error && (
            <Message negative>
              <p>{response.data?.message || response.data}</p>
            </Message>
          )}
          <Segment basic>
            <StyledButton>
              <Trans>Changer</Trans>
            </StyledButton>
          </Segment>
        </StyledForm>
      )}

      {resetDone && (
        <>
          <Message positive icon className="forgot">
            <Message.Content>
              <Trans>
                Your password has been changed. You can use it to login :-)
              </Trans>
            </Message.Content>
          </Message>
        </>
      )}
      {error && (
        <Message negative>
          <p>{customError || response.data?.message || response.data}</p>
        </Message>
      )}
    </>
  )
}

export default LoginForm
