// imports

import { Trans, t } from "@lingui/macro"
import { primaryColor, secondaryColor } from "../../Styled"
import { Icon, Segment } from "semantic-ui-react"
import { useEffect, useLayoutEffect, useState } from "react"
import { useSetRecoilState } from "recoil"
import { drawerIsOpen } from "../../../recoil/Atoms"
import useDrawer from "../../../hooks/useDrawer"
import { Mixpanel } from "../../../utils/mixpanel"

const SessionPageHelpIcon = ({
  high_impact,
  important,
  displayImportant = true,
}) => {
  const setRecoilDrawerIsOpen = useSetRecoilState(drawerIsOpen)
  const { setContentAndOpen } = useDrawer()
  const [sessionPageHelpSeen, setSessionPageHelpSeen] = useState(
    localStorage.getItem("RFRSessionPageHelpSeen")
  )
  const setDrawerContentAndOpen = () => {
    if (!localStorage.getItem("RFRSessionPageHelpSeen")) {
      localStorage.setItem("RFRSessionPageHelpSeen", "1")
      setSessionPageHelpSeen(true)
    }
    setContentAndOpen(
      <Segment basic>
        {important && displayImportant && (
          <p>
            <Trans>
              This session is a "
              <span style={{ display: "inline-block" }}>
                <Icon
                  className={"bookmark"}
                  style={{ color: secondaryColor }}
                />
                <span>{t`do not miss`}</span>
              </span>
              " session. It contains key exercises for your progress. Try not to
              miss it this week.
            </Trans>
          </p>
        )}
        {high_impact && (
          <p>
            <Trans>
              This "
              <span style={{ display: "inline-block" }}>
                <Icon className={"heartbeat"} style={{ color: primaryColor }} />
                <span>{t`high impact`}</span>
              </span>
              " session may induce fatigue and / or soreness in your legs, try
              to do it on an easy run day, ideally with a complete rest day the
              following day.
            </Trans>
          </p>
        )}
      </Segment>
    )
  }
  useEffect(() => {
    Mixpanel.track("Session Help Displayed")
  }, [])

  useLayoutEffect(() => {
    return () => {
      setRecoilDrawerIsOpen(false)
    }
  }, [setRecoilDrawerIsOpen])
  return (
    (high_impact || important) && (
      <span
        style={{ position: "absolute", right: 5, top: 20 }}
        onClick={setDrawerContentAndOpen}
        className={sessionPageHelpSeen ? "" : "blink"}
      >
        {important && displayImportant && (
          <Icon className={"bookmark"} style={{ color: secondaryColor }} />
        )}

        {high_impact && (
          <Icon className={"heartbeat"} style={{ color: primaryColor }} />
        )}
      </span>
    )
  )
}

export default SessionPageHelpIcon
