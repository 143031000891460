import { primaryColor } from "../Styled"
export const populateExercises = (blocks, exercisesList) => {
  blocks?.forEach((block) => {
    let blockExercises = block.exercises
    if (blockExercises) {
      blockExercises = blockExercises.map((blockExercise) => {
        let movement = exercisesList.find(
          (exercise) => exercise?._id === blockExercise.exercise
        )
        if (movement) {
          blockExercise.data = { ...movement, id: movement._id }
        }
        return blockExercise
      })
      block.exercises = blockExercises
    }
  })
}

export const createTimeTable = (blocks) => {
  let intervals = []
  let blocksTypesArray = blocks.map(({ format: { name } }) => ({
    name,
  }))
  blocks.forEach((block, blockNumber) => {
    const {
      format: { name, rounds, time },
      exercises,
    } = block // number of rounds
    if (name === "rest") {
      let restInterval = {
        time,
        type: "rest",
        videoId: null,
        name,
        blockNumber,
        blocksTypesArray,
        timer: "countdown",
      }
      intervals = [...intervals, restInterval]
    } else if (
      [
        "tabata",
        "intervals",
        "rounds for reps",
        "accessory",
        "warmup",
      ].includes(name)
    ) {
      for (let round = 0; round < rounds; round++) {
        //for every rounds

        for (let i = 0; i < exercises.length; i++) {
          const exercise = exercises[i]

          let {
            work,
            rest,
            data: { videoId, name: exerciseName, id },
          } = exercise
          if (work) {
            let workInterval = {
              round: round + 1,
              time: work,
              type: "work",
              name,
              rounds,
              blockNumber,
              blocksTypesArray,
              timer: "countdown",
              exercises: [{ id, videoId, exerciseName }],
            }
            intervals = [...intervals, workInterval]
          }
          if (rest) {
            let restInterval = {
              round: round + 1,
              rounds,
              time: rest,
              type: "rest",
              name,
              blockNumber,
              blocksTypesArray,
              timer: "countdown",
            }
            intervals = [...intervals, restInterval]
          }
        }
      }
    } else if (name === "amrap") {
      let workInterval = {
        time,
        type: "work",
        name,
        exercises: exercises.map(
          ({ data: { videoId, name: exerciseName, id }, reps }) => ({
            id,
            videoId,
            exerciseName: (
              <>
                <span
                  style={{
                    marginRight: 10,
                    color: primaryColor,
                    fontSize: "1.2em",
                  }}
                >
                  {reps}
                </span>
                {exerciseName}
              </>
            ),
          })
        ),
        //	rounds,
        blockNumber,
        blocksTypesArray,
        timer: "countdown",
      }
      intervals = [...intervals, workInterval]
    } else if (name === "rounds for time") {
      for (let round = 0; round < rounds; round++) {
        let workInterval = {
          time: null,
          type: "work",
          name,
          round: round + 1,
          rounds,
          exercises: exercises.map(
            ({ data: { videoId, name: exerciseName, id }, reps }) => ({
              id,
              videoId,
              exerciseName: (
                <>
                  <span
                    style={{
                      marginRight: 10,
                      color: primaryColor,
                      fontSize: "1.2em",
                    }}
                  >
                    {reps}
                  </span>
                  {exerciseName}
                </>
              ),
            })
          ),
          //	rounds,
          blockNumber,
          blocksTypesArray,
          timer: "stopwatch",
        }
        intervals = [...intervals, workInterval]
      }
    }
  })
  return intervals
}

export const computeTimerTime = (seconds) => {
  const time = new Date()
  return time.setSeconds(time.getSeconds() + seconds)
}

export const formatSecondsToTime = (seconds) => {
  return new Date(seconds * 1000).toISOString().substring(14, 19)
}
export const formatSecondsToTimeSmart = (seconds) => {
  return seconds > 60
    ? new Date(seconds * 1000).toISOString().substring(14, 19)
    : `${seconds}"`
}

export const secondsToMinutes = (seconds) => {
  return parseInt(new Date(seconds * 1000).toISOString().substring(14, 16))
}

export const formatMinutesAndSeconds = (minutes, seconds) =>
  `${("0" + minutes).slice(-2)}:${("0" + seconds).slice(-2)}`
