//UI

import loginBg from "../../images/identificationBackground.jpg"
import { StyledBody } from "../Styled"

const IdentificationPagesBackground = () => {
  return <StyledBody url={loginBg} backgroundPosition="30% center" />
}

export default IdentificationPagesBackground
