import { atom, selector, selectorFamily } from "recoil"
import dayjs from "dayjs"
import localizedFormat from "dayjs/plugin/localizedFormat"
import { formatWeekendFromSunday } from "../utils"
import { t } from "@lingui/macro"

import NoSleep from "@uriopass/nosleep.js"

dayjs.extend(localizedFormat)
export const programs = ["road", "trail"]
export const levels = ["beginner", "intermediate", "advanced"]
export const serverURL =
  window.location.hostname === "localhost" ||
  window.location.origin.includes("192.168")
    ? window.location.origin.replace(window.location.port, "9000")
    : "https://api.renforun.app"

export const GoogleAuthClientId =
  "88924564801-0eujta7429es7c1qmg8jqlmliinlu7ba.apps.googleusercontent.com"

const freeProducts = [
  {
    name: "Free",
    productId: "free",
    frequency: "monthly",
    currency: "eur",
    unit_amount: 0,
    priceId: "free",
  },
  {
    name: "Free",
    productId: "free",
    frequency: "yearly",
    currency: "eur",
    unit_amount: 0,
    priceId: "free",
  },
]

export const social = [
  {
    icon: "globe",
    label: "web",
    url: "https://www.renforun.com",
  },
  {
    icon: "instagram",
    label: "instagram",
    url: "https://instagram.com/renforun",
  },
  {
    icon: "facebook",
    label: "facebook",
    url: "https://facebook.com/renforunapp",
  },
]

const distanceListData = {
  road: [
    {
      key: "5k",
      title: "5 km",
    },
    {
      key: "10k",
      title: "10 km",
    },
    {
      key: "21k",
      title: "Half-marathon",
    },
    {
      key: "42k",
      title: "Marathon",
    },
  ],
  trail: [
    {
      key: "short",
      title: "Short trail",
    },
    {
      key: "long",
      title: "Long trail",
    },
    {
      key: "utra",
      title: "Ultra trail",
    },
  ],
}
export const openSessionCards = [
  { title: t`full-body`, key: "fullbody" },
  { title: t`dynamic core`, key: "core" },
  { title: t`lower body`, key: "lower" },
  { title: t`upper body`, key: "upper" },
  { title: t`favorites`, key: "favorites" },
]
const visiblePlanifications = ["daily", "on-ramp", "race-ready"]

export const googlePlayStoreInstallUrl =
  "https://play.google.com/store/apps/details?id=app.renforun.twa"

export const countAudioPlayer = atom({
  key: "countAudioPlayer",
  default: null,
})
export const goAudioPlayer = atom({
  key: "goAudioPlayer",
  default: null,
})

export const click175Player = atom({
  key: "click175Player",
  default: null,
})

export const click178Player = atom({
  key: "click178Player",
  default: null,
})

export const click180Player = atom({
  key: "click180Player",
  default: null,
})

export const installMessageDisplayed = atom({
  key: "installMessageDisplayed",
  default: false,
})

export const RFRToken = atom({
  key: "RFRToken",
  default: localStorage.getItem("RFRToken"),
})

export const authHeaders = selector({
  key: "authHeaders",
  get: async ({ get }) => {
    const token = get(RFRToken)
    return {
      Authorization: RFRToken ? `Bearer ${token}` : null,
    }
  },
})

const noSleep = new NoSleep()
export const noSleepIsEnabled = atom({
  key: "noSleepIsEnabled",
  default: false,
})

export const noSleepActivate = selector({
  key: "noSleepActivate",
  get: ({ get }) => get(noSleepIsEnabled),
  set: ({ get, set }, enable) => {
    const isNoSleepEnabled = get(noSleepIsEnabled)

    if (isNoSleepEnabled === enable) {
      console.log(`no sleep already ${enable ? "activated" : "deactivaded"}`)
      return
    }
    if (enable) {
      noSleep.enable()
      console.log("no sleep enabled")
    } else {
      noSleep.disable()
      console.log("no sleep disabled")
    }
    set(noSleepIsEnabled, enable)
  },
})

export const wrapPost = selectorFamily({
  key: "wrapPost",
  get:
    ({ url, body }) =>
    async ({ get }) => {
      let headers = get(authHeaders)
      const res = await fetch(`${serverURL}${url}`, {
        method: "post",
        headers,
        body: JSON.stringify(body),
      })
      return await res.json()
    },
})
export const signupEmail = atom({
  key: "signupEmail",
  default: null,
})

export const homeCssReservedHeight = atom({
  key: "homeCssReservedHeight",
  default: 0,
})

export const homeCards = atom({
  key: "homeCards",
  default: [],
})

export const homeCardIndex = atom({
  key: "homeCardIndex",
  default: 0,
})

export const forcePreferencesUpdate = atom({
  key: "forcePreferencesUpdate",
  default: 0,
})
export const preferences = selector({
  key: "preferences",
  get: async ({ get }) => {
    if (!RFRToken) return {}
    get(forcePreferencesUpdate)
    let url = "/user-preferences/my"
    let headers = get(authHeaders)
    return await fetch(`${serverURL}${url}`, { headers }).then((res) =>
      res.json()
    )
  },
})

export const forceAnalysisUpdate = atom({
  key: "forceAnalysisUpdate",
  default: 0,
})

export const analysis = selector({
  key: "analysis",
  get: async ({ get }) => {
    if (!RFRToken) return {}
    get(forceAnalysisUpdate)
    let url = "/analyses/current"
    let headers = get(authHeaders)
    return await fetch(`${serverURL}${url}`, { headers }).then((res) =>
      res.json()
    )
  },
})

export const forceSubscriptionUpdate = atom({
  key: "forceSubscriptionUpdate",
  default: 0,
})

export const subscription = selector({
  key: "subscription",
  get: async ({ get }) => {
    get(forceSubscriptionUpdate)
    let url = "/subscriptions/current"
    let headers = get(authHeaders)
    return await fetch(`${serverURL}${url}`, { headers }).then((res) =>
      res.json()
    )
  },
})

export const forcePlanificationUpdate = atom({
  key: "forcePlanificationUpdate",
  default: 0,
})

export const userPlanification = selector({
  key: "userPlanification",
  get: async ({ get }) => {
    get(forcePlanificationUpdate)
    let url = "/user-planifications/my"
    let headers = get(authHeaders)
    return await fetch(`${serverURL}${url}`, { headers }).then((res) =>
      res.json()
    )
  },
})

export const forcePlanificationWeekUpdate = atom({
  key: "forcePlanificationWeekUpdate",
  default: 0,
})

export const openSessions = selector({
  key: "openSessions",
  get: async ({ get }) => {
    if (!RFRToken) return {}

    let headers = get(authHeaders)
    let program = get(userProgram)

    let url = `/sessions/open/${program}?sort=custom_name,week,weekIndex&limit=100`

    const { rows: data = [] } = await fetch(`${serverURL}${url}`, {
      headers,
    }).then((res) => res.json())
    return data
  },
})

export const openSessionsFiltered = selectorFamily({
  key: "openSessionsFiltered",
  get:
    (filter) =>
    ({ get }) => {
      let allOpenSessions = get(openSessions)

      let filtered = []

      if (filter === "favorites") {
        filtered = allOpenSessions.filter((session) =>
          get(bookmarkedSessionsIds).includes(session.id)
        )
      } else {
        filtered = allOpenSessions.filter(
          (session) => session.planificationName === `open-${filter}`
        )
      }

      return filtered
    },
})

export const forceBookmarksUpdate = atom({
  key: "forceBookmarksUpdate",
  default: 0,
})

export const bookmarkedSessions = selector({
  key: "bookmarkedSessions",
  get: async ({ get }) => {
    if (!RFRToken) return {}
    get(forceBookmarksUpdate)
    let url = "/bookmarks/my"
    let headers = get(authHeaders)
    let { sessions = [] } = await fetch(`${serverURL}${url}`, { headers }).then(
      (res) => res.json()
    )
    return sessions
  },
})

export const bookmarkedSessionsIds = selector({
  key: "bookmarkedSessionsIds",

  get: async ({ get }) => {
    let ids = get(bookmarkedSessions)?.map((session) => session.id)

    return ids || []
  },
})

export const planificationWeek = selectorFamily({
  key: "planificationWeek",
  get:
    (week) =>
    async ({ get }) => {
      if (!RFRToken) return {}

      let noUserPlanification = get(userPlanification)?.notSet

      if (noUserPlanification) return { code: "no-planification" }

      get(forcePlanificationWeekUpdate)
      let url = `/sessions/week/${week ? week : ""}`
      let headers = get(authHeaders)
      return await fetch(`${serverURL}${url}`, { headers }).then((res) =>
        res.json()
      )
    },
})

export const forcePlanificationGetUpdate = atom({
  key: "forcePlanificationGetUpdate",
  default: 0,
})
export const planificationSession = selectorFamily({
  key: "planificationSession",
  get:
    (sessionId) =>
    async ({ get }) => {
      if (!RFRToken) return {}
      get(forcePlanificationGetUpdate)
      let url = `/sessions/${sessionId}`
      let headers = get(authHeaders)
      return await fetch(`${serverURL}${url}`, { headers }).then((res) => {
        if (res.ok) {
          return res.json()
        }
        throw new Error(t`Could not fetch session`)
      })
    },
})

export const forceStatsUpdate = atom({
  key: "forceStatsUpdate",
  default: 0,
})

export const userStats = selector({
  key: "userStats",
  get: async ({ get }) => {
    get(forceStatsUpdate)
    let url = "/stats"
    let headers = get(authHeaders)
    return await fetch(`${serverURL}${url}`, { headers }).then((res) =>
      res.json()
    )
  },
})

export const exercisesList = selector({
  key: "exercisesList",
  get: async ({ get }) => {
    let headers = get(authHeaders)
    const limit = 500
    let url = `/exercises/list?&sort=name&limit=${limit}`
    const { rows: data = [] } = await fetch(`${serverURL}${url}`, {
      headers,
    }).then((res) => res.json())
    return data
  },
})

export const exercises = selector({
  key: "exercises",
  get: async ({ get }) => {
    let exercises = get(exercisesList).filter(
      (exercise) => !exercise.name.endsWith(" L")
    )

    return exercises
  },
})

export const exerciseById = selectorFamily({
  key: "exerciseById",
  get:
    (exerciseId) =>
    ({ get }) => {
      return get(exercisesList).find(
        (exercise) => "" + exercise.id === "" + exerciseId
      )
    },
})

export const eventsList = selector({
  key: "eventsList",
  get: async ({ get }) => {
    let headers = get(authHeaders)
    let url = `/events/?&sort=date&isVisible=true`
    const { rows: data = [] } = await fetch(`${serverURL}${url}`, {
      headers,
    }).then((res) => res.json())
    return data
  },
})

export const availableEvents = selector({
  key: "availableEvents",
  get: ({ get }) => {
    const eventsAvailable = !!get(eventsList).filter(
      ({ registrationStartDate, registrationEndDate }) =>
        dayjs().isBefore(dayjs(registrationStartDate))
          ? false
          : dayjs().isAfter(dayjs(registrationEndDate), "day")
          ? false
          : true
    ).length
    return eventsAvailable
  },
})

export const distanceListForUserProgram = selector({
  key: "distanceListForUserProgram",
  get: ({ get }) => {
    let program = get(userProgram)

    return distanceListData[program]
  },
})

export const selectedRacePack = atom({
  key: "selectedRacePack",
  default: null,
})

export const selectedRace = atom({
  key: "selectedRace",
  default: {
    distance: null,
    raceName: null,
    raceDate: null,
    pack: {},
  },
})

export const programSelectChoicesProgram = selector({
  key: "programSelectChoicesProgram",
  get: ({ get }) => {
    const { program = null } = get(programSelectChoices)
    return program
  },
})

export const stripeProducts = selector({
  key: "stripeProducts",
  get: async ({ get }) => {
    let headers = get(authHeaders)
    let url = `/subscriptions/products`
    const products = await fetch(`${serverURL}${url}`, {
      headers,
    }).then((res) => res.json())
    return [
      ...freeProducts,
      ...products.sort((a, b) => a.name.localeCompare(b.name)),
    ]
  },
})

export const stripePackPrices = selector({
  key: "stripePackPrices",
  get: ({ get }) => {
    let priceArray = get(stripeProducts)
      .filter((product) => product?.metadata?.type === "pack")
      .map(({ unit_amount, metadata: { service }, priceId, currency }) => ({
        priceId,
        title: service,
        price: unit_amount,
        currency,
      }))

    return priceArray
  },
})

export const planificationChoices = selector({
  key: "planificationChoices",
  get: async ({ get }) => {
    let headers = get(authHeaders)
    let program = get(userProgram)
    //  let url = `/planifications?program=${program}`
    let url = `/planifications?program=${program}&version=1`
    const { rows: data = [] } = await fetch(`${serverURL}${url}`, {
      headers,
    }).then((res) => res.json())

    return data.filter((planification) =>
      visiblePlanifications.includes(planification.name)
    )
  },
})

export const raceReadyPlanification = selector({
  key: "raceReadyPlanification",
  get: async ({ get }) => {
    let headers = get(authHeaders)
    let program = get(userProgram)
    let url = `/planifications?program=${program}&version=1`
    const { rows: data = [] } = await fetch(`${serverURL}${url}`, {
      headers,
    }).then((res) => res.json())

    return data.find((planification) =>
      ["race-ready-base"].includes(planification.name)
    )
  },
})

export const strengthPlanificationChoices = selector({
  key: "strengthPlanificationChoices",
  get: async ({ get }) => {
    let headers = get(authHeaders)
    let program = get(userProgram)
    let url = `/planifications?program=${program}`
    const { rows: data = [] } = await fetch(`${serverURL}${url}`, {
      headers,
    }).then((res) => res.json())

    return data.filter((planification) =>
      ["on-ramp", "daily"].includes(planification.name)
    )
  },
})

export const me = selector({
  key: "me",
  get: async ({ get }) => {
    let headers = get(authHeaders)
    let url = `/users/me`
    const data = await fetch(`${serverURL}${url}`, {
      headers,
    }).then((res) => res.json())
    return data
  },
})
export const stripeId = selector({
  key: "stripeId",
  get: async ({ get }) => {
    let { stripeId = null } = get(me)
    return stripeId
  },
})

export const hasError = atom({
  key: "hasError",
  default: true,
})

export const currentLocale = atom({
  key: "currentLocale",
  default: "fr-fr",
})

export const isPlanificationLoading = atom({
  key: "isPlanificationLoading",
  default: false,
})

export const drawerIsOpen = atom({
  key: "drawerIsOpen",
  default: false,
})

export const drawerContent = atom({
  key: "drawerContent",
  default: null,
})

export const drawerEvents = atom({
  key: "drawerEvents",
  default: {},
})

export const drawerButtonTitleOverride = atom({
  key: "drawerButtonTitleOverride",
  default: "",
})

export const hasSubscription = selector({
  key: "hasSubscription",
  get: ({ get }) => {
    const { name = null } = get(subscription)
    return ["classic", "premium"].includes(name)
  },
})

export const subscriptionId = selector({
  key: "subscriptionId",
  get: ({ get }) => {
    const { id = null } = get(subscription)
    return id
  },
})

export const subscriptionName = selector({
  key: "subscriptionName",
  get: ({ get }) => {
    const { name = null } = get(subscription)
    return name
  },
})

export const subscriptionFrequency = selector({
  key: "subscriptionFrequency",
  get: ({ get }) => {
    const { frequency = null } = get(subscription)
    return frequency
  },
})

export const hasPremiumSubscription = selector({
  key: "hasPremiumSubscription",
  get: ({ get }) => {
    const { name = null } = get(subscription)
    return name === "premium"
  },
})

export const subscriptionStartDate = selector({
  key: "subscriptionStartDate",
  get: ({ get }) => {
    const { startDate = null } = get(subscription)
    return startDate ? dayjs(startDate).format("ll") : null
  },
})

export const subscriptionEndate = selector({
  key: "subscriptionEndate",
  get: ({ get }) => {
    const { endDate = null } = get(subscription)
    return endDate ? dayjs(endDate).format("ll") : null
  },
})
export const subscriptionDayjsEndate = selector({
  key: "subscriptionDayjsEndate",
  get: ({ get }) => {
    const { endDate = null } = get(subscription)
    return endDate ? dayjs(endDate) : null
  },
})

export const estimatedPublishedDate = selector({
  key: "estimatedPublishedDate",
  get: ({ get }) => {
    const { estimatedPublished = null } = get(analysis)
    const dayjsDate = dayjs(estimatedPublished)
    return `${dayjsDate.format("dddd")} ${dayjsDate.format("ll")}`
  },
})

export const published = selector({
  key: "published",
  get: ({ get }) => {
    const { published = null } = get(analysis)
    if (!published) return null
    const dayjsDate = dayjs(published)
    return `${dayjsDate.format("ll")}`
  },
})

export const analysisStatus = selector({
  key: "analysisStatus",
  get: ({ get }) => {
    const { status = null } = get(analysis)
    return status
  },
})
export const analysisFocus = selector({
  key: "analysisFocus",
  get: ({ get }) => {
    const { focus = null, status = "in-progress" } = get(analysis) || {}
    return status === "available" || status === "viewed" ? focus : null
  },
})

export const userProgram = selector({
  key: "userProgram",
  get: ({ get }) => {
    const { program = null } = get(preferences)
    return program
  },
  set: ({ set, get }, newValue) =>
    set(preferences, { ...get(preferences), program: newValue }),
})

export const userLevel = selector({
  key: "userLevel",
  get: ({ get }) => {
    const { level = null } = get(preferences)
    return level
  },
  set: ({ set, get }, newValue) =>
    set(preferences, { ...get(preferences), level: newValue }),
})

export const userPlanificationName = selector({
  key: "userPlanificationName",
  get: ({ get }) => {
    let {
      planification: { name = null, type = null } = {},
      raceDate = null,
      raceName = null,
    } = get(userPlanification) || {}

    return {
      name,
      type,
      raceName,
      raceDate,
    }
  },
})

export const userPlanificationFocus = selector({
  key: "userPlanificationFocus",
  get: ({ get }) => {
    let { planification: { focus } = {} } = get(userPlanification) || {}

    return focus
  },
})

export const planificationRaceDateLocalized = selector({
  key: "planificationRaceDateLocalized",
  get: ({ get }) => {
    const { raceDate = null } = get(userPlanification)
    return raceDate ? dayjs(raceDate).format("ll") : null
  },
})

export const raceName = atom({
  key: "raceName",
  default: null,
})

export const raceDate = atom({
  key: "raceDate",
  //default: "trail",
  default: null,
})

export const raceDateLocalized = selector({
  key: "raceDateLocalized",
  get: ({ get }) => {
    const date = get(raceDate)
    return date ? dayjs(date).format("ll") : null
  },
})

export const raceDateWeekend = selector({
  key: "raceDateWeekend",
  get: ({ get }) => {
    const sundayDate = get(raceDate)
    if (!sundayDate) return
    let removeYear = false
    return formatWeekendFromSunday(sundayDate, removeYear)
  },
})

export const raceDateWeekendNoYear = selector({
  key: "raceDateWeekendNoYear",
  get: ({ get }) => {
    const sundayDate = get(raceDate)
    if (!sundayDate) return
    let removeYear = true
    return formatWeekendFromSunday(sundayDate, removeYear)
  },
})

export const programSelectChoices = atom({
  key: "programSelectChoices",
  default: {},
})

export const sessionsCount = selector({
  key: "sessionsCount",
  get: ({ get }) => {
    const { count } = get(userStats)
    return count
  },
})

export const programWeek = atom({
  key: "programWeek",
  default: 12,
})

export const muscleToStrike = atom({
  key: "muscleToStrike",
  default: null,
})

export const currentSlide = atom({
  key: "currentSlide",
  default: 0,
})

export const currentSession = atom({
  key: "currentSession",
  default: null,
})

export const currentIntervalIndex = atom({
  key: "currentIntervalIndex",
  default: 0,
})

export const currentIntervalExerciseIndex = atom({
  key: "currentIntervalExerciseIndex",
  default: 0,
})

export const currentInterval = atom({
  key: "currentInterval",
  default: {},
})

export const currentVideoId = atom({
  key: "currentVideoId",
  default: null,
})

export const currentTimer = atom({
  key: "currentTimer",
  default: null,
})

export const timerIsRunning = atom({
  key: "timerIsRunning",
  default: false,
})
export const timerTime = atom({
  key: "timerTime",
  default: {},
})

export const stopwatchIsRunning = atom({
  key: "stopwatchIsRunning",
  default: false,
})

export const stopwatchTime = atom({
  key: "stopwatchTime",
  default: {},
})

export const isTimerOrWatchPaused = selector({
  key: "isTimerOrWatchPaused",
  get: ({ get }) => {
    const { minutes: timerMinutes, seconds: timerSeconds } = get(timerTime)

    const { minutes: watchMinutes, seconds: watchSeconds } = get(stopwatchTime)
    const curTimer = get(currentTimer)

    const watchIsPaused =
      !get(stopwatchIsRunning) &&
      parseInt(watchMinutes) + parseInt(watchSeconds) !== 0

    const timerIsPaused =
      !get(timerIsRunning) &&
      parseInt(timerMinutes) + parseInt(timerSeconds) !== 0

    return curTimer === "timer"
      ? timerIsPaused
      : curTimer === "stopwatch"
      ? watchIsPaused
      : false
  },
})

export const selectedFile = atom({
  key: "selectedFile",
  default: null,
})

export const selectedFilename = selector({
  key: "selectedFilename",
  get: ({ get }) => {
    return get(selectedFile)?.name
  },
})

export const selectedFilePreviewUrl = atom({
  key: "selectedFilePreviewUrl",
  default: null,
})

export const canUpload = atom({
  key: "canUpload",
  default: false,
})

export const canUploadVideo = atom({
  key: "canUploadVideo",
  default: true,
})

export const timeFrameWatcher = atom({
  key: "timeFrame",
  default: null,
})
