import { Trans } from "@lingui/macro"
import { Image, Segment } from "semantic-ui-react"
import { StyledHeader } from "../Styled"
import qrcode from "../../images/qr-code.png"
import { Mixpanel } from "../../utils/mixpanel"
import { useEffect } from "react"

const FromDesktopToPhone = ({ firstname }) => {
  useEffect(() => {
    Mixpanel.track("App Redirect To Phone")
  }, [])
  return (
    <StyledHeader style={{ paddingTop: 20 }}>
      <Trans>For and Optimal experience, please switch to your phone !</Trans>
      <Segment basic textAlign="center">
        <p>
          <Trans>Scan this QR code or go to https://renforun.app</Trans>
        </p>

        <Image src={qrcode} size="large" style={{ margin: "0 auto" }} />
      </Segment>
    </StyledHeader>
  )
}

export default FromDesktopToPhone
