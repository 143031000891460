//UI
import { Container } from "semantic-ui-react"
import { useEffect } from "react"
import { useLogout } from "../../../hooks/AuthHooks"

const LogoutPage = () => {
  const logout = useLogout()
  useEffect(() => {
    logout()
  }, [logout])

  return <Container className="page login"></Container>
}

export default LogoutPage
