// imports

import { Trans } from "@lingui/macro"
import PropTypes from "prop-types"
import { Suspense, useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"
import { useRecoilState, useRecoilValue } from "recoil"
import { Icon } from "semantic-ui-react"
import useDrawer from "../../hooks/useDrawer"
import { levels, programSelectChoices, userLevel } from "../../recoil/Atoms"
import Loading from "../App/Loading"
import NextBackButtons from "../shared/NextBackButtons"
import { StyledHeader, StyledOptionCard } from "../Styled"
import SelectorHelp from "./selectorHelp/SelectorHelp"

const LevelSelector = (props) => {
  const { setContentAndOpen } = useDrawer()
  const recoilProfileLevel = useRecoilValue(userLevel)
  const levelIcons = {
    beginner: (
      <>
        <Icon className={"bolt"} />
      </>
    ),
    intermediate: (
      <>
        <Icon className={"bolt"} />
        <Icon className={"bolt"} />
      </>
    ),
    advanced: (
      <>
        <Icon className={"bolt"} />
        <Icon className={"bolt"} />
        <Icon className={"bolt"} />
      </>
    ),
  }
  const [recoilSelectProgramChoices, setRecoilSelectProgramChoices] =
    useRecoilState(programSelectChoices)

  const [mustHideBackNav, setMustHideBackNav] = useState("hidenav")

  const [searchParams] = useSearchParams()

  const selectLevel = (level) => {
    setContentAndOpen(
      <Suspense fallback={<Loading />}>
        <SelectorHelp helpItemName={level} />
      </Suspense>
    )
    setRecoilSelectProgramChoices({
      ...recoilSelectProgramChoices,
      level,
    })
  }

  useEffect(() => {
    setMustHideBackNav(
      searchParams.has("hidenav") && searchParams.get("hidenav") ? true : false
    )
  }, [searchParams])

  useEffect(() => {
    if (!recoilSelectProgramChoices.level)
      setRecoilSelectProgramChoices({
        ...recoilSelectProgramChoices,
        level: recoilProfileLevel,
        program: null,
        planification: null,
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recoilProfileLevel, setRecoilSelectProgramChoices])

  return (
    <>
      <StyledHeader data-testid="level-select">
        <Trans>What is your experience in strength training?</Trans>
      </StyledHeader>
      {levels.map((level) => (
        <StyledOptionCard
          key={level}
          onClick={() => selectLevel(level)}
          data-testid={"option-" + level}
          className={
            "secondary " +
            (recoilSelectProgramChoices.level === level && "selected")
          }
        >
          <div className="icon-container">
            {recoilSelectProgramChoices.level === level && levelIcons[level]}
          </div>
          <div className="title">
            <Trans id={level}></Trans>
          </div>
        </StyledOptionCard>
      ))}
      {recoilSelectProgramChoices.level && (
        <NextBackButtons
          hideBack={mustHideBackNav}
          nextUrl={"confirm"}
          nextButtonProps={{ secondary: "true" }}
        />
      )}
    </>
  )
}

LevelSelector.propTypes = {
  floated: PropTypes.string,
}
export default LevelSelector
