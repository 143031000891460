import { Statistic } from "semantic-ui-react"

const Price = ({ price, originalPrice, subscription, isCurrentlyFollowed }) => {
  const isNotPremiumUser = subscription.name !== "premium"

  const isPremiumUser = subscription.name === "premium" && !subscription.event

  const hasPurchasedCurrentEvent =
    subscription.name === "premium" && subscription.event && isCurrentlyFollowed

  const displayPrice = () => {
    if (isNotPremiumUser) {
      return true
    } else if (isPremiumUser) {
      return false
    } else if (hasPurchasedCurrentEvent) {
      return false
    } else return true //haspurchased an event but not this one
  }
  return (
    displayPrice() && (
      <div className="prices">
        <Statistic inverted className="price" size="tiny" horizontal>
          <Statistic.Value>{price / 100}</Statistic.Value>
          <Statistic.Label>€</Statistic.Label>
        </Statistic>

        {originalPrice && (
          <Statistic inverted className="original-price" size="tiny" horizontal>
            <Statistic.Value>{originalPrice / 100}</Statistic.Value>
            <Statistic.Label>€</Statistic.Label>
          </Statistic>
        )}
      </div>
    )
  )
}

export default Price
