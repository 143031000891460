//UI
import { Container, Grid } from "semantic-ui-react"
import NextBackButtons from "../../shared/NextBackButtons"
import PasswordChangeForm from "./PasswordChangeForm"

const PasswordChangePage = () => {
  return (
    <Container className="page signup">
      <Grid
        textAlign="center"
        style={{ height: "90vh" }}
        verticalAlign="middle"
      >
        <Grid.Column style={{ maxWidth: 450 }}>
          <PasswordChangeForm />
        </Grid.Column>
      </Grid>
      <NextBackButtons backOnly />
    </Container>
  )
}

export default PasswordChangePage
