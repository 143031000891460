import { t, Trans } from "@lingui/macro"
import { useParams } from "react-router-dom"
import { Container, Icon, Image, Segment } from "semantic-ui-react"
import NextBackButtons from "../shared/NextBackButtons"
import Instructions from "./steps/Instructions"
import setup_run from "../../images/setup_run.gif"

import SelectFile from "./steps/SelectFile"
import Upload from "./steps/Upload"
import { canUpload, selectedFile } from "../../recoil/Atoms"
import { useRecoilValue } from "recoil"
import Confirm from "./steps/Confirm"
import Done from "./steps/Done"
import { primaryColor, StyledHeader } from "../Styled"
import Loading from "../App/Loading"
import SelectorHelp from "../ProgramSelect/selectorHelp/SelectorHelp"
import { Suspense } from "react"
import useDrawer from "../../hooks/useDrawer"

const VideoUploadPage = () => {
  let { step = "outside" } = useParams()
  const recoilSelectedFile = useRecoilValue(selectedFile)
  const recoilCanUpload = useRecoilValue(canUpload)
  const { setContentAndOpen } = useDrawer()

  let nextUrls = {
    general: "outside",
    outside: "phone",
    phone: "selectFile",
    selectFile: "confirm",
    confirm: "upload",
    upload: "done",
    done: "/",
  }

  let instructions = {
    outside: [
      t`You will need a friend ...or a tripod`,
      t`Film outside, in broad daylight`,
      t`Run on stable and even ground`,
    ],
    phone: [
      t`The phone in landscape mode and DOES NOT MOVE `,
      t`Runner is going from LEFT to RIGHT`,
      t`You must film at least 4 complete strides`,
    ],
  }

  let stepsComponents = {
    outside: <Instructions listItems={instructions["outside"]} />,
    phone: <Instructions listItems={instructions["phone"]} />,
    selectFile: <SelectFile />,
    confirm: <Confirm />,
    upload: <Upload />,
    done: <Done />,
  }

  let stepSubtitles = {
    outside: t`Film outside`,
    phone: t`keep your phone still`,
  }

  const hideNext =
    (step === "selectFile" && !recoilSelectedFile?.name) ||
    (step === "confirm" && !recoilCanUpload)

  const hideBack = step === "done"

  const showUploadHelp = () => {
    setContentAndOpen(
      <Suspense fallback={<Loading />}>
        <SelectorHelp helpItemName={"video-analysis"} />
      </Suspense>
    )
  }

  return (
    <>
      <Container className="page upload video">
        <StyledHeader className={"page-title"}>
          <Trans>Make even faster progress!</Trans>
          <Icon
            style={{
              marginLeft: 5,
              backgroundColor: primaryColor,
            }}
            data-testid={`label-required-analysis`}
            name="exclamation"
            circular
            inverted
            size="tiny"
            onClick={showUploadHelp}
          />
        </StyledHeader>
        <StyledHeader textAlign="left">{stepSubtitles[step]}</StyledHeader>

        {["outside", "phone"].includes(step) && (
          <div
            style={
              {
                /*    position: "absolute",
              margin: "10px auto",
              width: "100%",
              maxHeight: "50vh",
              top: "45%",
              left: "50%",
              transform: "translate(-50%,-100%)",*/
              }
            }
          >
            <Image
              src={setup_run}
              alt={"Indication"}
              style={{
                width: "100%",
                height: "auto",
              }}
            />
            <div
              style={{
                margin: "10px auto",
                //  transform: "translatey(-50%)",
                //width: 400,
              }}
            >
              <Icon name="check" color="green" />
              <Trans>Example of video allowing a precise analysis</Trans>{" "}
            </div>
          </div>
        )}
        <Segment basic>{stepsComponents[step]}</Segment>
        <NextBackButtons
          nextUrl={nextUrls[step]}
          hideNext={hideNext}
          hideBack={hideBack}
        />
      </Container>
    </>
  )
}

export default VideoUploadPage
