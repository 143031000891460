import { t, Trans } from "@lingui/macro"
import dayjs from "dayjs"
import { useEffect, useMemo, useState } from "react"
import { useForm } from "react-hook-form"
import { useNavigate } from "react-router-dom"
import countryList from "react-select-country-list"
import { toast } from "react-toastify"
import { Form, Segment } from "semantic-ui-react"
import { useUpdateProfile } from "../../../hooks/appfetch"
import { useLocalStorage } from "../../../hooks/useLocalStorage"
import { useEmail, useUser } from "../../../hooks/useUserInfo"
import { Mixpanel } from "../../../utils/mixpanel"
import { StyledButton, StyledForm } from "../../Styled"

var utc = require("dayjs/plugin/utc")
dayjs.extend(utc)
var localeData = require("dayjs/plugin/localeData")
dayjs.extend(localeData)

const UserForm = () => {
  const email = useEmail()
  const [, setStorage] = useLocalStorage()
  const currentUser = useUser()
  const {
    id,
    firstname,
    lastname,
    gender,
    country,
    birthYear,
    birthMonth,
    birthDay,
  } = currentUser
  const updateProfile = useUpdateProfile()

  const navigate = useNavigate()

  const {
    setValue,
    watch,
    register,
    handleSubmit,

    formState: { errors },
  } = useForm({
    defaultValues: {
      firstname,
      lastname,
      gender,
      country,
      birthYear,
      birthMonth,
      birthDay,
    },
  })

  const [days, setDays] = useState([])

  const months = dayjs
    .months()
    .map((m, i) => ({ key: i + 1, text: m, value: i + 1 }))

  const currentYear = new Date().getFullYear()
  const range = (start, stop, step) =>
    Array.from(
      { length: (stop - start) / step + 1 },
      (_, i) => start + i * step
    )
  const years = range(currentYear - 15, currentYear - 80, -1).map((year) => ({
    text: year,
    value: year,
  }))

  const selectedYear = watch("birthYear")
  const selectedMonth = watch("birthMonth")
  useEffect(() => {
    if (selectedYear && selectedMonth) {
      let daysInMonth = dayjs(`${selectedYear}-${selectedMonth}`).daysInMonth()
      setDays(
        Array.from({ length: daysInMonth }, (_, i) => i + 1).map((day) => ({
          text: day,
          value: day,
        }))
      )
    }
  }, [selectedMonth, selectedYear])

  const onSubmit = async (data) => {
    let updatedProfile = await updateProfile(id, data)

    let {
      firstname,
      lastname,
      gender,
      country,
      birthYear,
      birthMonth,
      birthDay,
    } = updatedProfile
    let user = {
      ...currentUser,

      firstname,
      lastname,
      gender,
      country,
      birthYear,
      birthMonth,
      birthDay,
    }

    toast.success(t`Profile successfully updated`)

    setStorage("RFRUser", user)

    Mixpanel.people.set({
      "$first_name": firstname,
      "$last_name": lastname,
      "Gender": gender,
      "Country": country,
      "$name": `${firstname} ${lastname}`,
      "Sessions Done": 0,
      "Sessions Started": 0,
    })
    //Mixpanel.alias()

    navigate("/")
  }

  const genders = [
    {
      value: "M",
      text: t`Male`,
    },
    {
      value: "F",
      text: t`Female`,
    },
    {
      value: "O",
      text: t`Other`,
    },
  ]

  const countries = useMemo(
    () =>
      countryList()
        .getData()
        .map(({ value, label }) => {
          let code = value.toLowerCase()
          return { key: code, value: code, flag: code, text: label }
        }),
    []
  )

  useEffect(() => {
    register("firstname", { required: true })
    register("lastname", { required: true })
    register("birthYear", {
      validate: (birthYear) => {
        return (
          (birthYear && dayjs().year() - birthYear < 100) ||
          !!watch("birthYear") + !!watch("birthMonth") + !!watch("birthDay") ===
            0
        )
      },
    })
    register("birthMonth", {
      validate: () => {
        return [0, 3].includes(
          !!watch("birthYear") + !!watch("birthMonth") + !!watch("birthDay")
        )
      },
    })
    register("birthDay", {
      validate: () => {
        return [0, 3].includes(
          !!watch("birthYear") + !!watch("birthMonth") + !!watch("birthDay")
        )
      },
    })

    register("gender")
    register("country")
  }, [register, watch])

  return (
    <>
      <StyledForm style={{ textAlign: "left" }}>
        <Segment basic>
          <Form.Input
            name="firstname"
            fluid
            label={t`Firstname`}
            defaultValue={firstname}
            onChange={(e, { name, value }) => {
              setValue(name, value)
            }}
          />
          {errors.firstname && (
            <div style={{ marginTop: "-1em", fontSize: "0.9em" }}>
              <Trans>Enter your firstname</Trans>
            </div>
          )}

          <Form.Input
            name="lastname"
            fluid
            label={t`Lastname`}
            defaultValue={lastname}
            onChange={(e, { name, value }) => {
              setValue(name, value)
            }}
          />
          {errors.lastname && (
            <div style={{ marginTop: "-1em", fontSize: "0.9em" }}>
              <Trans>Enter your lastname</Trans>
            </div>
          )}

          <label>{t`Birth date`}</label>
          <Form.Group widths={3} unstackable>
            <Form.Select
              name="birthYear"
              placeholder={t`year`}
              options={years}
              defaultValue={birthYear}
              onChange={(e, { name, value }) => {
                setValue(name, value)
              }}
            />
            <Form.Select
              name="birthMonth"
              placeholder={t`month`}
              options={months}
              defaultValue={birthMonth}
              onChange={(e, { name, value }) => {
                setValue(name, value)
              }}
            />
            <Form.Select
              name="birthDay"
              placeholder={t`day`}
              options={days}
              defaultValue={birthDay}
              onChange={(e, { name, value }) => {
                setValue(name, value)
              }}
            />
          </Form.Group>

          {(errors.birthYear || errors.birthMonth || errors.birthDay) && (
            <div style={{ marginTop: "0.1em", fontSize: "0.9em" }}>
              <Trans>Check your birthdate</Trans>
            </div>
          )}

          <Form.Select
            name="gender"
            fluid
            label={t`Gender`}
            inline
            defaultValue={gender}
            options={genders}
            onChange={(e, { name, value }) => {
              setValue(name, value)
            }}
          />

          <Form.Select
            name="country"
            label={t`Country`}
            search
            selection
            defaultValue={country}
            options={countries}
            onChange={(e, { name, value }) => {
              setValue(name, value)
            }}
          />
        </Segment>

        <Segment basic textAlign="center">
          <StyledButton onClick={handleSubmit(onSubmit)}>
            <Trans>Done!</Trans>
          </StyledButton>
        </Segment>

        <Segment
          basic
          compact
          textAlign="center"
          style={{ border: "1px solid red", margin: "0px auto" }}
        >
          <a
            href={`mailto:contact@renforun.com?subject=${t`Data suppression request`}&body=${t`Please, delete all my data for my account${email}, and remove my access to RenfoRun.`}`}
          >
            <Trans>Delete my account and all associate data</Trans>
          </a>
        </Segment>
      </StyledForm>
    </>
  )
}

export default UserForm
