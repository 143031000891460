import { useEffect } from "react"
import { useRecoilValue, useSetRecoilState } from "recoil"
import {
  programSelectChoices,
  raceReadyPlanification,
  selectedRace,
  userProgram,
} from "../../recoil/Atoms"
import { Mixpanel } from "../../utils/mixpanel"

const Confirm = () => {
  const recoilSelectedRace = useRecoilValue(selectedRace)
  const recoilUserProgram = useRecoilValue(userProgram)
  let setRecoilSelectProgramChoices = useSetRecoilState(programSelectChoices)

  let recoilRaceReadyPlanification = useRecoilValue(raceReadyPlanification)

  const {
    raceName,
    distance,
    raceDate,

    // pack: { priceId, title },
  } = recoilSelectedRace

  useEffect(() => {
    Mixpanel.track(
      "Race confirm Page Viewed",

      {
        frequency: "onetime",
        RaceName: raceName,
        RaceDate: raceDate.format("YYYY-MM-DD"),
        Distance: distance,
        Program: recoilUserProgram,
      }
    )
  }, [distance, raceDate, raceName, recoilUserProgram])

  useEffect(() => {
    let newPlanification = {
      ...recoilRaceReadyPlanification,
      raceDate,
      raceName,
    }

    let newRecoilSelectProgramChoices = {
      program: null,
      level: null,
      planification: newPlanification,
    }

    setRecoilSelectProgramChoices(newRecoilSelectProgramChoices)
  }, [
    raceDate,
    raceName,
    recoilRaceReadyPlanification,
    setRecoilSelectProgramChoices,
  ])
  /*
  const confirm = async () => {
    setConfirmClicked(true)

    let payload = {
      locale: "fr",
      allow_promotion_codes: true,
      race: {
        distance,
        raceName,
        raceDate: raceDate.unix(),
        program: recoilUserProgram,
      },
    }

    const response = await pay(payload, "pack")
  }
*/
  /*
  {
	id: "63370b2f697825924c8316c7",
	name: "race-ready",
	program: "road",
	type: "race",
	isFree: false,
	isVisible: true,
	displayOrder: 40,
	weeksCount: 12,
	createdAt: "2022-09-30T15:28:47.741Z",
	updatedAt: "2022-11-09T15:25:07.125Z",
	raceName: "Marathon de Londres",
	raceDate: {
	  $L: "fr",
	  $d: "2023-04-15T22:00:00.000Z",
	  $x: {
	  },
	  $y: 2023,
	  $M: 3,
	  $D: 16,
	  $W: 0,
	  $H: 0,
	  $m: 0,
	  $s: 0,
	  $ms: 0,
	},
  }
*/

  return (
    <></>
    /*
    <div style={{ textAlign: "center" }}>
      <StyledButton
        ambiant="true"
        uppercase={1}
        data-testid={`confirm`}
        onClick={confirm}
      >
        {confirmClicked ? <Loading /> : <Trans>Checkout</Trans>}
      </StyledButton>
    </div>
	*/
  )
}

export default Confirm
