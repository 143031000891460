import { Trans } from "@lingui/macro"

import Preferences from "../Home/Preferences"
import { StyledHeader } from "../Styled"
import SessionCard from "./OpenSessions/SessionCard"

export function SessionsPageHeader({
  name = "",
  weekNumber,
  weeksCount,
  planificationName,

  hasSessions,
}) {
  let keyName = name.includes("race") ? "raceready" : "daily"

  return (
    <>
      {hasSessions && name && (
        <>
          <SessionCard
            title={planificationName}
            keyName={keyName}
            bottom={
              <Preferences weekNumber={weekNumber} weeksCount={weeksCount} />
            }
          />
        </>
      )}

      {!hasSessions && name && (
        <>
          <StyledHeader
            className={"page-title"}
            style={{
              textTransform: "capitalize",
            }}
          >
            <Trans>Planification done !</Trans>
          </StyledHeader>
        </>
      )}
    </>
  )
}
