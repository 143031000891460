import { useRecoilValue } from "recoil"
import { subscriptionFrequency, subscriptionName } from "../../recoil/Atoms"
import CTACustomize from "../shared/CTACustomize"
import CTASubscribe from "../shared/CTASubscribe"

const CallToAction = () => {
  const recoilSubscriptionName = useRecoilValue(subscriptionName)
  const recoilSubscriptionFrequency = useRecoilValue(subscriptionFrequency)

  const isPackUser = recoilSubscriptionFrequency === "onetime"
  const isClassicUser = recoilSubscriptionName === "classic"

  return isClassicUser && isPackUser ? (
    false
  ) : recoilSubscriptionName === "free" ? (
    <CTASubscribe />
  ) : (
    <CTACustomize />
  )
}

export default CallToAction
