// imports

import { t, Trans } from "@lingui/macro"
import PropTypes from "prop-types"
import { useCallback, useEffect, useLayoutEffect } from "react"
import { Link } from "react-router-dom"
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from "recoil"

import { useAnalysisViewed } from "../../hooks/appfetch"
import useDrawer from "../../hooks/useDrawer"
import {
  analysis,
  drawerEvents,
  drawerIsOpen,
  forceAnalysisUpdate,
} from "../../recoil/Atoms"
import Available from "../Analysis/Available"
import InProgress from "../Analysis/InProgress"
import DrawerOpenerButton from "./DrawerOpenerButton"
import { StyledButton } from "../Styled"
import { Mixpanel } from "../../utils/mixpanel"

const CTACustomize = ({ titleOverride, styleOverride = {} }) => {
  const { setContentAndOpen } = useDrawer()
  const setRecoilDrawerEvents = useSetRecoilState(drawerEvents)
  const resetRecoilDrawerEvents = useResetRecoilState(drawerEvents)
  const recoilAnalysis = useRecoilValue(analysis)
  const recoilAnalysisForceUpdate = useSetRecoilState(forceAnalysisUpdate)
  const setAnalysisViewed = useAnalysisViewed()

  const setRecoilDrawerIsVisible = useSetRecoilState(drawerIsOpen)
  const setRecoilDrawerIsOpen = useSetRecoilState(drawerIsOpen)
  const { id: recoilAnalysisId, status = null, firstViewed } = recoilAnalysis

  useEffect(() => {
    recoilAnalysisForceUpdate((prevState) => prevState + 1)
  }, [recoilAnalysisForceUpdate])

  const setViewed = useCallback(async () => {
    setAnalysisViewed(recoilAnalysisId)
    setRecoilDrawerIsVisible(false)
    resetRecoilDrawerEvents()
  }, [
    setAnalysisViewed,
    recoilAnalysisId,
    setRecoilDrawerIsVisible,
    resetRecoilDrawerEvents,
  ])

  const AnalisysAvailableNotification = useCallback(
    (params) => {
      setRecoilDrawerEvents((prevState) => ({
        ...prevState,
        onButtonClose: setViewed,
      }))
      setContentAndOpen(<Available />)
    },
    [setContentAndOpen, setRecoilDrawerEvents, setViewed]
  )

  useEffect(() => {
    if (status === "available" && !firstViewed) {
      AnalisysAvailableNotification()
    }
  }, [AnalisysAvailableNotification, firstViewed, status])

  useLayoutEffect(() => {
    return () => {
      setRecoilDrawerIsOpen(false)
    }
  }, [setRecoilDrawerIsOpen])
  return (
    <>
      {status === "none" && (
        <Link
          to="/video-upload"
          onClick={() => Mixpanel.track("Video Upload Button Clicked")}
        >
          <div style={{ textAlign: "center" }}>
            <StyledButton
              ambiant="true"
              uppercase={1}
              wide="true"
              style={{ paddingRight: "0.1em", paddingLeft: "0.1em" }}
              data-testid="analyse-me"
            >
              {titleOverride || <Trans>Adjust to my stride</Trans>}
            </StyledButton>
          </div>
        </Link>
      )}

      {status === "in-progress" && (
        <div style={{ textAlign: "center" }}>
          <DrawerOpenerButton
            alpha="60"
            secondary
            uppercase={1}
            wide="true"
            style={{ paddingRight: "0.1em", paddingLeft: "0.1em" }}
            data-testid="analyse-inprogress"
            drawerContent={<InProgress />}
            content={t`Your stride is beeing reviewed...`}
          />
        </div>
      )}
    </>
  )
}

CTACustomize.propTypes = {
  floated: PropTypes.string,
}
export default CTACustomize
