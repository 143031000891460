import { Trans } from "@lingui/macro"
import { Icon, Segment } from "semantic-ui-react"

const GlobalLoading = () => {
  return (
    <Segment
      basic
      style={{
        position: "absolute",
        top: "50%",
        left: 0,
        right: 0,
        transform: "translatey(-50%)",
        padding: 0,
      }}
    >
      <Icon loading name="circle notch" /> <Trans>Almost there..</Trans>
    </Segment>
  )
}

export default GlobalLoading
