// imports

import PropTypes from "prop-types"
import { Trans, Plural } from "@lingui/macro"
import {
  StyledHeader,
  StyledLabel,
  StyledList,
  StyledSegment,
  StyledSpan,
} from "../../../../Styled"
import { Icon, Label, List, Segment } from "semantic-ui-react"
import { Fragment } from "react"
import { secondsToMinutes } from "../../../utils"
import AmrapHelp from "./AmrapHelp"
import Part from "./Part"
import { useNavigate } from "react-router-dom"

const Amrap = ({ block, index, partsCount }) => {
  const navigate = useNavigate()
  const {
    format: { time },
    exercises,
  } = block
  return (
    <StyledSegment basic lightbgalpha="true">
      {partsCount > 1 && <Part index={index} />}

      <StyledHeader
        size="small"
        style={{
          textTransform: "uppercase",
          marginBottom: "0em",
          marginTop: "1em",
        }}
      >
        <StyledSpan>
          <Trans>amrap</Trans>
          <AmrapHelp exercises={exercises} time={time} />
        </StyledSpan>
      </StyledHeader>

      <div style={{ textAlign: "left" }}>
        <span style={{ fontSize: "1.1em", fontWeight: 800 }}>
          <Trans>During </Trans>
        </span>{" "}
        <span
          style={{
            fontSize: "1.5em",
            fontWeight: 800,
            marginRight: 5,
            marginLeft: 5,
          }}
        >
          {secondsToMinutes(time)}
        </span>{" "}
        <span style={{ fontSize: "1.1em", fontWeight: 800 }}>
          <Plural value={time / 60} one="minute" other="minutes" />
        </span>
      </div>
      <Segment basic style={{ marginTop: 5, padding: 0 }}>
        <StyledList inverted relaxed>
          {exercises.map(({ reps, rest, data: { _id, name } }, index) => (
            <Fragment key={index}>
              <List.Item onClick={() => navigate(`/exercise/${_id}`)}>
                <List.Content floated="right" style={{ marginTop: 5 }}>
                  <Icon name="chevron right" />
                </List.Content>
                <List.Content>
                  <StyledLabel
                    style={{ marginRight: "1em", minWidth: 45 }}
                    secondary="true"
                  >{`${reps}`}</StyledLabel>
                  {name}
                </List.Content>
              </List.Item>
              {rest && (
                <List.Item>
                  <List.Content>
                    <Label
                      style={{
                        marginRight: "1em",
                        backgroundColor: "transparent",
                        color: "#fff",
                        border: "1px solid #fff",
                      }}
                    >
                      {`${rest}"`}
                    </Label>
                    <Trans>Rest</Trans>
                  </List.Content>
                </List.Item>
              )}
            </Fragment>
          ))}
        </StyledList>
      </Segment>
    </StyledSegment>
  )
}

Amrap.propTypes = {
  floated: PropTypes.string,
}
export default Amrap
