import { useEffect } from "react"
import { Container, Segment } from "semantic-ui-react"
import { Mixpanel } from "../../../utils/mixpanel"
import IdentificationPagesBackground from "../../shared/IdentificationPagesBackground"
import GoogleAuthProvider from "../GoogleAuthProvider"
import SignupForm from "./SignupForm"

const SignupPage = () => {
  useEffect(() => {
    Mixpanel.track("Signup Page Viewed")
  }, [])

  return (
    <Container className="page signup">
      <IdentificationPagesBackground />

      <Segment
        basic
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%,-50%)",
          paddingTop: "30%",
          border: "1px solid transparent",
          padding: 0,
          minWidth: 300,
        }}
      >
        <GoogleAuthProvider />
        <SignupForm />
      </Segment>
    </Container>
  )
}

export default SignupPage
