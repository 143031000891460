import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import { useRecoilState, useSetRecoilState } from "recoil"
import { computeTimerTime } from "../components/Session/utils"
import {
  currentIntervalIndex,
  currentVideoId,
  currentInterval,
  currentIntervalExerciseIndex,
} from "../recoil/Atoms"

export default function useIntervalNavigation(intervals, restart) {
  const [searchParams] = useSearchParams()
  const [recoilCurrentIntervalIndex, setRecoilCurrentIntervalIndex] =
    useRecoilState(currentIntervalIndex)
  const setRecoilCurrentIntervalExerciseIndex = useSetRecoilState(
    currentIntervalExerciseIndex
  )
  const setRecoilCurrentInterval = useSetRecoilState(currentInterval)
  const setRecoilCurrentVideoId = useSetRecoilState(currentVideoId)

  const navigate = useNavigate()

  const { id } = useParams()

  const prevInterval = () => {
    let prevIntervalIndex = recoilCurrentIntervalIndex - 1

    if (prevIntervalIndex < 0) return
    let prevInterval = intervals[prevIntervalIndex]

    let prevIntervalTime = prevInterval.time
    setRecoilCurrentIntervalIndex(prevIntervalIndex)
    setRecoilCurrentIntervalExerciseIndex(0)
    setRecoilCurrentInterval(prevInterval)
    setRecoilCurrentVideoId(prevInterval?.exercises?.[0].videoId)

    if (prevInterval.timer === "countdown" && prevIntervalTime && restart) {
      setTimeout(() => {
        restart(computeTimerTime(prevIntervalTime), true)
      }, 1000)
    }
  }

  const nextInterval = () => {
    let nextIntervalIndex = recoilCurrentIntervalIndex + 1

    if (nextIntervalIndex >= intervals.length) {
      if (searchParams.has("is-warmup") && searchParams.has("sessionId")) {
        let mainSesionId = searchParams.get("sessionId")
        navigate(`/session/${mainSesionId}?&warmup-done=1`)
      } else {
        navigate(`/session/done/${id}`)
      }
      return
    }
    let nextInterval = intervals[nextIntervalIndex]

    setRecoilCurrentIntervalIndex(nextIntervalIndex)
    setRecoilCurrentIntervalExerciseIndex(0)
    setRecoilCurrentInterval(nextInterval)
    setRecoilCurrentVideoId(nextInterval?.exercises?.[0].videoId)

    let nextIntervalTime = nextInterval.time

    if (nextInterval.timer === "countdown" && nextIntervalTime && restart) {
      setTimeout(() => {
        restart(computeTimerTime(nextIntervalTime), true)
      }, 1000)
    }
  }

  return { prevInterval, nextInterval }
}
