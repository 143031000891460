// imports

import { Trans } from "@lingui/macro"
import { useState } from "react"
import { StyledLabel } from "../Styled"

const Focuses = ({ focusStats = {} }) => {
  const [displayAll] = useState(false)

  return (
    <>
      {Object.keys(focusStats).map((part, index) => (
        <StyledLabel
          ambiant="true"
          basic
          size="small"
          key={part}
          style={{
            fontSize: "1em",
            display: index > 3 && !displayAll ? "none" : "inline-block",
          }}
        >
          <Trans id={part}></Trans>
        </StyledLabel>
      ))}

      {Object.keys(focusStats).length > 4 && (
        <span
          style={{
            fontSize: "1.5em",
            display: "inline-block",
            verticalAlign: "middle",
          }}
        >
          ...
        </span>
      )}
    </>
  )
}

export default Focuses
