import { Trans, t } from "@lingui/macro"
import { Divider, Form, Message, Segment } from "semantic-ui-react"
import { StyledButton, StyledForm } from "../../Styled"
import { useForm } from "react-hook-form"
import { useEffect } from "react"
import { Buffer } from "buffer"
import useFetch from "use-http"
import { useUser } from "../../../hooks/useUserInfo"
import { toast } from "react-toastify"
import { useNavigate } from "react-router-dom"

const PasswordChangeForm = () => {
  const { put, response, error } = useFetch()
  const { id, email } = useUser()
  const navigate = useNavigate()
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: {
      firstname: "",
    },
  })
  const onSubmit = async (data) => {
    const { old_password, password } = data

    let hash = Buffer.from(email + ":" + old_password).toString("base64")
    var payload = {
      password,
      hash,
    }

    await put(`/users/${id}/password`, payload)
    if (response.ok) {
      toast.success(t`Password successfully changed`)
      navigate("/")
    }
  }

  useEffect(() => {
    register("old_password", { required: true })
    register("password", { required: true, minLength: 6 })
    register("passwordConfirm", {
      validate: {
        match: (passwordConfirm) => watch("password") === passwordConfirm,
      },
    })
  }, [register, watch])

  return (
    <StyledForm>
      <Segment basic textAlign="left">
        <Form.Input
          name="old_password"
          label={t`Current password`}
          icon="lock"
          type="password"
          iconPosition="left"
          fluid
          placeholder={t`Current password`}
          onChange={(e, { name, value }) => {
            setValue(name, value)
          }}
        />
        <Divider hidden />

        <Form.Input
          name="password"
          label={t`New password`}
          icon="lock"
          type="password"
          iconPosition="left"
          fluid
          placeholder={t`New password`}
          onChange={(e, { name, value }) => {
            setValue(name, value)
          }}
        />
        {errors.password && (
          <div style={{ marginTop: "-1em", fontSize: "0.9em" }}>
            <Trans>Enter at least 6 characters</Trans>
          </div>
        )}
        <Form.Input
          name="passwordConfirm"
          label={t`New password (again)`}
          icon="lock"
          type="password"
          iconPosition="left"
          fluid
          placeholder={t`New password (again)`}
          onChange={(e, { name, value }) => {
            setValue(name, value)
          }}
        />
        {errors.passwordConfirm && (
          <div style={{ marginTop: "-1em", fontSize: "0.9em" }}>
            <Trans>Passwords do not match</Trans>
          </div>
        )}
      </Segment>
      {error && (
        <Message negative>
          <p>{response.data?.message || response.data}</p>
        </Message>
      )}
      <Segment basic>
        <StyledButton onClick={handleSubmit(onSubmit)}>
          <Trans>Changer</Trans>
        </StyledButton>
      </Segment>
    </StyledForm>
  )
}

export default PasswordChangeForm
