// imports

import PropTypes from "prop-types"
import { useLayoutEffect } from "react"
import { useRecoilState } from "recoil"
import { muscleToStrike } from "../../recoil/Atoms"

import { ambiantColorRGB } from "../Styled"

const BodyHighlight = ({ bodyParts, height, contour, contourStroke }) => {
  const contourColor = contour || "#747474"
  const defaultFillColor = "rgb(65 89 143 / 20%)" // "#41598f"
  /*const selectedFillColor = "rgb(0 181 173 / 80%)"
  const selectedAccessoryFillColor = "rgb(0 181 173 / 35%)"*/
  const selectedFillColor = `rgb(${ambiantColorRGB} / 80%)`
  const selectedAccessoryFillColor = `rgb(${ambiantColorRGB} / 40%)`
  const innerStroke = "2px"

  const outerStroke = contourStroke || "2.4"

  let selectedPrincipalBodyParts = []
  let selectedAccessoryBodyParts = []

  bodyParts?.principal?.forEach(
    (bodyPart) =>
      (selectedPrincipalBodyParts = [...selectedPrincipalBodyParts, bodyPart])
  )
  bodyParts?.accessory?.forEach(
    (bodyPart) =>
      (selectedAccessoryBodyParts = [...selectedAccessoryBodyParts, bodyPart])
  )

  const mustHighlight = (muscle) =>
    selectedPrincipalBodyParts.includes(muscle)
      ? selectedFillColor
      : selectedAccessoryBodyParts.includes(muscle)
      ? selectedAccessoryFillColor
      : defaultFillColor

  const [recoilMuscleToStrike, setRecoilMuscleToStrike] =
    useRecoilState(muscleToStrike)

  const mustStroke = (muscle) =>
    recoilMuscleToStrike === muscle ? true : false

  const trapeziusColor = mustHighlight("trapezius")
  const trapeziusStroke = mustStroke("trapezius")
  const latsColor = mustHighlight("lats")
  const latsStroke = mustStroke("lats")
  const tricepsColor = mustHighlight("triceps")
  const tricepsStroke = mustStroke("triceps")
  const glutesColor = mustHighlight("glutes")
  const glutesStroke = mustStroke("glutes")
  const hamstringsColor = mustHighlight("hamstrings")
  const hamstringsStroke = mustStroke("hamstrings")
  const calvesColor = mustHighlight("calves")
  const calvesStroke = mustStroke("calves")
  const deltoidsColor = mustHighlight("deltoids")
  const deltoidsStroke = mustStroke("deltoids")
  const bicepsColor = mustHighlight("biceps")
  const bicepsStroke = mustStroke("biceps")
  const pectoralsColor = mustHighlight("pectorals")
  const pectoralsStroke = mustStroke("pectorals")
  const obliquesColor = mustHighlight("obliques")
  const obliquesStroke = mustStroke("obliques")
  const absColor = mustHighlight("abdominals")
  const absStroke = mustStroke("abdominals")
  const quadsColor = mustHighlight("quadriceps")
  const quadsStroke = mustStroke("quadriceps")
  const adductorsColor = mustHighlight("adductors")
  const adductorsStroke = mustStroke("adductors")
  const achillesColor = mustHighlight("achilles")
  const achillesStroke = mustStroke("achilles")
  const lowerbackColor = mustHighlight("lowerback")
  const lowerbackStroke = mustStroke("lowerback")
  const footworkColor = mustHighlight("footwork")
  const footworkStroke = mustStroke("footwork")

  useLayoutEffect(() => {
    return () => {
      setRecoilMuscleToStrike(false)
    }
  }, [setRecoilMuscleToStrike])
  return (
    <>
      <svg
        version="1.1"
        // width="100%"
        height={height || "42vh"}
        viewBox="350 0 1250 1080"
        xmlns="http://www.w3.org/2000/svg"
      >
        {/**
	 *
}
<svg
        version="1.1"
        id="Calque_1"
        xmlns="http://www.w3.org/2000/svg"
      //  xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 1920 1080"

      >
*/}
        <style type="text/css"></style>

        <g id="Back-Muscles">
          <g id="Trapezius">
            <g>
              <path
                d="M1176.75,398.89c0.39,2.01,3.85-15.38,8.18-13.46c4.33,1.93,9.66,13.48,9.61,12.98
				c-1.36-14.23,11.39-36.38,20.68-51.44c10.08-16.36,15.34-17.31,22.11-38.46c2.7-8.43,0-63.46,11.54-83.65
				c11.54-20.19,19.71-18.12,28.37-23.07c1.89-1.08-70.84-6.83-77.11-55.93c-1.03-8.12-1.1-15.51,0.19-22.91
				c1.29-7.41-37.81-10.41-39.9-0.48c-3.19,15.16,23.8,58.46-68.26,72.59c-6.46,0.99,19.07,10.85,33.17,32.21
				c3.44,5.21,3.38,17.57,13.94,87.02C1141.61,329.81,1172.23,375.77,1176.75,398.89L1176.75,398.89z"
                style={{
                  fill: trapeziusColor,
                  stroke: trapeziusStroke,
                  strokeWidth: innerStroke,
                }}
                className={trapeziusStroke ? "blink" : ""}
              />
            </g>
          </g>
          <g id="Lats">
            <path
              d="M1201.77,394.43c0,0,26.34,26.91,29.2,42.94c2.86,16.03,2.91,45.12,16.61,42.94
			c0.18-0.03-4.67-10.63,1.7-21.58c21.88-37.6,22.93-81.49,22.93-81.49l7.45-71.57c0,0-20.43,7.27-36.65-3.44
			c-3.25-2.14-1.34,24.9-16.96,38.99C1219.64,346.99,1195.48,378.4,1201.77,394.43L1201.77,394.43z"
              style={{
                fill: latsColor,
                strokeWidth: innerStroke,
              }}
              className={latsStroke ? "blink" : ""}
            />
            <path
              d="M1167.52,394.43c0,0-26.34,26.91-29.2,42.94c-2.86,16.03-2.91,45.12-16.61,42.94
			c-0.18-0.03,4.67-10.63-1.7-21.58c-21.87-37.6-22.92-81.49-22.92-81.49l-7.45-71.57c0,0,20.43,7.27,36.65-3.44
			c3.25-2.14,1.33,24.9,16.96,38.99C1149.65,346.99,1173.82,378.4,1167.52,394.43L1167.52,394.43z"
              style={{
                fill: latsColor,
                strokeWidth: innerStroke,
              }}
              className={latsStroke ? "blink" : ""}
            />
          </g>
          <g id="Triceps">
            <path
              d="M1279.65,257.59c0,0,19.38-13.55,26.21-9.65c6.83,3.9,19.51,28.77,20.97,39.02
			c1.46,10.24,7.8,91.69,7.8,91.69s-2.44,10.15-9.27,12.64c-6.82,2.48-5.85-21.18-11.02-23.77c-5.17-2.59-0.23,18.34-5.41,19.99
			c-5.17,1.64-20.22-5.65-20.22-5.65l-11.45-33.63C1277.26,348.23,1288,275.62,1279.65,257.59L1279.65,257.59z"
              style={{
                fill: tricepsColor,
                strokeWidth: innerStroke,
              }}
              className={tricepsStroke ? "blink" : ""}
            />
            <path
              d="M1088.72,257.59c0,0-19.38-13.55-26.21-9.65c-6.83,3.9-19.51,28.77-20.97,39.02
			c-1.46,10.24-7.8,91.69-7.8,91.69s2.44,10.15,9.27,12.64c6.82,2.48,5.85-21.18,11.02-23.77c5.17-2.59,0.23,18.34,5.41,19.99
			c5.18,1.64,20.23-5.65,20.23-5.65l11.45-33.63C1091.11,348.23,1080.37,275.62,1088.72,257.59z"
              style={{
                fill: tricepsColor,
                strokeWidth: innerStroke,
              }}
              className={tricepsStroke ? "blink" : ""}
            />
          </g>
          {/**
		   *
          <g id="Forearms">
            <path
              d="M1334.63,383.01c0,0,19.45,30.81,23.36,54.28c3.91,23.47-0.49,69.93,10.76,80.69
			c11.25,10.76-32.53,19.56-34.12,8.8c-1.58-10.76-49.57-91.83-44.13-106.61c1.47-4-1.47-38.63-1.47-38.63l16.91,9.74
			c0,0,3.63,14.72-4.69,19.12c-8.31,4.4,4.89,17.12,10.27,11.74s8.8-15.65,15.16-17.61c6.36-1.96-4.89-10.98-0.98-13.06
			C1329.63,389.37,1334.63,383.01,1334.63,383.01L1334.63,383.01z"
            />
            <path
              d="M1035.36,383.01c0,0-19.45,30.81-23.36,54.28c-3.91,23.47,0.49,69.93-10.76,80.69
			c-11.25,10.76,32.54,19.56,34.12,8.8c1.58-10.76,49.57-91.83,44.13-106.61c-1.47-4,1.47-38.63,1.47-38.63l-16.91,9.74
			c0,0-3.63,14.72,4.69,19.12c8.31,4.4-4.89,17.12-10.27,11.74c-5.38-5.38-8.81-15.65-15.16-17.61c-6.36-1.96,4.89-10.98,0.98-13.06
			C1040.37,389.37,1035.36,383.01,1035.36,383.01L1035.36,383.01z"
            />
          </g>
		   */}

          <g id="Glutes">
            <path
              d="M1107.61,469.37c4.21-0.7,70.61,32.47,74.22,83.68c3.62,51.21-36.15,69.55-69.7,60.58
			c-33.55-8.97-31.06-49.02-30.19-58.65c0.63-6.88,6.63-22.31,12.08-59.71C1094.97,488.79,1103.41,470.07,1107.61,469.37
			L1107.61,469.37z"
              style={{
                fill: glutesColor,
                strokeWidth: innerStroke,
              }}
              className={glutesStroke ? "blink" : ""}
            />
            <path
              d="M1270.23,475.65c-3.75-1.49-70.85,18.39-83.36,67.97c-12.5,49.58,20.84,75.17,53.35,72.77
			c32.51-2.39,37.4-42.18,38.32-51.81c0.66-6.87-2.1-23.17-0.43-60.91C1278.4,497.13,1273.98,477.14,1270.23,475.65L1270.23,475.65z
			"
              style={{
                fill: glutesColor,
                strokeWidth: innerStroke,
              }}
              className={glutesStroke ? "blink" : ""}
            />
          </g>
          <g id="Hamstrings">
            <path
              d="M1200.16,619.44c0,0,64.42,2.54,74.63-6.42c10.22-8.96,12.94,49.02,0,111.68
			c-12.94,62.66-4.78,69.66-6.67,68.01c-14.29-12.38-22.88-43.29-23.89-42.54c-0.79,0.6-29.76,43.37-25,76.7
			c0.27,1.92-27.14-151.17-31.23-169.78C1183.93,638.47,1177.76,621.47,1200.16,619.44L1200.16,619.44z"
              style={{
                fill: hamstringsColor,
                strokeWidth: innerStroke,
              }}
              className={hamstringsStroke ? "blink" : ""}
            />
            <path
              d="M1168.24,619.44c0,0-64.09,2.54-74.25-6.42c-10.16-8.96-12.87,49.02,0,111.68
			c12.88,62.66,4.76,69.66,6.64,68.01c14.22-12.38,22.76-43.29,23.76-42.54c0.79,0.6,29.61,43.37,24.87,76.7
			c-0.27,1.92,27-151.17,31.06-169.78C1184.38,638.47,1181.22,620.01,1168.24,619.44z"
              style={{
                fill: hamstringsColor,
                strokeWidth: innerStroke,
              }}
              className={hamstringsStroke ? "blink" : ""}
            />
          </g>
          <g id="Calves">
            <path
              d="M1246.36,794.73c0,0-29.83,36.43-31.81,51.29c-1.97,14.85-2.02,86.71,3.53,99.19
			c4.06,9.13,23.94-20.3,28.93-38.75c4.99-18.45-0.07,17.12,12.49,30.35c2.12,2.23,24.39-70.49,17.98-94.57
			c-8.88-33.41-12.23-42.89-9.98-58.38c3.54-24.35-13.43,10.88-15.89,21.85C1249.15,816.69,1246.36,794.73,1246.36,794.73
			L1246.36,794.73z"
              style={{
                fill: calvesColor,
                strokeWidth: innerStroke,
              }}
              className={calvesStroke ? "blink" : ""}
            />
            <path
              d="M1123.01,794.73c0,0,29.83,36.43,31.81,51.29c1.97,14.85,2.02,86.71-3.53,99.19
			c-4.06,9.13-23.94-20.3-28.93-38.75c-4.99-18.45,0.07,17.12-12.49,30.35c-2.12,2.23-24.39-70.49-17.98-94.57
			c8.88-33.41,12.23-42.89,9.98-58.38c-3.54-24.35,13.43,10.88,15.89,21.85C1120.22,816.69,1123.01,794.73,1123.01,794.73
			L1123.01,794.73z"
              style={{
                fill: calvesColor,
                strokeWidth: innerStroke,
              }}
              className={calvesStroke ? "blink" : ""}
            />
          </g>
          <path
            id="Lowerback"
            d="M1140.19,483.23c0,0-7.18-80.03,42.57-77.54s40.29,79.41,40.29,79.41s-37.85,20.48-37.19,36.42
		C1181.86,505.36,1140.19,483.23,1140.19,483.23z"
            style={{
              fill: lowerbackColor,
              strokeWidth: innerStroke,
            }}
            className={lowerbackStroke ? "blink" : ""}
          />

          <g id="Achilles">
            <path
              d="M1119.18,943.83c0,0,1.75-12.52,4.08-18.79c2.15-5.8,0.93,23.86,21.07,31.26c0.67,0.25,1.19,0.78,1.38,1.46
			c4.84,17.14,11.89,61.23,11.89,61.23s7.43,37.6-14.63,38.15c-0.37,0.01-0.76-0.09-1.09-0.27c-15.04-8.07-10.1-12.6-9.45-38.39
			c0.69-13.54,1.85-26.7-5.66-48.03c-0.03-0.1-0.08-0.19-0.12-0.28C1117.96,953.47,1119.18,943.83,1119.18,943.83z"
              style={{
                fill: achillesColor,
                strokeWidth: innerStroke,
              }}
              className={achillesStroke ? "blink" : ""}
            />
            <path
              d="M1249.27,943.83c0,0-1.75-12.52-4.08-18.79c-2.15-5.8-0.93,23.86-21.07,31.26c-0.67,0.25-1.19,0.78-1.38,1.46
			c-4.84,17.14-11.89,61.23-11.89,61.23s-7.43,37.6,14.63,38.15c0.37,0.01,0.76-0.09,1.09-0.27c15.04-8.07,10.1-12.6,9.45-38.39
			c-0.69-13.54-1.85-26.7,5.66-48.03c0.03-0.1,0.08-0.19,0.12-0.28C1250.49,953.47,1249.27,943.83,1249.27,943.83z"
              style={{
                fill: achillesColor,
                strokeWidth: innerStroke,
              }}
              className={achillesStroke ? "blink" : ""}
            />
          </g>
        </g>

        <g id="Front-Muscles">
          <g id="Deltoids">
            <path
              d="M687.05,198.99c0,0-64.86-36.97-91.37,18.14c-9.75,20.27-2.39,84.24-2.09,87.18
			c0.2,1.95,19.25-41.11,35.57-50.22C631.96,252.53,628.4,210.73,687.05,198.99z"
              style={{
                fill: deltoidsColor,
                strokeWidth: innerStroke,
              }}
              className={deltoidsStroke ? "blink" : ""}
            />
            <path
              d="M774.59,198.99c0,0,64.86-36.97,91.37,18.14c9.75,20.27,2.39,84.24,2.09,87.18
			c-0.2,1.95-19.25-41.11-35.57-50.22C829.68,252.53,833.23,210.73,774.59,198.99L774.59,198.99z"
              style={{
                fill: deltoidsColor,
                strokeWidth: innerStroke,
              }}
              className={deltoidsStroke ? "blink" : ""}
            />
          </g>
          <g id="Biceps">
            <path
              d="M632.51,255.31c0,0,5.77,87.57-0.97,91.49c-7.73,4.49-11.68,47.69-11.68,47.69s-3.89-10.95-8.27-10.46
			c-4.38,0.49-12.65,8.76-14.36,11.68C595.53,398.62,569.98,305.43,632.51,255.31L632.51,255.31z"
              style={{
                fill: bicepsColor,
                strokeWidth: innerStroke,
              }}
              className={bicepsStroke ? "blink" : ""}
            />
            <path
              d="M832.32,266.6c0,0-5.77,87.57,0.98,91.48c7.73,4.49,11.68,47.69,11.68,47.69s3.89-10.95,8.27-10.46
			c4.38,0.49,12.65,8.75,14.35,11.68C869.31,409.91,894.85,316.72,832.32,266.6L832.32,266.6z"
              style={{
                fill: bicepsColor,
                strokeWidth: innerStroke,
              }}
              className={bicepsStroke ? "blink" : ""}
            />
          </g>
          <g id="Pectorals">
            <path
              d="M720.3,209.28c-1.62,20.79,10.47,46.72,10.63,62.29c0.21,21.07-1.72,38.1-38.94,42.25
			c-29.91,3.33-36.64-9.06-46.33-15.23c-6.05-3.85-7.9-43.69-13.42-49.71c-3.77-4.11,20.82-46.48,54.9-46.18
			C721.22,203,720.64,204.92,720.3,209.28L720.3,209.28z"
              style={{
                fill: pectoralsColor,
                strokeWidth: innerStroke,
              }}
              className={pectoralsStroke ? "blink" : ""}
            />
            <path
              d="M741.58,209.28c1.62,20.79-10.48,46.72-10.63,62.29c-0.21,21.07,1.72,38.1,38.94,42.25
			c29.91,3.33,36.64-9.06,46.33-15.23c6.05-3.85,7.9-43.69,13.43-49.71c3.77-4.11-20.82-46.48-54.9-46.18
			C740.66,203,741.23,204.92,741.58,209.28L741.58,209.28z"
              style={{
                fill: pectoralsColor,
                strokeWidth: innerStroke,
              }}
              className={pectoralsStroke ? "blink" : ""}
            />
          </g>
          <g id="Obliques">
            <path
              d="M688.73,327.84c0,0,7.28,165.96,0,171.89c-7.27,5.93-8.75-33.09-35.43-37.54
			c-4.97-0.83-0.85-89.9-12.78-111.13C628.61,329.82,683.66,320.93,688.73,327.84L688.73,327.84z"
              style={{
                fill: obliquesColor,
                strokeWidth: innerStroke,
              }}
              className={obliquesStroke ? "blink" : ""}
            />
            <path
              d="M771.74,327.84c0,0-7.28,165.96,0,171.89c7.27,5.93,8.75-33.09,35.43-37.54c4.97-0.83,0.85-89.9,12.78-111.13
			C831.87,329.82,776.81,320.93,771.74,327.84L771.74,327.84z"
              style={{
                fill: obliquesColor,
                strokeWidth: innerStroke,
              }}
              className={obliquesStroke ? "blink" : ""}
            />
          </g>
          <g id="Abs">
            <path
              d="M732.22,314.49c0,0-30.7-9.75-44.66,7.69c-13.96,17.43,2.43,85.03-1.29,110.15
			c-3.72,25.12,17.18,129.39,27.81,133.37c6.61,2.48,11.18,2.97,18.61-0.46c6.55-3.03,37.18-72.43,41.2-133.53
			c1.86-28.26,25.33-113.37-15.61-119.55C734.6,308.59,732.22,314.49,732.22,314.49z"
              style={{
                fill: absColor,
                strokeWidth: innerStroke,
              }}
              className={absStroke ? "blink" : ""}
            />
          </g>
          <g id="Quads">
            <path
              d="M665.41,500.34c0,0,57.77,123.3,53.57,181.03c-4.2,57.73-10.5,77.67-10.5,92.37
			c0,14.69,1.88-21.96-16.51-22.04c-21.56-0.1-37.66,17.38-43.5,36.64c-0.83,2.75-5.21-54.77-5.21-54.77s-7.39-15.85-12.45-69.08
			C623.14,583.89,684.54,554.84,665.41,500.34"
              style={{
                fill: quadsColor,
                strokeWidth: innerStroke,
              }}
              className={quadsStroke ? "blink" : ""}
            />
            <path
              d="M794.76,500.34c0,0-57.77,123.3-53.57,181.03c4.19,57.73,10.5,77.67,10.5,92.37
			c0,14.69-1.88-21.96,16.51-22.04c21.55-0.1,37.66,17.38,43.5,36.64c0.83,2.75,5.21-54.77,5.21-54.77s7.39-15.85,12.45-69.08
			C837.02,583.89,775.63,554.84,794.76,500.34"
              style={{
                fill: quadsColor,
                strokeWidth: innerStroke,
              }}
              className={quadsStroke ? "blink" : ""}
            />
          </g>
          <g id="Adductors">
            <path
              d="M681.83,490.16c0,0,22.07,61.13,30.7,77.53c12.8,24.35,20.11,44.07,18.19,47.21
			c-1.92,3.14-6.54,51.13-6.89,46.78c-2.47-31.39-38.87-152.99-44.83-161.34C675.72,495.74,681.81,490.13,681.83,490.16
			L681.83,490.16z"
              style={{
                fill: adductorsColor,
                strokeWidth: innerStroke,
              }}
              className={adductorsStroke ? "blink" : ""}
            />
            <path
              d="M777.58,490.16c0,0-22.07,61.13-30.7,77.53c-12.8,24.35-20.11,44.07-18.19,47.21
			c1.92,3.14,6.54,51.13,6.89,46.78c2.47-31.39,38.87-152.99,44.83-161.34C783.69,495.74,777.6,490.13,777.58,490.16L777.58,490.16z
			"
              style={{
                fill: adductorsColor,
                strokeWidth: innerStroke,
              }}
              className={adductorsStroke ? "blink" : ""}
            />
          </g>
          <g id="Footwork">
            <path
              d="M714.26,1035.3c0,0-46.09-11.96-47.64-0.2c0,0-13.09,26.08-1.32,26.84c0,0,0.92,2.51,4.91,0.52
			c0,0,6.36,6.56,13.23,3c0,0,6.36,5.08,11.8,0.56c0,0,4.36,5.01,10.72,0.96c0,0,8.35,5.63,13.3-0.47
			C719.26,1066.52,729.27,1073.17,714.26,1035.3"
              style={{
                fill: footworkColor,
                strokeWidth: innerStroke,
              }}
              className={footworkStroke ? "blink" : ""}
            />
            <path
              d="M748.29,1035.3c-15,37.87-5,31.22-5,31.22c4.96,6.1,13.3,0.47,13.3,0.47
			c6.36,4.05,10.72-0.96,10.72-0.96c5.44,4.52,11.8-0.56,11.8-0.56c6.87,3.56,13.23-3,13.23-3c3.98,1.98,4.91-0.52,4.91-0.52
			c11.77-0.76-1.32-26.84-1.32-26.84C794.38,1023.34,748.29,1035.3,748.29,1035.3"
              style={{
                fill: footworkColor,
                strokeWidth: innerStroke,
              }}
              className={footworkStroke ? "blink" : ""}
            />
          </g>
        </g>
        <path
          id="Front"
          d="M730.72,616.48c-1.57,18.42,16.62,116.38,23.07,154.62c3.07,18.11,11.5,47.63,8.47,63.31
	c-4.33,21.85-5.58,50.11-3.2,66.57c1.5,10,6.23,56.1-0.54,72.96c-3.54,8.84-10.03,54.16-10.03,54.16
	c-16.91,42.69-7.37,40.59-7.37,40.59c5.23,6.42,14.2,0.51,14.2,0.51c6.83,4.35,11.56-1.03,11.56-1.03c5.86,4.85,12.7-0.6,12.7-0.6
	c7.37,3.82,14.2-3.22,14.2-3.22c4.23,2.13,5.26-0.56,5.26-0.56c12.69-0.81-7.08-41.43-7.08-41.43c-4.74-36.49,4.7-56.79,4.7-56.79
	c30.9-91.64,32.47-115.96,20.12-150.5c-3.47-9.97-4.36-13.92-2.75-18.25c3.7-9.99,1-50.18,5.51-66.13
	c8.71-30.77,17.3-108.81,21.78-145.23c6.02-49.05-21.31-114.82-21.31-114.82c-5.98-26.76,2.79-122.12,2.79-122.12
	c12.26,19.07,11.79,52.73,11.79,52.73c-1.95,35.3,28.52,89.25,28.52,89.25c14.64,22.3,20.18,43.45,20.18,45.02
	c0,6.42-1.4,21.98-1.4,21.98l0.56,13.54c0.26,3.45,2.19,15.32,1.88,21.06c-2.29,35.32,3.33,28.67,3.33,28.67
	c4.73,0,9.93-28.42,9.93-28.42c0,7.33-1.79,29.27,2.17,37.55c4.73,9.87,8.21-1.7,8.27-4.02c1.25-44.97,3.95-33.19,3.95-33.19
	c2.63,36.48,5.86,44.72,11.66,41.87c4.39-2.09,0.38-43.78,0.38-43.78c7.52,24.76,13.22,28.71,13.22,28.71
	c12.41,8.71,4.74-15.35,3.02-20.12c-9.18-25.33-9.47-34.1-9.47-34.1c11.47,22.75,20.12,21.91,20.12,21.91
	c11.19-3.57-9.78-35.79-22.07-51.23c-6.27-7.86-14.35-18.4-16.7-24.65c-3.82-10.59-6.71-44.65-6.71-44.65
	c-1.16-40.18-11.09-57.64-11.09-57.64c-16.98-27.19-20.18-77.9-20.18-77.9l-0.75-85.62c-5.95-58.4-48.99-58.82-48.99-58.82
	c-43.5-6.47-49.55-20.53-49.55-20.53c-9.21-13.26-3.95-38.67-3.95-38.67c7.64-6.22,10.59-22.72,10.59-22.72
	c12.69-9.74,12.07-23.98,6.21-23.82c-4.71,0.13-3.64-3.77-3.64-3.77C792,13.05,735.08,9.78,735.08,9.78h-8.69
	c0,0-56.94,3.27-49.02,67.37c0,0,1.06,3.9-3.68,3.77c-5.85-0.16-6.39,14.09,6.26,23.82c0,0,2.94,16.5,10.59,22.72
	c0,0,5.26,25.41-3.95,38.67c0,0-6.03,14.06-49.55,20.53c0,0-43.11,0.42-48.97,58.82l-0.81,85.62c0,0-3.14,50.71-20.19,77.9
	c0,0-9.89,17.46-11.03,57.64c0,0-2.9,34.05-6.71,44.65c-2.32,6.22-10.4,16.75-16.72,24.65c-12.39,15.41-33.22,47.56-22.08,51.23
	c0,0,8.69,0.84,20.12-21.91c0,0-0.23,8.71-9.4,34.1c-1.8,4.7-9.46,28.77,2.95,20.12c0,0,5.75-3.95,13.23-28.71
	c0,0-4.01,41.69,0.45,43.78c5.83,2.86,9.02-5.39,11.65-41.87c0,0,2.7-11.78,3.94,33.19c0.06,2.32,3.46,13.89,8.22,4.02
	c4.01-8.28,2.21-30.18,2.21-37.55c0,0,5.14,28.42,9.95,28.42c0,0,5.66,6.64,3.33-28.67c-0.38-5.77,1.65-17.61,1.9-21.06l0.55-13.54
	c0,0-1.41-15.52-1.41-21.98c0-1.6,5.55-22.72,20.19-45.02c0,0,30.43-53.98,28.47-89.25c0,0-0.42-33.66,11.83-52.73
	c0,0,8.7,95.35,2.8,122.12c0,0-27.38,65.77-21.34,114.82c4.45,36.51,13.02,114.45,21.75,145.23c4.56,15.92,1.86,56.1,5.52,66.13
	c1.65,4.36,0.78,8.37-2.76,18.25c-12.29,34.53-10.72,58.86,20.18,150.5c0,0,9.51,20.31,4.71,56.79c0,0-19.74,40.62-7.1,41.43
	c0,0,0.99,2.69,5.27,0.56c0,0,6.83,7.05,14.21,3.22c0,0,6.83,5.46,12.68,0.6c0,0,4.69,5.38,11.51,1.03c0,0,8.96,6.05,14.29-0.51
	c0,0,9.46,2.1-7.38-40.59c0,0-6.45-45.26-10.01-54.16c-6.78-16.86-1.99-63.06-0.55-72.96c2.33-16.55,1.09-44.75-3.18-66.57
	c-3.12-15.64,5.33-45.16,8.45-63.31C714.16,732.88,730.72,616.48,730.72,616.48L730.72,616.48z"
          style={{
            fill: "none",
            stroke: contourColor,
            strokeWidth: `${outerStroke}px`,
          }}
        />
        <g id="Back_1_">
          <path
            id="Back"
            d="M1184.19,616.48c-1.57,18.42,16.61,116.38,23.07,154.62c3.07,18.11,11.49,47.63,8.46,63.31
		c-4.33,21.85-5.58,50.11-3.19,66.57c1.5,10,6.23,56.1-0.54,72.96c-3.54,8.84-10.03,54.16-10.03,54.16
		c-7.83,17.32-8.1,27.41-7.65,32.61c0.29,3.4,2.71,6.25,6.03,7.06c4.1,0.99,8.46,1.43,8.46,1.43c7.5,1,11.74,1,11.74,1
		c5.58-0.04,15.19,0,15.19,0c5.23,0,9.76-0.96,9.76-0.96c9.9-0.13,13.28-1.43,13.28-1.43c12.69-0.81-9.44-29.74-9.44-29.74
		c-4.73-36.49,0.8-72.5,0.8-72.5c30.9-91.64,32.47-115.96,20.12-150.5c-3.48-9.97-4.36-13.92-2.76-18.25
		c3.7-9.99,1.01-50.18,5.52-66.13c8.71-30.77,17.3-108.81,21.78-145.23c6.02-49.05-21.31-114.82-21.31-114.82
		c-5.99-26.76,2.79-122.12,2.79-122.12c12.26,19.07,11.79,52.73,11.79,52.73c-1.95,35.3,28.52,89.25,28.52,89.25
		c14.63,22.3,20.18,43.45,20.18,45.02c0,6.42-1.41,21.98-1.41,21.98l0.56,13.54c0.25,3.45,2.19,15.32,1.88,21.06
		c-2.29,35.32,3.32,28.67,3.32,28.67c4.74,0,9.94-28.42,9.94-28.42c0,7.33-1.79,29.27,2.17,37.55c4.73,9.87,8.21-1.7,8.27-4.02
		c1.25-44.97,3.95-33.19,3.95-33.19c2.63,36.48,5.86,44.72,11.66,41.87c4.39-2.09,0.38-43.78,0.38-43.78
		c7.52,24.76,13.22,28.71,13.22,28.71c12.41,8.71,4.74-15.35,3.01-20.12c-9.18-25.33-9.46-34.1-9.46-34.1
		c11.47,22.75,20.12,21.91,20.12,21.91c11.19-3.57-9.78-35.79-22.06-51.23c-6.27-7.86-14.35-18.4-16.7-24.65
		c-3.82-10.59-6.71-44.65-6.71-44.65c-1.16-40.18-11.09-57.64-11.09-57.64c-16.99-27.19-20.18-77.9-20.18-77.9l-0.75-85.62
		c-5.96-58.4-48.99-58.82-48.99-58.82c-43.5-6.47-49.55-20.53-49.55-20.53c-9.21-13.26-3.94-38.67-3.94-38.67
		c7.64-6.22,10.59-22.72,10.59-22.72c12.69-9.74,12.07-23.98,6.21-23.82c-4.71,0.13-3.64-3.77-3.64-3.77
		c7.94-64.11-48.98-67.38-48.98-67.38h-8.69c0,0-56.94,3.27-49.01,67.37c0,0,1.06,3.9-3.68,3.77c-5.85-0.16-6.4,14.09,6.25,23.82
		c0,0,2.95,16.5,10.59,22.72c0,0,5.27,25.41-3.94,38.67c0,0-6.03,14.06-49.55,20.53c0,0-43.11,0.42-48.97,58.82l-0.81,85.62
		c0,0-3.13,50.71-20.19,77.9c0,0-9.89,17.46-11.03,57.64c0,0-2.9,34.05-6.7,44.65c-2.32,6.22-10.4,16.75-16.72,24.65
		c-12.4,15.41-33.22,47.56-22.08,51.23c0,0,8.69,0.84,20.12-21.91c0,0-0.23,8.71-9.4,34.1c-1.81,4.7-9.46,28.77,2.95,20.12
		c0,0,5.75-3.95,13.22-28.71c0,0-4.01,41.69,0.45,43.78c5.83,2.86,9.01-5.39,11.65-41.87c0,0,2.69-11.78,3.94,33.19
		c0.06,2.32,3.46,13.89,8.22,4.02c4.01-8.28,2.21-30.18,2.21-37.55c0,0,5.14,28.42,9.95,28.42c0,0,5.66,6.64,3.33-28.67
		c-0.38-5.77,1.65-17.61,1.9-21.06l0.55-13.54c0,0-1.41-15.52-1.41-21.98c0-1.6,5.55-22.72,20.18-45.02c0,0,30.43-53.98,28.48-89.25
		c0,0-0.42-33.66,11.83-52.73c0,0,8.7,95.35,2.8,122.12c0,0-27.38,65.77-21.34,114.82c4.45,36.51,13.02,114.45,21.75,145.23
		c4.56,15.92,1.86,56.1,5.52,66.13c1.64,4.36,0.78,8.37-2.76,18.25c-12.29,34.53-10.72,58.86,20.18,150.5c0,0,5.81,35.49,1,71.97
		c0,0-23.33,30.38-10.68,31.19c0,0,8.37,1.85,12.71,1.48c0,0,5.23,0,12.2,0.37c0,0,9.17,0.6,17.08-1.62c0,0,2.86,1.26,8.96,0.25
		l5.32-0.8c0,0,18.43,2.39,1.58-40.3c0,0-6.45-45.26-10.01-54.16c-6.78-16.86-1.99-63.06-0.55-72.96
		c2.33-16.55,1.08-44.75-3.18-66.57c-3.12-15.64,5.33-45.16,8.44-63.31C1167.62,732.88,1184.19,616.48,1184.19,616.48
		L1184.19,616.48z"
            style={{
              fill: "none",
              stroke: contourColor,
              strokeWidth: `${outerStroke}px`,
            }}
          />
          <path
            id="Heel"
            d="M1120.58,1027.66c0,0-9.67,43.96,21.82,42.48c31.48-1.48,25.67-31.54,24.1-42.07"
            style={{
              fill: "none",
              stroke: contourColor,
              strokeWidth: `${outerStroke}px`,
            }}
          />
          <path
            id="Heel_1_"
            d="M1247.93,1027.66c0,0,9.67,43.96-21.82,42.48c-31.48-1.48-25.67-31.54-24.1-42.07"
            style={{
              fill: "none",
              stroke: contourColor,
              strokeWidth: `${outerStroke}px`,
            }}
          />
        </g>
      </svg>
    </>
  )
}

BodyHighlight.propTypes = {
  floated: PropTypes.string,
}
export default BodyHighlight
