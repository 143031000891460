// imports

import PropTypes from "prop-types"
import { Trans } from "@lingui/macro"
import { ambiantColor, StyledHeader, StyledSegment } from "../../../../Styled"
import { formatSecondsToTime } from "../../../utils"

const Rest = ({ block, index }) => {
  const {
    format: { time },
  } = block
  return (
    <StyledSegment basic lightbgalpha="true">
      <StyledHeader size="small">
        <span style={{ textTransform: "uppercase" }}>
          <Trans>Rest</Trans>{" "}
          <span style={{ color: ambiantColor }}>
            {formatSecondsToTime(time)}
          </span>
        </span>
      </StyledHeader>
    </StyledSegment>
  )
}

Rest.propTypes = {
  floated: PropTypes.string,
}
export default Rest
