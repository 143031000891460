import { Trans } from "@lingui/macro"
import { Divider, Icon } from "semantic-ui-react"
import InProgress from "../../Analysis/InProgress"
import { StyledHeader } from "../../Styled"

const Done = () => {
  return (
    <div>
      <StyledHeader>
        <Icon name="check" color="teal" />{" "}
        <Trans>Video successfully uploaded</Trans>
      </StyledHeader>
      <Divider />
      <InProgress />
    </div>
  )
}

export default Done
