import { t } from "@lingui/macro"
import { useEffect } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import { toast } from "react-toastify"
import { useSetRecoilState } from "recoil"
import { forceSubscriptionUpdate } from "../../recoil/Atoms"
import { Mixpanel } from "../../utils/mixpanel"

const SuccessPage = () => {
  const [searchParams] = useSearchParams()
  useEffect(() => {
    let product, frequency

    if (searchParams.has("product")) {
      product = searchParams.get("product")
    }
    if (searchParams.has("frequency")) {
      frequency = searchParams.get("frequency")
    }
    //fbq('track', 'Purchase', {value: 0.00, currency: 'USD'});

    Mixpanel.track("Subscription Success Page Viewed", {
      Product: product,
      frequency: frequency,
    })
  }, [searchParams])

  const navigate = useNavigate()
  const recoilForceSubscriptionUpdate = useSetRecoilState(
    forceSubscriptionUpdate
  )

  useEffect(() => {
    toast.success(t`Your subscription was successfully created !`)
    recoilForceSubscriptionUpdate((prev) => prev + 1)
    navigate("/", { replace: true })
  }, [navigate, recoilForceSubscriptionUpdate])

  return <></>
}

export default SuccessPage
