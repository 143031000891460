// imports

import PropTypes from "prop-types"
import { Icon, Modal } from "semantic-ui-react"
import { useResetRecoilState } from "recoil"
import {
  currentIntervalIndex,
  timerIsRunning,
  stopwatchIsRunning,
  stopwatchTime,
  currentIntervalExerciseIndex,
} from "../../../recoil/Atoms"
import { useState } from "react"
import { Trans } from "@lingui/macro"
import { StyledButton } from "../../Styled"
import { useNavigate, useSearchParams } from "react-router-dom"
import { Mixpanel } from "../../../utils/mixpanel"

const ExitButton = ({ nextUrl = "" }) => {
  const [open, setOpen] = useState(false)
  const resetRecoilCurrentIntervalIndex =
    useResetRecoilState(currentIntervalIndex)

  const resetRecoilCurrentIntervalExerciseIndex = useResetRecoilState(
    currentIntervalExerciseIndex
  )

  const resetRecoilTimerIsRunning = useResetRecoilState(timerIsRunning)
  const resetRecoilStopwatchIsRunning = useResetRecoilState(stopwatchIsRunning)
  const resetRecoilStopwatchTime = useResetRecoilState(stopwatchTime)
  const navigate = useNavigate()

  const [searchParams] = useSearchParams()
  let isWarmup = false
  let mainSesionId
  if (searchParams.has("is-warmup")) {
    isWarmup = true
  }

  if (searchParams.has("is-warmup") && searchParams.has("sessionId")) {
    mainSesionId = searchParams.get("sessionId")
  }

  let confirm = () => {
    resetRecoilCurrentIntervalIndex()
    resetRecoilTimerIsRunning()
    resetRecoilStopwatchIsRunning()
    resetRecoilStopwatchTime()
    resetRecoilCurrentIntervalExerciseIndex()

    if (!isWarmup) {
      navigate(-1)
      Mixpanel.track("Button Skip Warmup Clicked")
    } else {
      navigate(`/session/${mainSesionId}`)
      Mixpanel.track("Button Quit Session Clicked")
    }
    setOpen(false)
  }

  let cancel = () => {
    setOpen(false)
  }

  return (
    <>
      <Modal
        basic
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
        size="small"
        trigger={
          <Icon
            style={{
              position: "fixed",
              bottom: "1.5rem",
              left: "50%",
              transform: "translateX(-50%)",
              opacity: 1,
            }}
            size="big"
            name="times circle outline"
          ></Icon>
        }
      >
        <Modal.Content style={{ textAlign: "center", fontSize: "1.2em" }}>
          {isWarmup ? (
            <Trans>Do you really want to skip the warm-up?</Trans>
          ) : (
            <Trans>Do you really want to quit this workout ?</Trans>
          )}
        </Modal.Content>
        <Modal.Actions style={{ textAlign: "center" }}>
          <StyledButton simple="true" onClick={confirm}>
            <Icon name="checkmark" /> <Trans>Yes</Trans>
          </StyledButton>
          <StyledButton onClick={cancel}>
            <Icon name="remove" /> <Trans>No</Trans>
          </StyledButton>
        </Modal.Actions>
      </Modal>
    </>
  )
}

ExitButton.propTypes = {
  floated: PropTypes.string,
}
export default ExitButton
