import { t, Trans } from "@lingui/macro"
import dayjs from "dayjs"
import PropTypes from "prop-types"
import { useCallback, useEffect, useState } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import { toast } from "react-toastify"
import { useRecoilValue, useResetRecoilState } from "recoil"
import { Divider, Segment } from "semantic-ui-react"
import {
  useSetPlanification,
  useResetPlanification,
  useSetPreferences,
} from "../../hooks/appfetch"
import {
  userPlanification,
  programSelectChoices,
  userLevel,
  userProgram,
  subscriptionName,
  subscription,
} from "../../recoil/Atoms"
import { Mixpanel } from "../../utils/mixpanel"
import { StyledButton, StyledHeader } from "../Styled"

const ChoicesConfirm = (props) => {
  const [showConfirm, setShowConfirm] = useState(false)
  const [showSelectPlanification, setShowSelectPlanification] = useState(false)
  const recoilPlanification = useRecoilValue(userPlanification)

  let setUserPlanification = useSetPlanification()
  let resetUserPlanification = useResetPlanification()
  let setUserPreferences = useSetPreferences()
  let navigate = useNavigate()

  let recoilProfileProgram = useRecoilValue(userProgram)
  let recoilProfileLevel = useRecoilValue(userLevel)
  const resetRecoilprogramSelectChoices =
    useResetRecoilState(programSelectChoices)
  let recoilSubscriptionName = useRecoilValue(subscriptionName)
  let recoilSubscription = useRecoilValue(subscription)

  let [searchParams] = useSearchParams()

  const resetplanification = searchParams.get("reset-planification")

  const {
    program: selectedProgram,
    level: selectedLevel,
    planification: selectedPlanification,
  } = useRecoilValue(programSelectChoices)

  const setNewPlanification = useCallback(async () => {
    let updatedPlanification = await setUserPlanification(selectedPlanification)
    if (updatedPlanification) {
      toast.success(t`New planification set`)

      Mixpanel.track("Plannification Selected", {
        Name: selectedPlanification.name,
        EaceDate: selectedPlanification.raceDate?.toDate(),
        RaceName: selectedPlanification?.raceName,
      })

      if (selectedPlanification.name === "on-ramp") {
        Mixpanel.track("On-ramp Started", {
          Subscription: recoilSubscriptionName,
        })
      }
    }
  }, [recoilSubscriptionName, selectedPlanification, setUserPlanification])

  const setNewPreferences = useCallback(async () => {
    let updatedPreferences = await setUserPreferences({
      program: selectedProgram || recoilProfileProgram,
      level: selectedLevel || recoilProfileLevel,
    })
    if (updatedPreferences) {
      selectedProgram
        ? toast.success(t`Playground updated`)
        : toast.success(t`Level updated`)
    }
  }, [
    recoilProfileLevel,
    recoilProfileProgram,
    selectedLevel,
    selectedProgram,
    setUserPreferences,
  ])

  const goHome = useCallback(() => {
    resetRecoilprogramSelectChoices()
    navigate("/", { replace: true })
  }, [navigate, resetRecoilprogramSelectChoices])

  const setPlanificationChecks = useCallback(async () => {
    if (selectedLevel) {
      if (selectedLevel === userLevel) {
        toast.info(t`No modification made`)
      } else {
        await setNewPreferences()
      }
      goHome()
    } else if (selectedProgram) {
      //if no planification

      if (recoilPlanification.notSet === true) {
        if (selectedProgram !== recoilProfileProgram) {
          await setNewPreferences()
        }
        setShowSelectPlanification(true)
      } else if (
        //planification program different than new one
        recoilPlanification.planification.program !== selectedProgram ||
        selectedProgram !== recoilProfileProgram
      ) {
        setShowConfirm(true)
      } else {
        //no changes made
        toast.info(t`No modification made`)
        goHome()
      }
    } else if (selectedPlanification) {
      //if no planification go through
      if (
        recoilPlanification.notSet === true ||
        !recoilPlanification.planification?.id
      ) {
        await setNewPlanification()
        goHome()
      } else if (
        //planification  different than new one
        recoilPlanification.planification?.id !== selectedPlanification.id ||
        !dayjs(recoilPlanification.raceDate).isSame(
          dayjs(selectedPlanification.raceDate),
          "day"
        )
      ) {
        setShowConfirm(true)
      } else {
        //no changes made
        toast.info(t`No modification made to the planification`)
        goHome()
      }
    }

    //if current user planification exists and program is different
    //--> ask for confirmation
  }, [
    goHome,
    recoilPlanification.notSet,
    recoilPlanification.planification?.id,
    recoilPlanification.planification?.program,
    recoilPlanification.raceDate,
    recoilProfileProgram,
    selectedLevel,
    selectedPlanification,
    selectedProgram,
    setNewPlanification,
    setNewPreferences,
  ])

  const cancelPlanificationChange = () => {
    toast.info(t`Current planfication not modified`)
    goHome()
  }

  const postConfirm = async () => {
    if (selectedProgram) {
      await setNewPreferences()
      await resetUserPlanification()
      navigate("choose", { replace: true })
    } else if (selectedPlanification) {
      await setNewPlanification()
      goHome()
    }
  }

  const forcePlanificationReset = async () => {
    await resetUserPlanification()
    navigate(-1, { replace: true })
  }

  useEffect(() => {
    setPlanificationChecks()
  }, [setPlanificationChecks])

  return (
    <>
      {resetplanification && (
        <Segment basic>
          <div style={{ textAlign: "center" }}>
            <StyledHeader>
              <Trans>
                This modification will abort the current planification.
              </Trans>
            </StyledHeader>
            {recoilSubscription?.frequency === "onetime" && (
              <p style={{ textAlign: "center", fontSize: "1em" }}>
                <Trans>
                  Your {recoilSubscriptionName} subscription will stay
                  available.
                </Trans>
              </p>
            )}
            <Divider hidden />
            <p style={{ textAlign: "center", fontSize: "1.1em" }}>
              <Trans> Do you confirm ? </Trans>
            </p>
          </div>

          <StyledButton
            style={{ marginTop: "1em" }}
            onClick={() => navigate(-1, { replace: true })}
          >
            <Trans>No, keep the current one</Trans>
          </StyledButton>
          <StyledButton
            style={{ marginTop: "1em" }}
            simple="true"
            onClick={forcePlanificationReset}
          >
            <Trans>Yes, abort the current one</Trans>
          </StyledButton>
        </Segment>
      )}
      {showConfirm && (
        <Segment basic>
          <div style={{ textAlign: "center" }}>
            <StyledHeader>
              <Trans>
                This modification will abort the current planification and start
                a new one.
              </Trans>
            </StyledHeader>
            {recoilSubscription?.frequency === "onetime" && (
              <p style={{ textAlign: "center", fontSize: "1em" }}>
                <Trans>
                  Your {recoilSubscriptionName} subscription will stay
                  available.
                </Trans>
              </p>
            )}
            <Divider hidden />
            <p style={{ textAlign: "center", fontSize: "1.1em" }}>
              <Trans> Do you confirm ? </Trans>
            </p>
          </div>

          <StyledButton
            style={{ marginTop: "1em" }}
            onClick={cancelPlanificationChange}
          >
            <Trans>No, keep the current one</Trans>
          </StyledButton>
          <StyledButton
            style={{ marginTop: "1em" }}
            simple="true"
            onClick={postConfirm}
          >
            <Trans>Yes, start the new one</Trans>
          </StyledButton>
        </Segment>
      )}
      {showSelectPlanification && (
        <Segment basic>
          <div style={{ textAlign: "center" }}>
            <StyledHeader>
              <Trans>You are not following a planification currently.</Trans>
            </StyledHeader>
            <Divider hidden />
            <p style={{ textAlign: "center", fontSize: "1.1em" }}>
              {" "}
              <Trans> Do you want to choose one now ? </Trans>
            </p>
          </div>

          <StyledButton
            style={{ marginTop: "1em" }}
            onClick={cancelPlanificationChange}
          >
            <Trans>No, not yet</Trans>
          </StyledButton>
          <StyledButton
            style={{ marginTop: "1em" }}
            simple="true"
            onClick={() => navigate("choose", { replace: true })}
          >
            <Trans>Yes, let's go</Trans>
          </StyledButton>
        </Segment>
      )}
    </>
  )
}

ChoicesConfirm.propTypes = {
  floated: PropTypes.string,
}
export default ChoicesConfirm
