// imports

import { t } from "@lingui/macro"
import { Divider, Segment } from "semantic-ui-react"
import BodyPartsButtons from "./BodyPartsButtons"
import BodyHighlight from "./BodyHighlight"
import DrawerOpenerButton from "../shared/DrawerOpenerButton"
import KeyPoints from "./KeyPoints"

const ExerciseContent = ({
  id,
  style = {},
  mainFocus = [],
  secondaryFocus = [],
}) => {
  let bodyParts = {
    principal: mainFocus,
    accessory: secondaryFocus,
  }

  return (
    <div style={style}>
      <KeyPoints id={id} />

      <Divider hidden></Divider>

      {(mainFocus?.length !== 0 || secondaryFocus?.length !== 0) && (
        <DrawerOpenerButton
          ambiant="true"
          style={{ marginTop: "1.5em" }}
          drawerContent={
            <>
              <BodyPartsButtons bodyParts={bodyParts} />
              <Segment basic style={{ paddingTop: 0 }}>
                <BodyHighlight bodyParts={bodyParts} />
              </Segment>
            </>
          }
          content={t`Display the areas strengthened`}
        />
      )}
    </div>
  )
}

export default ExerciseContent
