// imports

import PropTypes from "prop-types"
import VideoLoop from "../../Exercise/VideoLoopFrames"
import { StyledSlider, StyledSliderDots } from "../../Styled"
import { useEffect, useLayoutEffect } from "react"
import { useNavigate } from "react-router-dom"
import { currentSlide } from "../../../recoil/Atoms"
import { useRecoilState } from "recoil"
import { Icon } from "semantic-ui-react"

const VideoCarousel = ({ videos }) => {
  const [recoilCurrentSlide, setRecoilCurrentSlide] =
    useRecoilState(currentSlide)
  let thisSlider

  const navigate = useNavigate()

  const settings = {
    //dots: false,
    infinite: false,
    //centerMode: true,
    speed: 500,
    slidesToShow: 1.125,
    slidesToScroll: 1,
    arrows: false,
    initialSlide: recoilCurrentSlide,
    afterChange: (current) => {
      setRecoilCurrentSlide(Math.ceil(current))
    },
  }

  const handleClick = (exerciseId, slideIndex) => {
    setRecoilCurrentSlide(slideIndex)
    navigate(`/exercise/${exerciseId}`)
  }
  useEffect(() => {
    thisSlider.slickGoTo(recoilCurrentSlide)
  }, [recoilCurrentSlide, thisSlider])

  useLayoutEffect(() => {
    return () => {
      //	on unmount reset currentslide to 0
      setRecoilCurrentSlide(0)
    }
  }, [setRecoilCurrentSlide])

  return (
    <div style={{ paddingTop: "0" }}>
      <StyledSlider {...settings} ref={(slider) => (thisSlider = slider)}>
        {videos.map(
          (
            {
              id,
              videoId,
              name,
              videoLoopStart,
              videoLoopEnd,
              metronomeStart,
              metronomeBpm,
            },
            index
          ) => (
            <div key={videoId} onClick={() => handleClick(id, index)}>
              <VideoLoop
                key={videoId}
                id={videoId}
                videoLoopStart={videoLoopStart}
                videoLoopEnd={videoLoopEnd}
                style={{ marginRight: 5 }}
                metronomeStart={metronomeStart}
                metronomeBpm={metronomeBpm}
                isPlaying={recoilCurrentSlide === index}
                metronomeStartsMuted={true}
              />
              <div className="title" style={{ textTransform: "capitalize" }}>
                {name}
              </div>
            </div>
          )
        )}
      </StyledSlider>
      <StyledSliderDots>
        {videos.map(({ videoId }, index) => (
          <Icon
            key={videoId}
            name="circle"
            size="tiny"
            inverted
            className={index === recoilCurrentSlide ? "" : "black"}
          />
        ))}
      </StyledSliderDots>
    </div>
  )
}

VideoCarousel.propTypes = {
  floated: PropTypes.string,
}
export default VideoCarousel
