// imports

import { Trans } from "@lingui/macro"
import PropTypes from "prop-types"
import { Fragment } from "react"
import { useRecoilState, useRecoilValue } from "recoil"

import useDrawer from "../../hooks/useDrawer"
import { hasSubscription, selectedRacePack } from "../../recoil/Atoms"
import {
  defaultTextColor,
  StyledHeader,
  StyledHelpIcon,
  StyledOptionCard,
} from "../Styled"
import SelectorHelp from "./selectorHelp/SelectorHelp"
import NextBackButtons from "../shared/NextBackButtons"
import { Icon } from "semantic-ui-react"
import { useNavigate } from "react-router-dom"

const RaceSelector = () => {
  const [recoilSelectedRacePack, setRecoilSelectedRacePack] =
    useRecoilState(selectedRacePack)

  const { setContentAndOpen } = useDrawer()
  const navigate = useNavigate()

  const recoilHasSubscription = useRecoilValue(hasSubscription)

  const selectRace = (selectedRaceOption) => {
    if (selectedRaceOption === "with-current-subscription") {
      navigate("/race")
    } else
      setContentAndOpen(
        <SelectorHelp
          helpItemName={selectedRaceOption}
          canSelectPlanification={true}
        />
      )
    setRecoilSelectedRacePack(selectedRaceOption)
  }

  const nextUrl =
    recoilSelectedRacePack === "per-unit"
      ? "/race"
      : !recoilHasSubscription
      ? "/subscribe"
      : "/race"

  const optionsCard = [
    {
      key: "per-unit",
      title: <Trans>Per unit</Trans>,
      subtitle: (
        // <Trans>starting from 39€</Trans>
        <Trans>10 to 16 weeks</Trans>
      ),

      hide: recoilHasSubscription,
    },
    {
      key: "with-subscription",
      title: !recoilHasSubscription ? (
        <Trans>With a subscription</Trans>
      ) : (
        <Trans>With your current subscription</Trans>
      ),
      subtitle: !recoilHasSubscription ? (
        // <Trans>starting from 4,99€ /mo</Trans>
        <Trans>10 to 16 weeks</Trans>
      ) : (
        ""
      ),
      hide: recoilHasSubscription,
    },
    {
      key: "with-current-subscription",
      title: <Trans>Get ready for a race</Trans>,

      hide: !recoilHasSubscription,
    },
  ]

  return (
    <>
      <StyledHeader style={{ position: "relative" }}>
        <Trans>Prepare for a race</Trans>

        <StyledHelpIcon
          style={{
            display: "inline-block",
            fontSize: "2em",
          }}
          onClick={() =>
            setContentAndOpen(
              <SelectorHelp
                helpItemName={"race-ready"}
                canSelectPlanification={true}
              />
            )
          }
        >
          <Icon name="info circle" />
        </StyledHelpIcon>
      </StyledHeader>

      {optionsCard.map(({ title, subtitle, key, hide }) =>
        hide ? (
          <Fragment key={key}></Fragment>
        ) : (
          <StyledOptionCard
            key={key}
            onClick={() => selectRace(key)}
            className={`ambiant${
              key === recoilSelectedRacePack ? " selected" : ""
            }`}
            data-testid={key}
          >
            <div className="title" style={{ textTransform: "none" }}>
              {title}
              <div
                style={{
                  fontSize: "1rem",
                  color: defaultTextColor,
                  textTransform: "none",
                }}
              >
                {subtitle}
              </div>
            </div>
          </StyledOptionCard>
        )
      )}

      <NextBackButtons
        nextUrl={nextUrl}
        hideNext={!recoilSelectedRacePack}
        nextButtonProps={{ ambiant: "true" }}
      />
    </>
  )
}

RaceSelector.propTypes = {
  floated: PropTypes.string,
}
export default RaceSelector
