//UI
import { Container, Grid } from "semantic-ui-react"
import { StyledHeader } from "../../Styled"
import SetupForm from "./SetupForm"
import { Trans } from "@lingui/macro"

const SetupPage = () => {
  return (
    <Container className="page login">
      <Grid
        textAlign="center"
        style={{ height: "90vh" }}
        verticalAlign="middle"
      >
        <Grid.Column style={{ maxWidth: 450 }}>
          <StyledHeader data-testid="level-select">
            <Trans>Almost done...</Trans>
          </StyledHeader>
          <SetupForm />
        </Grid.Column>
      </Grid>
    </Container>
  )
}

export default SetupPage
