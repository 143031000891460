// imports

import PropTypes from "prop-types"
import { useSetRecoilState } from "recoil"
import { StyledHelpIcon } from "../Styled"
import { drawerIsOpen } from "../../recoil/Atoms"
import { useLayoutEffect } from "react"
import { Icon } from "semantic-ui-react"
import useDrawer from "../../hooks/useDrawer"
const HelpIcon = (props) => {
  const { drawerContent: newDrawerContent } = props

  const setRecoilDrawerIsOpen = useSetRecoilState(drawerIsOpen)

  const { setContentAndOpen } = useDrawer()

  useLayoutEffect(() => {
    return () => {
      setRecoilDrawerIsOpen(false)
    }
  }, [setRecoilDrawerIsOpen])

  const setDrawerContentAndOpen = () => {
    setContentAndOpen(newDrawerContent)
  }

  return (
    <StyledHelpIcon {...props} onClick={setDrawerContentAndOpen}>
      <Icon name="info circle" />
    </StyledHelpIcon>
  )
}

HelpIcon.propTypes = {
  floated: PropTypes.string,
}
export default HelpIcon
