import { Trans } from "@lingui/macro"
import { Link } from "react-router-dom"
import { Icon } from "semantic-ui-react"

import dayjs from "dayjs"
import localizedFormat from "dayjs/plugin/localizedFormat"
dayjs.extend(localizedFormat)

const Available = () => {
  return (
    <div
      data-testid="drawer-content-analysis-available"
      style={{ textAlign: "left" }}
    >
      <Trans>
        Our team has reviewed your stride. You can now select a new program that
        will be personalized to your stride!
        <br />
        <br />
        Your main area of work are available on your{" "}
        <span
          style={{
            whiteSpace: "nowrap",
            display: "inline-block",
            marginLeft: 5,
          }}
        >
          <Link to="/dashboard">
            <Icon name="th large" style={{ fontSize: "1em" }} />
            dashboard
          </Link>
        </span>
      </Trans>
    </div>
  )
}

export default Available
