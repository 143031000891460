import { GoogleLogin } from "@react-oauth/google"
import { StyledGAuthContainer } from "../Styled"
import { Divider } from "semantic-ui-react"
import { Segment } from "semantic-ui-react"
import { Trans } from "@lingui/macro"
import useFetch from "use-http"
import { useAuthResponse } from "../../hooks/AuthHooks"
import { useNavigate } from "react-router-dom"

const GoogleAuthProvider = () => {
  const { post, response } = useFetch()
  const { postAuth } = useAuthResponse()
  const navigate = useNavigate()
  const handleGAuth = async ({ clientId, credential: access_token }) => {
    const authResponse = await post(`/auth/google`, { access_token })

    if (response.ok) {
      //Mixpanel.alias()
      postAuth(authResponse)
      navigate("/setup")
    }
  }

  return (
    <>
      <StyledGAuthContainer basic compact style={{ margin: "0 auto" }}>
        <GoogleLogin
          onSuccess={(credentialResponse) => {
            handleGAuth(credentialResponse)
          }}
          onError={() => {
            console.log("Login Failed")
          }}
        />
      </StyledGAuthContainer>
      <Segment
        basic
        style={{ marginTop: 0, marginBottom: 0, paddingBottom: 0 }}
      >
        <Divider inverted horizontal>
          <Trans>or</Trans>
        </Divider>
      </Segment>
    </>
  )
}

export default GoogleAuthProvider
