import { Trans, t } from "@lingui/macro"
import { Form, Message, Segment } from "semantic-ui-react"
import { StyledButton, StyledForm } from "../../Styled"
import { useForm } from "react-hook-form"
import { Link, useNavigate } from "react-router-dom"
import { useEffect, useState } from "react"
import useFetch from "use-http"
import { useAuthResponse } from "../../../hooks/AuthHooks"
import { signupEmail } from "../../../recoil/Atoms"
import { useRecoilValue } from "recoil"
import Loading from "../../App/Loading"

const SignupForm = () => {
  const [clicked, setClicked] = useState(false)
  const recoilSignupEmail = useRecoilValue(signupEmail)
  const { post, response, error } = useFetch()
  const navigate = useNavigate()
  const { postAuth } = useAuthResponse()
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: {
      email: recoilSignupEmail,
    },
  })

  const onSubmit = async (data) => {
    setClicked(true)
    var payload = data
    const authResponse = await post("/users/wOAuth", payload)
    if (response.ok) {
      postAuth(authResponse)
      //Mixpanel.alias()
      setClicked(false)
      navigate("/setup")
    }
  }

  useEffect(() => {
    register("email", {
      required: true,
      pattern:
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    })
    register("password", { required: true, minLength: 6 })
    register("passwordConfirm", {
      validate: {
        match: (passwordConfirm) => watch("password") === passwordConfirm,
      },
    })
  }, [register, watch])

  const errorStyle = {
    marginTop: "-1em",
    fontSize: "0.9em",
    color: "#dd1313",
    fontWeight: 700,
  }
  return (
    <StyledForm>
      <Segment basic textAlign="left">
        <Form.Input
          name="email"
          icon="at"
          iconPosition="left"
          fluid
          placeholder={t`Email`}
          defaultValue={recoilSignupEmail}
          onChange={(e, { name, value }) => {
            setValue(name, value.trim())
          }}
        />
        {errors.email && (
          <div style={errorStyle}>
            <Trans>Enter your email</Trans>
          </div>
        )}
        <Form.Input
          name="password"
          icon="lock"
          type="password"
          iconPosition="left"
          fluid
          placeholder={t`Password`}
          onChange={(e, { name, value }) => {
            setValue(name, value)
          }}
        />
        {errors.password && (
          <div style={errorStyle}>
            <Trans>Enter at least 6 characters</Trans>
          </div>
        )}
        <Form.Input
          name="passwordConfirm"
          icon="lock"
          type="password"
          iconPosition="left"
          fluid
          placeholder={t`Password (again)`}
          onChange={(e, { name, value }) => {
            setValue(name, value)
          }}
        />
        {errors.passwordConfirm && (
          <div style={errorStyle}>
            <Trans>Passwords do not match</Trans>
          </div>
        )}
      </Segment>
      {(error || !!Object.keys(errors).length) && (
        <Segment basic>
          {error && (
            <Message negative>
              <Trans>This email is already used !</Trans>
            </Message>
          )}
          {!!Object.keys(errors).length && (
            <Message negative>
              <Trans>Please, fill in all the required fields</Trans>
            </Message>
          )}
        </Segment>
      )}

      <Segment basic>
        <StyledButton fluid onClick={handleSubmit(onSubmit)}>
          {clicked ? <Loading /> : <Trans>Sign up</Trans>}
        </StyledButton>
        <div style={{ marginTop: 20 }}>
          <StyledButton simple="true" secondary>
            <Link to="/login">
              <Trans>Sign in</Trans>
            </Link>
          </StyledButton>
        </div>
      </Segment>
    </StyledForm>
  )
}

export default SignupForm
