// imports

import { useRecoilValue } from "recoil"
import { subscriptionName } from "../../recoil/Atoms.js"
import { StyledLabel } from "../Styled.js"

const SubscriptionLabel = ({ requiredSubscription }) => {
  const recoilSubscriptionName = useRecoilValue(subscriptionName)

  return (
    requiredSubscription &&
    requiredSubscription !== recoilSubscriptionName && (
      <>
        <StyledLabel
          style={{
            position: "absolute",
            right: 10,
            top: 10,
            fontSize: "0.9em",
          }}
          size="small"
          basicwhite={requiredSubscription === "classic" ? "true" : null}
          data-testid={`label-${requiredSubscription}`}
        >
          {requiredSubscription}
        </StyledLabel>
      </>
    )
  )
}

SubscriptionLabel.propTypes = {}

export default SubscriptionLabel
