import { Trans, t } from "@lingui/macro"
import { i18n } from "@lingui/core"
import { Form, Message, Segment } from "semantic-ui-react"
import { StyledButton, StyledFormCheckbox, StyledForm } from "../../Styled"
import { useForm } from "react-hook-form"
import { useNavigate } from "react-router-dom"
import { useEffect, useState } from "react"
import { useLocalStorage } from "../../../hooks/useLocalStorage"
import { Mixpanel } from "../../../utils/mixpanel"
import { programs } from "../../../recoil/Atoms"
import { upperFirst } from "../../../utils"
import Loading from "../../App/Loading"
import { useUpdateProfile } from "../../../hooks/appfetch"
import { useUser } from "../../../hooks/useUserInfo"
import { toast } from "react-toastify"
const SetupForm = () => {
  const [clicked, setClicked] = useState(false)
  const [, setStorage] = useLocalStorage()
  const navigate = useNavigate()

  const firstname = JSON.parse(window.localStorage.getItem("RFRUser"))?.[
    "given_name"
  ]
  const lastname = JSON.parse(window.localStorage.getItem("RFRUser"))?.[
    "family_name"
  ]
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: {
      firstname,
      lastname,
    },
  })

  const currentUser = useUser()
  const { id, email } = currentUser

  const updateProfile = useUpdateProfile()

  const onSubmit = async (data) => {
    setClicked(true)
    const setup = true
    data = { ...data, email, setup }
    let updatedProfile = await updateProfile(id, data)

    let { firstname, lastname, program } = updatedProfile
    let user = {
      ...currentUser,
      firstname,
      lastname,
      program,
    }
    toast.success(t`Profile successfully completed`)
    setStorage("RFRUser", user)

    Mixpanel.track("Subscribed", { Email: email, Program: program })
    Mixpanel.people.set_once({
      "First Login Date": new Date(),
      "$created": new Date(),
    })
    Mixpanel.people.set({
      "Subscription": "Free",
      "Program": program,
      "$first_name": firstname,
      "$last_name": lastname,
      "$name": `${firstname} ${lastname}`,
      "$email": email,
      "Sessions Done": 0,
      "Sessions Started": 0,
    })
    //Mixpanel.alias()
    setClicked(false)
    navigate("/")
  }

  useEffect(() => {
    register("firstname", { required: true })
    register("lastname", { required: true })
    register("program", { required: true })
  }, [register, watch])

  const errorStyle = {
    marginTop: "-1em",
    fontSize: "0.9em",
    color: "#dd1313",
    fontWeight: 700,
  }
  return (
    <StyledForm>
      <Segment basic textAlign="left">
        <Form.Input
          name="firstname"
          placeholder={t`Firstname`}
          icon="user"
          iconPosition="left"
          fluid
          defaultValue={firstname}
          onChange={(e, { name, value }) => {
            setValue(name, value)
          }}
        />
        {errors.firstname && (
          <div style={errorStyle}>
            <Trans>Enter your firstname</Trans>
          </div>
        )}
        <Form.Input
          name="lastname"
          placeholder={t`Lastname`}
          icon="user"
          iconPosition="left"
          fluid
          defaultValue={lastname}
          onChange={(e, { name, value }) => {
            setValue(name, value)
          }}
        />
        {errors.lastname && (
          <div style={errorStyle}>
            <Trans>Enter your lastname</Trans>
          </div>
        )}

        <Form.Select
          name="program"
          icon="running"
          fluid
          selection
          //  defaultValue={"trail"}
          className="left icon signup-select"
          options={programs.map((value) => ({
            key: value,
            value: value,
            text: upperFirst(i18n._(value)),
          }))}
          placeholder={t`Your playground`}
          onChange={(e, { name, value }) => {
            setValue(name, value)
          }}
        />
        {errors.program && (
          <div style={errorStyle}>
            <Trans>Enter your playground, (you can change it later)</Trans>
          </div>
        )}

        <StyledFormCheckbox style={{ marginTop: "1em" }}>
          <input
            type="checkbox"
            {...register("checkbox", {
              required: true,
            })}
          />{" "}
          <label>
            <Trans>
              I have read and accept the{" "}
              <a
                href={t`https://renforun.com/terms`}
                target="_blank"
                rel="noreferrer"
              >
                terms of use
              </a>
            </Trans>
          </label>
        </StyledFormCheckbox>
        {errors.checkbox && (
          <div style={errorStyle}>
            <Trans>Please accept the terms of use</Trans>
          </div>
        )}
      </Segment>
      <Segment basic>
        {!!Object.keys(errors).length && (
          <Message negative>
            <Trans>Please, fill in all the required fields</Trans>
          </Message>
        )}
      </Segment>
      <Segment basic style={{ paddingTop: "0em", paddingBottom: "0em" }}>
        <Trans>
          Your data is used solely to improve your user experience and is not
          sold to third parties.
        </Trans>
      </Segment>
      <Segment basic>
        <StyledButton
          fluid
          onClick={handleSubmit(onSubmit)}
          disabled={!watch("checkbox") || !watch("program")}
        >
          {clicked ? <Loading /> : <Trans>Finalize my accout</Trans>}
        </StyledButton>
      </Segment>
    </StyledForm>
  )
}

export default SetupForm
