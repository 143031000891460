// imports

import { Trans, t } from "@lingui/macro"
import HelpIcon from "../../shared/HelpIcon"
import Help from "../../shared/Help"
import { primaryColor, secondaryColor } from "../../Styled"
import { Icon } from "semantic-ui-react"
import { useEffect, useState } from "react"
import { Mixpanel } from "../../../utils/mixpanel"

const SessionsPageHelpIcon = ({ title, weeksCount, helpKey, iconStyle }) => {
  const [sessionsPageHelpSeen, setSessionsPageHelpSeen] = useState(
    localStorage.getItem("RFRSessionsPageHelpSeen")
  )
  const helpKeys = {
    "race-ready": {
      description: (
        <>
          {" "}
          <Trans>
            <p> Your training plan is {weeksCount} weeks long. </p>
            <p>
              Each four-week block contains 3 weeks during which the workload
              during which the workload gradually increases. Then, you have a
              lighter week to allow your muscles to assimilate the work done.
            </p>
            <p>
              The last two weeks leading up to the race, we lower the volume,
              but we keep some intensity to recover while maintaining what you
              have gained.
            </p>
          </Trans>
        </>
      ),
      details: (
        <>
          <p>
            <Trans>
              Each week there are several sessions, the order is not important.
              important. You just have to adapt them to your running training.
            </Trans>
          </p>{" "}
          <p>
            <Trans>
              {" "}
              The "
              <span style={{ display: "inline-block" }}>
                <Icon
                  className={"bookmark"}
                  style={{ color: secondaryColor }}
                />
                <span>{t`do not miss`}</span>
              </span>
              " sessions are key, try to complete them every week.
            </Trans>
          </p>
          <p>
            <Trans>
              {" "}
              It is better to do the "
              <span style={{ display: "inline-block" }}>
                <Icon className={"heartbeat"} style={{ color: primaryColor }} />
                <span>{t`high impact`}</span>
              </span>
              " session on an easy run day. If the next day is a complete rest
              day, it's even better. You can do the other sessions whenever you
              want, they will have little impact on your intervals, threshold or
              long sessions tranings.
            </Trans>
          </p>
        </>
      ),
    },
    "on-ramp": {
      description: (
        <>
          <p>
            <Trans>
              Each week there are several sessions, the order is not important.
              important. You just have to adapt them to your running training.
            </Trans>
          </p>{" "}
          <p>
            <Trans>
              The "
              <span style={{ display: "inline-block" }}>
                <Icon
                  className={"bookmark"}
                  style={{ color: secondaryColor }}
                />
                <span>{t`do not miss`}</span>
              </span>
              " sessions are key, try to complete them every week.
            </Trans>
          </p>
          <p>
            <Trans>
              The "
              <span style={{ display: "inline-block" }}>
                <Icon className={"heartbeat"} style={{ color: primaryColor }} />
                <span>{t`high impact`}</span>
              </span>
              " session should be done on an easy run day. If the next day is a
              complete rest day, it's even better. You can do the other sessions
              whenever you want, they will have little impact on your intervals,
              threshold or long sessions tranings.
            </Trans>
          </p>
        </>
      ),
    },
    "daily": {
      description: (
        <>
          <p>
            <Trans>
              Each week there are several sessions, the order is not important.
              important. You just have to adapt them to your running training.
            </Trans>
          </p>{" "}
          <p>
            <Trans>
              The "
              <span style={{ display: "inline-block" }}>
                <Icon
                  className={"bookmark"}
                  style={{ color: secondaryColor }}
                />
                <span>{t`do not miss`}</span>
              </span>
              " sessions are key, try to complete them every week.
            </Trans>
          </p>
          <p>
            <Trans>
              The "
              <span style={{ display: "inline-block" }}>
                <Icon className={"heartbeat"} style={{ color: primaryColor }} />
                <span>{t`high impact`}</span>
              </span>
              " session should be done on an easy run day. If the next day is a
              complete rest day, it's even better. You can do the other sessions
              whenever you want, they will have little impact on your intervals,
              threshold or long sessions tranings.
            </Trans>
          </p>
        </>
      ),
    },
  }

  const dissmisSessionsPageHelp = () => {
    if (!localStorage.getItem("RFRSessionsPageHelpSeen")) {
      localStorage.setItem("RFRSessionsPageHelpSeen", "1")
      setSessionsPageHelpSeen(true)
    }
  }
  useEffect(() => {
    Mixpanel.track("Sessions Page Help Displayed")
  }, [])

  return (
    helpKeys[helpKey] && (
      <span onClick={dissmisSessionsPageHelp}>
        <HelpIcon
          style={iconStyle}
          fontSize="1em"
          className={`help-icon ${sessionsPageHelpSeen ? "" : "blink"}`}
          drawerContent={
            <Help
              title={title}
              description={helpKeys[helpKey]["description"]}
              detailsTitle={t`Explanations`}
              details={helpKeys[helpKey]["details"]}
            />
          }
        />
      </span>
    )
  )
}

export default SessionsPageHelpIcon
