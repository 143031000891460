//UI
import { Container, Grid } from "semantic-ui-react"
import IdentificationPagesBackground from "../../shared/IdentificationPagesBackground"
import ForgotForm from "./ForgotForm"

const ForgotPage = () => {
  //mix panel is in the form
  return (
    <Container className="page login">
      <IdentificationPagesBackground />
      <Grid
        textAlign="center"
        style={{ height: "90vh" }}
        verticalAlign="middle"
      >
        <Grid.Column style={{ maxWidth: 450 }}>
          <ForgotForm />
        </Grid.Column>
      </Grid>
    </Container>
  )
}

export default ForgotPage
