// imports

import { Trans } from "@lingui/macro"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { Container } from "semantic-ui-react"
import { StyledHeader, StyledSegment } from "../Styled"

const SessionSubscriptionMismatch = () => {
  return (
    <Container className="page session done">
      <StyledHeader
        className={"page-title"}
        style={{ textTransform: "capitalize" }}
      >
        <Trans>Update your subscription</Trans>
      </StyledHeader>

      <div
        style={{
          width: "100%",
          position: "absolute",
          left: "50%",
          top: "33%",
          transform: "translate(-50%, -50%)",
          maxWidth: 800,
        }}
      >
        <StyledSegment basic style={{ fontSize: "1.1em", textAlign: "left" }}>
          <Trans>
            Your current planification does not match your subscription!
          </Trans>
          <ul>
            <li style={{ marginBottom: 10 }}>
              <Trans>
                {" "}
                You may upgrade your subscription from the{" "}
                <Link to="/settings">settings page</Link>
              </Trans>
            </li>
          </ul>
        </StyledSegment>
      </div>
    </Container>
  )
}

SessionSubscriptionMismatch.propTypes = {
  floated: PropTypes.string,
}
export default SessionSubscriptionMismatch
