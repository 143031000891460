// imports

import { Trans } from "@lingui/macro"
import PropTypes from "prop-types"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { Icon, Segment } from "semantic-ui-react"
import Loading from "../App/Loading"
import { ambiantColor, StyledButton } from "../Styled"

const NextBackButtons = ({
  nextUrl = "",
  backUrl = "",
  backOnly = false,
  hideBack = false,
  hideNext = false,
  nextButtonProps = {},
  position = "fixed",
}) => {
  let navigate = useNavigate()
  const [backClicked, setbackClicked] = useState(false)
  const [nextClicked, setNextClicked] = useState(false)
  const url = window.location.pathname
  useEffect(() => {
    setNextClicked(false)
    setbackClicked(false)
  }, [url])

  const goBack = () => {
    if (backUrl) {
      navigate(backUrl)
    } else navigate(-1)
  }

  return (
    <>
      {position !== "relative" && (
        <style>{`.App{padding-bottom:65px!important;}`}</style>
      )}
      {backOnly ? (
        <Segment
          basic
          style={{
            position,
            bottom: position === "relative" ? 0 : 10,
            marginTop: position === "relative" ? 0 : 10,
            right: 5,
            width: "100%",
            maxWidth: 800,
            left: "50%",
            transform: "translateX(-50%)",
          }}
        >
          <div
            style={{
              position: "absolute",
              left: "1em",
              bottom: "0.8em",
              backgroundColor: "rgb(6 9 14 / 55%)",
              padding: "0.6em 0.3em 0.5em 0.5em",
              borderRadius: "50em",
              zIndex: 1000,
              border: `2px solid ${ambiantColor}`,
            }}
          >
            {backClicked ? (
              <Loading />
            ) : (
              <Icon
                name="arrow left"
                style={{
                  fontSize: "1.5em",
                }}
                onClick={
                  goBack
                  //   setbackClicked(true)
                }
                data-testid="navigate-back"
              />
            )}
          </div>
        </Segment>
      ) : (
        <Segment
          basic
          style={{
            position,
            marginLeft: "-1em",
            marginRight: "-1em",
            bottom: 10,
            right: 5,
            width: "100%",
            maxWidth: 800,
            left: "50%",
            transform: "translateX(-50%)",
          }}
        >
          {!hideBack && (
            <StyledButton
              floated="left"
              none="true"
              onClick={goBack}
              data-testid="navigate-back"
            >
              {backClicked ? <Loading /> : <Trans>back</Trans>}
            </StyledButton>
          )}
          {!hideNext && (
            <StyledButton
              floated="right"
              onClick={() => {
                navigate(nextUrl)
                setNextClicked(true)
              }}
              data-testid="navigate-next"
              {...nextButtonProps}
            >
              {nextClicked ? <Loading /> : <Trans>next</Trans>}
            </StyledButton>
          )}
        </Segment>
      )}
    </>
  )
}

NextBackButtons.propTypes = {
  floated: PropTypes.string,
}
export default NextBackButtons
