import { Trans } from "@lingui/macro"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { useRecoilValue } from "recoil"
import useStripe from "../../hooks/useStripe"
import { stripeId, subscriptionName } from "../../recoil/Atoms"
import { Mixpanel } from "../../utils/mixpanel"
import Loading from "../App/Loading"
import { StyledButton } from "../Styled"

const CTASubscribe = ({ orderFunction, buttonLabel }) => {
  const recoilSubscriptionName = useRecoilValue(subscriptionName)
  const recoilStripeId = useRecoilValue(stripeId)
  const navigate = useNavigate()
  const { navigateToPortal } = useStripe()
  const [clicked, setClicked] = useState(false)

  const go = async () => {
    setClicked(true)
    if (!recoilStripeId) {
      let subscribePageUrl = "/subscribe"
      if (window.location.pathname === subscribePageUrl) {
        await orderFunction()
        Mixpanel.track("Checkout Button Clicked", { Destination: "Stripe" })
      } else {
        navigate("/subscribe")
      }
    } else {
      Mixpanel.track("To premium Button Clicked")
      await navigateToPortal({ configuration: "to-Premium" })
    }
    setClicked(false)
  }

  const setButtonLabel = () =>
    buttonLabel ? (
      buttonLabel
    ) : recoilSubscriptionName === "free" ? (
      <Trans>I suscribe </Trans>
    ) : (
      <Trans>Go premium</Trans>
    )

  return (
    recoilSubscriptionName !== "premium" && (
      <div style={{ textAlign: "center" }}>
        <StyledButton
          primary
          uppercase={1}
          wide="true"
          style={{ paddingRight: "0.1em", paddingLeft: "0.1em" }}
          data-testid={`subscribe`}
          onClick={go}
        >
          {clicked ? <Loading /> : setButtonLabel()}
        </StyledButton>
      </div>
    )
  )
}

export default CTASubscribe
