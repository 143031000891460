import { useRecoilValue } from "recoil"
import { currentIntervalIndex } from "../../../../recoil/Atoms"

import "react-circular-progressbar/dist/styles.css"
import { ambiantColorRGB } from "../../../Styled"
import { Icon } from "semantic-ui-react"
import useIntervalNavigation from "../../../../hooks/useIntervalNavigation"
import { useMediaQuery } from "react-responsive"

const NextPrevIntervalButtons = ({ intervals, restart }) => {
  const recoilCurrentIntervalIndex = useRecoilValue(currentIntervalIndex)
  const isPortrait = useMediaQuery({ query: "(orientation: portrait)" })
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" })
  const hasNextInterval = () => {
    return recoilCurrentIntervalIndex + 1 < intervals.length
  }

  const hasPrevInterval = () => {
    return recoilCurrentIntervalIndex - 1 >= 0
  }

  const { nextInterval, prevInterval } = useIntervalNavigation(
    intervals,
    restart
  )

  return (
    <>
      {hasNextInterval() && (
        <Icon
          onClick={nextInterval}
          name="step forward"
          style={{
            position: "absolute",
            top: "55%",
            transform: "translateY(-50%)",
            right: "0.2em",
            color: `rgb(${ambiantColorRGB} / 50%)`,
            //   color: defaultTextColor,
            fontSize: isPortrait || !isTabletOrMobile ? "2.2em" : "1.7em",
          }}
        />
      )}
      {!hasNextInterval() && (
        <Icon
          onClick={nextInterval}
          name="check"
          style={{
            position: "absolute",
            top: "55%",
            transform: "translateY(-50%)",
            right: "0.2em",
            color: `rgb(${ambiantColorRGB} / 50%)`,
            //   color: defaultTextColor,
            fontSize: isPortrait || !isTabletOrMobile ? "2.2em" : "1.7em",
          }}
        />
      )}

      {hasPrevInterval() && (
        <Icon
          onClick={prevInterval}
          name="step backward"
          style={{
            position: "absolute",
            top: "55%",
            transform: "translateY(-50%)",
            left: "0.2em",
            color: `rgb(${ambiantColorRGB} / 50%)`,
            //   color: defaultTextColor,
            fontSize: isPortrait || !isTabletOrMobile ? "2.2em" : "1.7em",
          }}
        />
      )}
    </>
  )
}

export default NextPrevIntervalButtons
