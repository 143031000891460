import { t } from "@lingui/macro"

export const planificationInfos = {
  "on-ramp-001": t`This program contains 2 sessions per week except for the last week which contains 3 sessions.
  The first and last session of the program will challenge you a bit more than the other sessions of the program.
  No pressure, just do the best you can!`,

  "race-ready-base-004": t`This week is light on volume in order to assimilate the work done previously, don't try to do more. Intensity will be back next week!`,
  //  Cette semaine est légère en volume afin d'assimiler le travail effectué précédemment, n'essayez pas d'en faire plus. L'intensité sera de retour la semaine prochaine ! !

  "race-ready-base-008": t`This week is light on volume in order to assimilate the work done previously, don't try to do more. Intensity will be back next week!`,
  //  Cette semaine est légère en volume afin d'assimiler le travail effectué précédemment, n'essayez pas d'en faire plus. L'intensité sera de retour la semaine prochaine ! !

  "race-ready-base-012": t`This week is light on volume in order to assimilate the work done previously, don't try to do more. Intensity will be back next week!`,
  //  Cette semaine est légère en volume afin d'assimiler le travail effectué précédemment, n'essayez pas d'en faire plus. L'intensité sera de retour la semaine prochaine ! !

  "race-ready-base-016": t`This week is the lightest week, we keep a little bit of intensity to maintain gains but still be able to recover. Enjoy this last week before your race!`,
}
