// imports

import { t } from "@lingui/macro"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { StyledSlider } from "../../Styled"
import useWindowSize from "../../../hooks/useWindowSize"
import HomeCard from "./HomeCard"
import { useRecoilState, useRecoilValue } from "recoil"
import {
  homeCardIndex,
  homeCards,
  homeCssReservedHeight,
} from "../../../recoil/Atoms"

import exercisesImg from "../../../images/library.jpg"
import { useEffect, useRef, useState } from "react"
//import eventsImg from "../../../images/events.jpg"
const HomeCarousel = (props) => {
  const { height: windowSize } = useWindowSize()
  let iosMenuBar = 0
  // Detects if device is on iOS
  const isIos = () => {
    const userAgent = window.navigator.userAgent.toLowerCase()
    return /iphone|ipad|ipod/.test(userAgent)
  }
  // Detects if device is in standalone mode
  const isInStandaloneMode = () =>
    "standalone" in window.navigator && window.navigator.standalone

  // Checks if should display install popup notification:
  if (isIos() && !isInStandaloneMode()) {
    iosMenuBar = 50
  }
  const recoilhomeCards = useRecoilValue(homeCards)

  const [recoilHomeCardIndex, setRecoilHomeCardIndex] =
    useRecoilState(homeCardIndex)

  const recoilHomeCssReservedHeight = useRecoilValue(homeCssReservedHeight)

  const cssReservedHeight =
    recoilHomeCssReservedHeight + 90 + 25 + 10 /* top menu*/ + iosMenuBar

  const height = parseInt(windowSize || 0) - cssReservedHeight

  const settings = {
    dots: false,
    className: "center",
    centerMode: true,
    infinite: false,
    centerPadding: "10px",
    speed: 500,
    slidesToShow: 1.1,
    slidesToScroll: 1,
    arrows: false,
    //  initialSlide: recoilHomeCardIndex,
    afterChange: function (index) {
      setRecoilHomeCardIndex(Math.ceil(index))
    },
  }
  const loadingCard = {
    title: null,
    header: null,
    reaceDate: null,
    description: null,
    link: null,
    image: null,
  }
  /*
  const eventsCard = {
    title: t`Race Packs`,
    description: t`Prepare for one of our favorite races`,
    image: eventsImg,
    link: "/events/",
    testid: "view-events",
  }
*/
  const slider = useRef(0)
  const [hasSetPosition, setHasSetPosition] = useState(false)

  useEffect(() => {
    if (slider.current && !hasSetPosition) {
      slider.current?.slickGoTo(recoilHomeCardIndex, false)
      setHasSetPosition(true)
    }
  }, [recoilHomeCardIndex, hasSetPosition, slider])

  const libraryCard = {
    title: t`Library`,
    description: t`The best exercises for the runner`,
    image: exercisesImg,
    link: "/library/",
    testid: "view-library",
  }

  const defaultStaticCards = [libraryCard]

  let cards =
    recoilhomeCards?.length === 0
      ? [loadingCard, ...defaultStaticCards]
      : [...recoilhomeCards, ...defaultStaticCards]

  return (
    <>
      <StyledSlider
        {...settings}
        style={{ height, marginTop: 10 }}
        ref={slider}
      >
        {cards?.map((cardParams, index) => (
          <HomeCard
            key={cardParams.link || index}
            {...cardParams}
            {...{ height: height - 20 }}
          />
        ))}
      </StyledSlider>
    </>
  )
}

export default HomeCarousel
