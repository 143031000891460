//import useLocalStorage from 'react-use-localstorage'

import { useCallback } from "react"

export function useLocalStorage() {
  const getStorageValue = useCallback(
    (item) => JSON.parse(window.localStorage.getItem(item)),
    []
  )

  const setStorageValue = useCallback(
    (item, value) =>
      window.localStorage.setItem(
        item,
        (value = typeof value === "string" ? value : JSON.stringify(value))
      ),
    []
  )

  return [getStorageValue, setStorageValue]
}
