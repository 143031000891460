// imports
import { StyledHomeCard } from "../../Styled"
import { useNavigate } from "react-router-dom"
import { isMobile } from "react-device-detect"
import { upperFirst } from "../../../utils"
import { Placeholder } from "semantic-ui-react"
import { useState } from "react"
import Loading from "../../App/Loading"
import { useMediaQuery } from "react-responsive"

const HomeCard = ({
  height = 0,
  image,
  header,
  title,
  subtitle,
  description,
  link,
  raceDate = null,
  preferences,
  actionButton,
  testid,
  imagePosition = "center center",
}) => {
  const navigate = useNavigate()

  const [clicked, setClicked] = useState(false)
  const isPortrait = useMediaQuery({ query: "(orientation: portrait)" })
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" })

  let clickAction = () => {
    if (!link) return
    setClicked(true)
    setTimeout(() => {
      navigate(link)
    }, 100)
  }
  return (
    <StyledHomeCard
      style={{
        height,
        backgroundImage: `url(${image})`,
        backgroundPosition: imagePosition,
        // transition: "all 5.9s ease-in",
      }}
      onClick={() => (isMobile ? clickAction(link) : null)}
    >
      <style>{`
		.transparent{opacity:0;}
		.visible{opacity:1}

		`}</style>
      <Placeholder
        inverted
        className={image ? "transparent" : "visible"}
        style={{
          height: "100%",
          width: "100%",
          borderRadius: "0.75rem",
          // minHeight: 350,
          transition: "opacity .9s ease-in",
          maxWidth: "none",
        }}
      >
        <Placeholder.Image />
      </Placeholder>

      <div className="overlay" />
      <div className="content" style={{ cursor: "pointer" }}>
        {clicked ? (
          <Loading textAlign="left" />
        ) : (
          <div onClick={() => (isMobile ? null : clickAction(link))}>
            {header && <div className="header">{header}</div>}
            {title === "loading" ? (
              <Loading textAlign="left" />
            ) : (
              <div className="title" data-testid={`${testid}`}>
                {title && upperFirst(title)}

                {(raceDate || subtitle) && (
                  <div
                    style={{ fontSize: "1.2rem" }}
                    data-testid={`planification-race-date`}
                  >
                    {raceDate || subtitle}
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </div>
      {actionButton && (
        <div onClick={() => (isMobile ? null : clickAction(link))}>
          {actionButton}
        </div>
      )}

      <div className="bottom">
        {description && (
          <div
            className="description"
            style={{
              marginLeft: "0.5em",
              textAlign: isPortrait || !isTabletOrMobile ? "center" : "right",
              marginBottom: isPortrait || !isTabletOrMobile ? 0 : -20,
            }}
          >
            {description}
          </div>
        )}
        {preferences && (
          <div
            style={{
              marginLeft: "0.5em",

              textAlign: isPortrait || !isTabletOrMobile ? "center" : "right",
              marginBottom: isPortrait || !isTabletOrMobile ? -10 : -20,
            }}
          >
            {preferences}
          </div>
        )}
      </div>
    </StyledHomeCard>
  )
}

HomeCard.propTypes = {}

export default HomeCard
