// imports

import PropTypes from "prop-types"
import { Container, Divider } from "semantic-ui-react"
import NextBackButtons from "../shared/NextBackButtons"
import { StyledHeader } from "../Styled"
import { useParams } from "react-router-dom"
import ExerciseContent from "./ExerciseContent"
import VideoLoop from "./VideoLoopFrames"
import { movementsTranslations } from "../../locales/movementsKeys"
import { i18n } from "@lingui/core"
import { useRecoilValue } from "recoil"
import { exerciseById } from "../../recoil/Atoms"
import { useEffect } from "react"
import { Mixpanel } from "../../utils/mixpanel"

const ExercisePage = () => {
  let { id } = useParams()

  const recoilExercise = useRecoilValue(exerciseById(id))
  let {
    videoId,
    videoLoopEnd,
    videoLoopStart,
    mainFocus,
    secondaryFocus,
    metronomeStart,
    metronomeBpm,
    name,
  } = recoilExercise

  useEffect(() => {
    if (name) Mixpanel.track("Exercise Page Opened", { Exercise: name })
  }, [name])

  return (
    <>
      <Container className="page exercise">
        <StyledHeader className={"page-title"}>
          {movementsTranslations[id]?.["name"] &&
          (i18n._(movementsTranslations[id]?.["name"]).endsWith(" R") ||
            i18n._(movementsTranslations[id]?.["name"]).endsWith(" L"))
            ? i18n
                ._(movementsTranslations[id]?.["name"])
                .slice(0, -2)
                .replace(" R", "")
            : i18n._(movementsTranslations[id]?.["name"])}
        </StyledHeader>
      </Container>
      <div style={{ paddingTop: 20 }}>
        {videoId && (
          <VideoLoop
            id={videoId}
            isPlaying={true}
            videoLoopStart={videoLoopStart}
            videoLoopEnd={videoLoopEnd}
            metronomeStart={metronomeStart}
            metronomeBpm={metronomeBpm}
          />
        )}

        <Divider hidden></Divider>
        <Divider hidden></Divider>
        <ExerciseContent
          id={id}
          mainFocus={mainFocus}
          secondaryFocus={secondaryFocus}
        />

        <NextBackButtons backOnly />
      </div>
    </>
  )
}

ExercisePage.propTypes = {
  floated: PropTypes.string,
}
export default ExercisePage
