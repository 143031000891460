import dayjs from "dayjs"
import localizedFormat from "dayjs/plugin/localizedFormat"
dayjs.extend(localizedFormat)

export const upperFirst = (string) => {
  if (!string) return
  return string[0].toUpperCase() + string.slice(1)
}

export const formatWeekendFromSunday = (sundayDate, noYear = false) => {
  /* if (new Date(sundayDate).getTime() > 0) {
    sundayDate = dayjs.unix(sundayDate)
  } else
  */
  if (!dayjs.isDayjs(sundayDate)) {
    sundayDate = dayjs(sundayDate)
  }

  let saturdayDate = sundayDate.subtract(1, "d").startOf("d")
  let sundayYear = sundayDate.year()
  let saturdayYear = saturdayDate.year()
  let saturday = saturdayDate.format("ll").replace(saturdayYear, "")
  let sunday = noYear
    ? sundayDate.format("ll").replace(sundayYear, "")
    : sundayDate.format("ll")

  return `${saturday} - ${sunday} `
}

export const mustExcludeFromTracking = () => {
  const id = JSON.parse(window.localStorage.getItem("RFRUser"))?.["id"]

  let idsToExclude = [
    "62f65c1543eaeca5b479df10", //	jey	jey@molokoy.io
    "6312182217920f9abf8d9e8d", //	jey	jey@renforun.com
    "633a86dcb7064b0765d65a5e", //	free.user	free.user@gmail.com
    "633d3bb02ff2409931fed545", //	classic.user	classic.user@gmail.com
    "633d72ed6e8a1e2cae6cb69a", //	premium.user	premium.user@gmail.com
    "633e7ed99f946f3d90806911", //	potiron.vanina	potiron.vanina@gmail.com
    "634ea3a07894b67f183b1871", //	premium.video.user	premium.video.user@gmail.com
    "634fa1af29ce5e40922b3d70", //	malvesin.manon	malvesin.manon@gmail.com
    "636d317e3fb506fd40a169d2", //	m.jocharp	m.jocharp@gmail.com
    "6373185f00dd32a7a520c221", //	jyzinsou	jyzinsou@gmail.com
  ]

  return idsToExclude.includes(id)
}
