import { t } from "@lingui/macro"

export const movementsTranslations = {
  "6314e980cd50762675a8374d": {
    "name": t`90 to 90`,
    "focus.1": t`Knees and hips at 90°`,
    "focus.2": t`Keep your torso upright as much as possible`,
    "focus.3": t`Slow and controlled movements`,
  },
  "631de35c405629e64448b6ff": {
    "name": t`Air Leg curl L`,
    "focus.1": t`Keep your torso upright`,
    "focus.2": t`Support leg naturally extended`,
    "focus.3": t`Bring the foot to your butt in a straight line`,
  },
  "631de35c405629e64448b701": {
    "name": t`Air Leg curl R`,
    "focus.1": t`Keep your torso upright`,
    "focus.2": t`Support leg naturally extended`,
    "focus.3": t`Bring the foot to your butt in a straight line`,
  },
  "6314e980cd50762675a8373b": {
    "name": t`Air Squat`,
    "focus.1": t`Feet at shoulders width`,
    "focus.2": t`Lower yourself as much as possible`,
    "focus.3": t`Keep your heels in contact with the ground`,
  },
  "6314e980cd50762675a8373c": {
    "name": t`Air Squat recovery`,
    "focus.1": t`Feet at shoulders width`,
    "focus.2": t`Keep your heels in contact with the ground`,
    "focus.3": t`Slow and controlled movements for active recovery`,
  },
  "6314e980cd50762675a83745": {
    "name": t`Alt box pistol squat`,
    "focus.1": t`Adjust the height of the target to your ability`,
    "focus.2": t`Slight touch the box, chair or couch`,
    "focus.3": t`Stand tall between each rep`,
  },
  "63147aa7cd50762675a83724": {
    "name": t`Ankle supination`,
    "focus.1": t`Keep your torso upright`,
    "focus.2": t`Controlled movements`,
    "focus.3": t``,
  },
  "6314e980cd50762675a8372b": {
    "name": t`Ankle weighted pulls L`,
    "focus.1": t`Pull your foot up to opposite knee height`,
    "focus.2": t`Keep the ankle relaxed`,
    "focus.3": t``,
  },
  "6314e980cd50762675a8372c": {
    "name": t`Ankle weighted pulls R`,
    "focus.1": t`Pull your foot up to opposite knee height`,
    "focus.2": t`Keep the ankle relaxed`,
    "focus.3": t``,
  },
  "6314e980cd50762675a8376f": {
    "name": t`Back extension`,
    "focus.1": t`Keep your feet in countact with the ground`,
    "focus.2": t`Do not arch your neck`,
    "focus.3": t`Keeps your abs tight`,
  },
  "6314e980cd50762675a83743": {
    "name": t`Backward lunge`,
    "focus.1": t`Back knee slightly touches the ground`,
    "focus.2": t`Front knee stays behind the toes`,
    "focus.3": t`Keep your torso upright`,
  },
  "631de35c405629e64448b6f8": {
    "name": t`Banded pulls L`,
    "focus.1": t`Pull your foot up to opposite knee height`,
    "focus.2": t`Keep the ankle relaxed`,
    "focus.3": t``,
  },
  "631de35c405629e64448b6fc": {
    "name": t`Banded pulls R`,
    "focus.1": t`Pull your foot up to opposite knee height`,
    "focus.2": t`Keep the ankle relaxed`,
    "focus.3": t``,
  },
  "631de35c405629e64448b707": {
    "name": t`Box pistol squat L`,
    "focus.1": t`Stand close to the box, chair or couch`,
    "focus.2": t`Slight touch the box, chair or couch`,
    "focus.3": t`Stand tall between each rep`,
  },
  "631de35c405629e64448b706": {
    "name": t`Box pistol squat R`,
    "focus.1": t`Stand close to the box, chair or couch`,
    "focus.2": t`Slight touch the box, chair or couch`,
    "focus.3": t`Stand tall between each rep`,
  },
  "6319b2b6cd50762675a83771": {
    "name": t`Bulgarian squat L`,
    "focus.1": t`One big step away from the box`,
    "focus.2": t`Keep heel of front leg down`,
    "focus.3": t`Back knee slightly touches the ground`,
  },
  "6314e980cd50762675a83740": {
    "name": t`Bulgarian squat R`,
    "focus.1": t`One big step away from the box`,
    "focus.2": t`Keep heel of front leg down`,
    "focus.3": t`Back knee slightly touches the ground`,
  },
  "631de35c405629e64448b6f5": {
    "name": t`Burpees`,
    "focus.1": t`Hands on the ground approximately shoulder-width`,
    "focus.2": t`Lift your hips as high as possible when bringing back your feet`,
    "focus.3": t`Put your foot flat on the ground before jumping`,
  },
  "63147aa7cd50762675a83729": {
    "name": t`Calf raises in pose L`,
    "focus.1": t`Forefoot on the plate`,
    "focus.2": t`Leg is straight`,
    "focus.3": t`Elevate dynamically your heel`,
  },
  "63147aa7cd50762675a83728": {
    "name": t`Calf raises in pose R`,
    "focus.1": t`Forefoot on the plate`,
    "focus.2": t`Leg is straight`,
    "focus.3": t`Elevate dynamically your heel`,
  },
  "6314e980cd50762675a83744": {
    "name": t`Cossack squat`,
    "focus.1": t`Keep your torso upright as much as possible`,
    "focus.2": t`Keep a back flat`,
    "focus.3": t`Lower yourself as much as possible`,
  },
  "631de35c405629e64448b70d": {
    "name": t`Criss-cross`,
    "focus.1": t`Cross your feet, alternating the foot in front`,
    "focus.2": t`Heel slightly kisses the ground`,
    "focus.3": t``,
  },
  "631de35c405629e64448b708": {
    "name": t`Curb foot switch`,
    "focus.1": t`Jump and swap your feet positions`,
    "focus.2": t`Shrug your shoulder to unweigh yourself`,
    "focus.3": t`Keep a steady rhythm`,
  },
  "631de35c405629e64448b70c": {
    "name": t`Curb jumps`,
    "focus.1": t`Jump on and off the curb `,
    "focus.2": t`Shrug your shoulder to unweigh yourself`,
    "focus.3": t`Focus on pulling your feet from the floor and not pushing `,
  },
  "631de35c405629e64448b711": {
    "name": t`Curb jumps in pose L`,
    "focus.1": t`Jump on and off the curb in Pose`,
    "focus.2": t`Shrug your shoulder to unweigh yourself`,
    "focus.3": t`Focus on pulling your feet from the floor and not pushing `,
  },
  "631de35c405629e64448b709": {
    "name": t`Curb jumps in pose R`,
    "focus.1": t`Jump on and off the curb in Pose`,
    "focus.2": t`Shrug your shoulder to unweigh yourself`,
    "focus.3": t`Focus on pulling your feet from the floor and not pushing `,
  },
  "631de35c405629e64448b704": {
    "name": t`Cyclist squat`,
    "focus.1": t`Heels on a plate, feet close `,
    "focus.2": t`Keep your torso upright`,
    "focus.3": t`Lower yourself as much as possible`,
  },
  "6314e980cd50762675a8374a": {
    "name": t`Dips`,
    "focus.1": t`Hands on the support`,
    "focus.2": t`Lower yourself to bring your shoulders at your elbows height`,
    "focus.3": t`Keep your legs straight`,
  },
  "631de35c405629e64448b6fb": {
    "name": t`Exagerated pull L`,
    "focus.1": t`Run in place, pulling one foot as high as possible`,
    "focus.2": t`Keep the torso upright, no bending to the side or in front`,
    "focus.3": t`Pull your foot in a straight, vertical line`,
  },
  "631de35c405629e64448b702": {
    "name": t`Exagerated pull R`,
    "focus.1": t`Run in place, pulling one foot as high as possible`,
    "focus.2": t`Keep the torso upright, no bending to the side or in front`,
    "focus.3": t`Pull your foot in a straight, vertical line`,
  },
  "6314e980cd50762675a83759": {
    "name": t`Floor touches`,
    "focus.1": t`Legs extended, feet together`,
    "focus.2": t`Bend as much as possible`,
    "focus.3": t`Feel the stretch in the hamstrings`,
  },
  "6314e980cd50762675a83751": {
    "name": t`Floor touches feet apart`,
    "focus.1": t`Legs extended, feet wider that soulder width`,
    "focus.2": t`Bend as much as possible`,
    "focus.3": t`You must feel the stretch in the hamstrings`,
  },
  "631de35c405629e64448b6f7": {
    "name": t`Foot tapping L`,
    "focus.1": t`Bring one ankle to the hand while hopping in place`,
    "focus.2": t`Keep the torso upright, no bending to the side or in front`,
    "focus.3": t``,
  },
  "631de35c405629e64448b703": {
    "name": t`Foot tapping R`,
    "focus.1": t`Bring one ankle to the hand while hopping in place`,
    "focus.2": t`Keep the torso upright, no bending to the side or in front`,
    "focus.3": t``,
  },
  "63147aa7cd50762675a83721": {
    "name": t`Forefoot balance L`,
    "focus.1": t`Support  leg extended`,
    "focus.2": t`Airborne foot at opposite knee height`,
    "focus.3": t`Heel up, stay in balance on your forefoot`,
  },
  "63147aa7cd50762675a83722": {
    "name": t`Forefoot balance R`,
    "focus.1": t`Support  leg extended`,
    "focus.2": t`Airborne foot at opposite knee height`,
    "focus.3": t`Heel up, stay in balance on your forefoot`,
  },
  "6314e980cd50762675a8373f": {
    "name": t`Front lunge`,
    "focus.1": t`Heel of forward foot stays on the ground`,
    "focus.2": t`Lower torso until back knee kisses the ground`,
    "focus.3": t`Front knee stays behind the toes`,
  },
  "631de35c405629e64448b6fe": {
    "name": t`Get in pose L`,
    "focus.1": t`Bring your ankle at opposite knee height`,
    "focus.2": t`Keep the ankle relaxed`,
    "focus.3": t`Knee of support leg is bent`,
  },
  "631de35c405629e64448b6fa": {
    "name": t`Get in pose R`,
    "focus.1": t`Bring your ankle at opposite knee height`,
    "focus.2": t`Keep the ankle relaxed`,
    "focus.3": t`Knee of support leg is bent`,
  },
  "6314e980cd50762675a83735": {
    "name": t`Goblet backward lunge`,
    "focus.1": t`Back knee slightly touches the ground`,
    "focus.2": t`Front knee stays behind the toes`,
    "focus.3": t`Keep your torso upright`,
  },
  "6314e980cd50762675a83741": {
    "name": t`Goblet bulgarian squat L`,
    "focus.1": t`One big step away from the box`,
    "focus.2": t`Keep heel of front leg down`,
    "focus.3": t`Back knee slightly touches the ground`,
  },
  "6314e980cd50762675a83739": {
    "name": t`Goblet bulgarian squat R`,
    "focus.1": t`One big step away from the box`,
    "focus.2": t`Keep heel of front leg down`,
    "focus.3": t`Back knee slightly touches the ground`,
  },
  "631de35c405629e64448b705": {
    "name": t`Goblet cyclist squat`,
    "focus.1": t`Heels on a plate, feet close `,
    "focus.2": t`Keep your torso upright`,
    "focus.3": t`Lower yourself as much as possible`,
  },
  "6314e980cd50762675a83742": {
    "name": t`Goblet front lunge`,
    "focus.1": t`Heel of forward foot stays on the ground`,
    "focus.2": t`Lower torso until back knee kisses the ground`,
    "focus.3": t`Front knee stays behind the toes`,
  },
  "6314e980cd50762675a83730": {
    "name": t`Good morning`,
    "focus.1": t`Push your hip back, keeping your back straight`,
    "focus.2": t`Descend until your chest is parallel with floor`,
    "focus.3": t`No rounding the lower back`,
  },
  "6314e980cd50762675a8372d": {
    "name": t`Good morning w band`,
    "focus.1": t`Push your hip back, keeping your back straight`,
    "focus.2": t`Descend until your chest is parallel with floor`,
    "focus.3": t`No rounding the lower back`,
  },
  "6314e980cd50762675a83762": {
    "name": t`Hip dips`,
    "focus.1": t`Plank position on the hands`,
    "focus.2": t`Bring the hips down and up`,
    "focus.3": t`Keep the arms straight`,
  },
  "631de35c405629e64448b6f3": {
    "name": t`Hip dips arm up L`,
    "focus.1": t`Plank position, one hand at shoulder height`,
    "focus.2": t`Bring the hips down and up`,
    "focus.3": t`Keep the support arm straight`,
  },
  "631de35c405629e64448b6f0": {
    "name": t`Hip dips arm up R`,
    "focus.1": t`Plank position, one hand at shoulder height`,
    "focus.2": t`Bring the hips down and up`,
    "focus.3": t`Keep the support arm straight`,
  },
  "6314e980cd50762675a83764": {
    "name": t`Hip dips face up`,
    "focus.1": t`Seated with straight legs`,
    "focus.2": t`Hands on the floor behind the hips`,
    "focus.3": t`Raise the hips to align them with the shoulders and ankles`,
  },
  "6314e980cd50762675a8376a": {
    "name": t`Hip dips face up one leg L`,
    "focus.1": t`Seated on the floor with straight legs, one foot up`,
    "focus.2": t`Hands on the floor behind the hips`,
    "focus.3": t`Raise the hips to align them with the shoulders and ankles`,
  },
  "6314e980cd50762675a83766": {
    "name": t`Hip dips face up one leg R`,
    "focus.1": t`Seated on the floor with straight legs, one foot up`,
    "focus.2": t`Hands on the floor behind the hips`,
    "focus.3": t`Raise the hips to align them with the shoulders and ankles`,
  },
  "6314e980cd50762675a8376d": {
    "name": t`Hip dips lateral L`,
    "focus.1": t`Side plank`,
    "focus.2": t`Bring your hips down and up`,
    "focus.3": t`Body aligned, no butt going back`,
  },
  "6314e980cd50762675a83760": {
    "name": t`Hip dips lateral R`,
    "focus.1": t`Side plank`,
    "focus.2": t`Bring your hips down and up`,
    "focus.3": t`Body aligned, no butt going back`,
  },
  "6314e980cd50762675a83770": {
    "name": t`Hip dips leg up L`,
    "focus.1": t`Plank position on the hands, one foot up`,
    "focus.2": t`Bring the hips down and up`,
    "focus.3": t`Keep the arms straight`,
  },
  "6314e980cd50762675a8376b": {
    "name": t`Hip dips leg up R`,
    "focus.1": t`Plank position on the hands, one foot up`,
    "focus.2": t`Bring the hips down and up`,
    "focus.3": t`Keep the arms straight`,
  },
  "6314e980cd50762675a8375f": {
    "name": t`Hip switch`,
    "focus.1": t`Hands approximatively at soulder width`,
    "focus.2": t`Arms slightly bent`,
    "focus.3": t`Put your foot flat on the ground`,
  },
  "6314e980cd50762675a8374e": {
    "name": t`Hips inward rotation L`,
    "focus.1": t`Keep the knee as high as possible`,
    "focus.2": t`Keep your abs tight`,
    "focus.3": t`Stand tall `,
  },
  "6314e980cd50762675a83755": {
    "name": t`Hips inward rotation R`,
    "focus.1": t`Keep the knee as high as possible`,
    "focus.2": t`Keep your abs tight`,
    "focus.3": t`Stand tall `,
  },
  "6314e980cd50762675a8374c": {
    "name": t`Hips outward rotation L`,
    "focus.1": t`Keep the knee as high as possible`,
    "focus.2": t`Keep your abs tight`,
    "focus.3": t`Stand tall `,
  },
  "6314e980cd50762675a83753": {
    "name": t`Hips outward rotation R`,
    "focus.1": t`Keep the knee as high as possible`,
    "focus.2": t`Keep your abs tight`,
    "focus.3": t`Stand tall `,
  },
  "631de35c405629e64448b70a": {
    "name": t`Hops in place`,
    "focus.1": t`Knees bent`,
    "focus.2": t`Body weight on the ball of foot`,
    "focus.3": t`Heels kiss the ground at each jump`,
  },
  "631de35c405629e64448b70e": {
    "name": t`Hops in place front & back`,
    "focus.1": t`Knees bent`,
    "focus.2": t`Body weight on the ball of foot`,
    "focus.3": t`Heels kiss the ground at each jump`,
  },
  "631de35c405629e64448b70b": {
    "name": t`Hops in place lateral`,
    "focus.1": t`Knees bent`,
    "focus.2": t`Body weight on the ball of foot`,
    "focus.3": t`Heels kiss the ground at each jump`,
  },
  "631de35c405629e64448b710": {
    "name": t`Hops in pose Front and Back L`,
    "focus.1": t`Foot under the hip, ankle at opposite knee height`,
    "focus.2": t`Body weight on the ball of foot`,
    "focus.3": t`Heels kiss the ground at each jump`,
  },
  "631de35c405629e64448b70f": {
    "name": t`Hops in pose Front and Back R`,
    "focus.1": t`Foot under the hip, ankle at opposite knee height`,
    "focus.2": t`Body weight on the ball of foot`,
    "focus.3": t`Heels kiss the ground at each jump`,
  },
  "631de35c405629e64448b718": {
    "name": t`Hops in pose L`,
    "focus.1": t`Foot under the hip, ankle at opposite knee height`,
    "focus.2": t`Body weight on the ball of foot`,
    "focus.3": t`Heels kiss the ground at each jump`,
  },
  "631de35c405629e64448b715": {
    "name": t`Hops in pose Lateral L`,
    "focus.1": t`Foot under the hip, ankle at opposite knee height`,
    "focus.2": t`Body weight on the ball of foot`,
    "focus.3": t`Heels kiss the ground at each jump`,
  },
  "631de35c405629e64448b71b": {
    "name": t`Hops in pose Lateral R`,
    "focus.1": t`Foot under the hip, ankle at opposite knee height`,
    "focus.2": t`Body weight on the ball of foot`,
    "focus.3": t`Heels kiss the ground at each jump`,
  },
  "631de35c405629e64448b717": {
    "name": t`Hops in pose R`,
    "focus.1": t`Foot under the hip, ankle at opposite knee height`,
    "focus.2": t`Body weight on the ball of foot`,
    "focus.3": t`Heels kiss the ground at each jump`,
  },
  "6314e980cd50762675a8373e": {
    "name": t`In place lunges L`,
    "focus.1": t`Heel of forward foot stays on the ground`,
    "focus.2": t`Lower torso until back knee kisses the ground`,
    "focus.3": t`Front knee stays behind the toes`,
  },
  "6314e980cd50762675a8373d": {
    "name": t`In place lunges R`,
    "focus.1": t`Heel of forward foot stays on the ground`,
    "focus.2": t`Lower torso until back knee kisses the ground`,
    "focus.3": t`Front knee stays behind the toes`,
  },
  "6314e980cd50762675a8375b": {
    "name": t`Inchworm`,
    "focus.1": t`Bring your hands as far as possible depending of the grip of the floor`,
    "focus.2": t`Keep your abs tight`,
    "focus.3": t`Stand tall between each rep`,
  },
  "6314e980cd50762675a8375c": {
    "name": t`Inchworm push up`,
    "focus.1": t`Try to bring your chest to the floor`,
    "focus.2": t`Keep your abs tight`,
    "focus.3": t`Stand tall between each rep`,
  },
  "6314e980cd50762675a83756": {
    "name": t`Interlaced wrist rotation`,
    "focus.1": t`Keep your torso upright`,
    "focus.2": t`Keeps your shoulders relaxed`,
    "focus.3": t`keep your palms gently joined`,
  },
  "6314e980cd50762675a83737": {
    "name": t`Jumping Bulgarian squat L`,
    "focus.1": t`One big step away from the box`,
    "focus.2": t`Back knee slightly touches the ground`,
    "focus.3": t`Keep your torso upright`,
  },
  "6314e980cd50762675a83738": {
    "name": t`Jumping Bulgarian squat R`,
    "focus.1": t`One big step away from the box`,
    "focus.2": t`Back knee slightly touches the ground`,
    "focus.3": t`Keep your torso upright`,
  },
  "631de35c405629e64448b719": {
    "name": t`Jumping jack to pose`,
    "focus.1": t`Bring the airborne foot at oposite knee height`,
    "focus.2": t`Avoid side to side movement of the body`,
    "focus.3": t`Heels touch the ground on every landing`,
  },
  "631de35c405629e64448b716": {
    "name": t`Jumping jacks`,
    "focus.1": t`Elbows slightly bent`,
    "focus.2": t`Keep your torso upright`,
    "focus.3": t`Heels touch the ground on every landing`,
  },
  "631de35c405629e64448b713": {
    "name": t`Jumping jacks legs only`,
    "focus.1": t`Elbows bent at 90°`,
    "focus.2": t`Keep your torso upright`,
    "focus.3": t`Heels touch the ground on every landing`,
  },
  "6314e980cd50762675a83736": {
    "name": t`Jumping lunges`,
    "focus.1": t`Get tall during the jump`,
    "focus.2": t`Knee slightly kisses the ground`,
    "focus.3": t`Keep your torso upright`,
  },
  "6314e980cd50762675a8373a": {
    "name": t`Jumping Squat`,
    "focus.1": t`Get tall during the jump`,
    "focus.2": t`Keep a back flat`,
    "focus.3": t`Lower yourself as much as possible`,
  },
  "631de35c405629e64448b714": {
    "name": t`Jumps feet apart and together`,
    "focus.1": t`Shrug your shoulder to unweigh yourself`,
    "focus.2": t`Get tall during the jump`,
    "focus.3": t`Heels touch the ground on every landing`,
  },
  "631de35c405629e64448b71a": {
    "name": t`Jumps feet together and apart`,
    "focus.1": t`Shrug your shoulder to unweigh yourself`,
    "focus.2": t`Get tall during the jump`,
    "focus.3": t`Heels touch the ground on every landing`,
  },
  "6314e980cd50762675a83747": {
    "name": t`Knee push up`,
    "focus.1": t`Chest makes contact with the ground`,
    "focus.2": t`Keep your abs tight`,
    "focus.3": t`Keep your hips aligned with your shoulders and knees`,
  },
  "6314e980cd50762675a8375a": {
    "name": t`Lateral hurdle`,
    "focus.1": t`Lift your knee as high as possible`,
    "focus.2": t`Keep your torso upright`,
    "focus.3": t`Keep your abs tight`,
  },
  "6314e980cd50762675a8376e": {
    "name": t`Leg raises`,
    "focus.1": t`Limit the arch in the lowerback`,
    "focus.2": t`Keep your legs straight`,
    "focus.3": t`Keep your head on the floor`,
  },
  "6314e980cd50762675a83752": {
    "name": t`Leg swing L`,
    "focus.1": t`Leg naturally extended`,
    "focus.2": t`Keep your torso upright`,
    "focus.3": t`Keep your abs tight`,
  },
  "6314e980cd50762675a83757": {
    "name": t`Leg swing R`,
    "focus.1": t`Leg naturally extended`,
    "focus.2": t`Keep your torso upright`,
    "focus.3": t`Keep your abs tight`,
  },
  "6314e980cd50762675a83765": {
    "name": t`Mountain climber`,
    "focus.1": t`Knees close to the chest`,
    "focus.2": t`Keep your arms straight`,
    "focus.3": t`Keep your abs tight`,
  },
  "6314e980cd50762675a83731": {
    "name": t`One legged good morning alternate`,
    "focus.1": t`Extend your arms to the side to help balance`,
    "focus.2": t`Keep your moving leg and torso aligned`,
    "focus.3": t`Keep your abs tight`,
  },
  "6314e980cd50762675a83732": {
    "name": t`One legged good morning L`,
    "focus.1": t`Extend your arms to the side to help balance`,
    "focus.2": t`Keep your moving leg and torso aligned`,
    "focus.3": t`Keep your abs tight`,
  },
  "6314e980cd50762675a8372e": {
    "name": t`One legged good morning R`,
    "focus.1": t`Extend your arms to the side to help balance`,
    "focus.2": t`Keep your moving leg and torso aligned`,
    "focus.3": t`Keep your abs tight`,
  },
  "6314e980cd50762675a83749": {
    "name": t`Pike push up`,
    "focus.1": t`Hips must be as high as you can`,
    "focus.2": t`Forehead slightly touches the ground`,
    "focus.3": t`Finish with the arms extended`,
  },
  "6314e980cd50762675a83748": {
    "name": t`Push up`,
    "focus.1": t`Chest makes contact with the ground`,
    "focus.2": t`Keep your abs tight`,
    "focus.3": t`Keep your hips aligned with your shoulders and knees`,
  },
  "63147aa7cd50762675a83723": {
    "name": t`Rest standing`,
    "focus.1": t`Stand tall`,
    "focus.2": t`Breathe deeply from stomach to the chest`,
    "focus.3": t`Exhale`,
  },
  "631de35c405629e64448b712": {
    "name": t`Run in place @175`,
    "focus.1": t`Run in place, stay in sync with the metronome`,
    "focus.2": t`Pull your foot up at a 175 step per minute cadence`,
    "focus.3": t``,
  },
  "631de35c405629e64448b722": {
    "name": t`Run in place @178`,
    "focus.1": t`Run in place, stay in sync with the metronome`,
    "focus.2": t`Pull your foot up at a 178 step per minute cadence`,
    "focus.3": t``,
  },
  "631de35c405629e64448b71f": {
    "name": t`Run in place @180`,
    "focus.1": t`Run in place, stay in sync with the metronome`,
    "focus.2": t`Pull your foot up at a 180 step per minute cadence`,
    "focus.3": t``,
  },
  "631de35c405629e64448b71c": {
    "name": t`Run in place @182`,
    "focus.1": t`Run in place, stay in sync with the metronome`,
    "focus.2": t`Pull your foot up at a 182 step per minute cadence`,
    "focus.3": t``,
  },
  "631de35c405629e64448b723": {
    "name": t`Run in place @184`,
    "focus.1": t`Run in place, stay in sync with the metronome`,
    "focus.2": t`Pull your foot up at a 184 step per minute cadence`,
    "focus.3": t``,
  },
  "631de35c405629e64448b71e": {
    "name": t`Run in place @186`,
    "focus.1": t`Run in place, stay in sync with the metronome`,
    "focus.2": t`Pull your foot up at a 186 step per minute cadence`,
    "focus.3": t``,
  },
  "6314e980cd50762675a8372f": {
    "name": t`Run in place ankle weighted`,
    "focus.1": t`Run in place with the weight straps on your ankles`,
    "focus.2": t`Keep the torso upright, no bending to the side or in front`,
    "focus.3": t`Pull your foot in a straight, vertical line`,
  },
  "6314e980cd50762675a83734": {
    "name": t`Runner squat`,
    "focus.1": t`Feet at shoulders width `,
    "focus.2": t`Heels stay off the ground`,
    "focus.3": t`Lower yourself as much as possible`,
  },
  "631de35c405629e64448b6fd": {
    "name": t`Running high pull`,
    "focus.1": t`Run in place, pulling your feet as high as possible`,
    "focus.2": t`Pull your foot in a straight, vertical line`,
    "focus.3": t`Keep the torso upright, no bending to the side or in front`,
  },
  "631de35c405629e64448b721": {
    "name": t`Seal jumps`,
    "focus.1": t`Elbows slightly bent`,
    "focus.2": t`Keep your torso upright`,
    "focus.3": t`Keep your abs tight`,
  },
  "631de35c405629e64448b720": {
    "name": t`Seal jumps legs only`,
    "focus.1": t`Elbows bent at 90°`,
    "focus.2": t`Keep your torso upright`,
    "focus.3": t`Keep your abs tight`,
  },
  "6314e980cd50762675a83758": {
    "name": t`Self hug`,
    "focus.1": t`Stand tall`,
    "focus.2": t`Arms stretched out to the side`,
    "focus.3": t`Alternate the upper arm `,
  },
  "6314e980cd50762675a83754": {
    "name": t`Shoulder rotations backwards`,
    "focus.1": t`Stand tall`,
    "focus.2": t`Elbows slightly bent`,
    "focus.3": t`Dynamic movement`,
  },
  "6314e980cd50762675a83750": {
    "name": t`Shoulder rotations forward`,
    "focus.1": t`Stand tall`,
    "focus.2": t`Elbows slightly bent`,
    "focus.3": t`Dynamic movement`,
  },
  "6314e980cd50762675a83763": {
    "name": t`Shoulder touches`,
    "focus.1": t`Hands approximately at shoulder-width`,
    "focus.2": t`Body remains rigid`,
    "focus.3": t`Right hand touches right shoulder, left hand, left shoulder`,
  },
  "6314e980cd50762675a8374b": {
    "name": t`Arm swings up&down`,
    "focus.1": t`Stand tall`,
    "focus.2": t`Elbows slightly bent`,
    "focus.3": t`Dynamic movement`,
  },
  "6314e980cd50762675a83767": {
    "name": t`Sit ups`,
    "focus.1": t`Hands on the opposite shoulder`,
    "focus.2": t`Roll and unroll your back`,
    "focus.3": t`Controlled movements`,
  },
  "631de35c405629e64448b6f6": {
    "name": t`Sprawls`,
    "focus.1": t`Hands approximately at shoulder-width`,
    "focus.2": t`Keep your feet at the same width throughout the movement.`,
    "focus.3": t`Elbows slightly bent`,
  },
  "63147aa7cd50762675a8372a": {
    "name": t`Stanish alternate`,
    "focus.1": t`Focus on the lowering phase`,
    "focus.2": t`Both feet for the ascending phase`,
    "focus.3": t`Slow and controlled movements`,
  },
  "6314e980cd50762675a83769": {
    "name": t`Supermen`,
    "focus.1": t`Keep your head facing the floor`,
    "focus.2": t`Arms slightly bent`,
    "focus.3": t`Keep your abs tight`,
  },
  "631de35c405629e64448b700": {
    "name": t`Support foot pull L`,
    "focus.1": t`One foot under the hip, ankle at opposite knee height`,
    "focus.2": t`Knee of support leg slightly bent`,
    "focus.3": t`Pull your support foot at opposite knee height`,
  },
  "631de35c405629e64448b6f9": {
    "name": t`Support foot pull R`,
    "focus.1": t`One foot under the hip, ankle at opposite knee height`,
    "focus.2": t`Knee of support leg slightly bent`,
    "focus.3": t`Pull your support foot at opposite knee height`,
  },
  "6314e980cd50762675a83761": {
    "name": t`Toes touches`,
    "focus.1": t`Legs slightly bent`,
    "focus.2": t`Touch the ground at each rep`,
    "focus.3": t`Touch your toes at each rep`,
  },
  "631de35c405629e64448b6f4": {
    "name": t`Tuck`,
    "focus.1": t`Feet do not touch the floor`,
    "focus.2": t`Synchronize the lifting of your torso and legs`,
    "focus.3": t`Stay in balance `,
  },
  "631de35c405629e64448b71d": {
    "name": t`Twist`,
    "focus.1": t`Keep your torso upright`,
    "focus.2": t`Keep your shoulders facing forward`,
    "focus.3": t`Heels must slightly kiss the ground`,
  },
  "631de35c405629e64448b6f1": {
    "name": t`V up`,
    "focus.1": t`Limit the arch in the lowerback`,
    "focus.2": t`Synchronize the lifting of your torso and legs`,
    "focus.3": t`Legs slightly bent`,
  },
  "631de35c405629e64448b6f2": {
    "name": t`V up Alternate`,
    "focus.1": t`Limit the arch in the lowerback`,
    "focus.2": t`Synchronize the lifting of your torso and legs`,
    "focus.3": t`Legs slightly bent`,
  },
  "6314e980cd50762675a83733": {
    "name": t`Walking lunges`,
    "focus.1": t`Back knee slightly touches the ground`,
    "focus.2": t`Keep your torso upright`,
    "focus.3": t`Every steps counts as 1 rep`,
  },
  "63147aa7cd50762675a83726": {
    "name": t`Wall calf raises`,
    "focus.1": t`Start one arm away from the wall, hands on the wall`,
    "focus.2": t`Legs are straight`,
    "focus.3": t`Elevate dynamically your heel`,
  },
  "63147aa7cd50762675a83727": {
    "name": t`Wall calf raises in pose L`,
    "focus.1": t`Start one arm away from the wall, hands on the wall`,
    "focus.2": t`Leg is straight`,
    "focus.3": t`Elevate dynamically your heel`,
  },
  "63147aa7cd50762675a83725": {
    "name": t`Wall calf raises in pose R`,
    "focus.1": t`Start one arm away from the wall, hands on the wall`,
    "focus.2": t`Leg is straight`,
    "focus.3": t`Elevate dynamically your heel`,
  },
  "6314e980cd50762675a83768": {
    "name": t`Wall fall`,
    "focus.1": t`Fall forward `,
    "focus.2": t`Keep your torso upright`,
    "focus.3": t`Catch yourself as late as possible with hands on the wall`,
  },
  "6314e980cd50762675a8375e": {
    "name": t`Wall fall in pose L`,
    "focus.1": t`One foot under the hip, ankle at opposite knee height`,
    "focus.2": t`Keep your torso upright and fall forward`,
    "focus.3": t`Catch yourself as late as possible with hands on the wall`,
  },
  "6314e980cd50762675a8376c": {
    "name": t`Wall fall in pose R`,
    "focus.1": t`One foot under the hip, ankle at opposite knee height`,
    "focus.2": t`Keep the torso upright and fall forward`,
    "focus.3": t`Catch yourself as late as possible with hands on the wall`,
  },
  "6314e980cd50762675a8375d": {
    "name": t`Wall fall run`,
    "focus.1": t`Lean in to the wall `,
    "focus.2": t`Run in place keeping your head up`,
    "focus.3": t`Keep the torso upright`,
  },
  "6314e980cd50762675a83746": {
    "name": t`Wide push up`,
    "focus.1": t`Chest makes contact with the ground`,
    "focus.2": t`Keep your abs tight`,
    "focus.3": t`Keep your hips aligned with your shoulders and knees`,
  },
  "6314e980cd50762675a8374f": {
    "name": t`Wrist rotation`,
    "focus.1": t`Hands clasped`,
    "focus.2": t`Loose grip`,
    "focus.3": t`Stand tall`,
  },
  "635b13a7c15a083f445048ce": {
    "name": t`Half foot step balance L`,
    "focus.1": t`Support  leg extended`,
    "focus.2": t`Airborne foot at opposite knee height`,
    "focus.3": t`Forefoot on a curb or stair, stay in balance `,
  },
  "635b13a7c15a083f445048cf": {
    "name": t`Half foot step balance R`,
    "focus.1": t`Support  leg extended`,
    "focus.2": t`Airborne foot at opposite knee height`,
    "focus.3": t`Forefoot on a curb or stair, stay in balance `,
  },
}
