// imports
import { StyledSessionCard } from "../../Styled"
import { useNavigate } from "react-router-dom"
import { upperFirst } from "../../../utils"
import { Placeholder } from "semantic-ui-react"
import { useState } from "react"
import Loading from "../../App/Loading"

import lowerImg from "../../../images/lower.jpg"
import upperImg from "../../../images/upper.jpg"
import coreImg from "../../../images/core.jpg"
import fullbodyImg from "../../../images/full-body.jpg"
import favoritesImg from "../../../images/favorites.jpg"
import libraryImg from "../../../images/library.jpg"
import dailyImage from "../../../images/daily.jpg"
import raceReadyImage from "../../../images/race-ready.jpg"

import { useMediaQuery } from "react-responsive"

const cardImage = {
  lower: lowerImg,
  core: coreImg,
  upper: upperImg,
  fullbody: fullbodyImg,
  favorites: favoritesImg,
  library: libraryImg,
  daily: dailyImage,
  raceready: raceReadyImage,
}

const imagePositions = { raceready: "0 25%", core: "0 25%", upper: "0 25%" }

const SessionCard = ({
  header,
  title,
  link,
  imagePosition = "center center",
  keyName,
  bottom,
}) => {
  const navigate = useNavigate()

  const isPortrait = useMediaQuery({ query: "(orientation: portrait)" })
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" })

  const [clicked, setClicked] = useState(false)
  const image = cardImage[keyName]
  const testid = `open-card-${keyName}`

  let clickAction = () => {
    if (!link) return
    setClicked(true)
    setTimeout(() => {
      navigate(link)
    }, 100)
  }
  return (
    <StyledSessionCard
      style={{
        backgroundImage: `url(${image})`,
        backgroundPosition: imagePositions[keyName]
          ? imagePositions[keyName]
          : imagePosition,
        // transition: "all 5.9s ease-in",
      }}
      onClick={() => clickAction(link)}
    >
      <style>{`
		.transparent{opacity:0;}
		.visible{opacity:1}

		`}</style>
      <Placeholder
        inverted
        className={image ? "transparent" : "visible"}
        style={{
          height: "100%",
          width: "100%",
          borderRadius: "0.75rem",
          // minHeight: 350,
          transition: "opacity .9s ease-in",
          maxWidth: "none",
        }}
      >
        <Placeholder.Image />
      </Placeholder>

      <div className="overlay" />
      <div className="content" style={{ cursor: "pointer" }}>
        {clicked ? (
          <Loading textAlign="left" />
        ) : (
          <div onClick={() => clickAction(link)}>
            {header && <div className="header">{header}</div>}
            {title === "loading" ? (
              <Loading textAlign="left" />
            ) : (
              <div className="title" data-testid={`${testid}`}>
                {title && upperFirst(title)}
              </div>
            )}
          </div>
        )}
      </div>
      <div className="bottom">
        <div
          style={{
            textAlign: isPortrait || !isTabletOrMobile ? "center" : "right",
            marginBottom: isPortrait || !isTabletOrMobile ? -10 : -20,
          }}
        >
          {bottom}
        </div>
      </div>
    </StyledSessionCard>
  )
}

export default SessionCard
