// imports

import { useSetRecoilState } from "recoil"
import { drawerIsOpen, drawerContent } from "../recoil/Atoms"
const useDrawer = () => {
  const setRecoilDrawerIsOpen = useSetRecoilState(drawerIsOpen)
  const setRecoilDrawerContent = useSetRecoilState(drawerContent)

  const setContentAndOpen = (newDrawerContent) => {
    setRecoilDrawerContent(newDrawerContent)
    setRecoilDrawerIsOpen(true)
  }
  const closeDrawer = () => {
    setRecoilDrawerIsOpen(false)
  }

  return { setContentAndOpen, closeDrawer }
}

export default useDrawer
