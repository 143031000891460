// imports

import { t, Trans } from "@lingui/macro"
import { Suspense } from "react"
import { Link } from "react-router-dom"
import { useRecoilValue } from "recoil"
import { Grid, Segment } from "semantic-ui-react"
import { userPlanification } from "../../recoil/Atoms"
import { formatWeekendFromSunday } from "../../utils"
import Loading from "../App/Loading"
import { StyledDashboardCard } from "../Styled"

const PlanificationData = () => {
  const recoilUserPlanification = useRecoilValue(userPlanification)

  let {
    notSet = false,
    planification: { name = null, type = null } = {},
    raceDate = null,
    raceName = null,
  } = recoilUserPlanification || {}
  return !notSet ? (
    <>
      <div className="title">
        <Trans>current planification</Trans>
      </div>
      <div className="content" data-testid={name}>
        {type === "race" ? raceName : name ? <Trans id={`${name}`} /> : ""}
      </div>
      {type === "race" && (
        <div className="extra">{formatWeekendFromSunday(raceDate, true)}</div>
      )}{" "}
      <div className="cards">
        <Link to={"/select-program/confirm?reset-planification=1"}>
          <StyledDashboardCard
            className="fluid "
            data-testid={"abort-planification"}
          >
            <Trans>Cancel</Trans>
          </StyledDashboardCard>
        </Link>
      </div>
    </>
  ) : (
    <>
      <div className="title" data-testid="no-program">
        <Trans>No current plan</Trans>
      </div>

      <div>
        <Grid columns={2} className="cards" style={{ padding: 2 }}>
          {[
            {
              label: t`Prepare for a race`,
              link: "/select-program/race",
              testId: "select-race",
            },
            {
              label: t`Start a planification`,
              link: "/select-program/planification",
              testId: "select-planification",
            },
          ].map(({ label, link, testId }) => (
            <Grid.Column key={link}>
              <Link to={link}>
                <StyledDashboardCard className="fluid " data-testid={testId}>
                  {label}
                </StyledDashboardCard>
              </Link>
            </Grid.Column>
          ))}
        </Grid>
      </div>
    </>
  )
}

const Planification = () => {
  return (
    <Segment basic style={{ marginTop: 0, paddingTop: 0, paddingBottom: 5 }}>
      <StyledDashboardCard
        className="fluid ambiant up"
        style={{ marginTop: "1.5rem" }}
      >
        <Suspense fallback={<Loading />}>
          <PlanificationData />
        </Suspense>
      </StyledDashboardCard>
    </Segment>
  )
}

export default Planification
