import { Trans } from "@lingui/macro"

const Part = ({ index }) => {
  return (
    <div style={{ position: "absolute", top: 5, right: "1em" }}>
      <Trans>Part {index}</Trans>
    </div>
  )
}

export default Part
