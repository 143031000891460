// imports

import { Trans } from "@lingui/macro"
import { Suspense } from "react"
import { Segment } from "semantic-ui-react"
import Loading from "../App/Loading"
import { StyledDashboardCard } from "../Styled"
import FocusContent from "./FocusContent"

const Focus = () => {
  return (
    <Segment basic style={{ marginTop: 0, marginBottom: 0, paddingTop: 0 }}>
      <StyledDashboardCard className="fluid ambiant">
        <div className="title">
          <Trans>Main areas of work</Trans>
        </div>
        <div style={{ height: 230 }}>
          <Suspense fallback={<Loading />}>
            <FocusContent />
          </Suspense>
        </div>
      </StyledDashboardCard>
    </Segment>
  )
}

export default Focus
