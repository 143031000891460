import { useRecoilValue } from "recoil"
import { Icon, List, Statistic, Transition } from "semantic-ui-react"
import {
  distanceListForUserProgram,
  selectedRace,
  userProgram,
} from "../../recoil/Atoms"
import { formatWeekendFromSunday } from "../../utils"
import { StyledHeader, StyledList, StyledSegment } from "../Styled"
import dayjs from "dayjs"
import { t, Trans } from "@lingui/macro"
import { i18n } from "@lingui/core"
import { useNavigate } from "react-router-dom"

const steps = ["distance", "date", "name", "pack", "confirm", "order"]

const Summary = ({ step: currentStep }) => {
  const { distance, raceDate, raceName, pack, giftcard } =
    useRecoilValue(selectedRace)
  const recoilDistances = useRecoilValue(distanceListForUserProgram)
  const recoilUserProgram = useRecoilValue(userProgram)

  const distanceTitle = recoilDistances.find(
    (distanceObj) => distanceObj.key === distance
  )?.title

  const navigate = useNavigate()

  const showStep = (step) => {
    return steps.indexOf(step) < steps.indexOf(currentStep)
  }

  return (
    <StyledSegment
      ambiantbg="1"
      alpha="60"
      style={{ borderRadius: "0.5em" }}
      data-testid="summary"
    >
      <StyledHeader
        className="white"
        style={{
          marginBottom: 0,
          fontWeight: 900,
          fontSize: "0.9em",
        }}
      >
        <Trans>Summary</Trans>
      </StyledHeader>

      <Transition.Group
        as={StyledList}
        duration={300}
        animation="fade down"
        verticalAlign="middle"
        style={{ marginTop: 0, marginBottom: 0 }}
        divided
        inverted
        className="very-very-relaxed divided-light"
      >
        {distance && showStep("distance") && (
          <List.Item onClick={() => navigate("/race/distance")}>
            {" "}
            <Icon
              style={{ marginLeft: -5, marginRight: 4 }}
              className={recoilUserProgram === "trail" ? "mountain" : "road"}
            />{" "}
            {i18n._(distanceTitle)}
          </List.Item>
        )}
        {raceDate && showStep("date") && (
          <List.Item onClick={() => navigate("/race/date")}>
            <Icon name="calendar check outline" />{" "}
            {`${formatWeekendFromSunday(raceDate)} (${Math.ceil(
              raceDate.diff(dayjs(), "week", true)
            )} ${t`wks`})`}{" "}
          </List.Item>
        )}
        {raceName && showStep("name") && (
          <List.Item onClick={() => navigate("/race/name")}>
            {" "}
            <Icon
              style={{ marginLeft: -5, marginRight: 4 }}
              name="map marker"
            />{" "}
            {raceName}
          </List.Item>
        )}
        {pack?.title && showStep("pack") && (
          <List.Item
            style={{ textTransform: "uppercase" }}
            onClick={() => navigate("/race/pack")}
          >
            <Icon name={pack.title === "classic" ? "star outline" : "star"} />{" "}
            {pack.title}
          </List.Item>
        )}
      </Transition.Group>
      {!!pack?.price && showStep("pack") && (
        <div style={{ textAlign: "center", marginTop: -10 }}>
          <Statistic inverted className="price" size="small" horizontal>
            <Statistic.Value>
              {pack?.price && pack?.price / 100}
            </Statistic.Value>
            <Statistic.Label>€</Statistic.Label>
          </Statistic>
        </div>
      )}
      {!pack?.price && giftcard && showStep("pack") && (
        <div style={{ textAlign: "center", marginTop: -10 }}>
          <Statistic inverted className="price" size="small" horizontal>
            <Statistic.Label>
              <Trans>Gift</Trans>
            </Statistic.Label>
          </Statistic>
        </div>
      )}
    </StyledSegment>
  )
}

export default Summary
