import { Provider } from "use-http"
import { serverURL, GoogleAuthClientId } from "../../recoil/Atoms"
import { GoogleOAuthProvider } from "@react-oauth/google"

const AuthProvider = ({ children }) => {
  const RFRToken = localStorage.getItem("RFRToken")
  return (
    <GoogleOAuthProvider clientId={GoogleAuthClientId}>
      <Provider
        url={serverURL}
        options={{
          cachePolicy: "no-cache",
          headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
          },
          interceptors: {
            // every time we make an http request, this will run 1st before the request is made
            // url, path and route are supplied to the interceptor
            // request options can be modified and must be returned
            request: async ({ options, route }) => {
              if (options.method === "GET" && route.includes("/password")) {
                options.headers.Authorization = null
              } else if (route.includes("auth/google")) {
                console.log("inauth/google")
                // options.headers.Authorization = null
              } else if (route === "/auth" || route.includes("/password")) {
                let body = JSON.parse(options?.body)
                options.headers.Authorization = `Basic ${body.hash}`
                delete body.hash
                options.body = JSON.stringify(body)
              } else if (route.includes("upload.renforun.app")) {
                const contentType = route.toLocaleLowerCase().includes("mp4")
                  ? "mp4"
                  : route.toLocaleLowerCase().includes("mov")
                  ? "mov"
                  : ""
                options.headers["Content-Type"] = contentType
                options.headers["Content-Disposition"] = "attachment"
              } else {
                options.headers.Authorization = RFRToken
                  ? `Bearer ${RFRToken}`
                  : null
                if (route === "/users/upload") {
                  delete options.headers["Content-Type"]
                  //        options.headers['Content-Type'] = `multipart/form-data`
                }
              }
              return options
            },
          },
        }}
      >
        {children}
      </Provider>
    </GoogleOAuthProvider>
  )
}

export default AuthProvider
