// imports

import { Trans } from "@lingui/macro"
import PropTypes from "prop-types"
import { useEffect } from "react"
import { useRecoilState, useRecoilValue } from "recoil"
import { Icon, Segment } from "semantic-ui-react"
import { programs, userProgram, programSelectChoices } from "../../recoil/Atoms"
import { StyledHeader, StyledOptionCard } from "../Styled"

import NextBackButtons from "../shared/NextBackButtons"

const ProgramSelector = (props) => {
  let recoilProfileProgram = useRecoilValue(userProgram)

  let [recoilSelectProgramChoices, setRecoilSelectProgramChoices] =
    useRecoilState(programSelectChoices)

  let programIcons = { trail: "mountain", road: "road" }

  const setProgram = (program) => {
    setRecoilSelectProgramChoices({
      ...recoilSelectProgramChoices,
      program,
    })
  }

  useEffect(() => {
    setRecoilSelectProgramChoices((recoilSelectProgramChoices) => ({
      ...recoilSelectProgramChoices,
      program: recoilProfileProgram,
      level: null,
      planification: null,
    }))
  }, [recoilProfileProgram, setRecoilSelectProgramChoices])

  return (
    <>
      <Segment basic>
        <StyledHeader>
          <Trans>What is your playground ?</Trans>
        </StyledHeader>
      </Segment>
      {programs.map((program) => (
        <StyledOptionCard
          key={program}
          onClick={() => setProgram(program)}
          data-testid={"option-" + program}
          className={
            "primary " +
            (recoilSelectProgramChoices.program === program && " selected")
          }
        >
          <div className="icon-container">
            {recoilSelectProgramChoices.program === program && (
              <Icon className={programIcons[program]} />
            )}
          </div>
          <div className="title">
            <Trans id={program}></Trans>
          </div>
        </StyledOptionCard>
      ))}

      <NextBackButtons nextUrl={"confirm"} />
    </>
  )
}

ProgramSelector.propTypes = {
  floated: PropTypes.string,
}
export default ProgramSelector
