// imports

import { Trans } from "@lingui/macro"
import PropTypes from "prop-types"
import { List } from "semantic-ui-react"
import { StyledHeader, StyledList, StyledSegment } from "../Styled"
import { movementsTranslations } from "../../locales/movementsKeys"
import { i18n } from "@lingui/core"
const KeyPoints = ({ id }) => {
  const translationsKeyCodes = ["focus.1", "focus.2", "focus.3"]

  const translations = {}
  translationsKeyCodes.forEach((key) => {
    let translation = movementsTranslations[id]?.[key]

    translations[key] = translation
  })

  return (
    <StyledSegment
      primarybgalpha="true"
      style={{ margin: "0 1em", paddingTop: "0.7em" }}
    >
      <StyledHeader
        style={{
          //marginBottom: 0,
          fontSize: "1.2em",
          textTransform: "uppercase",
          letterSpacing: 2,
          fontWeight: 800,
        }}
        //   className="info"
      >
        <Trans>Key points</Trans>
      </StyledHeader>
      <StyledList inverted relaxed bulleted style={{ marginTop: 0 }}>
        {["focus.1", "focus.2", "focus.3"].map(
          (key) =>
            translations[key] && (
              <List.Item key={key}>
                <List.Content verticalAlign="middle" key={key}>
                  <List.Header>
                    {translations[key] && i18n._(translations[key])}
                  </List.Header>
                </List.Content>
              </List.Item>
            )
        )}
      </StyledList>
    </StyledSegment>
  )
}

KeyPoints.propTypes = {
  floated: PropTypes.string,
}
export default KeyPoints
