import "rc-slider/assets/index.css"
import {
  click175Player,
  click178Player,
  click180Player,
} from "../../../recoil/Atoms"
import { useRecoilValue } from "recoil"
import { useEffect } from "react"

const Metronome = ({ bpm = 180, isPlaying }) => {
  const recoilClick175Player = useRecoilValue(click175Player)
  const recoilClick178Player = useRecoilValue(click178Player)
  const recoilClick180Player = useRecoilValue(click180Player)

  useEffect(() => {
    const bpmPlayers = {
      175: recoilClick175Player,
      178: recoilClick178Player,
      180: recoilClick180Player,
    }
    const play = () => {
      let current = bpmPlayers[bpm]

      if (current) {
        current.play()
      }
    }
    if (isPlaying) {
      play()
    }

    return () => {
      let current = bpmPlayers[bpm]
      current?.pause()
    }
  }, [
    bpm,
    isPlaying,
    recoilClick175Player,
    recoilClick178Player,
    recoilClick180Player,
  ])

  /* return (
  <ProMetronome
      click1={recoilClick1Player}
      click2={recoilClick2Player}
      click3={recoilClick3Player}
      click180={recoilClick180Player}
      bpm={bpm}
      subdivision={2}
      soundEnabled={true}
      soundPattern="3232"
      isPlaying={isPlaying}
      render={() => null}
    />
  )*/
}

export default Metronome
