// imports

import { Trans, Plural } from "@lingui/macro"
import {
  StyledHeader,
  StyledLabel,
  StyledList,
  StyledSegment,
  StyledSpan,
} from "../../../../Styled"
import { Icon, Label, List, Segment } from "semantic-ui-react"
import { Fragment } from "react"
import { formatSecondsToTimeSmart } from "../../../utils"
import Part from "./Part"
import WarmupHelp from "./WarmupHelp"
import { useNavigate } from "react-router-dom"

const Warmup = ({ block, index, partsCount }) => {
  const {
    format: { rounds },
    exercises,
  } = block
  const navigate = useNavigate()
  return (
    <StyledSegment basic lightbgalpha="true" block="true">
      {partsCount > 1 && <Part index={index} />}
      <StyledHeader size="small">
        <StyledSpan style={{ textTransform: "uppercase", marginTop: "1em" }}>
          <Trans>Warm-up</Trans>
          <WarmupHelp exercises={exercises} rounds={rounds} />
        </StyledSpan>
      </StyledHeader>
      <div style={{ textAlign: "left" }}>
        <span style={{ fontSize: "1.5em", fontWeight: 800, marginRight: 5 }}>
          {rounds}
        </span>
        <span style={{ fontSize: "1.1em", fontWeight: 800 }}>
          <Plural value={rounds} one="round" other="rounds" />
        </span>
      </div>
      <Segment basic style={{ marginTop: 0, paddingTop: "0.3em" }}>
        <StyledList inverted relaxed>
          {exercises.map(({ work, rest, data: { _id, name } }, index) => (
            <Fragment key={index}>
              <List.Item onClick={() => navigate(`/exercise/${_id}`)}>
                <List.Content floated="right" style={{ marginTop: 5 }}>
                  <Icon name="chevron right" />
                </List.Content>
                <List.Content>
                  <StyledLabel
                    style={{ marginRight: "1em", minWidth: 40 }}
                  >{`${formatSecondsToTimeSmart(work)}`}</StyledLabel>
                  {name}
                </List.Content>
              </List.Item>
              {rest && (
                <List.Item>
                  <List.Content>
                    <Label
                      style={{
                        marginRight: "1em",
                        backgroundColor: "transparent",
                        color: "#fff",
                        border: "1px solid #fff",
                      }}
                    >
                      {`${rest}"`}
                    </Label>
                    <Trans>Rest</Trans>
                  </List.Content>
                </List.Item>
              )}
            </Fragment>
          ))}
        </StyledList>
      </Segment>
    </StyledSegment>
  )
}

export default Warmup
