// imports

import { t, Trans } from "@lingui/macro"
import { useRecoilState, useRecoilValue } from "recoil"
import { distanceListForUserProgram, selectedRace } from "../../recoil/Atoms"
import { defaultTextColor, StyledHeader, StyledOptionCard } from "../Styled"
import { i18n } from "@lingui/core"
import dayjs from "dayjs"

import weekday from "dayjs/plugin/weekday"
import localizedFormat from "dayjs/plugin/localizedFormat"
import { useNavigate } from "react-router-dom"
dayjs.extend(localizedFormat)
dayjs.extend(weekday)

const subtitles = {
  short: t`10 to 20 miles`,
  long: t`25 to 50 miles `,
  utra: t`55 miles and up`,
}

const Distancelector = () => {
  const recoilDistances = useRecoilValue(distanceListForUserProgram)
  const [recoilSelectedRace, setRecoilSelectedRace] =
    useRecoilState(selectedRace)
  const navigate = useNavigate()

  const selectPack = (distance) => {
    setRecoilSelectedRace((prevState) => ({ ...prevState, distance }))
    navigate("/race/date")
  }

  return (
    <>
      <div style={{ textAlign: "center" }}>
        <StyledHeader textAlign="center">
          <Trans>What distance?</Trans>
        </StyledHeader>
        {recoilDistances.map(({ key, title }) => (
          <StyledOptionCard
            key={key}
            style={{ height: "7em" }}
            onClick={() => selectPack(key)}
            data-testid={"option-" + key}
            className={
              "ambiant " + (recoilSelectedRace.distance === key && "selected")
            }
          >
            <div className="title">
              {i18n._(title)}
              {subtitles[key] && (
                <div
                  style={{
                    fontSize: "1rem",
                    color: defaultTextColor,
                    textTransform: "none",
                  }}
                >
                  {i18n._(subtitles[key])}
                </div>
              )}
            </div>
          </StyledOptionCard>
        ))}
      </div>
    </>
  )
}

export default Distancelector
