// imports

import { Trans } from "@lingui/macro"
import { i18n } from "@lingui/core"
import PropTypes from "prop-types"
import { useEffect } from "react"
import { useRecoilState, useRecoilValue } from "recoil"
import { Icon } from "semantic-ui-react"

import useDrawer from "../../hooks/useDrawer"
import {
  programSelectChoices,
  userProgram,
  subscriptionName,
  analysisFocus,
  userPlanification,
  strengthPlanificationChoices,
  // availableEvents,
} from "../../recoil/Atoms"
import { formatWeekendFromSunday, upperFirst } from "../../utils"
import SubscriptionLabel from "./SubscriptionLabel"
import {
  defaultTextColor,
  primaryColor,
  StyledHeader,
  StyledOptionCard,
} from "../Styled"
import SelectorHelp from "./selectorHelp/SelectorHelp"
import NextBackButtons from "../shared/NextBackButtons"

const PlanificationSelector = () => {
  const { setContentAndOpen } = useDrawer()
  let recoilProfileProgram = useRecoilValue(userProgram)
  let recoilSubscriptionName = useRecoilValue(subscriptionName)
  const recoilAnalysisFocus = useRecoilValue(analysisFocus)
  const recoilPlanificationChoices = useRecoilValue(
    strengthPlanificationChoices
  )
  let [recoilSelectProgramChoices, setRecoilSelectProgramChoices] =
    useRecoilState(programSelectChoices)
  const recoilUserPlanification = useRecoilValue(userPlanification)
  // const recoilAvailableEvents = useRecoilValue(availableEvents)

  let autorizedChoices = {
    "on-ramp": ["free", "classic", "premium"],
    "daily": ["classic", "premium"],
    "development": ["classic", "premium"],
    "race-ready": ["premium"],
  }
  const canSelectPlanification = (name) => {
    return autorizedChoices[name]?.includes(recoilSubscriptionName)
  }
  const selectPlanification = (selectedPlanification) => {
    const { name } = selectedPlanification

    if (name === "on-ramp" && hasFreeAndOnRampDone()) {
      return false
    }

    setContentAndOpen(
      <SelectorHelp
        helpItemName={name}
        hasFocus={recoilAnalysisFocus}
        canSelectPlanification={canSelectPlanification(name)}
      />
    )

    if (!recoilAnalysisFocus && name === "race-ready") {
      return false
    }

    if (!canSelectPlanification(name)) {
      return false
    }

    setRecoilSelectProgramChoices({
      ...recoilSelectProgramChoices,
      planification: selectedPlanification,
    })
  }

  const {
    name: selectedPlanificationName,
    raceDate: selectedPlanificationRaceDate,
    raceName: selectedPlanificationRaceName,
  } = recoilSelectProgramChoices?.planification || {}

  const subscriptionRequirementsLabels = {
    "on-ramp": "free",
    //  "development": "classic",
    "daily": null, //"classic",
    "race-ready": null, //"premium",
  }

  const needsAnalysis = (subscriptionName) => {
    return subscriptionName === "race-ready"
  }

  const hasFreeAndOnRampDone = () => {
    return (
      recoilUserPlanification.onRampDone && recoilSubscriptionName === "free"
    )
  }

  useEffect(() => {
    setRecoilSelectProgramChoices((recoilSelectProgramChoices) => ({
      ...recoilSelectProgramChoices,
      program: null,
      level: null,
      planification: {
        ...recoilUserPlanification.planification,
        raceDate: recoilUserPlanification.raceDate,
        raceName: recoilUserPlanification.raceName,
      },
    }))
  }, [
    recoilProfileProgram,
    recoilUserPlanification,
    setRecoilSelectProgramChoices,
  ])

  return (
    <>
      <StyledHeader>
        <Trans>Choose your planification</Trans>{" "}
        {recoilProfileProgram && upperFirst(i18n._(recoilProfileProgram))}
      </StyledHeader>

      {recoilPlanificationChoices?.map((planification) => (
        <StyledOptionCard
          key={planification.id}
          onClick={() => selectPlanification(planification)}
          className={`ambiant${
            selectedPlanificationName === planification.name ? " selected" : ""
          } ${
            hasFreeAndOnRampDone() && planification.name === "on-ramp"
              ? " unavailable"
              : ""
          }`}
          data-testid={`option-${planification.name}`}
        >
          {!canSelectPlanification(planification.name) && (
            <SubscriptionLabel
              requiredSubscription={
                subscriptionRequirementsLabels[planification.name]
              }
            />
          )}
          {planification.name === "on-ramp" && (
            <>
              {recoilSubscriptionName === "free" && hasFreeAndOnRampDone() ? (
                <Icon
                  name="check"
                  style={{
                    position: "absolute",
                    right: 10,
                    top: 10,
                    fontSize: "1.5em",
                  }}
                  //   basicwhite={requiredSubscription === "classic" ? "true" : null}
                  data-testid={`label-onrampdone`}
                />
              ) : recoilSubscriptionName === "free" ? (
                <></> // <LabelOffered />
              ) : (
                <></>
              )}
            </>
          )}
          {recoilSubscriptionName === "premium" &&
            !recoilAnalysisFocus &&
            needsAnalysis(planification.name) && (
              <Icon
                style={{
                  position: "absolute",
                  right: 5,
                  top: 5,
                  backgroundColor: primaryColor,
                }}
                data-testid={`label-required-analysis`}
                name="exclamation"
                circular
                inverted
                size="small"
              />
            )}

          <div className="title">
            <Trans id={planification.name} />{" "}
            {planification.type === "plan" && planification.weeksCount && (
              <div style={{ fontSize: "1rem", color: defaultTextColor }}>
                {planification.name.includes("on-ramp") &&
                recoilSubscriptionName === "free" ? (
                  <>
                    {planification.weeksCount}{" "}
                    <Trans>weeks: First 2 Free</Trans>
                  </>
                ) : (
                  <>
                    {planification.weeksCount} <Trans>weeks</Trans>
                  </>
                )}
              </div>
            )}
            {planification.type === "ongoing" && (
              <div style={{ fontSize: "1rem", color: defaultTextColor }}>
                <Trans>ongoing</Trans>
              </div>
            )}
            {planification.type === "race" &&
              !selectedPlanificationRaceName && (
                <div style={{ fontSize: "1rem", color: defaultTextColor }}>
                  <Trans>10 to 16</Trans> <Trans>weeks</Trans>
                </div>
              )}
            {selectedPlanificationName === planification.name &&
              selectedPlanificationRaceName && (
                <div style={{ fontSize: "0.9rem" }}>
                  {selectedPlanificationRaceName}
                </div>
              )}
            {selectedPlanificationName === planification.name &&
              selectedPlanificationRaceDate && (
                <div style={{ fontSize: "0.85rem" }}>
                  {formatWeekendFromSunday(selectedPlanificationRaceDate, true)}
                </div>
              )}
          </div>
        </StyledOptionCard>
      ))}

      <NextBackButtons
        nextUrl={"confirm"}
        hideNext={!recoilSelectProgramChoices?.planification?.id}
        nextButtonProps={{ ambiant: "true" }}
      />
    </>
  )
}

PlanificationSelector.propTypes = {
  floated: PropTypes.string,
}
export default PlanificationSelector
