// imports

import { Trans } from "@lingui/macro"
import { useEffect, useState } from "react"
import { Container, List, Icon } from "semantic-ui-react"
import { StyledHeader, StyledList } from "../Styled"

import { useNavigate } from "react-router-dom"

import { onRampSessions } from "../../mockup/onRampSessions"

const OnRampPage = (props) => {
  const [sessions, setSessions] = useState([])

  const navigate = useNavigate()
  useEffect(() => {
    setSessions(onRampSessions)
  }, [])

  return (
    <Container className="page onramp">
      <StyledHeader className={"page-title"}>
        <Trans>On-ramp sessions</Trans>
      </StyledHeader>
      <StyledList divided inverted>
        {sessions.map(({ id, name, program, duration }) => (
          <List.Item
            key={id}
            onClick={() => navigate(`/session/${id}`)}
            style={{ padding: "1.5em 0" }}
          >
            <List.Content floated="right">
              <Icon name="chevron right" />
            </List.Content>
            <List.Content>
              <List.Header
                style={{ textTransform: "uppercase", fontSize: "1.5em" }}
              >
                {name}
              </List.Header>
            </List.Content>
            <List.Content style={{ paddingTop: "0.5em" }}>
              <Icon
                className={
                  "primary " + (program === "trail" ? "mountain" : program)
                }
              />{" "}
              <span>
                {duration} <Trans>minutes</Trans>
              </span>
            </List.Content>
          </List.Item>
        ))}
      </StyledList>
    </Container>
  )
}

export default OnRampPage
