// imports

import { Trans } from "@lingui/macro"
import PropTypes from "prop-types"
import { useState } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import { useSetRecoilState } from "recoil"
import { Icon } from "semantic-ui-react"
import { noSleepActivate } from "../../../recoil/Atoms"
import { Mixpanel } from "../../../utils/mixpanel"
import Loading from "../../App/Loading"
import { primaryColorAlpha, StyledButton } from "../../Styled"

import useAudio from "../../../hooks/useAudio"

const StartButton = ({ active = true }) => {
  const navigate = useNavigate()
  const [clicked, setClicked] = useState(false)

  const [searchParams] = useSearchParams()
  const recoilNoSleepActivate = useSetRecoilState(noSleepActivate)
  const { startAudioContext } = useAudio()

  let isWarmup = false
  if (searchParams.has("is-warmup")) {
    isWarmup = true
  }

  const handleClick = () => {
    recoilNoSleepActivate(true)
    setClicked(true)
    navigate("workout?" + searchParams.toString())
    if (!isWarmup) {
      Mixpanel.people.increment("Sessions Started")
    }
    startAudioContext()
  }

  return active ? (
    <StyledButton
      style={{
        position: "absolute",
        bottom: "2.5rem",
        left: "50%",
        width: "75%",
        maxWidth: 200,
        transform: "translateX(-50%)",
        backgroundColor: primaryColorAlpha,
      }}
      ambiant={isWarmup ? "1" : null}
      onClick={handleClick}
    >
      {clicked ? (
        <Loading />
      ) : isWarmup ? (
        <Trans>Start warm-up</Trans>
      ) : (
        <Trans>Start workout</Trans>
      )}
    </StyledButton>
  ) : (
    <StyledButton
      disabled
      style={{
        position: "fixed",
        bottom: "2.5rem",
        left: "50%",
        transform: "translateX(-50%)",
      }}
    >
      <Icon
        name="check"
        style={{
          marginLeft: "0.8em",
        }}
      />
      <Trans>Already done !</Trans>
    </StyledButton>
  )
}

StartButton.propTypes = {
  floated: PropTypes.string,
}
export default StartButton
