import { StyledTopMenu } from "../Styled"
import logo from "../../images/logo_header_app.png"
import { Image } from "semantic-ui-react"
import { Link } from "react-router-dom"
const MenuTop = () => {
  return (
    <>
      <StyledTopMenu icon borderless fluid fixed="top" inverted>
        <Link to="/" style={{ display: "contents" }}>
          <Image className="app-logo" src={logo} />
        </Link>
      </StyledTopMenu>
    </>
  )
}

export default MenuTop
