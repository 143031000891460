import { Trans } from "@lingui/macro"
import dayjs from "dayjs"
import { StyledButton } from "../Styled"

const ActionButton = ({
  gotoPaymentLink,
  isAvailable,
  subscription,
  isCurrentlyFollowed,
  registrationStartDate,
  registrationEndDate,
}) => {
  //const isNotPremiumUser = subscription.name !== "premium"

  const isPremiumUser = subscription.name === "premium" && !subscription.event

  const hasPurchasedCurrentEvent =
    subscription.name === "premium" && subscription.event && isCurrentlyFollowed

  const hasAnActiveEventPurchase =
    subscription.name === "premium" && subscription.event

  const registrationStatus = dayjs().isBefore(dayjs(registrationStartDate))
    ? "before"
    : dayjs().isAfter(dayjs(registrationEndDate), "day")
    ? "past"
    : "available"

  const chooseAction = () => {
    if (isPremiumUser) {
      return (
        <div className="price-included">
          <Trans>Included in your current subscription</Trans>
        </div>
      )
    } else if (hasPurchasedCurrentEvent) {
      return (
        <div className="current-planification">
          <Trans>Current planification</Trans>
        </div>
      )
    } else if (hasAnActiveEventPurchase) {
      return (
        <div className="price-included">
          <Trans>Not available while preparing another event </Trans>
        </div>
      )
    } else {
      return (
        <div style={{ textAlign: "center" }}>
          <StyledButton
            wide="true"
            secondary
            disabled={registrationStatus !== "available"}
            onClick={() => gotoPaymentLink()}
          >
            {registrationStatus === "before" ? (
              <Trans>Available soon</Trans>
            ) : registrationStatus === "past" ? (
              <Trans>Registration closed</Trans>
            ) : (
              <Trans>Checkout</Trans>
            )}
          </StyledButton>
        </div>
      )
    }
  }

  return chooseAction()
}

export default ActionButton
