// imports

import LogRocket from "logrocket"
import { Suspense, useEffect } from "react"
import { useMediaQuery } from "react-responsive"
import { useRecoilValueLoadable } from "recoil"
import { Container } from "semantic-ui-react"
import {
  useFirstname,
  useUserId,
  useFullname,
  useEmail,
} from "../../hooks/useUserInfo"
import {
  exercises,
  openSessions,
  subscriptionName,
  userPlanification,
} from "../../recoil/Atoms"
import { Mixpanel } from "../../utils/mixpanel"
import Loading from "../App/Loading"
import CallToAction from "./CallToAction"
import FromDesktopToPhone from "./FromDesktopToPhone"
import Greetings from "./Greetings"
import HomeCarousel from "./HomeCarousel/HomeCarousel"
import { useServiceWorker } from "../../hooks/useServiceWorker"
import PwaInstallPrompt from "../App/PwaInstallPrompt"
import HomeCardsSet from "./HomeCarousel/HomeCardsSet"

const HomePage = () => {
  const firstname = useFirstname()
  const userId = useUserId()
  const fullname = useFullname()
  const email = useEmail()

  useRecoilValueLoadable(exercises)
  useRecoilValueLoadable(subscriptionName)
  useRecoilValueLoadable(userPlanification)
  useRecoilValueLoadable(openSessions)

  const isDesktopOrLaptop = useMediaQuery({ minWidth: 1224 })
  const { reloadPage } = useServiceWorker()

  useEffect(() => {
    if (window.localStorage.getItem("mustUpdatePwa")) {
      console.log("Updating app, clearing flag")
      localStorage.removeItem("mustUpdatePwa")
      Mixpanel.track("App Update Done")

      reloadPage()
    }

    Mixpanel.people.set({
      "Last App Opened": new Date(),
    })

    Mixpanel.track("Home page viewed")
    LogRocket.identify(userId, {
      name: fullname,
      email: email,
    })
  }, [email, fullname, isDesktopOrLaptop, reloadPage, userId])

  return isDesktopOrLaptop && process.env.NODE_ENV !== "development" ? (
    <FromDesktopToPhone />
  ) : (
    <Container className="page home">
      <Greetings floated="left" firstname={firstname} />

      <Suspense fallback={<></>}>
        <HomeCardsSet />
      </Suspense>

      <HomeCarousel />
      <Suspense fallback={<Loading />}>
        <CallToAction />
      </Suspense>
      <PwaInstallPrompt />
    </Container>
  )
}

export default HomePage
