import { t, Trans } from "@lingui/macro"
import { useEffect, useLayoutEffect, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from "recoil"
import { Divider, Icon, List } from "semantic-ui-react"
import {
  canUpload,
  selectedFilename,
  selectedFilePreviewUrl,
} from "../../../recoil/Atoms"
import { StyledHeader, StyledList } from "../../Styled"

const Confirm = () => {
  const recoilSelectedFilePreviewUrl = useRecoilValue(selectedFilePreviewUrl)
  const recoilSelectedFilename = useRecoilValue(selectedFilename)
  const setRecoilCanUpload = useSetRecoilState(canUpload)
  const resetRecoilCanUpload = useResetRecoilState(canUpload)
  const [verifications, setVerifications] = useState({})

  const player = useRef()
  const navigate = useNavigate()

  useEffect(() => {
    if (!recoilSelectedFilename) {
      navigate("selectFile", { replace: true })
    }
  }, [navigate, recoilSelectedFilename])

  const checklist = [
    {
      key: "side",
      text: t`Landscape mode`,
    },
    {
      key: "nomove",
      text: t`Phone is not moving`,
    },
    {
      key: "direction",
      text: t`Runner is going from left to right`,
    },
    {
      key: "strides",
      text: t`4 full strides are visible`,
    },
  ]

  const toggleCheck = (name) => {
    let newVerifications = { ...verifications, [name]: !verifications?.[name] }
    setVerifications(newVerifications)
    //	console.log(ver)
    setRecoilCanUpload(
      newVerifications.side &&
        newVerifications.nomove &&
        newVerifications.direction &&
        newVerifications.strides
    )
  }

  //reset the can upload on unmount
  useLayoutEffect(() => {
    return () => {
      resetRecoilCanUpload()
    }
  }, [resetRecoilCanUpload])

  return (
    recoilSelectedFilename && (
      <>
        <div
          style={{
            position: "absolute",
            top: 0,
            transform: "translateY(-100%)",
            margin: "0 auto",
            width: "90vw",
            display: "none",
          }}
        >
          <video
            muted
            volume={0}
            width="100%"
            height="100%"
            loop={true}
            controls
            playsInline
            ref={player}
          >
            <source
              /*
              type="video/mp4"
			  */
              type={`video/${recoilSelectedFilename
                .split(".")
                .pop()
                .toLowerCase()}`}
              src={recoilSelectedFilePreviewUrl}
            ></source>
          </video>
        </div>
        <div>
          <StyledHeader style={{ fontSize: "1.1em" }}>
            <Trans>Please, review your video</Trans>
          </StyledHeader>
          <Divider />
          <StyledList
            size="large"
            className="default-color"
            style={{
              textAlign: "left",
              width: "90vw",
              margin: "0 auto",
            }}
          >
            {checklist.map(({ key, text }) => (
              <List.Item
                key={key}
                style={{ marginTop: 10 }}
                onClick={() => toggleCheck(key)}
              >
                <Icon
                  name={
                    verifications[key]
                      ? "square check outline"
                      : "square outline"
                  }
                  size="large"
                  className={verifications[key] ? "secondary" : null}
                />
                <List.Content>{text}</List.Content>
              </List.Item>
            ))}
          </StyledList>
        </div>
      </>
    )
  )
}

export default Confirm
