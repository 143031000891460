import { t, Trans } from "@lingui/macro"
import { useEffect } from "react"
import { Form } from "semantic-ui-react"
import { StyledForm } from "../Styled"

import dayjs from "dayjs"
import localizedFormat from "dayjs/plugin/localizedFormat"

import weekday from "dayjs/plugin/weekday"
import { useRecoilState } from "recoil"
import { selectedRace } from "../../recoil/Atoms"
import { useForm } from "react-hook-form"
import { useNavigate } from "react-router-dom"
dayjs.extend(weekday)
dayjs.extend(localizedFormat)

const RaceNameSelector = () => {
  const [recoilSelectedRace, setRecoilSelectedRace] =
    useRecoilState(selectedRace)

  const navigate = useNavigate()

  const { raceName: recoilRaceName = null } = recoilSelectedRace || {}

  const {
    setValue,
    register,

    formState: { errors },
  } = useForm()

  const onEnter = (e) => {
    if (e.key === "Enter") {
      e.target.blur()
      navigate("/race/pack")
    }
  }

  useEffect(() => {
    register("raceName", { required: true })
    setValue("raceName", recoilRaceName)
  }, [recoilRaceName, register, setValue])

  const handleChange = ({ name, value }) => {
    setValue(name, value)
    setRecoilSelectedRace((previousState) => ({
      ...previousState,
      [name]: value,
    }))
  }

  return (
    <>
      <StyledForm
        style={{ textAlign: "left", marginTop: "2em" }}
        data-testid="raceform"
      >
        <label>{t`Race name`}</label>
        <Form.Input
          fluid
          className="name-input"
          name="raceName"
          defaultValue={recoilRaceName}
          onChange={(e, { name, value }) => {
            handleChange({ name, value })
          }}
          onKeyUp={onEnter}
        />
        {errors.raceName && (
          <div
            style={{
              marginTop: "-1em",
              fontSize: "0.9em",
              marginBottom: "1em",
            }}
          >
            <Trans>Enter the name of the race</Trans>
          </div>
        )}
      </StyledForm>
    </>
  )
}

export default RaceNameSelector
