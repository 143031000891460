// imports

import PropTypes from "prop-types"

import { useNavigate, useParams } from "react-router-dom"
import { populateExercises } from "./utils"
import Abstract from "./Abstract/Abstract"
import Workout from "./Workout/Workout"
import { useRecoilValue, useSetRecoilState } from "recoil"
import {
  currentSession,
  planificationSession,
  subscriptionName,
} from "../../recoil/Atoms"
import { useEffect, useState } from "react"
import cloneDeep from "lodash.clonedeep"
import { t } from "@lingui/macro"
import { Mixpanel } from "../../utils/mixpanel"
const SessionPage = () => {
  let setRecoilCurrentSession = useSetRecoilState(currentSession)
  let { id, mode } = useParams()
  const [session, setSession] = useState()
  const [, setError] = useState()
  const navigate = useNavigate()

  const recoilPlanificationSession = useRecoilValue(planificationSession(id))
  const recoilSubscriptionName = useRecoilValue(subscriptionName)

  useEffect(() => {
    Mixpanel.track("Session Page Viewed")
  }, [])

  const isFreeUser = recoilSubscriptionName === "free"

  const isOpenPlanification =
    recoilPlanificationSession.planificationName.includes("open")

  const isSessionFree = recoilPlanificationSession.isFree

  const isFreeSessionDone = localStorage.getItem(
    recoilPlanificationSession.planificationName + "Done"
  )

  useEffect(() => {
    if (
      isFreeUser &&
      isOpenPlanification &&
      (!isSessionFree || isFreeSessionDone)
    ) {
      navigate("/subscribe", { replace: true })
    } else if (
      isFreeUser &&
      !["on-ramp", "warmup"].includes(
        recoilPlanificationSession.planificationName
      ) &&
      !isSessionFree
    ) {
      navigate("/session/mismatch", { replace: true })
    } else {
      if (recoilPlanificationSession) {
        setRecoilCurrentSession(recoilPlanificationSession)
        let newSession = cloneDeep(recoilPlanificationSession)
        setSession(newSession)
      }
    }
  }, [
    isFreeSessionDone,
    isFreeUser,
    isOpenPlanification,
    isSessionFree,
    navigate,
    recoilPlanificationSession,
    setRecoilCurrentSession,
  ])

  const { blocks, exercisesList = [] } = session || {}
  if (Array.isArray(blocks) && blocks.length === 0) {
    setError(() => {
      throw new Error(t`Could not get content of session`)
    })
  }

  populateExercises(blocks, exercisesList)

  let movementsVideos = exercisesList?.map(
    ({
      _id,
      videoId,
      name,
      videoLoopStart,
      videoLoopEnd,
      metronomeStart,
      metronomeBpm,
    }) => ({
      id: _id,
      videoId,
      name,
      videoLoopStart,
      videoLoopEnd,
      metronomeStart,
      metronomeBpm,
    })
  )

  return (
    session && (
      <div>
        {mode !== "workout" ? (
          <Abstract videos={movementsVideos} session={session} />
        ) : (
          <Workout videos={movementsVideos} session={session} />
        )}
      </div>
    )
  )
}

SessionPage.propTypes = {
  floated: PropTypes.string,
}
export default SessionPage
