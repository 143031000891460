// imports

import { Trans } from "@lingui/macro"
import { useEffect } from "react"
import { Container } from "semantic-ui-react"
import { Mixpanel } from "../../utils/mixpanel"
import { StyledHeader } from "../Styled"
import Focus from "./Focus"
import Planification from "./Planification"
import Preferences from "./Preferences"
import Stats from "./Stats"

const DashboardPage = () => {
  useEffect(() => {
    Mixpanel.track("Dashboard Page Viewed")
  }, [])
  return (
    <Container className="page profile">
      <StyledHeader className={"page-title"}>
        <Trans>Dashboard</Trans>
      </StyledHeader>
      <Preferences />
      <Planification />
      <Focus />
      <Stats />
    </Container>
  )
}

export default DashboardPage
