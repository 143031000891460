// imports

import { Trans } from "@lingui/macro"
import PropTypes from "prop-types"
import { useRecoilState } from "recoil"
import { muscleToStrike } from "../../recoil/Atoms"
import { StyledLabel } from "../Styled"

const BodyPartsList = ({
  bodyParts: { principal = [], accessory = [] } = {},
  title = null,
}) => {
  const [recoilMuscleToStrike, setRecoilMuscleToStrike] =
    useRecoilState(muscleToStrike)
  const toggleStroke = (muscle) => {
    setRecoilMuscleToStrike(recoilMuscleToStrike === muscle ? null : muscle)
  }

  return (
    <>
      {title && (
        <div
          style={{
            marginBottom: "0.5rem",
            padding: "0 0.2em",
            display: "inline-block",
            lineHeight: "1em",
          }}
        >
          {title}
        </div>
      )}
      <div>
        {principal?.map((part) => (
          <StyledLabel
            ambiant="true"
            size="small"
            key={part}
            onClick={() => toggleStroke(part)}
            overrideborder={recoilMuscleToStrike === part ? "#fff" : null}
          >
            <Trans id={part}></Trans>
          </StyledLabel>
        ))}
        {accessory?.map((part) => (
          <StyledLabel
            ambiant="true"
            alpha="true"
            size="small"
            key={part}
            onClick={() => toggleStroke(part)}
            overrideborder={recoilMuscleToStrike === part ? "#fff" : null}
          >
            <Trans id={part}></Trans>
          </StyledLabel>
        ))}
      </div>
    </>
  )
}

BodyPartsList.propTypes = {
  floated: PropTypes.string,
}
export default BodyPartsList
