import { i18n } from "@lingui/core"
import { Trans } from "@lingui/macro"
import { Container, Grid } from "semantic-ui-react"
import { StyledHeader } from "../../Styled"
import SessionCard from "./SessionCard"

import NextBackButtons from "../../shared/NextBackButtons"

import Preferences from "../../Home/Preferences"
import { useNavigate } from "react-router-dom"
import { openSessionCards } from "../../../recoil/Atoms"
import { Mixpanel } from "../../../utils/mixpanel"
import { useEffect } from "react"
const OpenSessionsPage = () => {
  const navigate = useNavigate()

  const handleClick = (key) => (e) => {
    // if (key === "favorites") return false

    navigate(`./${key}`)
  }

  useEffect(() => {
    Mixpanel.track("Open Sessions Page Viewed")
  }, [])

  return (
    <Container className="page open-sessions">
      <StyledHeader className={"page-title"}>
        <Trans>Open sessions</Trans>
      </StyledHeader>
      <div style={{ textAlign: "left", marginTop: -5, marginBottom: 10 }}>
        <Preferences />
      </div>

      <Grid stackable columns={2} style={{ marginBottom: 0 }}>
        {openSessionCards.map(({ title, key }) => (
          <Grid.Column key={key} onClick={handleClick(key)}>
            <SessionCard title={i18n._(title)} keyName={key} />
          </Grid.Column>
        ))}
      </Grid>
      <NextBackButtons backOnly />
    </Container>
  )
}

export default OpenSessionsPage
