// imports

import { t } from "@lingui/macro"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import { useRecoilValue } from "recoil"
import { authHeaders, serverURL, stripeId } from "../recoil/Atoms"
import { Mixpanel } from "../utils/mixpanel"

const useStripe = () => {
  const recoilHeaders = useRecoilValue(authHeaders)
  const [, setError] = useState()
  const recoilStripeId = useRecoilValue(stripeId)

  const navigate = useNavigate()
  const navigateToPortal = async (configurationHash) => {
    let body = JSON.stringify({
      return_url: window.location.href,
      ...configurationHash,
    })

    if (!recoilStripeId) {
      navigate("/subscribe")

      return null
    }
    Mixpanel.track("Stripe Portal Navigated")

    const data = await fetch(`${serverURL}/subscriptions/portal`, {
      method: "post",
      headers: {
        ...recoilHeaders,
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
      body,
    }).then(
      (res) => {
        if (res.ok) {
          return res.json()
        }
        toast.error(
          t`You do not have access to the subscriptions management page yet`
        )
      },
      (error) => {
        if (error) {
          setError(() => {
            throw new Error(t`Could not get access to portal`)
          })
        }
      }
    )
    if (data) {
      let { url } = data
      window.location.href = url // return data
      return url
    } else {
      return false
    }
  }

  const pay = async (body, service = null) => {
    body = JSON.stringify(body)

    const data = await fetch(
      `${serverURL}/subscriptions/pay${service ? "/" + service : ""}`,
      {
        method: "post",
        headers: {
          ...recoilHeaders,
          "Accept": "application/json",
          "Content-Type": "application/json",
        },
        body,
      }
    ).then(
      (res) => res.json(),
      (error) => {
        if (error) {
          setError(() => {
            throw new Error(t`Could not get access to payment server`)
          })
        }
      }
    )
    return data
  }

  const validateCoupon = async (couponCode) => {
    const data = await fetch(
      `${serverURL}/subscriptions/coupon/${couponCode}`,
      {
        method: "get",
        headers: {
          ...recoilHeaders,
        },
      }
    ).then(
      (res) => res.json(),
      (error) => {
        if (error) {
          setError(() => {
            throw new Error(t`Could not get access to payment server`)
          })
        }
      }
    )
    return data
  }

  return { navigateToPortal, pay, validateCoupon }
}

export default useStripe
