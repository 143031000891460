// imports

import PropTypes from "prop-types"
import { Trans } from "@lingui/macro"
import { StyledList } from "../../../../Styled"
import { List } from "semantic-ui-react"
import HelpIcon from "../../../../shared/HelpIcon"
import Help from "../../../../shared/Help"

const TabataHelp = ({ exercises }) => {
  return (
    <HelpIcon
      style={{ display: "inline" }}
      drawerContent={
        <Help
          title={<Trans>tabata</Trans>}
          description={
            <Trans>
              <p>
                This format is an intervals work where you alternate 20 seconds
                of work with 10 seconds of rest for a total of 4 minutes.
              </p>
              <p>
                The goal is to do as many repetitions as possible during the
                work interval.
              </p>
            </Trans>
          }
          details={
            <>
              {exercises.length === 1 ? (
                <>
                  <Trans>For 4 minutes, do :</Trans>
                  <StyledList inverted style={{ marginTop: "0.5em" }}>
                    {exercises.map(({ data: { name } }, index) => (
                      <List.Item key={index}>
                        <List.Icon
                          name="dot circle outline"
                          className="primary"
                        />
                        <List.Content>
                          <div>
                            <Trans>20 seconds of</Trans>{" "}
                            <Trans id={`${name}`} />,{" "}
                          </div>
                          <div>
                            <Trans>10 seconds of rest</Trans>{" "}
                          </div>
                        </List.Content>
                      </List.Item>
                    ))}
                  </StyledList>
                </>
              ) : (
                <>
                  <Trans>For 4 minutes, alternate between :</Trans>
                  <StyledList inverted style={{ marginTop: "0.5em" }}>
                    {exercises.map(({ data: { name } }, index) => (
                      <List.Item key={index}>
                        <List.Icon
                          name="dot circle outline"
                          className="primary"
                        />
                        <List.Content>
                          <div>
                            {" "}
                            <Trans>20 seconds of</Trans>{" "}
                            <Trans id={`${name}`} />,{" "}
                          </div>
                          <div>
                            <Trans>10 seconds of rest</Trans>{" "}
                          </div>
                        </List.Content>
                      </List.Item>
                    ))}
                  </StyledList>
                </>
              )}
            </>
          }
        />
      }
    />
  )
}

TabataHelp.propTypes = {
  floated: PropTypes.string,
}
export default TabataHelp
