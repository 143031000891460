// imports

import { Fragment } from "react"
import { Trans, plural } from "@lingui/macro"
import { StyledList } from "../../../../Styled"
import { List } from "semantic-ui-react"
import { formatSecondsToTimeSmart } from "../../../utils"
import HelpIcon from "../../../../shared/HelpIcon"
import Help from "../../../../shared/Help"

const WarmupHelp = ({ rounds, exercises }) => {
  return (
    <HelpIcon
      style={{ display: "inline" }}
      drawerContent={
        <Help
          title={<Trans>Warm-up</Trans>}
          description={
            <Trans>
              <p>Warming up prepares your body for the upcoming workout.</p>

              <p>Don't push hard, the goal is to get you moving</p>
            </Trans>
          }
          details={
            <>
              {plural(rounds, {
                one: "1 round of :",
                other: "# rounds of :",
              })}

              <StyledList inverted style={{ marginTop: "0.5em" }}>
                {exercises.map(({ data: { name }, work, rest }, index) => (
                  <Fragment key={index}>
                    <List.Item key={index}>
                      <List.Icon
                        name="dot circle outline"
                        className="primary"
                      />

                      <List.Content>
                        <div>
                          <Trans> {formatSecondsToTimeSmart(work)} of</Trans>{" "}
                          <Trans id={`${name}`} />,{" "}
                        </div>
                      </List.Content>
                    </List.Item>
                    {rest && (
                      <List.Item key={index}>
                        <List.Icon
                          name="dot circle outline"
                          className="primary"
                        />
                        <List.Content>
                          <Trans>
                            {formatSecondsToTimeSmart(rest)} seconds of rest
                          </Trans>{" "}
                        </List.Content>
                      </List.Item>
                    )}
                  </Fragment>
                ))}
              </StyledList>
            </>
          }
        />
      }
    />
  )
}

export default WarmupHelp
