// imports

import { plural, t } from "@lingui/macro"
import { Fragment, Suspense } from "react"
import { useRecoilValue } from "recoil"
import { Grid, Segment, Statistic } from "semantic-ui-react"
import { sessionsCount } from "../../recoil/Atoms"
import Loading from "../App/Loading"
import { StyledStatCard } from "../Styled"
import StatCard from "./StatCard"

const Stats = () => {
  let secondaryStatCards = [
    {
      label: t`full`,
      value: 20,
    },

    {
      label: t`daily`,
      value: 10,
    },
  ]

  const SessionsCountData = (params) => {
    const recoilSessionsDoneCount = useRecoilValue(sessionsCount)

    return (
      <Fragment>
        <Statistic.Value>{recoilSessionsDoneCount}</Statistic.Value>
        <Statistic.Label>
          {plural(recoilSessionsDoneCount, {
            one: "session done",
            other: "sessions done",
          })}
        </Statistic.Label>
      </Fragment>
    )
  }

  return (
    <Segment basic style={{ marginTop: 0 }}>
      <StyledStatCard className="ternary fluid" inverted size="tiny">
        <Suspense fallback={<Loading />}>
          <SessionsCountData />
        </Suspense>
      </StyledStatCard>

      <Grid columns={2} className="cards" style={{ display: "none" }}>
        {secondaryStatCards.map(({ label, value }) => (
          <Grid.Column
            key={label}
            style={{ paddingLeft: "0.5em", paddingRight: "0.5em" }}
          >
            <StatCard className={`ternary fluid`} label={label} value={value} />
          </Grid.Column>
        ))}
      </Grid>
    </Segment>
  )
}

export default Stats
