import { useNavigate, useSearchParams } from "react-router-dom"

import { Icon } from "semantic-ui-react"
import { Trans } from "@lingui/macro"
import { primaryColor, StyledButton, StyledHeader } from "../../../Styled"
export function WarmupButton({ warmupId, sessionId }) {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  let warmupDone = false
  if (searchParams.has("warmup-done")) {
    warmupDone = true
  }
  return (
    <>
      {warmupId &&
        (warmupDone ? (
          <StyledHeader ambiant="1">
            <Trans>Warm-up done</Trans>
            <Icon
              name="check"
              style={{
                marginLeft: 5,
                color: primaryColor,
              }}
            />
          </StyledHeader>
        ) : (
          <StyledButton
            ambiant="true"
            small="true"
            onClick={() =>
              navigate(
                `/session/${warmupId}?&is-warmup=1&sessionId=${sessionId}`
              )
            }
            style={{
              marginTop: "1em",
              marginBottom: "1em",
            }}
          >
            <Trans> I want to warmup first</Trans>
          </StyledButton>
        ))}
    </>
  )
}
