// imports

import { Trans } from "@lingui/macro"
import { StyledHeader } from "../Styled"
import { Segment } from "semantic-ui-react"

const Help = ({ title, description, details, detailsTitle }) => {
  return (
    <>
      <StyledHeader>{title}</StyledHeader>
      <Segment basic>{description}</Segment>
      {details && (
        <>
          <StyledHeader>{detailsTitle || <Trans>Details</Trans>}</StyledHeader>
          <Segment basic>{details}</Segment>
        </>
      )}
    </>
  )
}

export default Help
