import { Trans } from "@lingui/macro"
import { Link } from "react-router-dom"
import { useRecoilValue } from "recoil"
import { Icon } from "semantic-ui-react"
import { estimatedPublishedDate } from "../../recoil/Atoms"

import dayjs from "dayjs"
import localizedFormat from "dayjs/plugin/localizedFormat"
dayjs.extend(localizedFormat)

const InProgress = () => {
  const recoilEstimatedPublishedDate = useRecoilValue(estimatedPublishedDate)

  return (
    <div
      data-testid="drawer-content-analysis-inprogress"
      style={{ textAlign: "left" }}
    >
      <Trans>
        Your video should be reviewed by one of our coach around{" "}
        <span style={{ fontWeight: 600 }}>{recoilEstimatedPublishedDate}</span>.{" "}
        <br />
        <br />
        Your strength program will then be customized to help you get stronger
        and improve your stride efficiency.
        <br />
        <br />
        You will be able to view the focus of your personalized programing on
        your{" "}
        <span style={{ whiteSpace: "nowrap" }}>
          <Link to="/dashboard">
            <Icon name="th large" style={{ fontSize: "1em" }} />
            dashboard
          </Link>
        </span>
      </Trans>
    </div>
  )
}

export default InProgress
