import { t } from "@lingui/macro"
import { useEffect } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import { toast } from "react-toastify"
import { useSetRecoilState } from "recoil"
import { forceSubscriptionUpdate } from "../../../recoil/Atoms"

import { Mixpanel } from "../../../utils/mixpanel"

const SuccessPage = () => {
  const [searchParams] = useSearchParams()
  useEffect(() => {
    let product, frequency, distance, program, value, currency
    if (searchParams.has("product")) {
      product = searchParams.get("product")
    }
    if (searchParams.has("frequency")) {
      frequency = searchParams.get("frequency")
    }
    if (searchParams.has("distance")) {
      distance = searchParams.get("distance")
    }
    if (searchParams.has("program")) {
      program = searchParams.get("program")
    }

    if (searchParams.has("value")) {
      value = searchParams.get("value")
    }
    if (searchParams.has("currency")) {
      currency = searchParams.get("currency")
    }

    if (window.location.host === "renforun.app") {
      // eslint-disable-next-line no-undef
      fbq("track", "Purchase", { value, currency })
    }

    Mixpanel.track("Subscription Success Page Viewed", {
      Product: product,
      frequency: frequency,
      Distance: distance,
      Program: program,
    })
  }, [searchParams])

  const navigate = useNavigate()
  const recoilForceSubscriptionUpdate = useSetRecoilState(
    forceSubscriptionUpdate
  )

  useEffect(() => {
    toast.success(t`Your race program was successfully created !`)
    recoilForceSubscriptionUpdate((prev) => prev + 1)
    navigate("/", { replace: true })
  }, [navigate, recoilForceSubscriptionUpdate])

  return <></>
}

export default SuccessPage
