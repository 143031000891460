import { useEffect } from "react"
import { useTimer } from "react-timer-hook"
import { useSetRecoilState } from "recoil"
import {
  currentTimer,
  timerIsRunning,
  timerTime,
} from "../../../../recoil/Atoms"

import "react-circular-progressbar/dist/styles.css"
import CountDownAudio from "./CountdownAudio"
import TimerDisplay from "./TimerDisplay"
import useIntervalNavigation from "../../../../hooks/useIntervalNavigation"
import { computeTimerTime } from "../../utils"
import NextPrevIntervalButtons from "./NextPrevIntervalButtons"

const Timer = ({ intervals, currentInterval }) => {
  const setRecoilTimerIsRunning = useSetRecoilState(timerIsRunning)
  const setRecoilTimerTime = useSetRecoilState(timerTime)
  const setRecoilCurrentTimer = useSetRecoilState(currentTimer)
  let startTime = currentInterval.time

  const { seconds, minutes, isRunning, /*start,*/ pause, resume, restart } =
    useTimer({
      expiryTimestamp: computeTimerTime(startTime),
      autoStart: true,
      onExpire: () => {
        nextInterval()
      },
    })

  const { nextInterval } = useIntervalNavigation(intervals, restart)

  useEffect(() => {
    setRecoilCurrentTimer("timer")
    setRecoilTimerIsRunning(isRunning)
  }, [isRunning, setRecoilCurrentTimer, setRecoilTimerIsRunning])

  useEffect(() => {
    setRecoilTimerTime({ minutes, seconds })
  }, [minutes, seconds, setRecoilTimerTime])
  const percentage = () => {
    return ((minutes * 60 + seconds) / currentInterval.time) * 100
  }

  const togglePlayPause = () => (isRunning ? pause() : resume())

  let { type } = currentInterval
  return (
    <>
      <CountDownAudio seconds={minutes * 60 + seconds} />

      <div
        style={{ width: "45%", margin: "0em auto", maxWidth: 162 }}
        onClick={togglePlayPause}
      >
        <TimerDisplay
          value={percentage()}
          minutes={minutes}
          seconds={seconds}
          isRunning={isRunning}
          type={type}
        />
      </div>
      {isRunning && (
        <NextPrevIntervalButtons intervals={intervals} restart={restart} />
      )}
    </>
  )
}

export default Timer
