import { useNavigate, useParams } from "react-router-dom"
import {
  defaultTextColor,
  secondaryColor,
  StyledButton,
  StyledHeader,
  StyledList,
} from "../Styled"
import { Trans, t } from "@lingui/macro"
import { Container, Divider, Icon, List, Segment } from "semantic-ui-react"
import { Suspense, useEffect, useState } from "react"
import { useRecoilValue, useSetRecoilState } from "recoil"
import {
  analysisFocus,
  bookmarkedSessionsIds,
  noSleepActivate,
  planificationSession,
  stopwatchTime,
  subscriptionName,
  userLevel,
  userPlanification,
} from "../../recoil/Atoms"
import NextBackButtons from "../shared/NextBackButtons"
import { formatMinutesAndSeconds } from "./utils"
import { toast } from "react-toastify"
import { useLogSession } from "../../hooks/appfetch"
import { Mixpanel } from "../../utils/mixpanel"
import Loading from "../App/Loading"
import { Bookmark } from "./Abstract/SessionContent/Bookmark"

const SessionDonePage = () => {
  let { id } = useParams()

  let recoilLevel = useRecoilValue(userLevel)

  const [done, setDone] = useState(null)
  const [difficulty, setDifficulty] = useState(0)
  const [fun, setFun] = useState(0)
  const [clicked, setClicked] = useState(false)
  const navigate = useNavigate()
  const { minutes, seconds } = useRecoilValue(stopwatchTime)
  const setLogSession = useLogSession()
  const recoilPlanificationSession = useRecoilValue(planificationSession(id))
  const recoilUserPlanification = useRecoilValue(userPlanification)
  const recoilAnalysisFocus = useRecoilValue(analysisFocus)
  const recoilSubscriptionName = useRecoilValue(subscriptionName)
  const recoilNoSleepActivate = useSetRecoilState(noSleepActivate)

  const recoilBookmarkedSessionsIds = useRecoilValue(bookmarkedSessionsIds)
  const isBookmarked = recoilBookmarkedSessionsIds.includes(id)
  let {
    week,
    weekIndex,
    planificationName,
    blocks,
    road,
    program,
    duration,
    name,
    custom_name,
  } = recoilPlanificationSession

  useEffect(() => {
    recoilNoSleepActivate(false)
  }, [recoilNoSleepActivate])

  const logSession = async () => {
    if (clicked === true) {
      return
    }
    setClicked(true)

    let durationFocus
    if (recoilAnalysisFocus) {
      durationFocus =
        recoilPlanificationSession["duration_" + recoilAnalysisFocus]
    }
    let score = minutes * 60 + seconds

    const { id: userPlanificationId } = recoilUserPlanification

    let payload = {
      fun,
      difficulty,
      userPlanificationId,
      session: {
        name,
        custom_name,
        id,
        week,
        weekIndex,
        planificationName,
        blocks,
        road,
        program,
        duration,
        focus: recoilAnalysisFocus || "generic",
        durationFocus,
      },
      score,
      unit: "time",
      level: recoilLevel,
    }
    let { isSessionlast } = await setLogSession(payload)
    Mixpanel.track("Session Done", {
      "Fun": fun,
      "Difficulty Perceived": difficulties.find(
        (option) => option.value === difficulty
      )?.["text"],
      "User level": recoilLevel,
      "Time": score,
      "Focus": recoilAnalysisFocus || "generic",
      "session Name": `${name} `,
      "Session Custom Name": custom_name,
    })
    Mixpanel.people.increment("Sessions Done")
    if (!isSessionlast) {
      toast.info(t`Session logged.`)
      navigate("/", { replace: true })
    } else if (recoilSubscriptionName === "free") {
      navigate(`/subscribe?planification-done=${planificationName}`, {
        replace: true,
      })
    } else {
      navigate(`/session/all-done?planification-name=${planificationName}`, {
        replace: true,
      })
    }
  }

  useEffect(() => {
    let { planificationName } = recoilPlanificationSession

    if (planificationName.includes("open")) {
      localStorage.setItem(planificationName + "Done", "true")
    }
  }, [recoilPlanificationSession])

  const confirm = () => {
    setDone(true)
  }
  const cancel = () => {
    setDone(false)
    navigate(`/session/${id}`)
  }

  let difficulties = [
    {
      value: 1,
      icon: "sad tear outline",
      text: t`too easy`,
    },
    {
      value: 2,
      icon: "grin beam outline",
      text: t`appropriate`,
    },
    {
      value: 3,
      icon: "grin beam sweat outline",
      text: t`appropriate, but challenging`,
    },
    {
      value: 4,
      icon: "dizzy outline",
      text: t`too hard`,
    },
  ]
  let funs = [
    {
      thumb: "down",
      value: -1,
    },
    {
      thumb: "up",
      value: 1,
    },
  ]

  return (
    <Container className="page session done">
      <div
        style={{
          width: "100%",
          paddingTop: 50,

          maxWidth: 800,
        }}
      >
        {(!!minutes || !!seconds) && (
          <>
            <div
              style={{
                color: defaultTextColor,
                fontSize: "5rem",
                fontFamily: "Bakbak One",
                width: "100%",
                maxWidth: 800,
              }}
            >
              {formatMinutesAndSeconds(minutes, seconds)}
            </div>
            <Divider style={{ marginTop: "2rem" }} />
          </>
        )}

        <Segment basic style={{ margin: 0, paddingTop: 0 }}>
          <StyledHeader style={{ marginTop: 10 }}>
            <Trans>Did you complete this session ?</Trans>
          </StyledHeader>
          <div>
            <StyledButton
              secondary
              simple={done !== false ? "1" : null}
              onClick={cancel}
            >
              <Icon name="remove" /> <Trans>No</Trans>
            </StyledButton>
            <StyledButton
              size="big"
              secondary
              simple={done !== true ? "1" : null}
              onClick={confirm}
            >
              <Icon name="checkmark" /> <Trans>Yes</Trans>
            </StyledButton>
          </div>
        </Segment>

        <Segment basic compact style={{ margin: "0 auto" }}>
          <StyledHeader>
            <Trans>How did you find it ?</Trans>
          </StyledHeader>
          <StyledList inverted size="large">
            {difficulties.map(({ icon, text, value }) => (
              <List.Item
                key={value}
                className={
                  value === difficulty ? "secondary-bg selected" : "secondary"
                }
                onClick={() => setDifficulty(value)}
                style={{
                  marginTop: "0.5em",
                  marginBottom: "0.5em",
                  paddingTop: "0.2em",
                }}
              >
                <Icon className={icon} size="large" />

                <List.Content verticalAlign="middle">{text}</List.Content>
              </List.Item>
            ))}
          </StyledList>
        </Segment>
        <Segment basic compact style={{ margin: "0 auto" }}>
          <StyledHeader>
            <Trans>Was the session fun?</Trans>
          </StyledHeader>
          {funs.map(({ thumb, value }) => (
            <Icon
              key={value}
              className={`thumbs ${thumb} ${
                fun === value ? "" : "outline"
              } large`}
              style={{
                margin: "0 0.8em",
                color: secondaryColor,
              }}
              onClick={() => setFun(value)}
            />
          ))}
          <div style={{ display: "inline-block" }} onClick={() => setFun(2)}>
            <Icon
              className={`thumbs up ${fun === 2 ? "" : "outline"} large`}
              style={{
                color: secondaryColor,
              }}
            />
            <div
              style={{
                display: "inline-block",
                width: 18,
                overflow: "hidden",
                verticalAlign: "bottom",
                marginLeft: -8,
              }}
            >
              <Icon
                className={`thumbs up ${fun === 2 ? "" : "outline"} large`}
                style={{
                  float: "right",
                  marginLeft: -3,
                  color: secondaryColor,
                }}
              />
            </div>
          </div>
        </Segment>
        {[1, 2].includes(fun) && (
          <Segment basic compact style={{ margin: "0 auto" }}>
            <StyledHeader>
              {!isBookmarked ? (
                <Trans>Add this session to your favorites?</Trans>
              ) : (
                <Trans>This session is one of your favorites!</Trans>
              )}
            </StyledHeader>
            <Suspense
              fallback={
                <span
                  style={{
                    // marginRight: "0.5em",
                    marginTop: 2,
                    float: "right",
                  }}
                >
                  <Loading />
                </span>
              }
            >
              <Bookmark
                style={{
                  fontSize: "2em",
                  textTransform: "initial",
                  marginRight: "0.5em",
                  marginTop: 2,
                  fontWeight: "normal",
                }}
                sessionInfos={{ id, custom_name }}
              />
            </Suspense>
          </Segment>
        )}
      </div>
      {
        <StyledButton
          secondary
          disabled={!done}
          style={{
            marginTop: "1em",
          }}
          onClick={() => logSession()}
        >
          {clicked ? <Loading /> : <Trans>Validate</Trans>}
        </StyledButton>
      }
      <NextBackButtons backOnly={true} backUrl={`/session/${id}`} />
    </Container>
  )
}

export default SessionDonePage
