import { useState } from "react"
import { useRecoilValue } from "recoil"
import { currentIntervalIndex, currentVideoId } from "../../../recoil/Atoms"
import Video from "../../Exercise/VideoLoopFrames"
import { createTimeTable } from "../utils"
import ExitButton from "./ExitButton"
import StartCountdown from "./StartCountdown"
import WorkoutContent from "./WorkoutContent"

const Workout = ({ videos, session }) => {
  const [showCountDown, setShowCountdown] = useState(true)
  let recoilCurrentVideo = useRecoilValue(currentVideoId)
  let recoilCurrentIntervalIndex = useRecoilValue(currentIntervalIndex)
  let { blocks } = session

  let timerIntervals = createTimeTable(blocks)

  const mustDisplayCurrentVideo = (videoId) => {
    let currentInterval = timerIntervals[recoilCurrentIntervalIndex]
    return (
      currentInterval.type === "work" &&
      "" + recoilCurrentVideo === "" + videoId
    )
  }

  const mustDisplayNextVideo = (videoId) => {
    let currentInterval = timerIntervals[recoilCurrentIntervalIndex]
    if (currentInterval.type === "work") return

    let nextInterval = getNextInterval()
    //  let prevInterval = getPrevInterval()
    if (nextInterval) {
      return (
        /*    "" + prevInterval?.exercises?.[0].videoId !==
          "" + nextInterval?.exercises?.[0].videoId &&*/
        "" + nextInterval?.exercises?.[0].videoId === "" + videoId
      )
    } else {
      return false
    }
  }

  const mustDisplayVideo = (videoId) => {
    return mustDisplayCurrentVideo(videoId) || mustDisplayNextVideo(videoId)
  }

  let displayOverlay = () => {
    let currentInterval = timerIntervals[recoilCurrentIntervalIndex]
    if (currentInterval.type === "rest") {
      let nextInterval = getNextInterval()
      if (nextInterval) {
        return nextInterval?.exercises?.[0].exerciseName
      } else {
        return false
      }
    }
  }

  const getNextInterval = () => {
    if (recoilCurrentIntervalIndex + 1 <= timerIntervals.length) {
      let nextInterval = timerIntervals[recoilCurrentIntervalIndex + 1]
      return nextInterval
    } else {
      return null
    }
  }

  const countdownDone = () => {
    setShowCountdown(false)
  }

  return (
    <>
      {showCountDown ? (
        <StartCountdown countdownDone={countdownDone} />
      ) : (
        <div>
          <div className="videos">
            {videos.map(
              (
                {
                  videoId,
                  videoLoopStart,
                  videoLoopEnd,
                  metronomeStart,
                  metronomeBpm,
                },
                index
              ) => (
                <div
                  key={videoId}
                  style={{
                    visibility: mustDisplayVideo(videoId)
                      ? "visible"
                      : "hidden", //astuce pour que la hauteur soit toujours présente
                    position:
                      index === videos.length - 1 ? "relative" : "absolute", //astuce pour que la hauteur soit toujours présente meme lorsque deux repos se suivent
                    left: 0,
                    right: 0,
                  }}
                >
                  <Video
                    key={videoId}
                    id={videoId}
                    isPlaying={mustDisplayVideo(videoId)}
                    videoLoopStart={videoLoopStart}
                    videoLoopEnd={videoLoopEnd}
                    overlay={displayOverlay()}
                    metronomeStart={metronomeStart}
                    metronomeBpm={metronomeBpm}
                  />
                </div>
              )
            )}
          </div>
          <WorkoutContent intervals={timerIntervals} />

          <ExitButton />
        </div>
      )}
    </>
  )
}

export default Workout
