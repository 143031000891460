import Block from "./Block"

const SessionContent = ({ session }) => {
  let { blocks } = session
  let partIndex = 0

  return (
    <>
      {blocks.map((block, i, blocks) => {
        partIndex = block.format.name !== "rest" ? partIndex + 1 : partIndex
        return (
          <Block
            block={block}
            index={partIndex}
            key={block._id}
            partsCount={blocks.length}
          />
        )
      })}
    </>
  )
}

export default SessionContent
