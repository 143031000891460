import { primaryColor } from "../Styled"

const RenfoRun = () => {
  return (
    <span style={{ fontWeight: 600 }}>
      <span>Renfo</span>
      <span style={{ color: primaryColor }}>Run</span>
    </span>
  )
}

export default RenfoRun
