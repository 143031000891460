// imports

import { i18n } from "@lingui/core"
import { t, Trans } from "@lingui/macro"
import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { useRecoilValue } from "recoil"
import { Divider } from "semantic-ui-react"
import { priorityTitle } from "../../locales/dynamicKeys"
import { analysisFocus, userProgram } from "../../recoil/Atoms"
import BodyHighlight from "../Exercise/BodyHighlight"
import CTACustomize from "../shared/CTACustomize"
import { ambiantColorRGB } from "../Styled"
import BodyPartsList from "./BodyPartsList"

const FocusContent = () => {
  let recoilUserProgram = useRecoilValue(userProgram)

  const recoilAnalysisFocus = useRecoilValue(analysisFocus)
  const [highlight, setHighlight] = useState()

  const programBodyParts = {
    road: {
      principal: ["footwork", "quadriceps", "hamstrings"],
      accessory: ["abdominals", "lowerback"],
    },
    trail: {
      principal: ["quadriceps", "abdominals", "calves"],
      accessory: ["lowerback", "footwork"],
    },
  }

  const userPrioritizedBodyParts = {
    posture: {
      principal: ["abdominals", "lowerback", "glutes"],
    },
    efficiency: {
      principal: ["hamstrings", "footwork"],
    },
    dynamics: {
      principal: ["footwork", "hamstrings", "achilles"],
    },
  }

  const toggleFocus = (highlight) => {
    setHighlight(highlight)
  }
  const setBodyParts = () =>
    highlight === "user"
      ? userPrioritizedBodyParts[recoilAnalysisFocus]
      : programBodyParts[recoilUserProgram]

  const focusButtons = [
    <span
      key="user"
      onClick={() => toggleFocus("user")}
      className={`content button ${highlight === "user" ? "selected" : ""}`}
    >
      <Trans>for your stride</Trans>
    </span>,
    <span
      key="program"
      onClick={() => toggleFocus("program")}
      className={` content button ${highlight === "program" ? "selected" : ""}`}
    >
      {recoilUserProgram === "road" ? (
        <Trans>Road specific</Trans>
      ) : recoilUserProgram === "trail" ? (
        <Trans>Trail specific</Trans>
      ) : (
        <>
          <Trans>Program</Trans>
        </>
      )}
    </span>,
  ]
  useEffect(() => {
    if (recoilAnalysisFocus) {
      setHighlight("user")
    } else {
      setHighlight("program")
    }
  }, [recoilUserProgram, recoilAnalysisFocus])

  return (
    <>
      <div style={{ marginTop: "1.0em" }}>
        {recoilAnalysisFocus
          ? focusButtons
          : focusButtons.reverse().map((div) => div)}
      </div>

      <Divider hidden />
      <div>
        <div className="bodyHighlight">
          <BodyHighlight
            height="9em"
            contour="#747474"
            contourStroke="3.7"
            bodyParts={setBodyParts()}
          />
        </div>
        <div className="bodyparts">
          <BodyPartsList
            bodyParts={setBodyParts()}
            title={
              highlight === "user" && recoilAnalysisFocus ? (
                <>
                  <span style={{ color: "#fff", fontSize: "0.9rem" }}>
                    <Trans>Focus : </Trans>
                  </span>{" "}
                  <span
                    style={{
                      color: `rgb(${ambiantColorRGB})`,

                      fontSize: "1rem",
                      fontWeight: 600,
                    }}
                  >
                    {i18n._(priorityTitle[recoilAnalysisFocus])}
                  </span>
                </>
              ) : (
                ""
              )
            }
          />
          {highlight === "user" && !recoilAnalysisFocus && (
            <CTACustomize titleOverride={t`Adjust to my stride`} />
          )}
          {highlight === "program" && !recoilUserProgram && (
            <Link to="/select-program">
              <div style={{ textAlign: "center" }}>
                <div>-</div>
                <div data-testid="focus-select-planification">
                  <Trans>Select your planification</Trans>
                </div>
              </div>
            </Link>
          )}
        </div>
      </div>
    </>
  )
}

export default FocusContent
