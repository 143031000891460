import { t, Trans } from "@lingui/macro"
import { useEffect, useRef } from "react"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import { useRecoilValue, useSetRecoilState } from "recoil"
import { Icon } from "semantic-ui-react"
import {
  canUploadVideo,
  hasPremiumSubscription,
  selectedFile,
  selectedFilename,
  selectedFilePreviewUrl,
} from "../../../recoil/Atoms"

const SelectFile = () => {
  const setRecoilSelectedFile = useSetRecoilState(selectedFile)
  const recoilSelectedFilename = useRecoilValue(selectedFilename)
  const recoilhasPremiumSubscription = useRecoilValue(hasPremiumSubscription)
  const setRecoilSelectedFilePreviewUrl = useSetRecoilState(
    selectedFilePreviewUrl
  )

  const recoilCanUploadVideo = useRecoilValue(canUploadVideo)

  let navigate = useNavigate()

  const uploadInput = useRef()

  const handleChange = (e) => {
    let file = e.target.files[0]
    if (!file) return

    let fileName = file.name
    let fileType = fileName.split(".").pop().toLowerCase()

    if (["mov", "mp4"].indexOf(fileType) === -1) {
      toast.error(t`Please choose .mov or .mp4 file`)
      return
    }

    var videoPreviewUrl = URL.createObjectURL(file)

    setRecoilSelectedFile(file)
    setRecoilSelectedFilePreviewUrl(videoPreviewUrl)
    navigate("confirm")
  }

  useEffect(() => {
    if (!recoilCanUploadVideo) {
      navigate("/", { replace: true })
    }
    if (!recoilhasPremiumSubscription) {
      navigate("/subscribe", { replace: true })
    }
  }, [navigate, recoilCanUploadVideo, recoilhasPremiumSubscription])

  return (
    recoilhasPremiumSubscription && (
      <>
        <input
          onChange={handleChange}
          ref={uploadInput}
          type="file"
          id="fileinput"
          className="inputfile"
          style={{
            width: "0.1px",
            height: "0.1px",
            opacity: 0,
            overflow: "hidden",
            position: "absolute",
            zIndex: -1,
          }}
        />
        <label
          htmlFor="fileinput"
          style={{
            // position: "absolute",

            margin: "0 auto",
            width: "200px",
            //left: "50%",
            //transform: "translateX(50%)",
          }}
        >
          <Icon
            name="cloud upload"
            style={{
              marginTop: 50,
              fontSize: "10rem",
            }}
          />
          <div>
            <Trans>You can now proceed with filming and </Trans>
          </div>
          <div>
            <Trans>upload your video</Trans>
          </div>
          {recoilSelectedFilename && (
            <div style={{ marginTop: 20 }}>{recoilSelectedFilename}</div>
          )}
        </label>
      </>
    )
  )
}

export default SelectFile
