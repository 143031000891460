import mixpanel from "mixpanel-browser"
import { mustExcludeFromTracking } from "."

const PROJECT_KEY_DEV = "78396376d24a62cfe2987eb7f34edb43"
const PROJECT_KEY_PROD = "f7c939d3ed966544cf609b2aa8bb9952"

mixpanel.init(
  window.location.host !== "renforun.app" || mustExcludeFromTracking()
    ? PROJECT_KEY_DEV
    : PROJECT_KEY_PROD,
  {
    debug: false,
    api_host: "https://api-eu.mixpanel.com",
  }
)

const getUserId = () => {
  let { id = null } = JSON.parse(window.localStorage.getItem("RFRUser")) || {}
  return id
}

const getSrc = () => {
  let params = new URL(document.location).searchParams
  return params.get("src")
}

let actions = {
  // identify: (id) => {
  identify: () => {
    let id = getUserId()
    mixpanel.identify(id)
  },
  alias: () => {
    let id = getUserId()
    mixpanel.alias(id)
  },
  track: (name, props) => {
    let src = getSrc()
    if (src) {
      props = { ...props, src }
    }

    mixpanel.track(name, props)
  },
  people: {
    set: (props) => {
      let id = getUserId()
      mixpanel.identify(id)
      mixpanel.people.set(props)
    },

    set_once: (props) => {
      let id = getUserId()
      mixpanel.identify(id)
      mixpanel.people.set_once(props)
    },
    increment: (props) => {
      let id = getUserId()
      mixpanel.identify(id)
      mixpanel.people.increment(props)
    },
    append: (props) => {
      let id = getUserId()
      mixpanel.identify(id)
      mixpanel.people.append(props)
    },
  },
}

export let Mixpanel = actions
