import { Icon, List } from "semantic-ui-react"
import { StyledList } from "../../Styled"

const Instructions = ({ listItems }) => {
  return (
    <StyledList className="default-color" size="big">
      {listItems.map((text, i) => (
        <List.Item key={i} style={{ paddingTop: 8, paddingBottom: 8 }}>
          <Icon name="circle" className="primary" size="small" />
          <List.Content>{text}</List.Content>
        </List.Item>
      ))}
    </StyledList>
  )
}

export default Instructions
