// imports

import { t } from "@lingui/macro"
import { Segment } from "semantic-ui-react"
import { StyledOptionCard } from "../Styled"

import { useNavigate } from "react-router-dom"

const RaceOrPlanSelector = () => {
  const navigate = useNavigate()

  const options = [
    { key: "race", title: t`Prepare for a race`, link: "race" },
    {
      key: "planification",
      title: t`Start a planification`,
      link: "planification",
    },
  ]

  return (
    <Segment basic>
      {options.map(({ title, link, key }) => (
        <StyledOptionCard
          key={key}
          onClick={() => navigate(link)}
          data-testid={"option-" + key}
          className={"ambiant"}
        >
          <div className="title">{title}</div>
        </StyledOptionCard>
      ))}
    </Segment>
  )
}

export default RaceOrPlanSelector
