// imports

import { openSessionCards } from "../recoil/Atoms"
const useSessionCategory = () => {
  const getSessionCategory = (planificationName) => {
    const planificationKey = planificationName.replace("open-", "")
    return openSessionCards.find((card) => card.key === planificationKey).title
  }

  return getSessionCategory
}

export default useSessionCategory
