// imports

import { i18n } from "@lingui/core"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import dailyImage from "../../../images/daily.jpg"
import raceReadyImage from "../../../images/race-ready.jpg"
import sessionsImg from "../../../images/open-sessions.jpg"
import { t } from "@lingui/macro"
import Preferences from "../Preferences"
import PlayButton from "../PlayButton"
import { useRecoilValue, useSetRecoilState } from "recoil"
import {
  analysisStatus,
  homeCards,
  homeCssReservedHeight,
  subscriptionFrequency,
  subscriptionName,
  userPlanification,
} from "../../../recoil/Atoms"
import { useEffect, useMemo } from "react"
import { formatWeekendFromSunday } from "../../../utils"
import dayjs from "dayjs"

const HomeCardsSet = (props) => {
  const recoilAnalysisStatus = useRecoilValue(analysisStatus)

  const recoilSubscriptionFrequency = useRecoilValue(subscriptionFrequency)
  const recoilSubscriptionName = useRecoilValue(subscriptionName)

  const isClassicPackUser =
    recoilSubscriptionFrequency === "onetime" &&
    recoilSubscriptionName === "classic"

  const recoilUserPlanification = useRecoilValue(userPlanification)

  const setRecoilHomeCssReservedHeight = useSetRecoilState(
    homeCssReservedHeight
  )

  const setRecoilhomeCards = useSetRecoilState(homeCards)

  const cssReservedHeight =
    recoilAnalysisStatus === "viewed" || isClassicPackUser ? 0 : 55

  useEffect(() => {
    setRecoilHomeCssReservedHeight(cssReservedHeight)
  }, [cssReservedHeight, setRecoilHomeCssReservedHeight])

  let playButtonLinks = useMemo(
    () => ({
      "on-ramp": "/session/week",
      "development": "/session/week",
      "daily": "/session/week",
      "race-ready": "/session/week",
      "race-ready-base": "/session/week",
    }),
    []
  )

  useEffect(() => {
    let {
      notSet: planificationNotSet = false,
      planification: { name: planificationName = null } = {},
      raceDate = null,
      raceName = null,
    } = recoilUserPlanification

    let raceDatePassed = dayjs().add(1, "day").isAfter(dayjs(raceDate), "day")

    let raceDateLabel = null
    if (raceDate) {
      raceDateLabel = raceDatePassed
        ? t`Planification done !`
        : formatWeekendFromSunday(raceDate, true)
    }

    const selectRaceCard = {
      title: t`Prepare for a race`,
      header: null,
      testid: "select-race",
      description: null,
      link: "/select-program/race",
      preferences: <Preferences />,
      image: raceReadyImage,
      imagePosition: "center 25%",
      // actionButton: <ChoosePlanificationButton />,
    }

    const selectDailyCard = {
      title: t`Start a planification`,
      subtitle: t`On ramp or Daily`,
      header: null,
      description: null,
      link: "/select-program/planification",
      preferences: <Preferences />,
      image: dailyImage,
      // actionButton: <ChoosePlanificationButton />,
      testid: "select-daily",
    }
    const trainRaceCard = {
      title: raceName,
      raceDate: raceDateLabel,
      link: playButtonLinks[planificationName],
      preferences: <Preferences />,
      actionButton: raceDatePassed ? null : <PlayButton />,
      image: raceReadyImage,
      testid: "train-race",
    }
    const trainDailyCard = {
      title: planificationName ? i18n._(planificationName) : "",
      header: t`Selected planification`,
      link: playButtonLinks[planificationName],
      preferences: <Preferences />,
      actionButton: <PlayButton />,
      image: dailyImage,
      testid: "train-daily",
    }
    const openSessionsCard = {
      title: t`Open sessions`,
      description: t`Choose your workout`,
      image: sessionsImg,
      link: "/session/open/",
      testid: "view-open-sessions",
    }

    let userHomeCards = []

    if (planificationNotSet) {
      //free user no plan
      userHomeCards = [selectDailyCard, openSessionsCard, selectRaceCard]
    } else if (planificationName?.includes("race")) {
      //classic or premium pack
      userHomeCards = [trainRaceCard, openSessionsCard, selectDailyCard]
    } else if (["on-ramp", "daily"].includes(planificationName)) {
      //classic or premium pack
      userHomeCards = [trainDailyCard, openSessionsCard, selectRaceCard]
    }

    setRecoilhomeCards([...userHomeCards])
  }, [playButtonLinks, recoilUserPlanification, setRecoilhomeCards])
}

export default HomeCardsSet
