import { t, Trans } from "@lingui/macro"
import { i18n } from "@lingui/core"
import { StyledButton, StyledHeader } from "../../Styled"
import { Divider, Loader, Segment } from "semantic-ui-react"
import { useNavigate } from "react-router-dom"
import { useRecoilValue } from "recoil"
import { hasPremiumSubscription, hasSubscription } from "../../../recoil/Atoms"
import CTASubscribe from "../../shared/CTASubscribe"
import { Suspense, useEffect } from "react"
import Loading from "../../App/Loading"
import useDrawer from "../../../hooks/useDrawer"
import RenfoRun from "../../shared/Renforun"
import { Mixpanel } from "../../../utils/mixpanel"

const SelectorHelp = ({ helpItemName, hasFocus, canSelectPlanification }) => {
  return (
    <Suspense fallback={<Loader />}>
      <SelectorHelpContent
        helpItemName={helpItemName}
        hasFocus={hasFocus}
        canSelectPlanification={canSelectPlanification}
      />
    </Suspense>
  )
}

const SelectorHelpContent = ({
  helpItemName,
  hasFocus,
  canSelectPlanification,
}) => {
  const recoilHasPremiumSubscription = useRecoilValue(hasPremiumSubscription)
  const recoilHasSubscription = useRecoilValue(hasSubscription)
  const { closeDrawer } = useDrawer()
  const navigate = useNavigate()

  useEffect(() => {
    Mixpanel.track("Help Page Viewed", { Item: helpItemName })
  }, [helpItemName])
  //not translated
  let help = {
    "on-ramp": (
      <>
        <p>
          <Trans>
            Si tu en as assez de faire les mêmes 3 x 10 squats, suivis de 3 x 1
            min de planche... (tu vois l'idée), sans être sûr que c’est vraiment
            efficace, <RenfoRun /> va changer ta vie.
          </Trans>
        </p>
        <p>
          <Trans>
            Ce plan d'<b>initiation</b> de 4 semaines te permettra de découvrir
            comment faire du renforcement musculaire avec des{" "}
            <b>séances variées et efficaces</b>.
          </Trans>
        </p>
        <p>
          <Trans>
            Idéal pour débuter ou reprendre après une coupure, il te permettra
            rapidement de gagner en facilité sur tes sorties running.
          </Trans>
        </p>
      </>
    ),
    "development": (
      <>
        <p>
          <Trans>
            During your running season, you cycle between race-specific training
            and general or intermediate training phases.
          </Trans>
        </p>
        <p>
          <Trans>
            Outside of the race-specific periods, the <RenfoRun />{" "}
            <b>Development</b> plan allows you to spend four intense weeks
            strengthening your muscles, tendons and ligaments that are necessary
            for an efficient stride.
          </Trans>
        </p>
        <p>
          <Trans>
            It will put you in the best position to handle the training load of
            your next specific cycle.{" "}
          </Trans>
        </p>
        <p>
          <Trans>
            This is the ideal plan to follow after the <b>On ramp</b> plan.
          </Trans>
        </p>
        <p>
          <Trans>
            At the end of this plan you will have gained resistance and will
            feel more comfortable on all your paces.
          </Trans>
        </p>

        <div></div>
      </>
    ),
    "daily": (
      <>
        <p>
          <Trans>
            Quels exercices faire ? Quand les intégrer dans mon emploi du temps
            ? Est-ce vraiment utile pour moi ?
          </Trans>
        </p>

        <p>
          <Trans>
            Grâce au plan <b>Quotidien</b> de <RenfoRun />, c’est simple, tu
            ouvres l’application et tu te laisses guider. On t’a mijoté des
            séances d’environ 15 minutes, intenses, faciles à caser dans ta vie
            trépidante, pour que tu puisses garder le maximum de ton temps pour
            ta passion de courir.
          </Trans>
        </p>

        <p>
          <Trans>
            La <b>régularité</b> est la clé <b>pour progresser</b>.
          </Trans>
        </p>

        <p>
          <Trans>
            Les exercices sont variés, les formats de séance aussi. Tout le
            temps.
          </Trans>
        </p>

        <div>
          {!recoilHasPremiumSubscription ? (
            <>
              <div>
                <Trans>
                  Et, pour rentabiliser ton temps au maximum, ce plan peut être
                  adapté à ta foulée avec l’offre premium.
                </Trans>
              </div>
              <Divider hidden />
              <div>
                <Suspense fallback={<Loading />}>
                  <CTASubscribe />
                </Suspense>
              </div>
            </>
          ) : !hasFocus ? (
            <p>
              <Trans>
                Il semble que tu n'as pas encore demandé d'analyse vidéo. Tu
                peux commencer à utiliser le plan CONTINU dès maintenant. Une
                fois ta vidéo analysée par un de nos coach, ce plan sera modifié
                pour être adapté à ta foulée.
              </Trans>
            </p>
          ) : (
            <></>
          )}
        </div>
      </>
    ),

    "race-ready": (
      <>
        <p>
          <Trans>
            Comment intégrer le renforcement musculaire à ton plan
            d’entrainement?
          </Trans>
        </p>
        <p>
          <Trans>
            A quel période faire du gainage? Augmenter le travail des quadriceps
            et des mollets en début de programme plutôt à la fin? Comment
            intégrer du travail de pied sur la course sur route? Renforcer les
            stabilisateurs de la cheville pour le trail ?
          </Trans>
        </p>
        <p>
          <Trans>
            The <b>Race-ready planification</b> plan from <RenfoRun /> allows
            you to have an
            <b>optimal</b> plan for your playground. It can be optimized for
            your stride with the PREMIUM version of your pack or subscription.
            The PREMIUM access includes a<b>video analysis</b> so that the focus
            is made on the area of improvement of your stride that will make you
            improve the most.
          </Trans>
        </p>
        <p>
          <Trans>
            It is the ideal ally to boost the efficiency of your preparation, to
            arrive in <b>perfect condition</b> on D-day to{" "}
            <b>outperform your expectations</b>.
          </Trans>
        </p>
      </>
    ),
    "per-unit": (
      <>
        <p>
          <Trans>
            You can get just the race-ready plan, without a subscription. Your
            strength training plan will be 10 to 16 weeks long depending on the
            date of your race. You can choose a CLASSIC version, or a
            personalized PREMIUM. PREMIUM includes a video analysis to adapt
            your planification to your stride.
          </Trans>
        </p>

        <StyledButton
          ambiant="true"
          uppercase={1}
          wide="true"
          style={{ paddingRight: "0.1em", paddingLeft: "0.1em" }}
          data-testid={`goto-select-race`}
          onClick={() => navigate("/race")}
        >
          <Trans>Get ready for a race</Trans>
        </StyledButton>
      </>
    ),
    "with-subscription": (
      <>
        <p>
          <Trans>
            When you have a running subscription, the race-ready plan is
            included in your CLASSIC or PREMIUM subcription.
          </Trans>
        </p>

        <div>
          {!recoilHasSubscription ? (
            <Suspense fallback={<Loading />}>
              <CTASubscribe />
            </Suspense>
          ) : (
            <StyledButton
              ambiant="true"
              uppercase={1}
              wide="true"
              style={{ paddingRight: "0.1em", paddingLeft: "0.1em" }}
              data-testid={`goto-select-race`}
              onClick={() => navigate("/race")}
            >
              <Trans>Get ready for a race</Trans>
            </StyledButton>
          )}
        </div>
      </>
    ),

    "beginner": (
      <Trans>
        <p>
          Il est difficile de se mettre au renforcement musculaire, malgré
          toutes les informations que l’on trouve sur internet. Tu sais que ça
          aide à progresser, mais tu ne sais pas quels exercices faire, et à
          quelle fréquence.
        </p>
        <p>Du coup, tu ne t’y es jamais vraiment mis…</p>
        <p>
          Si tu n’as jamais fait de renfo, le niveau <b>débutant</b> de{" "}
          <RenfoRun /> t’aide à démarrer avec des exercices simples.
        </p>
      </Trans>
    ),
    "intermediate": (
      <Trans>
        <p>
          Tu fais déjà du renforcement musculaire, tu as l’habitude du travail
          de gainage, tu as déjà fait des squats, des fentes et des abdos.
        </p>
        <p>
          Le niveau <b>intermédiaire</b> inclut dans tes séances,{" "}
          <b>des compétences spécifiques</b> à la course comme le travail de
          pied et le travail de stabilité. Il te proposera des séances variées
          et stimulantes pour <b>accélérer tes progrès</b> en course à pied.
        </p>
      </Trans>
    ),
    "advanced": (
      <Trans>
        <p>
          Tu fais du renforcement musculaire sous forme de circuit training, tu
          fais même peut-être des spartans races. Tu as déjà de bonnes qualités
          musculaires, et de résistance.
        </p>
        <p>Les burpees, fentes sautées et les pompes sont ta tasse de thé.</p>
        <p>
          Le niveau <b>avancé</b> te challengera et t’aidera à <b>transférer</b>{" "}
          tes qualités athlétiques à la course à pied.
        </p>
      </Trans>
    ),

    "video-analysis": (
      <Trans>
        <p>
          Pour maximiser tes résultats, <RenfoRun /> te permet d’avoir des
          séances de renfo
          <b>adaptées à ta foulée.</b>
        </p>{" "}
        C’est simple, il te suffit de télécharger directement dans l'application{" "}
        une vidéo de ta foulée en respectant bien les instructions. Un de nos
        <b> coachs</b> analyse ta foulée et en fonction des axes d’amélioration
        identifiés, configure ton profil. Tous les plans intégreront alors des{" "}
        <b>exercices spécifiques</b> afin de rendre ta foulée plus{" "}
        <b>efficace</b>.
      </Trans>
    ),
  }
  const navigateNext = () => {
    if (helpItemName !== "race-ready") {
      navigate("/select-program/confirm")
    }

    closeDrawer()
  }

  let levelConfirmLabel = {
    beginner: t`I'm a beginner`,
    intermediate: t`I'm intermediate`,
    advanced: t`I'm a ninja`,
  }

  return (
    <div data-testid={`help-${helpItemName}`}>
      <StyledHeader>{helpItemName && i18n._(helpItemName)}</StyledHeader>
      <Segment basic>{help[helpItemName]}</Segment>
      {["on-ramp", "development", "daily", "race-ready"].includes(
        helpItemName
      ) &&
        canSelectPlanification &&
        helpItemName !== "race-ready" && (
          <div style={{ textAlign: "center" }}>
            <StyledButton ambiant="true" onClick={navigateNext}>
              <Trans>Choose this plan</Trans>
            </StyledButton>
          </div>
        )}

      {["beginner", "intermediate", "advanced"].includes(helpItemName) && (
        <div style={{ textAlign: "center" }}>
          <StyledButton
            secondary="true"
            onClick={navigateNext}
            data-testid="drawer-button"
          >
            {levelConfirmLabel[helpItemName]}
          </StyledButton>
        </div>
      )}
    </div>
  )
}

export default SelectorHelp
