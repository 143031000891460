import { useState } from "react"
import { useRecoilValue, useSetRecoilState } from "recoil"
import {
  preferences,
  authHeaders,
  serverURL,
  forcePreferencesUpdate,
  forcePlanificationUpdate,
  forceAnalysisUpdate,
  forcePlanificationGetUpdate,
  forceSubscriptionUpdate,
  forcePlanificationWeekUpdate,
  forceStatsUpdate,
  forceBookmarksUpdate,
} from "../recoil/Atoms"
import { t } from "@lingui/macro"

export const useSetPreferences = () => {
  const [, setError] = useState()
  const recoilHeaders = useRecoilValue(authHeaders)
  const recoilUserPreferences = useRecoilValue(preferences)
  const recoilForcePreferencesUpdate = useSetRecoilState(forcePreferencesUpdate)
  const recoilForceSubscriptionUpdate = useSetRecoilState(
    forceSubscriptionUpdate
  )
  const recoilForcePlanificationWeekUpdate = useSetRecoilState(
    forcePlanificationWeekUpdate
  )
  const recoilForcePlanificationGetUpdate = useSetRecoilState(
    forcePlanificationGetUpdate
  )
  const setUserPreferences = async (newValueObject) => {
    let body = JSON.stringify({ ...recoilUserPreferences, ...newValueObject })

    let data = await fetch(`${serverURL}/user-preferences`, {
      method: "put",
      headers: {
        ...recoilHeaders,
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
      body,
    }).then(
      (res) => res.json(),
      (error) => {
        if (error) {
          setError(() => {
            throw new Error(t`Could not set preferences`)
          })
        }
      }
    )
    recoilForcePreferencesUpdate((prev) => prev + 1)
    recoilForceSubscriptionUpdate((prev) => prev + 1)
    recoilForcePlanificationWeekUpdate((prev) => prev + 1)
    recoilForcePlanificationGetUpdate((prev) => prev + 1)

    return data
  }
  return setUserPreferences
}

export const useSetPlanification = () => {
  const recoilHeaders = useRecoilValue(authHeaders)
  const [, setError] = useState()
  const recoilForcePlanificationUpdate = useSetRecoilState(
    forcePlanificationUpdate
  )
  const recoilForcePlanificationWeekUpdate = useSetRecoilState(
    forcePlanificationWeekUpdate
  )
  const recoilForcePlanificationGetUpdate = useSetRecoilState(
    forcePlanificationGetUpdate
  )
  const setUserPlanification = async (newPlanification) => {
    let subscriptionData = {
      planification: newPlanification.id,
      raceDate: newPlanification.raceDate?.toDate(),
      raceName: newPlanification?.raceName,
    }

    if (newPlanification?.name.includes("on-ramp")) {
      subscriptionData = { ...subscriptionData, freeDurationWeeks: 2 }
      //console.log("set free")
    } else {
      //console.log("no free")
    }

    let body = JSON.stringify(subscriptionData)

    let data = await fetch(`${serverURL}/user-planifications`, {
      method: "put",
      headers: {
        ...recoilHeaders,
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
      body,
    }).then(
      (res) => res.json(),
      (error) => {
        if (error) {
          setError(() => {
            throw new Error(t`Could not set your planification`)
          })
        }
      }
    )

    recoilForcePlanificationWeekUpdate((prev) => prev + 1)
    recoilForcePlanificationGetUpdate((prev) => prev + 1)
    recoilForcePlanificationUpdate((prev) => prev + 1)
    return data
  }

  return setUserPlanification
}
export const useResetPlanification = () => {
  const recoilHeaders = useRecoilValue(authHeaders)
  const [, setError] = useState()
  const recoilForcePlanificationUpdate = useSetRecoilState(
    forcePlanificationUpdate
  )
  const recoilForcePlanificationWeekUpdate = useSetRecoilState(
    forcePlanificationWeekUpdate
  )
  const recoilForcePlanificationGetUpdate = useSetRecoilState(
    forcePlanificationGetUpdate
  )
  const resetUserPlanification = async (newPlanification) => {
    let data = await fetch(`${serverURL}/user-planifications/reset-all`, {
      method: "get",
      headers: {
        ...recoilHeaders,
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
    }).then(
      (res) => res.json(),
      (error) => {
        if (error) {
          setError(() => {
            throw new Error(t`Could not reset your current planification`)
          })
        }
      }
    )

    recoilForcePlanificationWeekUpdate((prev) => prev + 1)
    recoilForcePlanificationGetUpdate((prev) => prev + 1)
    recoilForcePlanificationUpdate((prev) => prev + 1)
    return data
  }

  return resetUserPlanification
}

export const useAnalysisViewed = () => {
  const recoilHeaders = useRecoilValue(authHeaders)
  const recoilForceAnalysisUpdate = useSetRecoilState(forceAnalysisUpdate)
  const setAnalysisViewed = async (recoilAnalysisId) => {
    let body = JSON.stringify({})

    let data = await fetch(
      `${serverURL}/analyses/set-viewed/${recoilAnalysisId}?`,
      {
        method: "put",
        headers: {
          ...recoilHeaders,
          "Accept": "application/json",
          "Content-Type": "application/json",
        },
        body,
      }
    ).then((res) => res.json())
    recoilForceAnalysisUpdate((prev) => prev + 1)
    return data
  }

  return setAnalysisViewed
}

export const useUpdateProfile = () => {
  const [, setError] = useState()
  const recoilHeaders = useRecoilValue(authHeaders)

  const updatedProfile = async (userId, body) => {
    body = JSON.stringify(body)

    let data = await fetch(`${serverURL}/profiles/${userId}`, {
      method: "put",
      headers: {
        ...recoilHeaders,
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
      body,
    }).then(
      (res) => {
        if (res.ok) return res.json()
        setError(() => {
          throw new Error(t`Could not update profile`)
        })
      },
      (error) => {
        if (error) {
          setError(() => {
            throw new Error(t`Could not update profile`)
          })
        }
      }
    )

    return data
  }
  return updatedProfile
}

export const useSetBookmarks = () => {
  const [, setError] = useState()
  const recoilHeaders = useRecoilValue(authHeaders)
  const recoilForceBookmarksUpdate = useSetRecoilState(forceBookmarksUpdate)

  const setUserBookmarks = async (newValueObject) => {
    let body = JSON.stringify(newValueObject)

    let data = await fetch(`${serverURL}/bookmarks/`, {
      method: "put",
      headers: {
        ...recoilHeaders,
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
      body,
    }).then(
      (res) => res.json(),
      (error) => {
        if (error) {
          setError(() => {
            throw new Error(t`Could not set bookmark`)
          })
        }
      }
    )
    recoilForceBookmarksUpdate((prev) => prev + 1)

    return data
  }
  return setUserBookmarks
}

export const useLogSession = () => {
  const recoilHeaders = useRecoilValue(authHeaders)
  const recoilForcePlanificationGetUpdate = useSetRecoilState(
    forcePlanificationGetUpdate
  )
  const recoilForcePlanificationWeekUpdate = useSetRecoilState(
    forcePlanificationWeekUpdate
  )
  const recoilForceStatsUpdate = useSetRecoilState(forceStatsUpdate)

  const logSession = async (body) => {
    body = JSON.stringify(body)
    let data = await fetch(`${serverURL}/session-logs/`, {
      method: "post",
      headers: {
        ...recoilHeaders,
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
      body,
    }).then((res) => res.json())
    recoilForcePlanificationGetUpdate((prev) => prev + 1)
    recoilForcePlanificationWeekUpdate((prev) => prev + 1)
    recoilForceStatsUpdate((prev) => prev + 1)
    return data
  }

  return logSession
}
