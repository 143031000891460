// imports

import PropTypes from "prop-types"
import Amrap from "./Block/Amrap"
import Tabata from "./Block/Tabata"
import Rest from "./Block/Rest"
import RoundsForReps from "./Block/RoundsForReps"
import RoundsForTime from "./Block/RoundsForTime"
import Accessory from "./Block/Accessory"
import Warmup from "./Block/Warmup"

const Block = ({ block, index, partsCount }) => {
  const props = { block, index, partsCount }

  const displays = {
    "rest": <Rest {...props} />,
    "tabata": <Tabata {...props} />,
    "amrap": <Amrap {...props} />,
    "rounds for reps": <RoundsForReps {...props} />,
    "accessory": <Accessory {...props} />,
    "warmup": <Warmup {...props} />,
    "rounds for time": <RoundsForTime {...props} />,
  }

  return <>{displays[block.format.name]}</>
}

Block.propTypes = {
  floated: PropTypes.string,
}
export default Block
