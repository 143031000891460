// imports

import { t, Trans } from "@lingui/macro"
import { i18n } from "@lingui/core"
import { useNavigate } from "react-router-dom"
import { useRecoilValue } from "recoil"
import { Container, Grid, Image, List, Segment } from "semantic-ui-react"
import { useLogout } from "../../hooks/AuthHooks"

import { useEmail, useFullname } from "../../hooks/useUserInfo"
import { priorityTitle } from "../../locales/dynamicKeys"
import {
  analysisFocus,
  hasSubscription,
  planificationRaceDateLocalized,
  published,
  social,
  stripeId,
  subscriptionEndate,
  subscriptionFrequency,
  subscriptionName,
  userPlanificationName,
} from "../../recoil/Atoms"
import { upperFirst } from "../../utils"
import { StyledHeader, StyledList, StyledSegment } from "../Styled"
import CTACustomize from "../shared/CTACustomize"
import { Fragment, useState } from "react"
import useStripe from "../../hooks/useStripe"
import { version } from "../../version"
import Loading from "../App/Loading"
import CTASubscribe from "../shared/CTASubscribe"
import useDrawer from "../../hooks/useDrawer"
import qrcode from "../../images/qr-code.png"
import { Mixpanel } from "../../utils/mixpanel"
import ShareIcon from "./Share/ShareIcon"

const SettingsPage = () => {
  const fullname = useFullname()
  const email = useEmail()

  const navigate = useNavigate()
  const logout = useLogout()
  const recoilStripeId = useRecoilValue(stripeId)

  const recoilSubscriptionEndDate = useRecoilValue(subscriptionEndate)
  const recoilSubscriptionName = useRecoilValue(subscriptionName)
  const recoilHasSubscription = useRecoilValue(hasSubscription)
  const recoilSubscriptionFrequency = useRecoilValue(subscriptionFrequency)

  const recoilPublished = useRecoilValue(published)
  const recoilAnalysisFocus = useRecoilValue(analysisFocus)
  const recoilUserPlanificationName = useRecoilValue(userPlanificationName)
  const recoilPlanificationRaceDateLocalized = useRecoilValue(
    planificationRaceDateLocalized
  )

  const { navigateToPortal } = useStripe()
  const [portalClicked, setPortalClicked] = useState(false)
  const [invoicesClicked, setInvoicesClicked] = useState(false)
  const { setContentAndOpen } = useDrawer()

  const isPackUser = recoilSubscriptionFrequency === "onetime"

  let applicationSettings = [
    {
      icon: "running",
      link: "/select-program/planification",
      label: (
        <>
          <div data-testid={recoilUserPlanificationName?.name}>
            {`${t`Current:`} ${
              recoilUserPlanificationName?.name === "race-ready"
                ? `${recoilUserPlanificationName?.raceName}`
                : recoilUserPlanificationName?.name
                ? upperFirst(
                    recoilUserPlanificationName?.name &&
                      i18n._(recoilUserPlanificationName?.name)
                  )
                : t`No current planification`
            }`}
          </div>
          <div style={{ fontSize: "0.9rem" }}>
            {`${
              recoilUserPlanificationName?.name === "race-ready"
                ? `${recoilPlanificationRaceDateLocalized}`
                : ""
            }`}
          </div>
        </>
      ),
      testid: recoilUserPlanificationName?.name
        ? `planification`
        : `no-planification`,
    },

    {
      icon: recoilAnalysisFocus ? "crosshairs" : null,
      label: (
        <>
          {recoilAnalysisFocus ? (
            <>
              <Trans> Areas of improvement:</Trans>
              {priorityTitle[recoilAnalysisFocus] &&
                i18n._(priorityTitle[recoilAnalysisFocus])}
            </>
          ) : (
            <CTACustomize titleOverride={t`Adjust to my stride`} />
          )}
        </>
      ),
      textAlign: recoilAnalysisFocus ? "left" : "center",
      hide:
        !!recoilAnalysisFocus ||
        (isPackUser && recoilSubscriptionName === "classic"),
      testid: recoilAnalysisFocus ? `focus` : `no-focus`,
      onClick: () => {
        return false
      },
    },
    {
      icon: "eye outline",
      label: `${t`Last analysis :`} ${recoilPublished}`,
      hide: !recoilPublished ? true : false,
    },
  ]

  let subscriptionSettings = [
    {
      icon: `star${!recoilHasSubscription ? " outline" : ""}${
        recoilSubscriptionName === "classic" ? " half" : ""
      }`,
      // link: "/subscribe",
      label: `${upperFirst(recoilSubscriptionName)} ${
        recoilSubscriptionEndDate
          ? `${t`until`} ${recoilSubscriptionEndDate}`
          : ""
      } `,
      // hide: recoilSubscriptionName === "free",
      testid: `subscription-${recoilSubscriptionName}`,
    },

    {
      icon: "credit card outline",
      label: portalClicked ? (
        <Loading textAlign="left" />
      ) : (
        `${t`Manage my subscription`}`
      ),
      hide: recoilSubscriptionName === "free" || !recoilStripeId || isPackUser,
      onClick: async () => {
        setPortalClicked(true)
        await navigateToPortal()
        setPortalClicked(false)
      },
      forceLinkDisplay: true,
    },
    {
      icon: "list",
      label: invoicesClicked ? (
        <Loading textAlign="left" />
      ) : (
        `${t`My invoices`}`
      ),
      hide: recoilSubscriptionName === "free" || !recoilStripeId,
      onClick: async () => {
        setInvoicesClicked(true)
        await navigateToPortal({ type: "invoices" })
        setInvoicesClicked(false)
      },
      forceLinkDisplay: true,
    },
    {
      icon: null,
      label: <CTASubscribe />,
      textAlign: "center",
      hide: recoilHasSubscription || isPackUser,
      //   onClick: () => navigate("/subscribe"),
    },
    {
      icon: null,
      label: <CTASubscribe />,
      textAlign: "center",

      hide:
        !(recoilHasSubscription && recoilSubscriptionName === "classic") ||
        isPackUser,
    },
  ]

  let userSettings = [
    {
      icon: "user",
      link: "/user",
      label: t`Personal data`,
    },
    {
      icon: "lock",
      link: "/password",
      label: t`Change password`,
    },
    {
      icon: "sign out alternate",
      //link: "/logout",
      onClick: logout,
      label: t`Log out`,
    },
    /*
	{
		icon: "user shield icon",
		link: "/personal-data",
		label: t`Delete my account`,
	  },*/
  ]

  return (
    <Container className="page settings">
      <StyledHeader className={"page-title"}>
        <Trans>Settings</Trans>
      </StyledHeader>

      <StyledSegment className="no-horizontal-padding">
        <StyledHeader className="section-title">
          <Trans>Personalisation & planification</Trans>
        </StyledHeader>
        <StyledList
          inverted
          size="large"
          style={{ textAlign: "left", marginTop: "0.5em" }}
          className="very-relaxed"
        >
          {applicationSettings.map(
            (
              { icon, link, onClick, label, hide, testid, textAlign = "left" },
              index
            ) =>
              hide ? (
                <Fragment key={index}></Fragment>
              ) : (
                <List.Item
                  key={index}
                  onClick={() =>
                    link ? navigate(link) : onClick ? onClick() : null
                  }
                  data-testid={testid}
                >
                  {link && (
                    <List.Content floated="right">
                      <List.Icon name="chevron right" />
                    </List.Content>
                  )}
                  {icon && <List.Icon className={icon}></List.Icon>}
                  <List.Content style={{ whiteSpace: "pre", textAlign }}>
                    {label}
                  </List.Content>
                </List.Item>
              )
          )}
        </StyledList>
      </StyledSegment>

      <StyledSegment className="no-horizontal-padding">
        <StyledHeader className="section-title">
          <Trans>Subscription</Trans>
        </StyledHeader>
        <StyledList
          inverted
          size="large"
          style={{ textAlign: "left", marginTop: "0.5em" }}
          className="very-relaxed"
        >
          {subscriptionSettings.map(
            (
              {
                icon,
                link,
                onClick,
                label,
                className,
                hide,
                testid,
                forceLinkDisplay,
                textAlign = "left",
              },
              index
            ) =>
              hide ? (
                <Fragment key={index}></Fragment>
              ) : (
                <List.Item
                  key={index}
                  onClick={() =>
                    link ? navigate(link) : onClick ? onClick() : null
                  }
                  className={className ? className : ""}
                  data-testid={testid}
                >
                  {(link || forceLinkDisplay) && (
                    <List.Content floated="right">
                      <List.Icon name="chevron right" />
                    </List.Content>
                  )}
                  {icon && <List.Icon className={icon}></List.Icon>}
                  <List.Content style={{ whiteSpace: "pre", textAlign }}>
                    {label}
                  </List.Content>
                </List.Item>
              )
          )}
        </StyledList>
      </StyledSegment>
      <StyledSegment className="no-horizontal-padding">
        <Grid>
          <Grid.Row columns={2}>
            <Grid.Column>
              <StyledHeader className="section-title">
                <Trans>Social</Trans>
              </StyledHeader>

              <StyledList
                inverted
                size="large"
                style={{ textAlign: "left", marginTop: "0.5em" }}
                className="very-relaxed"
              >
                <List.Item data-testid="social">
                  {social.map((linkInfos) => (
                    <ShareIcon key={linkInfos.url} {...linkInfos} />
                  ))}
                </List.Item>
              </StyledList>
            </Grid.Column>
            <Grid.Column>
              <StyledHeader className="section-title">
                <Trans>Share</Trans>
              </StyledHeader>

              <StyledList
                inverted
                size="large"
                style={{ textAlign: "left", marginTop: "0.5em" }}
                className="very-relaxed"
              >
                <List.Item data-testid="share">
                  <ShareIcon
                    icon="qrcode"
                    label="qrcode"
                    onClick={() =>
                      setContentAndOpen(
                        <div data-testid={`qrcode`}>
                          {Mixpanel.track("QRcode displayed")}

                          <StyledHeader>
                            <Trans>Share RenfoRun with a friend !</Trans>
                          </StyledHeader>
                          <Segment basic>
                            <Image src={qrcode} size="large" />
                          </Segment>
                        </div>
                      )
                    }
                  />
                  {window.navigator.share ? (
                    <ShareIcon
                      icon="share alternate"
                      label="qrcode alternate"
                      onClick={async () => {
                        await navigator.share({
                          url: "https://renforun.app?&src=share",
                          title: "RenfoRun",
                          text: t`RenfoRun, the strength app for runners`,
                        })
                        Mixpanel.track("Shared Button Clicked")
                      }}
                    />
                  ) : (
                    <ShareIcon icon="share alternate disabled" />
                  )}
                </List.Item>
              </StyledList>
            </Grid.Column>{" "}
          </Grid.Row>
        </Grid>
        <StyledList
          inverted
          size="large"
          style={{ textAlign: "left", marginTop: "0.5em" }}
          className="very-relaxed"
        >
          {" "}
          <List.Item
            onClick={() =>
              (window.location.href = `mailto:contact@renforun.com?subject=${t`How can we help you?`}`)
            }
            data-testid={"mailto"}
          >
            <List.Content floated="right">
              <List.Icon name="chevron right" />
            </List.Content>
            {<List.Icon className={"mail"}></List.Icon>}
            <List.Content>
              <Trans>Contact us</Trans>
            </List.Content>
          </List.Item>
        </StyledList>
      </StyledSegment>
      <StyledSegment className="no-horizontal-padding">
        <StyledHeader className="section-title">
          {fullname} - {email}
        </StyledHeader>
        <StyledList
          inverted
          size="large"
          style={{ textAlign: "left", marginTop: "0.5em" }}
          className="very-relaxed"
        >
          {userSettings.map(({ icon, link, onClick, label, testid }, index) => (
            <List.Item
              key={index}
              onClick={() =>
                link ? navigate(link) : onClick ? onClick() : null
              }
              data-testid={testid}
            >
              {link && (
                <List.Content floated="right">
                  <List.Icon name="chevron right" />
                </List.Content>
              )}
              {icon && <List.Icon className={icon}></List.Icon>}
              <List.Content>{label}</List.Content>
            </List.Item>
          ))}
        </StyledList>
      </StyledSegment>

      <div style={{ opacity: 0.5, fontSize: "0.8em" }}>{version}</div>
    </Container>
  )
}

export default SettingsPage
